namespace AppKitchen {
    export module Data {
        export module Export {

            // ReSharper disable once InconsistentNaming
            export interface ExcelExportColumnDefinition {
                field: string;
                header: string;
                width?: number;
                format?: string;
                align?: string;
                styles?: {
                    background?: string;
                    foreground?: string;
                    headerForeground?: string;
                    headerBackground?: string;
                    headerBorder?: kendo.ui.SpreadsheetSheetRowCellBorderBottom;
                };
                parse?: (value: any) => any;
            }

            // ReSharper disable once InconsistentNaming
            export interface CsvExportColumnDefinition {
                field: string;
                header?: string;
                format?: string;
            }

            // ReSharper disable once InconsistentNaming
            export interface CsvExportOptions {
                showHeader?: boolean;
                columnDelimiter?: string;
                rowDelimiter?: string;
                culture?: string;
                useSaveFileProxy?: boolean;
                mimeType?: string;
                charset?: string;
            }

            export function saveAsExcel(data: any[],
                columns: ExcelExportColumnDefinition[],
                sheetTitle: string,
                filename: string) {
                if (!data || data.length === 0) {
                    return;
                }

                if (!columns || columns.length === 0) {
                    throw "No fields for excel export defined!";
                }

                var sheetColumns = [];
                var rows = [{ cells: [] }];

                columns.forEach(column => {
                    sheetColumns.push({
                        autoWidth: column.width ? false : true,
                        width: column.width
                    });
                    rows[0].cells.push({
                        value: column.header,
                        background: (column.styles ? column.styles.headerBackground : undefined) || "#3E3E3E",
                        color: (column.styles ? column.styles.headerForeground : undefined) || "#FFFFFF",
                        fontSize: 12,
                        bold: true,
                        borderBottom: (column.styles ? column.styles.headerBorder : undefined) ||
                            { color: "#EF8200", size: 3 }
                    });
                });

                data.forEach(data => {
                    var row = { cells: [] };

                    columns.forEach(field => row.cells.push({
                        value: field.parse ? field.parse(data[field.field]) : data[field.field],
                        format: field.format,
                        textAlign: field.align || "left",
                        background: field.styles ? field.styles.background : undefined,
                        color: field.styles ? field.styles.foreground : undefined
                    }));

                    rows.push(row);
                });

                var workbook = new kendo.ooxml.Workbook({
                    sheets: [
                        {
                            columns: sheetColumns,
                            title: sheetTitle,
                            rows: rows
                        }
                    ]
                });

                kendo.saveAs({ dataURI: workbook.toDataURL(), fileName: filename + ".xlsx" });
            }

            export function saveAsCsv(data: any[],
                columns: CsvExportColumnDefinition[],
                filename: string,
                options?: CsvExportOptions) {
                if (!data || data.length === 0) {
                    return;
                }
                options = AppKitchen.OptionsHelper.merge<CsvExportOptions>(options,
                {
                    showHeader: true,
                    columnDelimiter: ";",
                    rowDelimiter: "\r\n",
                    culture: AppKitchen.GlobalSettings.culture,
                    useSaveFileProxy: true,
                    mimeType: "text/plain",
                    charset: null
                });

                var lines: string[] = [];

                if (options.showHeader) {
                    var values: string[] = [];
                    columns.forEach(column => {
                        values.push(column.header || column.field);
                    });
                    lines.push(values.join(options.columnDelimiter));
                }

                data.forEach(row => {
                    var values: string[] = [];
                    columns.forEach(column => {
                        var value = row[column.field];
                        var stringValue: string;
                        if (value instanceof Date) {
                            stringValue = kendo.toString(value, column.format || "g", options.culture);
                        } else if (typeof value === "number") {
                            stringValue = kendo.toString(value, "0.################", options.culture);
                        } else {
                            stringValue = value;
                        }
                        values.push(stringValue);
                    });
                    lines.push(values.join(options.columnDelimiter));
                });

                var text = lines.join(options.rowDelimiter);

                let dataUri = "data:" + options.mimeType;
                if (options.charset != null) {
                    dataUri += ";charset=" + options.charset;
                }
                dataUri += ";base64," + btoa(text);

                if (dataUri.length > 1000000 && !options.useSaveFileProxy) {
                    console.log("please use a proxy for large files, otherwise errors may occur");
                }

                var proxyUrl = options.useSaveFileProxy ? Data.getDataApiUrl("SaveFile") : null;
                kendo.saveAs({ dataURI: dataUri, fileName: filename + ".csv", proxyURL: proxyUrl, forceProxy: options.useSaveFileProxy });
            }
        }
    }
}
