namespace AppKitchen {

    // ReSharper disable once InconsistentNaming
    export module UIHelper {

        export function renderTemplateTo(container: HTMLElement, template: string, content?: any): JQuery {
            if (content) {
                $(container).html(_.template(template)(content));
            } else {
                $(container).html(template);
            }

            UIHelper.updateFullHeightGrids(container);

            return $(container);
        }

        export function appendTemplateTo(container: HTMLElement, template: string, content?: any): JQuery {
            if (content) {
                $(container).append(_.template(template)(content));
            } else {
                $(container).append(template);
            }

            UIHelper.updateFullHeightGrids(container);

            return $(container);
        }

        export function renderTemplate(template: string, content: any): string {
            return _.template(template)(content);
        }

        export function clearSelection() {
            if ((<any>document).selection) {
                (<any>document).selection.empty();
            } else if (window.getSelection) {
                window.getSelection().removeAllRanges();
            }
        }

        export function updateFullHeightGrids(container?: HTMLElement) {
            var target = $("body");
            if (container) {
                target = $(container).parent();
            }
            target.find(".full-height-grid").each((i, grid) => {
                // set grid height to available height inside parent (excluding padding)
                $(grid).css("height", "");
                var availableHeight = $(grid).parent().height();
                $(grid).outerHeight(availableHeight);
                $(grid).trigger("fill-height");

                // get available height inside grid (exclude padding)
                availableHeight = $(grid).height();
                // substract heights of all other children (include margins)
                $(grid).children().not(".fill-height").each((j, row) => {
                    if ($(row).is(":visible") && $(row).css("position") !== "absolute") {
                        availableHeight -= $(row).outerHeight(true);
                    }
                });

                // set heights of "fill-height" children to the remaining available height
                $(grid).find("> .fill-height").each((j, row) => {
                    $(row).outerHeight(availableHeight);
                    var actualNewHeight = $(row).outerHeight(true);
                    // hack to set element height including margin
                    if (actualNewHeight > availableHeight) {
                        $(row).outerHeight(availableHeight - (actualNewHeight - availableHeight));
                    }
                    $(row).trigger("fill-height");
                });
            });
        }

        BrowserHelper.WindowResize.on(() => UIHelper.updateFullHeightGrids());

        export type LoaderType = "mesap" | "circle" | "concentric";

        export type LoaderSize = "S" | "M" | "L" | "XL";

        export interface LoaderRenderOptions {
            size?: LoaderSize,
            fadeIn?: number,
            complete?: () => void,
            type?: LoaderType,
        }

        export interface LoaderRemoveOptions {
            fadeOut?: number,
            complete?: () => void,
        }

        export function renderLoader(target: HTMLElement, options?: LoaderRenderOptions) {
            var opt = OptionsHelper.merge<LoaderRenderOptions>(options, { size: "M", type: "concentric" });

            var template = AppKitchen.Templates.LoadingConcentricSpinner;
            if (opt.type) {
                if (opt.type === "concentric") {
                    template = AppKitchen.Templates.LoadingConcentricSpinner;
                } else if (opt.type === "circle") {
                    template = AppKitchen.Templates.LoadingCircleSpinner;
                } else if (opt.type === "mesap") {
                    template = AppKitchen.Templates.LoadingMesapSpinner;
                }
            }

            $(target).find("> .a-loading").remove();
            $(target).append(UIHelper.renderTemplate(template, { size: opt.size }));

            if (opt.fadeIn) {
                $(target).find("> .a-loading").fadeIn(opt.fadeIn, opt.complete);
            } else if (opt.complete) {
                opt.complete();
            }
        }

        export function removeLoader(target: HTMLElement, options?: LoaderRemoveOptions) {
            var opt = OptionsHelper.merge<LoaderRemoveOptions>(options, {});
            var animationContainer = $(target).find("> .a-loading-base");
            if (animationContainer.length > 0) {
                animationContainer.fadeOut(opt.fadeOut || 150,
                    () => {
                        animationContainer.remove();
                        if (opt.complete) {
                            opt.complete();
                        }
                    });
            } else if (opt.complete) {
                opt.complete();
            }
        }

        export function renderLoadingAnimation(container: HTMLElement,
            size: string,
            fadeIn?: number,
            complete?: () => void,
            loaderType?: LoaderType) {
            if (["S", "M", "L", "XL"].indexOf(size) === -1) {
                size = "M";
            }

            $(container).find("> .a-loading").remove();
            if (loaderType && loaderType === "concentric") {
                $(container)
                    .append(UIHelper.renderTemplate(AppKitchen.Templates.LoadingConcentricSpinner, { size: size }));
            } else if (loaderType && loaderType === "circle") {
                $(container).append(UIHelper.renderTemplate(AppKitchen.Templates.LoadingCircleSpinner, { size: size }));
            } else {
                $(container).append(UIHelper.renderTemplate(AppKitchen.Templates.LoadingMesapSpinner, { size: size }));
            }

            if (fadeIn) {
                $(container).find("> .a-loading").fadeIn(fadeIn, complete);
            } else if (complete) {
                complete();
            }
        }

        export function removeLoadingAnimation(container: HTMLElement, fadeOut?: number, complete?: () => void) {
            var animationContainer = $(container).find("> .a-loading-base");
            if (animationContainer.length > 0) {
                animationContainer.fadeOut(fadeOut || 150,
                    () => {
                        animationContainer.remove();
                        if (complete) {
                            complete();
                        }
                    });
            } else if (complete) {
                complete();
            }
        }

        export function hideTooltips(except?: kendo.ui.Tooltip) {
            $("[data-role=tooltip]").each((i, el) => {
                var tooltip = $(el).data("kendoTooltip");
                if (tooltip && tooltip !== except) {
                    tooltip.hide();
                }
            });
        }

        export function addTooltip(el: JQuery, position: string) {
            el.kendoTooltip({
                position: position,
                show: e => UIHelper.hideTooltips(e.sender)
            });
        }

        export function getHighestZIndex(): number {
            var maxZ = -1;
            $("*").each((i, e) => {
                var z = parseInt($(e).css("z-index"), 10);
                if (z && z > maxZ) {
                    maxZ = z;
                }
            });
            return maxZ;
        }

        export module Colors {

            // ReSharper disable once InconsistentNaming
            export interface RgbColor {
                r: number;
                g: number;
                b: number;
            }

            // ReSharper disable once InconsistentNaming
            export interface HslColor {
                h: number;
                s: number;
                l: number;
            }

            // ReSharper disable once InconsistentNaming
            export interface HsvColor {
                h: number;
                s: number;
                v: number;
            }

            export function parseColor(color: string): RgbColor {
                if (color && color.beginsWith("#")) {
                    return hex2Rgb(color);
                }

                if (color && color.beginsWith("rgb")) {
                    return parseRgbString(color);
                }

                throw "Invalid color string";
            }

            export function hex2Rgb(color: string): RgbColor {
                if (color.substr(0, 1) !== "#" || (color.length !== 7 && color.length !== 4))
                    throw "Invalid HEX color code";

                if (color.length === 4) {
                    color = extendShortHex(color);
                }

                var parser = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

                if (!parser || parser.length !== 4)
                    throw "Invalid HEX color code";

                return {
                    r: parseInt(parser[1], 16),
                    g: parseInt(parser[2], 16),
                    b: parseInt(parser[3], 16)
                }
            }

            export function rgb2Hex(color: RgbColor): string {
                validateRgbComponent("R", color.r);
                validateRgbComponent("G", color.g);
                validateRgbComponent("B", color.b);
                return "#" + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b);
            }

            export function extendShortHex(color: string): string {
                var parser = /^#([a-f\d])([a-f\d])([a-f\d])$/i.exec(color);
                if (!parser || parser.length !== 4) {
                    throw "Invalid short HEX color code";
                }
                return "#" + parser[1] + parser[1] + parser[2] + parser[2] + parser[3] + parser[3];
            }

            export function lightenRgb(color: RgbColor, amount: number): RgbColor {
                return {
                    r: Math.floor(color.r + (255 - color.r) * amount),
                    g: Math.floor(color.g + (255 - color.g) * amount),
                    b: Math.floor(color.b + (255 - color.b) * amount)
                }
            }

            export function darkenRgb(color: RgbColor, amount: number): RgbColor {
                return {
                    r: Math.floor(color.r * (1 - amount)),
                    g: Math.floor(color.g * (1 - amount)),
                    b: Math.floor(color.b * (1 - amount))
                }
            }

            export function rgb2rgba(color: RgbColor, a: number): string {
                return "rgba(" + color.r + "," + color.g + "," + color.b + "," + a + ")";
            }

            export function lightenHex(color: string, amount: number): string {
                return rgb2Hex(lightenRgb(hex2Rgb(color), amount));
            }

            export function darkenHex(color: string, amount: number): string {
                return rgb2Hex(darkenRgb(hex2Rgb(color), amount));
            }

            export function number2rgbaString(num: number): string {
                num >>>= 0;
                var b = num & 0xFF,
                    g = (num & 0xFF00) >>> 8,
                    r = (num & 0xFF0000) >>> 16,
                    a = ((num & 0xFF000000) >>> 24) / 255;
                return "rgba(" + [r, g, b, a].join(",") + ")";
            }

            function validateRgbComponent(component: string, value: number): number {
                if (value == null || value < 0 || value > 255) {
                    throw "Invalid " + component + " component";
                }

                return value;
            }

            function parseRgbString(color: string): RgbColor {
                color = color.trim();
                var parser = /^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/.exec(color);
                if (!parser || parser.length !== 4) {
                    throw "Invalid RGB string";
                }
                return {
                    r: validateRgbComponent("R", parseInt(parser[1])),
                    g: validateRgbComponent("G", parseInt(parser[2])),
                    b: validateRgbComponent("B", parseInt(parser[3]))
                }
            }

            function componentToHex(c) {
                var hex = c.toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            }

        }

        export module Scrolling {

            export function customScroller(targetElement: HTMLElement): JQuery {
                if ($(targetElement).hasClass("nano")) {
                    $(targetElement).nanoScroller({ destroy: true });
                    $(targetElement).removeClass("nano");
                    $(targetElement).children().removeClass("nano-content");
                }

                $(targetElement).addClass("nano");
                if ($(targetElement).children().length === 1) {
                    $(targetElement).children().addClass("nano-content");
                } else {
                    $('<div class="nano-content"></div>').appendTo(targetElement).append($(targetElement).children());
                }

                $(targetElement).nanoScroller({ disableResize: false });

                if ($(targetElement).hasClass("full-height-grid") || $(targetElement).hasClass("fill-height")) {
                    $(targetElement).on("fill-height",
                        () => {
                            $(targetElement).nanoScroller({ disableResize: true });
                        });
                }

                return $(targetElement).find(".nano-content");
            }

            export function synchronizeScrolling(scroller1: HTMLElement, scroller2: HTMLElement) {
                var $scroller1 = $(scroller1);
                var $scroller2 = $(scroller2);

                var scrolling1Timeout = null;
                var scrolling2Timeout = null;

                $scroller1.on('scroll',
                    () => {
                        if (!$scroller2.hasClass("scrolling")) {
                            $scroller1.addClass("scrolling");
                            $scroller2.scrollTo($scroller1.scrollTop());
                            clearTimeout(scrolling1Timeout);
                            scrolling1Timeout = setTimeout(() => $scroller1.removeClass("scrolling"), 300);
                        }
                    });

                $scroller2.on('scroll',
                    () => {
                        if ($scroller2.scrollTop() !== $scroller1.scrollTop()) {
                            if (!$scroller1.hasClass("scrolling")) {
                                $scroller2.addClass("scrolling");
                                $scroller1.scrollTo($scroller2.scrollTop());
                                clearTimeout(scrolling2Timeout);
                                scrolling2Timeout = setTimeout(() => $scroller2.removeClass("scrolling"), 300);
                            }
                        }
                    });
            }

            export function offsetToViewport(element: HTMLElement, scroller: HTMLElement, included?: boolean): number {
                var viewport = {
                    top: $(scroller).scrollTop(),
                    bottom: $(scroller).scrollTop() + $(scroller).height()
                };

                var elementBounds = {
                    top: element.offsetTop,
                    // ReSharper disable once DoubleNegationOfBoolean
                    bottom: element.offsetTop + $(element).outerHeight(!!included)
                }

                if (included) {
                    if (elementBounds.top >= viewport.top && elementBounds.bottom <= viewport.bottom) {
                        return 0;
                    }
                    if (elementBounds.top < viewport.top) {
                        return elementBounds.top - viewport.top;
                    }
                    return elementBounds.bottom - viewport.bottom;
                }

                if (elementBounds.top <= viewport.bottom && elementBounds.bottom >= viewport.top) {
                    return 0;
                }
                if (elementBounds.top > viewport.bottom) {
                    return elementBounds.top - viewport.bottom;
                }
                return elementBounds.bottom - viewport.top;
            }

            export function scrollIntoViewport(element: HTMLElement, scroller: HTMLElement, duration?: number) {
                var distance = Scrolling.offsetToViewport(element, scroller, true);
                if (distance !== 0) {
                    //$(scroller).animate({
                    //    scrollTop: $(scroller).scrollTop() + distance
                    //}, duration | 300);

                    $(scroller).scrollTo($(scroller).scrollTop() + distance,
                        {
                            axis: "y",
                            duration: duration | 300
                        });
                }
            }

        }

        export module Transitions {

            export function introduce(container: HTMLElement, duration: number, action: () => void) {
                $(container).css("opacity", 0);
                action();
                $(container).animate({ opacity: 1 }, duration);
            }

            export function swap(container: HTMLElement,
                fadeOutDuration: number,
                fadeInDuration: number,
                action: () => void,
                after?: () => void) {
                $(container).animate({ opacity: 0 },
                    fadeOutDuration,
                    () => {
                        action();
                        $(container).animate({ opacity: 1 },
                            fadeInDuration,
                            () => {
                                if (after) {
                                    after();
                                }
                            });
                    });
            }
        }

        export module Windows {

            // ReSharper disable once InconsistentNaming
            export interface WindowButton {
                name: string;
                icon?: string;
                action?: (window: kendo.ui.Window) => void;
            }

            // ReSharper disable once InconsistentNaming
            export interface WindowOptions {
                title?: string;
                buttons?: WindowButton[];
                width?: string;
                height?: string;
                minWidth?: string;
                maxWidth?: string;
                minHeight?: string;
                maxHeight?: string;
                position?: { top: string, left: string };
                easyClose?: boolean;
                modal?: boolean;
                resizable?: boolean;
                draggable?: boolean;
                center?: boolean;
                opened?: (window: kendo.ui.Window) => void;
                closed?: () => void;
                resized?: () => void;
                animate?: boolean;
                keepOnNavigate?: boolean;
                overflow?: boolean;
                windowCssClass?: string;
            }

            export function openWindow(content: HTMLElement, options?: WindowOptions) {
                options = OptionsHelper.merge<WindowOptions>(options,
                    {
                        title: undefined,
                        buttons: options && options.title != null ? [{ name: "close" }] : [],
                        width: "80%",
                        height: "80%",
                        maxWidth: "auto",
                        maxHeight: "auto",
                        minHeight: "auto",
                        minWidth: "auto",
                        position: undefined,
                        easyClose: false,
                        modal: false,
                        resizable: false,
                        draggable: options ? !options.modal : false,
                        center: false,
                        animate: true,
                        keepOnNavigate: false,
                        opened: () => {},
                        closed: () => {},
                        resized: () => {},
                        overflow: false
                    });

                var modalOverlay;
                var highestZ = UIHelper.getHighestZIndex();
                if (options.modal) {
                    modalOverlay = $('<div class="a-modal-overlay" style="display:none"></div>').appendTo($("body"));
                    modalOverlay.css("position", "absolute");
                    modalOverlay.css("z-index", highestZ + 1);
                    modalOverlay.fadeIn(options.animate ? 400 : 0);
                }

                var hasTitleBar = options.title || (options.buttons && options.buttons.length > 0);

                var popupWindow: kendo.ui.Window = $(content).kendoWindow({
                    title: hasTitleBar ? options.title || "" : false,
                    draggable: options.draggable,
                    modal: false,
                    scrollable: true,
                    resizable: options.resizable,
                    actions: [],
                    deactivate: () => {
                        if (options.closed) options.closed();
                        popupWindow.destroy();
                    },
                    close: () => {
                        if (options.modal) {
                            modalOverlay.fadeOut(options.animate ? 300 : 0, () => modalOverlay.remove());
                        }
                    },
                    visible: false,
                    animation: options.animate ? options.animate : false,
                    activate: e => {
                        if (options.opened) {
                            options.opened(popupWindow);
                        }
                        UIHelper.updateFullHeightGrids(e.sender.wrapper[0]);
                        // easyClose on overlay click (register after animation end)
                        if (options.easyClose) {
                            if (options.modal) {
                                modalOverlay.click(() => e.sender.close());
                            }
                        }
                    }
                }).data("kendoWindow");
                popupWindow.bind("close",
                    () => {
                        $("body").find(".a-main").removeClass("is-background");
                    });
                $("body").find(".a-main").addClass("is-background");

                // css properties
                popupWindow.wrapper.css("position", "absolute");
                popupWindow.wrapper.css("z-index", highestZ + 2);
                popupWindow.wrapper.css("width", options.width);
                popupWindow.wrapper.css("height", options.height);
                popupWindow.wrapper.css("min-width", options.minWidth);
                popupWindow.wrapper.css("max-width", options.maxWidth);
                popupWindow.wrapper.css("min-height", options.minHeight);
                popupWindow.wrapper.css("max-height", options.maxHeight);
                if (options.overflow) {
                    popupWindow.wrapper.find(".k-window-content").addClass("overflow");
                }
                if (options.position) {
                    popupWindow.wrapper.css("top", options.position.top);
                    popupWindow.wrapper.css("left", options.position.left);
                } else {
                    popupWindow.center();
                }

                // center
                if (options.center || (options.modal && !options.position)) {
                    popupWindow.center();
                    BrowserHelper.WindowResize.on(() => popupWindow.center(), popupWindow.wrapper[0]);
                }

                if (options.windowCssClass) {
                    popupWindow.wrapper.addClass(options.windowCssClass);
                }
                
                popupWindow.open();

                // easy close on ESC
                if (options.easyClose) {
                    popupWindow.wrapper.keydown((e) => {
                        if (e.which === 27) {
                            popupWindow.close();
                        }
                    });
                }

                // update full height grids & responsive on resize
                if (options.resizable) {
                    popupWindow.bind("resize",
                        () => {
                            Responsive.update();
                            UIHelper.updateFullHeightGrids(popupWindow.wrapper[0]);
                            if (options.resized) {
                                options.resized();
                            }
                        });
                }

                // close window on navigate
                if (!options.keepOnNavigate) {
                    $("body").on("navigate", () => popupWindow.close());
                }

                // add buttons
                if (options.buttons) {
                    var buttonsContainer = popupWindow.wrapper.find(".k-window-actions");
                    options.buttons.forEach(button => {

                        var $btn = $(UIHelper.renderTemplate(AppKitchen.Templates.WindowButton,
                            {
                                name: button.name,
                                icon: button.icon || button.name
                            })).prependTo(buttonsContainer);

                        if (button.action) {
                            $btn.click(button.action.bind(this, popupWindow));
                        } else {
                            // default actions
                            switch (button.name) {
                                case "close":
                                    $btn.click(() => popupWindow.close());
                                    break;
                                case "maximize":
                                    $btn.click(() => popupWindow.maximize());
                                    break;
                                case "minimize":
                                    $btn.click(() => popupWindow.minimize());
                                    break;
                                case "restore":
                                    $btn.click(() => popupWindow.restore());
                                    break;
                            }
                        }
                    });
                }

                return popupWindow;
            }
        }

        export module DialogBoxes {

            export type DialogBoxIcon = "ok" | "warning" | "fail" | "unknown";

            // ReSharper disable once InconsistentNaming
            export interface DialogBoxOptions {
                title?: string | boolean;
                icon?: DialogBoxIcon;
                cssClass?: string;
            }

            // ReSharper disable once InconsistentNaming
            export interface AlertDialogOptions extends DialogBoxOptions {
                label?: string;
                close?: () => void;
            }

            export function alert(content: string, dialogOptions?: AlertDialogOptions) {
                var options = OptionsHelper.merge<AlertDialogOptions>(dialogOptions,
                    {
                        title: false,
                        label: AppKitchen.Strings.Ok,
                        close: () => {},
                        icon: null
                    });

                $("body > .a-dialog-box").remove();
                $("body").append('<div class="a-dialog-box"></div>');
                var container = $("body > .a-dialog-box")[0];

                UIHelper.renderTemplateTo(container,
                    AppKitchen.Templates.DialogBoxAlert,
                    {
                        icon: options.icon,
                        title: options.title,
                        content: content,
                        ok: options.label
                    });

                var box = Windows.openWindow(container,
                    {
                        width: "30em",
                        height: "auto",
                        center: true,
                        easyClose: false,
                        modal: true,
                        animate: false,
                        buttons: [],
                        windowCssClass: options.cssClass
                    });

                $(container).find(".a-alert-btn-ok").click(() => {
                    box.close();
                    options.close();
                });
            }

            // ReSharper disable once InconsistentNaming
            export interface ConfirmDialogOptions extends DialogBoxOptions {
                strings?: { ok: string, cancel: string };
                cancel?: () => void;
            }

            export function confirm(content: string, confirm: () => void, dialogOptions?: ConfirmDialogOptions) {
                var options = OptionsHelper.merge<ConfirmDialogOptions>(dialogOptions,
                    {
                        title: false,
                        strings: { ok: AppKitchen.Strings.Ok, cancel: AppKitchen.Strings.Cancel },
                        cancel: () => {},
                        icon: null
                    });

                $("body > .a-dialog-box").remove();
                $("body").append('<div class="a-dialog-box"></div>');
                var container = $("body > .a-dialog-box")[0];

                UIHelper.renderTemplateTo(container,
                    AppKitchen.Templates.DialogBoxConfirm,
                    {
                        icon: options.icon,
                        title: options.title,
                        content: content,
                        ok: options.strings.ok,
                        cancel: options.strings.cancel
                    });

                var box = Windows.openWindow(container,
                    {
                        width: "30em",
                        height: "auto",
                        center: true,
                        easyClose: false,
                        modal: true,
                        animate: false,
                        buttons: [],
                        windowCssClass: options.cssClass
                    });

                $(container).find(".a-confirm-btn-ok").click(() => {
                    box.close();
                    confirm();
                });

                $(container).find(".a-confirm-btn-cancel").click(() => {
                    box.close();
                    options.cancel();
                });
            }

            export function yesNo(content: string, yes: () => void, no?: () => void, title?: string) {
                return DialogBoxes.confirm(content,
                    yes,
                    {
                        title: title || "",
                        strings: { ok: AppKitchen.Strings.Yes, cancel: AppKitchen.Strings.No },
                        cancel: () => { if (no) no(); }
                    });
            }

        }

        export module Notifications {
            var defaultNotificationStack: HTMLElement;

            export function notify(content: string,
                type: AppKitchen.Controls.Popups.NotificationType,
                options?: AppKitchen.Controls.Popups.NotificationOptions): AppKitchen.Controls.Popups.Notification {
                return new AppKitchen.Controls.Popups.Notification(content,
                    OptionsHelper.merge<AppKitchen.Controls.Popups.NotificationOptions>(options,
                        {
                            type: type,
                            stack: getNotificationStack()
                        }));
            }

            export function closeAll() {
                if (defaultNotificationStack) {
                    $(defaultNotificationStack).children().fadeOut(150,
                        () => {
                            $(defaultNotificationStack).empty();
                        });
                }
            }

            function getNotificationStack(): HTMLElement {
                // reuse existing notification stack
                if (!defaultNotificationStack) {
                    defaultNotificationStack = $(AppKitchen.Templates.DefaultNotificationStack).appendTo("body")[0];
                }

                // bring notification stack to front
                $(defaultNotificationStack).css("z-index", UIHelper.getHighestZIndex() + 1);

                return defaultNotificationStack;
            }
        }

        export module Responsive {
            var targets: { container: HTMLElement; minWidth: number; cssClass: string }[] = [];

            export function update() {
                if (targets) {
                    var lostTargets = [];
                    targets.forEach(target => {
                        if ($(target.container).closest("body").length === 0) {
                            lostTargets.push(target);
                        } else if ($(target.container).width() <= target.minWidth) {
                            $(target.container).addClass(target.cssClass);
                        } else {
                            $(target.container).removeClass(target.cssClass);
                        }
                    });

                    // cleanup targets
                    if (lostTargets.length > 0) {
                        lostTargets.forEach(target => {
                            var i = targets.indexOf(target);
                            targets.splice(i, 1);
                        });
                    }
                }
            }

            export function registerTarget(container: HTMLElement, minWidth: number, cssClass: string) {
                targets.push({
                    container: container,
                    minWidth: minWidth,
                    cssClass: cssClass
                });

                update();
            }

            BrowserHelper.WindowResize.on(() => Responsive.update());
        }
    }
}