namespace AppKitchen {
    export module Controls {
        export module TimeseriesDataViews {
            import ExportChartDataToExcelDefinition = AppKitchen.ExportHelper.ExportChartDataToExcelDefinition;

            // ReSharper disable once InconsistentNaming
            export interface TimeseriesDataViewOptions extends AppKitchen.ViewBaseOptions {
                chartOptions?: TimeseriesDataChartViewOptions;
                gridOptions?: TimeseriesDataSpreadsheetViewOptions;
                exportFilename?: string; //placeholders in {}: header, from, to
                enableDateSelection?: boolean;
                defaultStartDate?: Date;
                defaultEndDate?: Date;
                maxDateRangeDays?: number;
                title?: string;
                showExportButton: boolean; // used in template, therefore not optional
                showSaveButton: boolean;// used in template, therefore not optional
                onSave?: () => void;
            }

            export class TimeseriesDataView extends AppKitchen.ViewBase<TimeseriesDataViewModel> {
                options: TimeseriesDataViewOptions;

                private exportFilename: string;
                private containers: {
                    datePicker: HTMLElement,
                    title: HTMLElement,
                    chart: HTMLElement,
                    grid: HTMLElement,
                };
                private buttons: {
                    all: JQuery,
                    switchToChart: JQuery,
                    switchToGrid: JQuery,
                    excel: JQuery,
                    save: JQuery,
                };
                chartView: TimeseriesDataChartView;
                gridView: TimeseriesDataSpreadsheetBase;

                constructor(model: TimeseriesDataViewModel, targetContainer: HTMLElement, options?: TimeseriesDataViewOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge<TimeseriesDataViewOptions>(options, { 
                        exportFilename: "export",
                        enableDateSelection: true,
                        defaultStartDate: new Date().onlyDate().addDays(-30),
                        defaultEndDate: new Date().onlyDate().addDays(3),
                        maxDateRangeDays: 730,
                        showExportButton: true,
                        showSaveButton: false,
                        gridOptions: {
                            spreadsheetFormat: "asColumns"
                        }
                    }, true));

                    this.setTemplate(Templates.TimeseriesDataView);

                    this.render();
                }

                render() {
                    this.renderTemplate({
                        title: this.options.title,
                        showSaveButton: this.options.showSaveButton,
                        showExportButton: this.options.showExportButton
                    });
                    this.setContainers();
                    
                    if (this.options.enableDateSelection) {
                        this.createDatePicker();
                    }
                    this.createChartView();
                    this.createGridView();

                    AppKitchen.UIHelper.updateFullHeightGrids(this.el);

                    this.setEvents();

                    return this;
                }

                private createDatePicker() {
                    const datePickerModel = new Controls.DateRangePickerModel(this.options.defaultStartDate, this.options.defaultEndDate);
                    // ReSharper disable once WrongExpressionStatement
                    new Controls.DateRangePickerView(datePickerModel, this.containers.datePicker, {
                        labels: { from: Strings.TimeseriesDataView_DatePicker_From, to: Strings.TimeseriesDataView_DatePicker_To },
                        maxRange: this.options.maxDateRangeDays
                    });

                    datePickerModel.onRangeChange((start: Date, end: Date) => this.model.loader.loadData(start, end));
                    datePickerModel.routeRange("", this.options.defaultStartDate, this.options.defaultEndDate);
                }

                private createChartView() {
                    this.chartView = new TimeseriesDataChartView(this.model, this.containers.chart, this.options.chartOptions);
                }

                private createGridView() {
                    this.gridView = this.options.gridOptions.spreadsheetFormat === "asRows"
                        ? new RowBasedSpreadsheetView(this.model, this.containers.grid, this.options.gridOptions)
                        : new ColumnBasedSpreadsheetView(this.model, this.containers.grid, this.options.gridOptions);
                }

                private setContainers() {
                    this.containers = {
                        datePicker: this.$el.find(".a-tdv-datepicker-container")[0],
                        title: this.$el.find(".a-tdv-title-container")[0],
                        chart: this.$el.find(".a-tdv-chart-container")[0],
                        grid: this.$el.find(".a-tdv-grid-container")[0]
                    }

                    this.buttons = {
                        all: this.$el.find(".a-tdv-switcher"),
                        switchToChart: this.$el.find(".a-tdv-switcher.tdv-chart"),
                        switchToGrid: this.$el.find(".a-tdv-switcher.tdv-grid"),
                        excel: this.$el.find(".a-tdv-excel-export"),
                        save: this.$el.find(".a-tdv-save")
                    }
                }

                private setEvents() {
                    this.buttons.excel.kendoTooltip({ position: "top" });
                    this.buttons.save.kendoTooltip({ position: "top" });

                    this.buttons.save.click(() => this.onSave());

                    this.buttons.switchToChart.click(() => this.switchToChart());
                    this.buttons.switchToGrid.click(() => this.switchToGrid());

                    if (this.isGirdViewSelected()) {
                        this.switchToGrid();
                    } else {
                        this.switchToChart();
                    }

                    this.model.on("change:timeseries", () => this.configureExcelMenu());
                }

                private isGirdViewSelected() {
                    return AppKitchen.BrowserHelper.UrlQuery.getParameter("view") === "grid";
                }

                private onSave() {
                    this.gridView.updateModel();
                    if (this.options.onSave) {
                        this.options.onSave();
                    }
                }

                private switchToChart() {
                    this.buttons.all.removeClass("a-selected");
                    this.buttons.switchToChart.addClass("a-selected");
                    $(this.containers.chart).show(0);
                    $(this.containers.grid).hide(0);
                    if (this.chartView) {
                        this.chartView.resize();
                    }
                    AppKitchen.BrowserHelper.UrlQuery.setParameter("view", "chart");
                }

                private switchToGrid() {
                    this.buttons.all.removeClass("a-selected");
                    this.buttons.switchToGrid.addClass("a-selected");
                    $(this.containers.grid).show(0);
                    $(this.containers.chart).hide(0);
                    if (this.gridView) {
                        this.gridView.resize();
                    }
                    AppKitchen.BrowserHelper.UrlQuery.setParameter("view", "grid");
                }

                private configureExcelMenu() {
                    var mainList = $('<ul></ul>');
                    var xlsList = $("<li>" + Strings.TimeseriesDataView_Excel_EportToXls + "</li>");
                    var csvList = $("<li>" + Strings.TimeseriesDataView_Excel_EportToCsv + "</li>");
                    if (this.model.get().timeseries.length > 1) {
                        var ulXls = this.createSubMenu(this.exportToExcel.bind(this));
                        var ulCsv = this.createSubMenu(this.exportToCsv.bind(this));
                        xlsList.append(ulXls);
                        csvList.append(ulCsv);
                        mainList.append(xlsList);
                        mainList.append(csvList);

                        this.buttons.excel.find(" > li").append(mainList);
                        this.buttons.excel.kendoMenu({ openOnClick: true });
                    } else {
                        this.buttons.excel.click(() => this.exportToExcel([
                            {
                                timeseriesId: this.model.get().timeseries[0].timeseriesId,
                                hypothesis: this.model.get().timeseries[0].hypothesis,
                                header: this.model.get().timeseries[0].label,
                                color: this.model.get().timeseries[0].color
                            }
                        ]));
                    }
                }

                private createSubMenu(exportMethod: (definitions: ExportChartDataToExcelDefinition[]) => void): JQuery {
                    var ul = $('<ul></ul>');

                    this.model.get().timeseries.forEach(ts => {
                        var singleExportItem = $("<li>" + ts.label + " " + Strings.TimeseriesDataView_Excel_Export + "</li>");
                        singleExportItem.click(() => exportMethod([
                            {
                                timeseriesId: ts.timeseriesId,
                                hypothesis: ts.hypothesis,
                                header: ts.label,
                                color: ts.color
                            }
                        ]));
                        ul.append(singleExportItem);
                    });

                    var exportAllItem = $("<li>" + Strings.TimeseriesDataView_Excel_ExportAll + "</li>");
                    exportAllItem.click(() => {
                        var allExportDefinitions: ExportChartDataToExcelDefinition[] = this.model.get().timeseries
                            .AsLinq<TimeseriesDataViewData>()
                            .Select(ts => {
                                return {
                                    timeseriesId: ts.timeseriesId,
                                    hypothesis: ts.hypothesis,
                                    header: ts.label,
                                    color: ts.color,
                                }
                            })
                            .ToArray();
                        exportMethod(allExportDefinitions);
                    });
                    ul.append(exportAllItem);
                    return ul;
                }

                private exportToExcel(definitions: ExportChartDataToExcelDefinition[]) {
                    var chartsData = this.convertToChartData(this.model.get().timeseries);

                    if (chartsData == null || chartsData.length === 0) {
                        AppKitchen.UIHelper.DialogBoxes.alert(Strings.Error_ExcelExport_MissingData, { title: Strings.Error_ExcelExport_MissingData_Title, icon: "fail" });
                        return;
                    }

                    var filename = this.buildFileName(definitions);

                    AppKitchen.ExportHelper.exportChartDataToExcel(chartsData, definitions, "", filename);
                }

                private exportToCsv(definitions: ExportChartDataToExcelDefinition[]) {
                    var chartsData = this.convertToChartData(this.model.get().timeseries);

                    if (chartsData == null || chartsData.length === 0) {
                        AppKitchen.UIHelper.DialogBoxes.alert(Strings.Error_ExcelExport_MissingData, { title: Strings.Error_ExcelExport_MissingData_Title, icon: "fail" });
                        return;
                    }

                    var defs: AppKitchen.ExportHelper.ExportChartDataToCsvDefinition[] = [];
                    definitions.forEach(d => defs.push({ timeseriesId: d.timeseriesId, header: d.header }));
                    var filename = this.buildFileName(definitions);

                    AppKitchen.ExportHelper.exportChartDataToCsv(chartsData, defs, filename);
                }

                private buildFileName(definitions: ExportChartDataToExcelDefinition[]) {
                    var filename = this.options.exportFilename;
                    if (definitions.length === 1) {
                        filename = filename.replace("{header}", definitions[0].header);
                    }
                    filename = filename.replace("{header}", "")
                                       .replace("{from}", kendo.toString(this.model.get().startDate, "d"))
                                       .replace("{to}", kendo.toString(this.model.get().endDate, "d"));
                    return filename;
                }

                private convertToChartData(viewData: TimeseriesDataViewData[]) {
                    var data: Api.Models.ChartData[] = [];
                    viewData.forEach(d => data.push(<Api.Models.ChartData>{
                        timeseriesId: d.timeseriesId,
                        data: d.data,
                        hypothesis: d.hypothesis,
                        timeseriesName: d.label,
                        unit: d.unit,
                        //decimalsPlaces: //no effect
                        //timezone: ??
                    }));
                    return data;
                }

                setTitle(title: string) {
                    if (this.containers.title) {
                        this.containers.title.innerHTML = title;
                    }
                }
            }
        }
    }
}