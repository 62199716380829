namespace AppKitchen {
    export module Controls {
        export module TimeseriesDataViews {

            export module TimeseriesDataChartViewHelper {
                export function getDefaultConfig()  {
                    //TODO: remove cast to any!
                    return (({
                        exporting: {
                            enabled: false
                        },
                        credits: {
                            enabled: false
                        },
                        xAxis: {
                            title: {
                                style: {
                                    color: AppKitchen.Colors.Gray,
                                    fontFamily: "OpenSansSemiBold",
                                    fontWeight: "normal"
                                }
                            },
                            ordinal: false
                        },
                        yAxis: {
                            opposite: false,
                            min: 0,
                            title: {
                                text: "",
                                style: {
                                    color: AppKitchen.Colors.Gray,
                                    fontFamily: "OpenSansSemiBold",
                                    fontWeight: "normal"
                                }
                            }
                        },
                        subtitle: {
                            style: {
                                display: "none"
                            }
                        },
                        tooltip: {
                            borderColor: "transparent",
                            backgroundColor: AppKitchen.Colors.CoalGray,
                            borderRadius: 0,
                            borderWidth: 0,
                            valueDecimals: 2,
                            hideDelay: 10,
                            crosshairs: true,
                            shared: true,
                            shadow: false,
                            followPointer: false,
                            dateTimeLabelFormats: {
                                hour: "%A, %b %e, %Y, %H:%M",
                                day: "%A, %b %e, %Y, %H:%M",
                                minute: "%A, %b %e, %Y, %H:%M"
                            },
                            style: {
                                color: AppKitchen.Colors.WhiteSmoke
                            }
                        },
                        navigator: {
                            enabled: true,
                            maskFill: "rgba(125,125,125,0.15)",
                            baseSeries: 1,
                            height: 50,
                            series: <any>{
                                step: false,
                                type: "area",
                                color: "#b5b5b5",
                                fillOpacity: 0.15,
                                lineWidth: 1,
                                lineColor: "#b5b5b5"
                            },
                            xAxis: {
                                labels: {
                                    y: 14
                                }
                            },
                            outlineColor: "rgba(125,125,125,0.1)",
                            handles: {
                                backgroundColor: "#F6F6F6",
                                borderColor: "rgba(125,125,125,0.5)"
                            }
                        },
                        scrollbar: {
                            enabled: false
                        },
                        rangeSelector: {
                            enabled: false
                        },
                        title: <any>{
                            enabled: false
                        },
                        plotOptions: {
                            area: {
                                stacking: "normal"
                            },
                            series: {
                                animation: false
                            },
                            column: {
                                animation: false
                            }
                        },
                        legend: {
                            enabled: true,
                            verticalAlign: "top",
                            align: "right",
                            itemStyle: {
                                color: "#808080"
                            }
                        },
                        chart: {
                            zoomType: "xy",
                            resetZoomButton: {
                                theme: {
                                    display: "none"
                                }
                            },
                            animation: false,
                            borderRadius: 0,
                            backgroundColor: "transparent"
                        }
                    }) as any) as Highstock.Options;
                }
            }

            export type ChartMode = 'default' | 'step';

            // ReSharper disable once InconsistentNaming
            export interface TimeseriesDataChartViewOptions extends AppKitchen.ViewBaseOptions {
                seriesConfig?: { [tsId: string]: Highcharts.SeriesOptions };
                chartConfig?: Highstock.Options;
                chartMode?: ChartMode;
            }

            export class TimeseriesDataChartView extends AppKitchen.ViewBase<TimeseriesDataViewModel> {
                options: TimeseriesDataChartViewOptions;
                highchartsModel: Controls.Charts.HighchartsSeriesCollectionModel;
                highchartsView: Controls.Charts.HighchartsView;
                resetZoomButton: JQuery;
                chartContainer: HTMLElement;

                constructor(model: TimeseriesDataViewModel,
                    targetContainer: HTMLElement,
                    options?: TimeseriesDataChartViewOptions) {
                    super(model,
                        targetContainer,
                        AppKitchen.OptionsHelper.merge<TimeseriesDataChartViewOptions>(options,
                            {
                                seriesConfig: {},
                                chartConfig: TimeseriesDataChartViewHelper.getDefaultConfig(),
                                chartMode: 'default'
                            },
                            true));

                    this.render();
                }

                render() {
                    AppKitchen.UIHelper.renderTemplateTo(this.el, Templates.TimeseriesDataChartView);
                    this.resetZoomButton = this.$el.find(".a-tcv-reset-zoom-btn");
                    this.chartContainer = this.$el.find(".a-tcv-chart-container")[0];

                    this.resetZoomButton.click(() => this.resetAxisExtrema());

                    this.highchartsModel = new Controls.Charts.HighchartsSeriesCollectionModel([]);
                    this.highchartsModel.set({ config: this.options.chartConfig });
                    this.highchartsView = new Controls.Charts.HighchartsView(this.highchartsModel, this.chartContainer);

                    this.updateChartData();
                    this.resize();

                    this.model.on("change:timeseries", () => this.updateChartData());

                    return this;
                }

                updateChartData() {
                    var timeseries = this.model.get().timeseries;
                    if (timeseries.length > 0) {
                        let timeseriesLinq = timeseries.AsLinq<TimeseriesDataViewData>();
                        (<any>this.highchartsModel.get().config.yAxis).title.text = timeseriesLinq.First().unit;
                        this.highchartsModel.set({
                            seriesCollection: timeseriesLinq.Select(tsData =>
                                    new Controls.Charts.HighchartsSeriesModel(this.getTimeseriesData(tsData.data),
                                        this.getTimeseriesSettings(tsData)))
                                .ToArray()
                        });
                        this.resetAxisExtrema();
                    } else {
                        this.highchartsModel.set({ seriesCollection: [] });
                    }
                }

                getTimeseriesData(data: number[][]): number[][] {
                    if (this.options.chartMode === "step" && data && data.length > 0) {
                        var currentTimestamp = new Date().getTime();
                        var lastValue = data.AsLinq<number[]>().LastOrDefault(d => d[1] != null);

                        if (lastValue && lastValue[0] < currentTimestamp) {
                            var newData = data.slice();
                            newData.push([currentTimestamp, lastValue[1]]);
                            return newData;
                        }
                    }
                    return data;
                }

                private resetAxisExtrema() {
                    this.highchartsView.setExtremesX(this.getTimepointsExtrema());
                    this.highchartsView.setExtremesY(this.getDataExtrema(1.1));
                }

                private getTimeseriesSettings(tsData: TimeseriesDataViewData): Highcharts.SeriesOptions {

                    return AppKitchen.OptionsHelper.merge(this.options.seriesConfig[tsData.timeseriesId],
                        {
                            color: tsData.color,
                            name: tsData.label,
                            yAxis: 0
                        });
                }

                private getTimepointsExtrema() {
                    var timeseries = this.model.get().timeseries;

                    let minTotal = Number.MAX_VALUE;
                    let maxTotal = 0;

                    if (timeseries.length > 0) {
                        for (let timeserie of timeseries) {
                            let tsData = timeserie.data;
                            if (tsData && tsData.length) {
                                let min = tsData[0][0];
                                let max = tsData[tsData.length - 1][0];

                                if (min < minTotal) {
                                    minTotal = min;
                                }
                                if (max > maxTotal) {
                                    maxTotal = max;
                                }
                            }
                        }
                    } else {
                        minTotal = this.model.get().startDate.valueOf();
                        maxTotal = this.model.get().endDate.valueOf();
                    }
                    if (minTotal === Number.MAX_VALUE) {
                        minTotal = maxTotal;
                    }

                    return { min: minTotal, max: maxTotal }
                }

                private getDataExtrema(multiply: number = 1) {
                    const timeseriesDataViewDataList = this.model.get().timeseries;

                    var min = Number.MAX_VALUE;
                    var max = - Number.MAX_VALUE;
                    var hasData = false;

                    for (let timeseriesDataViewData of timeseriesDataViewDataList) {
                        if (!timeseriesDataViewData.data) {
                            continue;
                        }
                        for (let data of timeseriesDataViewData.data) {
                            if (data[1] != null) {
                                if (data[1] < min) min = data[1];
                                if (data[1] > max) max = data[1];
                                hasData = true;
                            }
                        }
                    }

                    if (min === max) {
                        max += 1;
                    }
                    const buffer = multiply - 1;
                    return {
                        min: hasData ? min - min * buffer : undefined,
                        max: hasData ? max + max * buffer : undefined
                    }
                }

                resize() {
                    this.highchartsView.resize();
                }

            }
        }
    }
}