namespace AppKitchen {
    export module Controls {
        export module Data {

            // ReSharper disable once InconsistentNaming
            export interface ImportExportAttributes extends AppKitchen.ModelBaseAttributes {
            }

            export class InportExportModel extends AppKitchen.ModelBase<ImportExportAttributes> {

            }

            // ReSharper disable once InconsistentNaming
            export interface InportExportViewOptions extends AppKitchen.ViewBaseOptions {
                importExcelCallback?: (file: File) => void;
                importCsvCallback?: (file: File) => void;
                exportExcelCallback?: () => void;
                exportCsvCallback?: () => void;
            }

            export class InportExportView extends AppKitchen.ViewBase<InportExportModel> {
                options: InportExportViewOptions;

                buttons: {
                    exportMenu: JQuery;
                    importMenu: JQuery;
                };

                exportMenu: kendo.ui.Menu;
                importMenu: kendo.ui.Menu;

                constructor(model: InportExportModel, target: HTMLElement, options?: InportExportViewOptions) {
                    super(model, target, options);
                    this.options = options;

                    this.render();
                }

                render() {
                    var importEnable = this.options.importExcelCallback || this.options.importCsvCallback;
                    var exportEnable = this.options.exportExcelCallback || this.options.exportCsvCallback;

                    AppKitchen.UIHelper.renderTemplateTo(this.el,
                        AppKitchen.Templates.ImportExport,
                        { importMenu: importEnable, exportMenu: exportEnable });

                    this.configureButtons();

                    this.configureExportMenu();

                    this.configureImportMenu();

                    return this;
                }

                configureButtons() {
                    this.buttons = {
                        exportMenu: this.$el.find(".a-file-export"),
                        importMenu: this.$el.find(".a-file-import")
                    }

                    this.buttons.exportMenu.kendoTooltip({
                        position: "top",
                        show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                    });
                    this.buttons.importMenu.kendoTooltip({
                        position: "top",
                        show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                    });
                }

                configureExportMenu() {
                    var mainList = $('<ul></ul>');

                    this.createMenuExport(mainList);

                    this.buttons.exportMenu.find(" > li").append(mainList);
                    this.buttons.exportMenu.show(0);
                    this.exportMenu = this.buttons.exportMenu.kendoMenu({ openOnClick: true }).data("kendoMenu");
                }

                createMenuExport(mainList: JQuery) {
                    var xlsList = $("<li>" + Strings.ImportExportView_ExportToXls + "</li>");
                    var csvList = $("<li>" + Strings.ImportExportView_ExportToCsv + "</li>");

                    xlsList.click(() => { this.options.exportExcelCallback()});
                    csvList.click(() => { this.options.exportCsvCallback()});

                    mainList.append(xlsList);
                    mainList.append(csvList);
                }

                configureImportMenu() {
                    var mainList = $('<ul></ul>');

                    this.createMenuImport(mainList);

                    this.buttons.importMenu.find(" > li").append(mainList);
                    this.buttons.importMenu.show(0);
                    this.importMenu = this.buttons.importMenu.kendoMenu({ openOnClick: true }).data("kendoMenu");
                }

                createMenuImport(mainList: JQuery) {
                    let xlsList = $(AppKitchen.UIHelper.renderTemplate(Templates.FileInputMenu,
                        {
                            labelText: Strings.ImportExportView_ImportFromXls,
                            allowedExtentions: ".xlsx"
                        }));
                    let csvList = $(AppKitchen.UIHelper.renderTemplate(Templates.FileInputMenu,
                        {
                            labelText: Strings.ImportExportView_ImportFromCsv,
                            allowedExtentions: ".csv"
                        }));

                    xlsList.find("input[type=file]").on("change", e => {
                        if (e.target && (<any>e.target).files && (<any>e.target).files.length > 0) {
                            var file = (<any>e.target).files[0];
                            this.options.importExcelCallback(file);
                        }
                        xlsList.find("input[type=file]").val('');
                    });
                    csvList.find("input[type=file]").on("change", e => {
                        if (e.target && (<any>e.target).files && (<any>e.target).files.length > 0) {
                            var file = (<any>e.target).files[0];
                            this.options.importCsvCallback(file);
                        }
                        // TODO: verbessern
                        csvList.find("input[type=file]").val('');
                    });

                    mainList.append(xlsList);
                    mainList.append(csvList);
                }
            }
        }
    }
}