namespace AppKitchen {

    // BrowserHelper must be always on Top
    export module BrowserHelper {

        export function triggerWindowResizeEvent() {
            var evt = window.document.createEvent('HTMLEvents');
            evt.initEvent('resize', true, false);
            window.dispatchEvent(evt);
        }

        // ReSharper disable once InconsistentNaming
        export function isIE(): boolean {
            var userAgent = BrowserHelper.getUserAgent();
            return userAgent.indexOf("MSIE") > 0 || !!navigator.userAgent.match(/Trident\/7\./);
        }

        // ReSharper disable once InconsistentNaming
        export function IEVersion(): number {
            var userAgent = BrowserHelper.getUserAgent();
            var index = userAgent.indexOf("MSIE");
            if (index > 0) {
                return parseInt(userAgent.substring(index + 5, Math.max(2, userAgent.indexOf(".", index))));
            } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
                return 11;
            }

            return undefined;
        }

        export function getUserAgent() {
            return window.navigator.userAgent;
        }
        
        // ReSharper disable once InconsistentNaming
        export interface BrowserWindowOptions {
            addressbar?: boolean;
            toolbar?: boolean;
            menubar?: boolean;
            statusbar?: boolean;
            resizable?: boolean;
            width?: number;
            height?: number;
            position?: { top: number, left: number };
        }

        export function openInWindow(url: string, minimal?: boolean, options?: BrowserWindowOptions): Window {
            options = OptionsHelper.merge<BrowserWindowOptions>(options,
                {
                    addressbar: true,
                    menubar: !minimal,
                    toolbar: !minimal,
                    statusbar: !minimal,
                    resizable: true,
                    width: undefined,
                    height: undefined,
                    position: undefined
                });

            var features = [];
            if (options.addressbar != null) features.push("location=" + (options.addressbar ? "yes" : "no"));
            if (options.menubar != null) features.push("menubar=" + (options.menubar ? "yes" : "no"));
            if (options.toolbar != null) features.push("toolbar=" + (options.toolbar ? "yes" : "no"));
            if (options.statusbar != null) features.push("status=" + (options.statusbar ? "yes" : "no"));
            if (options.resizable != null) features.push("resizable=" + (options.resizable ? "yes" : "no"));
            if (options.width != null) features.push("width=" + options.width);
            if (options.height != null) features.push("height=" + options.height);
            if (options.position != null) {
                features.push("left=" + options.position.left);
                features.push("top=" + options.position.top);
            }

            var openedWindow = window.open(url, "_blank", features.join(","));
            openedWindow.focus();

            return openedWindow;
        }

        // ReSharper disable once InconsistentNaming
        export interface RedirectOptions {
            refresh?: boolean;
            push?: boolean;
        }

        export function setUrl(url: string, options?: RedirectOptions) {
            options = OptionsHelper.merge(options,
                {
                    refresh: true,
                    push: true
                });

            if (!options.refresh && history.replaceState && history.pushState) {
                if (options.push) {
                    window.history.pushState({ path: url }, null, url);
                } else {
                    window.history.replaceState({ path: url }, null, url);
                }
            } else {
                if (window.location.href === url) {
                    window.location.reload();
                }
                if (options.push) {
                    window.location.href = url;
                } else {
                    window.location.replace(url);
                    window.setTimeout(() => window.location.reload(),
                        1000); // reloads if replace did not (when using #route)
                }
            }
        }

        export function getUrl(): string {
            return window.location.href;
        }

        export module UrlQuery {
            export function getParameters(): { [key: string]: any } {
                var queryParams = {};
                var url = BrowserHelper.getUrl() || "";
                var queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : null;
                if (queryString) {
                    var vars = queryString.split("&");
                    for (var i = 0; i < vars.length; i++) {
                        var pair = vars[i].split("=");
                        if (typeof queryParams[pair[0]] === "undefined") {
                            queryParams[pair[0]] = decodeURIComponent(pair[1]);
                        } else if (typeof queryParams[pair[0]] === "string") {
                            var arr = [queryParams[pair[0]], decodeURIComponent(pair[1])];
                            queryParams[pair[0]] = arr;
                        } else {
                            queryParams[pair[0]].push(decodeURIComponent(pair[1]));
                        }
                    }
                }

                return queryParams;
            }

            export function getParameter(key: string): string {
                var parameters = UrlQuery.getParameters();
                if (parameters) {
                    return parameters[key];
                }
                return undefined;
            }

            export function setParameter(key: string, value: string, options?: RedirectOptions) {
                options = OptionsHelper.merge(options,
                    {
                        push: false,
                        refresh: false
                    });

                var url = BrowserHelper.getUrl();
                var baseUrl = url.split("?")[0];

                var keyFound = false;
                var parameters = [];

                var queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : null;
                if (queryString) {
                    parameters = queryString.split("&");
                    for (var i = parameters.length - 1; i >= 0; i--) {
                        var parameterKey = parameters[i].split("=")[0];
                        if (parameterKey === key) {
                            parameters[i] = key + "=" + encodeURIComponent(value);
                            keyFound = true;
                        }
                    }
                }

                if (!keyFound) {
                    parameters.push(key + "=" + value);
                }

                var newUrl = baseUrl + "?" + parameters.join("&");

                BrowserHelper.setUrl(newUrl, options);
            }

            export function removeParameter(key: string, options?: RedirectOptions) {
                options = OptionsHelper.merge(options,
                    {
                        push: false,
                        refresh: false
                    });

                var url = BrowserHelper.getUrl();
                var newUrl = url.split("?")[0];
                var queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
                if (queryString !== "") {
                    var parameters = queryString.split("&");
                    for (var i = parameters.length - 1; i >= 0; i--) {
                        var parameterKey = parameters[i].split("=")[0];
                        if (parameterKey === key) {
                            parameters.splice(i, 1);
                        }
                    }
                    if (parameters.length > 0) {
                        newUrl = newUrl + "?" + parameters.join("&");
                    }
                }

                BrowserHelper.setUrl(newUrl, options);
            }

            export function clear(options?: RedirectOptions) {
                options = OptionsHelper.merge(options,
                    {
                        push: false,
                        refresh: false
                    });

                var url = BrowserHelper.getUrl();
                BrowserHelper.setUrl(url.split("?")[0], options);
            }
        }

        export function getLanguage() {

            var language =
                navigator.language || // All browsers
                    navigator['userLanguage']; // IE <= 10
            return language ? language.substr(0, 2) : undefined;
        }

        export module WindowResize {

            // ReSharper disable once InconsistentNaming
            interface WindowResizeAction {
                action: () => void;
                element?: HTMLElement;
            }

            var counter = 0;
            var actions: { [handle: string]: WindowResizeAction } = {};

            export function on(action: () => void, element?: HTMLElement): number {
                var handle = ++counter;

                actions[handle] = {
                    action: action,
                    element: element
                };

                trigger();
                return handle;
            }

            export function off(handle: number) {
                delete actions[handle];
            }

            export function trigger() {
                if (actions) {
                    var lostActions = [];
                    Object.keys(actions).forEach(key => {
                        var action = actions[key];
                        if (action.element && $(action.element).closest("body").length === 0) {
                            lostActions.push(key);
                        } else {
                            if (action.action) {
                                action.action();
                            }
                        }
                    });

                    // cleanup lost actions
                    if (lostActions.length > 0) {
                        lostActions.forEach(handle => off(handle));
                    }
                }
            }

            $(window).resize(() => WindowResize.trigger());
        }

        export function setCustomFunctionsKeyBinding(evt: JQuery.KeyDownEvent, fCode: number, action: () => void) {

            if (evt.keyCode === fCode) {
                evt.preventDefault();
                evt.stopPropagation();
                action();
                return false;
            }
        }
    }
}