///<reference types="marked"/>
///<reference path="../../Base.ts"/>

namespace AppKitchen {

    export module Controls {

        export interface IHelpAttributes extends AppKitchen.ModelBaseAttributes {
            category: string;
            show: boolean;
            documents?: string[];
        }

        export class ContextSensitiveHelpModel extends AppKitchen.ModelBase<IHelpAttributes> {
            constructor(c?: string) {
                super({
                    category: c || '',
                    show: false
                });

                // -> key code 112 = F1
                $(document).on('keydown', e => BrowserHelper.setCustomFunctionsKeyBinding(e, 112, () => this.set({ show: !this.get().show })));
                AppKitchen.getRouter().on("route", (r) => this.set({ category: r }));

                this.bind('change:show', this.toggleContextSensitiveHelpSidebar);
            }

            private getContent(c: string) {
                AppKitchen.Data.Api.getContextSensitiveHelp(c, (res: string[]) => this.set({ documents: this.parseContent(res) }));
            }

            private parseContent(res: string[]): string[] {
                return res.map(r => DOMPurify.sanitize(marked.parse(r,
                    {
                        gfm: true,
                        headerIds: false,
                        breaks: false,
                        smartLists: true,
                        smartypants: false,
                        xhtml: true
                    } as Object)));
            }

            private toggleContextSensitiveHelpSidebar() {
                $('body').find(".a-main").toggleClass("display-help");
                if (this.get().show) {
                    this.getContent(this.get().category);
                    this.bind('change:category', (r) => this.getContent(r.get().category));
                } else {
                    this.unbind('change:category');
                }
            }
        }

        export class ContextSensitiveHelpView extends AppKitchen.ViewBase<ContextSensitiveHelpModel> {

            slideIndex: number = 0;
            slideContainer: JQuery;
            dotsContainer: JQuery;

            constructor(model: ContextSensitiveHelpModel, targetContainer: HTMLElement, options?: AppKitchen.ViewBaseOptions) {
                super(model, targetContainer, options);

                this.render();
            }

            render() {

                AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.ContextSensitiveHelp, {});

                this.slideContainer = this.$el.find(".a-slideshow-container");
                this.dotsContainer = this.$el.find(".a-slideshow-dots");

                this.$el.find('.a-close-button').on('click', () => this.model.set({ show: false }));
                this.$el.find(".a-slideshow-footer > .prev").bind("click", () => this.moveSlides(-1));
                this.$el.find(".a-slideshow-footer > .next").bind("click", () => this.moveSlides(1));
                this.arrowKeysBinding();

                this.model.bind('change:documents', (m) => this.renderContent(m.get().documents));

                return this;
            }

            renderContent(docs: string[]) {

                this.reset();

                this.initSlideshowContent(docs);
                this.initSlideshowDots();

                AppKitchen.UIHelper.Scrolling.customScroller(this.slideContainer[0]);

                this.showSlides(this.slideIndex);
                
            }
            
            initSlideshowContent(slides: string[]) {

                slides.forEach((htmlContent, i) => {

                    AppKitchen.UIHelper.appendTemplateTo(this.slideContainer[0],
                        AppKitchen.Templates.SlideshowContent,
                        { content: htmlContent }
                    );
                    AppKitchen.UIHelper.appendTemplateTo(this.dotsContainer[0],
                        AppKitchen.Templates.SlideshowDot,
                        { index: i }
                    );
                });
            }

            initSlideshowDots() {
                this.dotsContainer.find(".dot").each((index, el) => {
                    $(el).bind('click', () => this.selectSlide(index));
                });
            }

            arrowKeysBinding() {
                $(document).keydown((e) => {
                    if (e.keyCode === 37) {
                        this.moveSlides(-1);
                        return false;
                    }
                    if (e.keyCode === 39) {
                        this.moveSlides(1);
                        return false;
                    }
                    return true;
                });
            }

            moveSlides(n: number) {
                this.showSlides(this.slideIndex += n);
            }

            selectSlide(n: number) {
                this.showSlides(this.slideIndex = n);
            }

            showSlides(n: number) {
                var slides = this.slideContainer.find(".a-slides");
                var dots = this.dotsContainer.find(".dot");
                
                if (slides?.length === 0) {
                    return;
                }

                if (n > slides.length - 1) {
                    this.slideIndex = 0;
                }
                if (n < 0) {
                    this.slideIndex = slides.length - 1;
                }

                for (let i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                    dots[i].classList.remove("active");
                }

                slides[this.slideIndex].style.display = "block";
                dots[this.slideIndex]?.classList.add("active");

                this.resetScrollbar();

            }

            reset() {
                this.slideContainer.empty();
                this.dotsContainer.empty();
                this.slideIndex = 0;
            }

            resetScrollbar() {
                $(".nano").nanoScroller({ scroll: 'top' });
                AppKitchen.UIHelper.updateFullHeightGrids(this.el);
            }
        }
    }
}