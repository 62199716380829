namespace AppKitchen {

    export module Controls {

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesStatusOptions {
            unit?: string;
            timespan?: number;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesStatusValue {
            value: number;
            unit: string;
            timestamp: Date;
            timeseriesName: string;
            timeseriesId: string;
            decimals: number;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesStatusAttributes {
            value?: TimeseriesStatusValue;
            unit?: string;
            valueDate?: Date;
            errorMsg?: string;
            loading?: boolean;
        }

        export class TimeseriesStatusModel extends AppKitchen.ModelBase<TimeseriesStatusAttributes> {
            options: TimeseriesStatusOptions;
            databaseId: string;
            timeseriesId: string;
            timeResolution: string;
            hypothesis: string;

            constructor(databaseId: string, timeseriesId: string, timeResolution: string, hypothesis: string, options?: TimeseriesStatusOptions) {
                super({
                    value: null,
                    unit: options ? options.unit : "",
                    valueDate: null,
                    errorMsg: null
                });

                this.options = AppKitchen.OptionsHelper.merge(options,
                {
                    timespan: 24 // default: last 24 hours
                });

                this.databaseId = databaseId;
                this.timeseriesId = timeseriesId;
                this.timeResolution = timeResolution;
                this.hypothesis = hypothesis;

                //ChangeManager.onTimeseriesChanged(this.databaseId,this.timeseriesId,this.update.bind(this));
                this.update();
            }

            update() {
                this.set({ loading: true });

                var config: Api.Models.InteractiveData = <any>{
                    databaseId: this.databaseId,
                    timeseries: [
                        {
                            timeseriesId: this.timeseriesId,
                            hypothesis: this.hypothesis,
                            unit: this.options.unit,
                            id: "T###",
                        }
                    ],

                    timeaxis: [
                        {
                            id: "A###",
                            begin: {
                                dynamic: {
                                    NowTimeResolution: "Hour",
                                    Offset: -this.options.timespan,
                                    OffsetTimeResolution: "Hour",
                                    ExcludeEndDate: false
                                }
                            },
                            end: {
                                dynamic: {
                                    NowTimeResolution: this.timeResolution,
                                    Offset: 0,
                                    OffsetTimeResolution: this.timeResolution,
                                    ExcludeEndDate: false
                                }
                            },
                            timeresolution: this.timeResolution,
                            timezone: "Central European Time",
                        }
                    ],

                    chartseries: [
                        {
                            id: "NEU_A_IW",
                            datasource: {
                                dataSourceId: "T###",
                                dataSourceType: Api.Models.DataSourceType.Timeseries,
                                timeaxisIds: ["A###"]
                            },
                        }
                    ],
                }

                AppKitchen.Data.getDataApi("TimeseriesData", config, (dataList: AppKitchen.Api.Models.ChartData[]) => {
                    if (!dataList || dataList.length === 0 || !dataList[0].data || dataList[0].data.length === 0) {
                        AppKitchen.log("No data for timeseries '" + this.timeseriesId + "'");
                        this.set({
                            errorMsg: null,
                            value: null
                        });
                    } else {
                        this.set({
                            value: {
                                value: dataList[0].data[dataList[0].data.length - 1][1],
                                unit: dataList[0].unit,
                                timestamp: new Date(dataList[0].data[dataList[0].data.length - 1][0]),
                                timeseriesName: dataList[0].timeseriesName,
                                timeseriesId: dataList[0].timeseriesId,
                                decimals: dataList[0].decimalsPlaces
                            }
                        });
                    }
                    this.set({ loading: false });
                }, (requestData: any, xhr: JQueryXHR) => {
                    if (xhr.statusText === "APPKITCHEN_ERROR") {
                        this.set({
                            errorMsg: xhr.responseText,
                            value: null
                        });
                    }
                    this.set({ loading: false });
                });
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesValueInterpretationRule {
            value: number;
            color: string;
            status?: string;
            icon?: string;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesValueInterpretation {
            [index: number]: TimeseriesValueInterpretationRule
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesStatusViewOptions extends AppKitchen.ViewBaseOptions {
            display: "text" | "status" | "icon" | "circle";
            label?: string;
            format?: string;
            size?: number;
            showTooltip?: boolean;
            noValue?: {
                text?: string;
                color?: string;
                icon?: string;
            }
            valueInterpretation?: TimeseriesValueInterpretation;
        }

        export class TimeseriesStatusView extends AppKitchen.ViewBase<TimeseriesStatusModel> {
            options: TimeseriesStatusViewOptions;

            constructor(model: TimeseriesStatusModel, targetContainer: HTMLElement, options?: TimeseriesStatusViewOptions) {
                super(model, targetContainer, AppKitchen.OptionsHelper.merge<TimeseriesStatusViewOptions>(options, {
                    loadingOverlay: '<div class="a-loading-overlay a-timeseries-status-loading-overlay"></div>',
                    display: "status",
                    label: "",
                    format: undefined,
                    showTooltip: false,
                    size: options && options.display === "circle"? 5 : 100,
                    noValue: {
                        text: "-",
                        color: AppKitchen.Colors.Silver
                    },
                    valueInterpretation: [
                        { value: 0, color: AppKitchen.Colors.Green, status: "ok" },
                        { value: 1, color: AppKitchen.Colors.OrangeRed, status: "warning" },
                        { value: 2, color: AppKitchen.Colors.Red, status: "error" }
                    ]
                }));

                // TODO sort this.options.valueInterpretation
                
                // TODO: write parametrized templates
                switch (this.options.display) {
                    case "text":
                        this.setTemplate(AppKitchen.Templates.TimeseriesStatus_Text);
                        break;
                    case "status":
                        this.setTemplate(AppKitchen.Templates.TimeseriesStatus_Circle);
                        break;
                    case "icon":
                        //this.setTemplate(Templates.TimeseriesStatusIcon);
                        break;
                    default:
                        throw "unknown timeseries status display type: '" + this.options.display + "'";
                }

                this.model.bind("change", () => this.render());
                this.model.bind("errorMsg", () => this.render());
                this.render();
            }

            render() {
                var status = this.getStatus();

                this.renderTemplate({
                    displayValue: this.getDisplayValue(),
                    unit: this.getUnit(),
                    color: status.color,
                    label: this.options.label,
                    size: this.options.size,
                    statusCss: status.status,
                    icon: status.icon
                });

                this.setTooltipIfNeeded();

                return this;
            }

            setTooltipIfNeeded() {
                if (this.model.get().errorMsg) {
                    this.$el.find(".a-timeseries-status-item")
                        .kendoTooltip({
                            content: "<div class='a-config-error'>" + this.model.get().errorMsg + "</div>"
                        });
                }
                else if (this.options.showTooltip) {

                    var data = {
                        title: this.options.label,
                        timeseriesId: this.model.timeseriesId,
                        timeseriesName: "-",
                        date: "-"
                    }
                    if (this.model.get().value) {
                        data.timeseriesId = this.model.get().value.timeseriesId;
                        data.timeseriesName = this.model.get().value.timeseriesName;
                        data.date = kendo.toString(this.model.get().value.timestamp, "F");
                    }

                    var html = AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.TimeseriesStatus_Tooltip,data);

                    this.$el.find(".a-timeseries-status-item").kendoTooltip({
                        content: html,
                        position: "top",
                        show: e => {
                            (<any>e.sender).popup.element.css('margin-bottom', '7px');
                            AppKitchen.UIHelper.hideTooltips(e.sender);
                        }
                    });
                }
            }

            getStatus(): TimeseriesValueInterpretationRule {
                var timeseriesValue = this.model.get().value;
                var interpretations = this.options.valueInterpretation;
                
                if (timeseriesValue != null) {
                    if (timeseriesValue.value <= interpretations[0].value) {
                        return interpretations[0];
                    }

                    for (let i = 0; i < (<Array<TimeseriesValueInterpretationRule>>interpretations).length - 1; i++) {
                        if (timeseriesValue.value >= interpretations[i].value && timeseriesValue.value < interpretations[i + 1].value) {
                            return interpretations[i];
                        }
                    }

                    return interpretations[(<Array<TimeseriesValueInterpretationRule>>interpretations).length - 1];
                }


                return {
                    value: null,
                    color: this.options.noValue.color,
                    status: "no-value",
                    icon: this.options.noValue.icon
                };
            }

            getUnit(): string {
                var timeseriesValue = this.model.get().value;
                if (timeseriesValue != null) {
                    return timeseriesValue.unit;
                }
                return this.model.get().unit;
            }

            getDisplayValue(): string {
                var timeseriesValue = this.model.get().value;
                if (timeseriesValue != null) {
                    if (this.options.format) {
                        return kendo.toString(timeseriesValue.value, this.options.format);
                    }
                    if (timeseriesValue.decimals != null) {
                        return kendo.toString(timeseriesValue.value, "n" + timeseriesValue.decimals);
                    }
                    return kendo.toString(timeseriesValue.value, "#,#.####################");
                }
                return this.options.noValue.text;
            }
            
        }
    }
}
