namespace General {
    
    function startsWith(funcStr: string, prefix: string) {
        return funcStr.substr(0, prefix.length) === prefix;
    }

    export function convertToFunction(funcStr: string) {
        var result = null;

        if (funcStr && startsWith(funcStr, "###js###")) {
            var procedureString = funcStr.substr("###js###".length);
            if (startsWith(procedureString, "function")) {
                procedureString = "(" + procedureString + ")";
            }
            try {
                result = eval(procedureString);
            }
            catch (e) {
                GeneralLogger.logError(e);
            }
        }

        return result;
    }

    export class GeneralRenderer {
        static renderFunc: (template: string, data, containerSelector, renderCallback?) => void;
        static init(renderFunc: (template: string, data, containerSelector, renderCallback) => void) {
            this.renderFunc = renderFunc;
        }

        static renderTemplate(template: string, data, containerSelector, renderCallback) {
            this.renderFunc(template, data, containerSelector, renderCallback);
        }
    }

    export class GeneralLogger {
        static debugLog: (msg: string) => void;
        static errorLog: (msg: string) => void;

        static init(debugLog: (msg: string) => void, errorLog: (msg: string) => void) {
            this.debugLog = debugLog;
            this.errorLog = errorLog;
        }

        static logDebug(msg: string) {
            if (this.debugLog) {
                this.debugLog(msg);
            }
        }

        static logError(msg: string) {
            if (GeneralLogger.logError) {
                this.errorLog(msg);
            }
        }
    }
}