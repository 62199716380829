namespace AppKitchen {

    export module Controls {

        // ReSharper disable once InconsistentNaming
        export interface LayoutGridConfig extends ConfigDocumentBase {
            content: LayoutGridPaneWithDomElement,
            minWidth: number,
            minHeight: number,
        }

        // ReSharper disable once InconsistentNaming
        export interface LayoutGridPane {
            configFile?: string;
            split?: {
                orientation: "horizontal" | "vertical";
                size: number;
                pane1: LayoutGridPane;
                pane2: LayoutGridPane;
            };
        }

        // ReSharper disable once InconsistentNaming
        export interface LayoutGridPaneWithDomElement extends LayoutGridPane {
            el?: HTMLElement;
        }

        // ReSharper disable once InconsistentNaming
        export interface LayoutGridAttributes extends ModelBaseAttributes {
            layoutGridConfig?: LayoutGridConfig;
        }

        export class LayoutGridModel extends AppKitchen.ModelBase<LayoutGridAttributes> {
            constructor(layoutgridConfig: LayoutGridConfig) {
                super({
                    layoutGridConfig: layoutgridConfig
                });
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface LayoutGridViewOptions extends AppKitchen.ViewBaseOptions{
            getUndockUrl?: (configFile: string) => string;
        }

        export class LayoutGridView extends AppKitchen.ViewBase<LayoutGridModel> {
            options: LayoutGridViewOptions;

            constructor(layoutGridModel: LayoutGridModel, targetContainer: HTMLElement, options?: LayoutGridViewOptions) {
                super(layoutGridModel, targetContainer, AppKitchen.OptionsHelper.merge<LayoutGridViewOptions>(options, {
                    getUndockUrl: null
                }));
                this.render();
            }

            render() {
                var layoutGridItemRoot = this.model.get().layoutGridConfig;
                var rootConfig: any = layoutGridItemRoot.content; //TODO: refactor this

                rootConfig.minWidth = layoutGridItemRoot.minWidth;
                rootConfig.minHeight = layoutGridItemRoot.minHeight;

                this.renderGridItem(rootConfig, this.el);
                return this;
            }

            renderGridItem(item: LayoutGridPane, target: HTMLElement) {

                AppKitchen.UIHelper.renderTemplateTo(target, AppKitchen.Templates.LayoutGrid_BasicTable, item);
                target = $(target).find(".a-layout-grid")[0];

                if (item.split) {
                    var data = {
                        size1: item.split.size,
                        size2: 100-item.split.size
                    }

                    if (item.split.orientation === "horizontal") {
                        AppKitchen.UIHelper.renderTemplateTo(target, AppKitchen.Templates.LayoutGrid_Horizontal, data);
                    } else {
                        AppKitchen.UIHelper.renderTemplateTo(target, AppKitchen.Templates.LayoutGrid_Vertical, data);
                    }

                    var newTargets = $(target).find(".a-layout-grid-cell");

                    this.processItem(item.split.pane1, newTargets[0]);
                    this.processItem(item.split.pane2, newTargets[1]);
                }
                this.loadApps(item);
            }

            loadApps(item: LayoutGridPaneWithDomElement) {
                if (item.split) {
                    if (item.split.pane1) {
                        this.loadApps(item.split.pane1);
                    }
                    if (item.split.pane2) {
                        this.loadApps(item.split.pane2);
                    }
                }
                if (item.configFile) {
                    this.startApp(item);
                }
               
            }

            startApp(item: LayoutGridPaneWithDomElement) {
                var appWrapper = new Wrappers.MaximizeWrapper<AppKitchen.Apps.ConfigFileStarterApp>(item.el, {
                    renderContent: container => this.renderContent(container, item),
                    maximized: (app: AppKitchen.Apps.ConfigFileStarterApp) => this.onChangeWindowSize(app),
                    restored: (app: AppKitchen.Apps.ConfigFileStarterApp) => this.onChangeWindowSize(app),
                    undockUrl: this.options.getUndockUrl? this.options.getUndockUrl(item.configFile) : null
                });
            }

            protected renderContent(target, item: LayoutGridPaneWithDomElement) {
                return new AppKitchen.Apps.ConfigFileStarterApp(target, { configFile: item.configFile });
            }


            processItem(item: LayoutGridPaneWithDomElement, target: HTMLElement) {
                if (!item.configFile) {
                    if (item.split && item.split.pane1 && item.split.pane2) {
                        this.renderGridItem(item, target);
                    } else {
                        AppKitchen.logError("Configuration error: If config file is not set, split.pane1 AND split.pane2 must be defined!");
                    }
                } else {
                    UIHelper.renderTemplateTo(target, AppKitchen.Templates.LayoutGrid_Cell);
                    item.el = $(target).find(".a-layout-grid-cell-content")[0];
                }
            }

            private onChangeWindowSize(app: AppKitchen.Apps.ConfigFileStarterApp) {
                app.redraw();
                AppKitchen.BrowserHelper.WindowResize.trigger();
            }

        }

    }

}