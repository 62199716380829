
/* 
 * Example: $P.Cache.getSet($P.Cache.keys.menu_docstoreItems, function(callback) { ... }, function(data) { ... }); 
 */
namespace General {
    export module Services {
        export class Cache {

        static localCache= {};
        static storage_overflow_size= 50* 1024* 1024;
            
        static getSet(key, createFunction, callback) {
            var me = this;

            var data = Cache.get(key);
            if (data !== undefined) {
                callback(data);
            } else {
                createFunction(d => {
                    Cache.set(key, d);
                    callback(d);
                });
            }
        }

        static getSetVolatile(key, createFunction, callback) {

            var data = Cache.get(key, true);
            if (data !== undefined) {
                callback(data);
            } else {
                createFunction(d => {
                    Cache.set(key, d, true);
                });
            }
        }

        static set(key, data, isVolatile?, ignoreOverflow?) {
            Cache.localCache[key] = data;
            if (!isVolatile) {
                try {
                    localStorage[key] = data;
                } catch (exception) {
                    GeneralLogger.logError(exception);

                    if (!ignoreOverflow &&
                        exception &&
                        (exception.name == "QUOTA_ERR" ||
                            exception.name == "QUOTA_EXCEEDED_ERR" ||
                            exception.name == "QuotaExceededError")) {
                        var totalBytes = 0;
                        for (var i in localStorage) {
                            totalBytes += localStorage[i].length;
                        }
                        GeneralLogger.logDebug("Total bytes in Storage: "+ totalBytes);

                        try {
                            var success = bytes => {
                                GeneralLogger.logDebug("LocalStorage Overflow was handled and the user granted " +bytes +" bytes of drive space.");
                                Cache.set(key, data, isVolatile, true);
                            };
                            var failed = e => {
                                GeneralLogger.logError("Failed to increase storage space after overflow, no new view screenshots will be saved from now on.");
                            };

                            if ((<any>navigator).webkitTemporaryStorage) {
                                (<any>navigator).webkitTemporaryStorage.requestQuota(
                                    Cache.storage_overflow_size,
                                    success,
                                    failed
                                );
                            } else {
                                ((<any>window).storageInfo || (<any>window).webkitStorageInfo).requestQuota((<any>window).TEMPORARY,Cache.storage_overflow_size,success,failed);
                            }
                        } catch (ex) {
                            GeneralLogger.logError(ex);
                        }
                    }
                }
            }
        }

        static get(key, isVolatile?) {
            var me = this;
            var result = Cache.localCache[key];

            if (!isVolatile && !result) {
                result = localStorage[key];
            }

            return result;
        }

        static remove(key) {
            var me = this;
            delete Cache.localCache[key];
            localStorage.removeItem(key);
        }

    }
}
}