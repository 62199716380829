namespace AppKitchen {
    export module Controls {

        export module Grids {

            // ReSharper disable once InconsistentNaming
            export interface TimeseriesDataGridModelAttributes extends ModelBaseAttributes {
                data?: Api.Models.ChartData[];
            }

            export class TimeseriesDataGridModel extends AppKitchen.ModelBase<TimeseriesDataGridModelAttributes> {
                dateRangePickerModel: Controls.DateRangePickerModel;
                requestData: AppKitchen.Api.Models.TimeseriesSimpleDataRequest;
                dateChangedLoadDataHandler: () => boolean;

                constructor(model: Controls.DateRangePickerModel, requestData: AppKitchen.Api.Models.TimeseriesSimpleDataRequest) {
                    super({ loading: false });
                    this.dateRangePickerModel = model;
                    this.requestData = requestData;
                    this.dateRangePickerModel.bind("change", this.refreshData.bind(this, false));
                    this.refreshData(false);
                }

                refreshData(force?: boolean) {
                    if (this.dateRangePickerModel.get().startDate && this.dateRangePickerModel.get().endDate) {

                        if (!force && this.get().data && this.dateChangedLoadDataHandler) {
                            if (!this.dateChangedLoadDataHandler()) {
                                return;
                            }
                        }

                        var request = <AppKitchen.Api.Models.TimeseriesSimpleDataRequest>$.extend({}, this.requestData);

                        request.StartDate = this.dateRangePickerModel.get().startDate;
                        request.EndDate = this.dateRangePickerModel.get().endDate;
                        this.set({ loading: true });
                        AppKitchen.Data.Api.getTimeseriesData(request, data => {
                            this.set({ data: data });
                            this.set({ loading: false });
                        });
                    }
                }

            }

            // ReSharper disable once InconsistentNaming
            export interface TimeseriesDataGridViewOptions extends AppKitchen.ViewBaseOptions {
                /** Data cells editability - default is TRUE   */
                editable?: boolean;
                defaultDecimalPlaces?: number;
                numberFormat?: string;
            }

            export class TimeseriesDataGridView extends AppKitchen.ViewBase<TimeseriesDataGridModel> {
                spreadsheet: kendo.ui.Spreadsheet;
                numberOfHeaderRows: number;
                options: TimeseriesDataGridViewOptions;
                renderFinished: boolean;

                constructor(model: TimeseriesDataGridModel, targetContainer: HTMLElement, options?: TimeseriesDataGridViewOptions) {
                    super(model, targetContainer, options);
                    this.model.dateChangedLoadDataHandler = this.dateChangedHandler.bind(this);

                    this.options = AppKitchen.OptionsHelper.merge(options,
                        {
                            editable: true,
                            defaultDecimalPlaces: 2
                        });
                    this.renderFinished = false;
                    model.bind("change:data", this.render.bind(this));
                    this.render();
                }

                render() {
                    if (this.model.get().data) {
                        this.renderFinished = false;
                        this.validateData(this.model.get().data, this.model.requestData.TimeResolution);

                        var sheetRows = this.getRowData(this.model.get().data, this.model.requestData.TimeResolution);
                        var columnConfig = this.getColumnConfig(this.model.get().data);

                        var spreadsheetOptions = <kendo.ui.SpreadsheetOptions>{
                            columns: sheetRows[0].cells.length,
                            rows: sheetRows.length,
                            toolbar: false,
                            sheetsbar: false,
                            sheets: [
                                <kendo.ui.SpreadsheetSheet>{
                                    frozenRows: this.numberOfHeaderRows,
                                    frozenColumns: 1,
                                    name: "Food Order",
                                    rows: sheetRows,
                                    columns: columnConfig
                                }
                            ]
                        };

                        (<any>spreadsheetOptions).change = this.valueChanged.bind(this);


                        AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.TimeseriesDataGrid);
                        this.spreadsheet = this.$el.find(".a-timeseriesdata-grid").kendoSpreadsheet(spreadsheetOptions).data("kendoSpreadsheet");

                        this.setCellEditability(this.spreadsheet, sheetRows.length + 1, this.numberOfHeaderRows);


                        // add 'only numbers' validator
                        var validator = <kendo.ui.SpreadsheetSheetRowCellValidation>{
                            from: "-999999999999",
                            to: "99999999999",
                            comparerType: "between",
                            type: "reject",
                            dataType: "number",
                            messageTemplate: AppKitchen.Strings.TimeseriesDataGrid_OnlyNumbers
                        };

                        (<any>this.spreadsheet.activeSheet().range("R" + (this.numberOfHeaderRows + 1) + "C2:R" + sheetRows.length + "C" + sheetRows[0].cells.length)).validation(validator);

                        // hide spreadsheet bars
                        this.$el.find(".k-spreadsheet-formula-bar").remove();
                        this.$el.find(".k-spreadsheet-sheets-bar").remove();
                        this.spreadsheet.resize();
                        this.renderFinished = true;
                        
                    }

                    return this;
                }

                /**
                 * This fixes style and format problems when pasting negative values from excel. Without that
                 * fix pasting e.g. -5,56 from excel will not work
                 * @param arg
                 */
                private valueChanged(arg) {
                    if (this.renderFinished) {
                        var changedCellsBackground = this.getChangedCellsBackgroundColor();
                        var range = <kendo.spreadsheet.Range>arg.range;
                        range.background(changedCellsBackground);
                    }
                }

                private getChangedCellsBackgroundColor():string {
                    var rgbColor = AppKitchen.UIHelper.Colors.parseColor(AppKitchen.Colors.CiColor);
                    return AppKitchen.UIHelper.Colors.rgb2rgba(rgbColor,0.07);
                }

                validateData(data: Api.Models.ChartData[], timeResolution: Mesap.Framework.Common.TimeResolution) {
                    if (data && data.length > 1 && timeResolution >= Mesap.Framework.Common.TimeResolution.Day && timeResolution !== Mesap.Framework.Common.TimeResolution.ThirtyMinutes) {
                        var numberOfDifferentTimezones = data.AsLinq<Api.Models.ChartData>()
                            .Select(cd => cd.timezone)
                            .Distinct()
                            .Count();

                        if (numberOfDifferentTimezones > 1) {
                            AppKitchen.logError("More than one timezone is not supported by the grid for time resolutions >= DAY");
                            throw "Too many timezones in grid";
                        }
                    }
                }

                dateChangedHandler() {
                    if (this.isDirty()) {
                        var template = $(AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.TimeseriesDataGrid_SaveDialog, {}));
                        template.appendTo("body");
                        var window = AppKitchen.UIHelper.Windows.openWindow(template[0], {
                            height: "130px", width: "500px", modal: true
                        });

                        template.find(".a-timeseriesdata-grid-savedialog-button-refuse").click(() => {
                            window.close();
                            this.model.refreshData(true);
                        });

                        template.find(".a-timeseriesdata-grid-savedialog-button-save").click(() => {
                            window.close();
                            this.saveChangedData();
                        });

                        return false;
                    }
                    return true;
                }

                saveChangedData() {
                    if (!this.isDirty()) {
                        return;
                    }
                    var saveRequest = this.getChangedData();
                    var notification = new Controls.Popups.Notification(AppKitchen.Strings.TimeseriesDataGrid_Notification_SaveData,
                    {
                        type: "loading",
                        closable: false
                    });
                    AppKitchen.Data.Api.updateTimeseriesData(saveRequest, response => {
                        if (!response) {
                            notification.setContent(AppKitchen.Strings.TimeseriesDataGrid_Notification_SaveDataError);
                            notification.setIcon(Controls.Popups.NotificationIcon.Error);
                            notification.setClosable(true);
                        } else {
                            notification.setContent(AppKitchen.Strings.TimeseriesDataGrid_Notification_SaveDataOK);
                            notification.setIcon(Controls.Popups.NotificationIcon.Ok);
                            notification.closeAfterTimeout(3000);
                            this.model.refreshData(true);
                        }
                    }, error => {
                        notification.setContent(AppKitchen.Strings.TimeseriesDataGrid_Notification_SaveDataError);
                        notification.setIcon(Controls.Popups.NotificationIcon.Error);
                        notification.setClosable(true);
                    });
                }

                setCellEditability(spreadsheet: kendo.ui.Spreadsheet, maxRows: number, headerRows: number) {
                    if (!this.options.editable) {
                        // disable all
                        (<any>this.spreadsheet.activeSheet().range("A1:AX" + maxRows)).enable(false);
                    } else {
                        // disable destriptive cells
                        (<any>this.spreadsheet.activeSheet().range("A1:A" + maxRows)).enable(false);
                        (<any>this.spreadsheet.activeSheet().range("A1:AX" + headerRows)).enable(false);
                    }
                }

                isDirty() {
                    var changedData = this.getChangedData();
                    return changedData.Timeseries.AsLinq<AppKitchen.Api.Models.TimeseriesDataSaveEntity>()
                        .Any(tw => tw.Data != null && tw.Data.length > 0);
                }

                getChangedData(): AppKitchen.Api.Models.TimeseriesDataSaveRequest {
                    var saveRequestData = <AppKitchen.Api.Models.TimeseriesDataSaveRequest>{
                        Timeseries: [],
                        DatabaseId: this.model.requestData.DatabaseId,
                        TimeResolution: this.model.requestData.TimeResolution
                    };

                    this.fillChangedData(saveRequestData.Timeseries);
                    return saveRequestData;
                }

                fillChangedData(chartData: AppKitchen.Api.Models.TimeseriesDataSaveEntity[]) {
                    var rowOffset = this.numberOfHeaderRows;
                    var colOffset = 1;

                    var sheet = <kendo.ui.SpreadsheetSheet>(<any>this.spreadsheet.activeSheet().toJSON());
                   

                    for (var i = 0; i < this.model.get().data.length; i++) {
                        chartData.push(<AppKitchen.Api.Models.TimeseriesDataSaveEntity>{
                            Data: [],
                            Hypothesis: this.model.get().data[i].hypothesis,
                            TimeseriesId: this.model.get().data[i].timeseriesId
                        });


                        for (var rowIdx = rowOffset; rowIdx < sheet.rows.length; rowIdx++) {
                            var rowDataIndex = rowIdx - rowOffset;
                            var colDataIndex = i;

                            var oldValue = this.model.get().data[colDataIndex].data[rowDataIndex][1];
                            var cellIdentifier = "R" + (rowIdx + 1) + "C" + (i + colOffset + 1);
                            var newValue = this.spreadsheet.activeSheet().range(cellIdentifier).value();

                            if (newValue === undefined) {
                                newValue = null;
                            }

                            if (oldValue !== newValue) {
                                var date = this.model.get().data[colDataIndex].data[rowDataIndex][0];
                                chartData[i].Data.push([date, newValue]);
                            }
                        }
                    }
                }

                getHeaderRows(data: Api.Models.ChartData[], cellBackgroundColor: string): kendo.ui.SpreadsheetSheetRow[] {
                    var retData: kendo.ui.SpreadsheetSheetRow[] = [];
                    var defaultHeaderStyle = { textAlign: "center", bold: true, background: cellBackgroundColor }
                    var i = 0;

                    var createHeaderRow = (data: Api.Models.ChartData[], style: any, getField: (chartData: Api.Models.ChartData) => string) => {
                        var spreadsheetRow: kendo.ui.SpreadsheetSheetRow = {
                            cells: []
                        }
                        spreadsheetRow.cells.push($.extend({}, defaultHeaderStyle));

                        for (i = 0; i < data.length; i++) {
                            spreadsheetRow.cells.push($.extend({ value: getField(data[i]) }, defaultHeaderStyle));
                        }
                        return spreadsheetRow;
                    }

                    retData.push(createHeaderRow(data, defaultHeaderStyle, (chartData) => chartData.timeseriesId));
                    retData.push(createHeaderRow(data, defaultHeaderStyle, (chartData) => chartData.timeseriesName));
                    retData.push(createHeaderRow(data, defaultHeaderStyle, (chartData) => chartData.unit));
                    retData[retData.length - 1].cells[0].value = AppKitchen.Strings.Date;
                    retData[0].cells[0].value = data[0].timezone;
                    retData[0].cells[0].bold = false;
                    //retData[0].cells[0].fontSize = 10;
                    return retData;
                }

                getRowData(data: Api.Models.ChartData[], timeResolution: Mesap.Framework.Common.TimeResolution): kendo.ui.SpreadsheetSheetRow[] {
                    var retArray: kendo.ui.SpreadsheetSheetRow[] = [];
                    var cellHighlightBackgroundColor = "#f3f3f3";

                    var headerRows = this.getHeaderRows(data, cellHighlightBackgroundColor);
                    headerRows.AsLinq<kendo.ui.SpreadsheetSheetRow>().ForEach(row => retArray.push(row));
                    this.numberOfHeaderRows = headerRows.length;

                    var valueFormats = this.getValueFormats(data);

                    for (let i = 0; i < data[0].data.length; i++) {
                        var row = <kendo.ui.SpreadsheetSheetRow>{
                            cells: []
                        }
                        retArray.push(row);


                        var tsData = data[0].data[i];

                        // add Date 
                        var dateString = this.getFormatDateForTimeResolution(tsData[0], timeResolution);
                        row.cells.push({ value: dateString, background: cellHighlightBackgroundColor, textAlign: "right" });

                        // add Values
                        for (let j = 0; j < data.length; j++) {
                            var n: number = data[j].data[i][1];
                            //evil hack because of wrong display of negative values -1 < x < 0 (e.g. -0.203 => -.203) inspite of correct format string
                            var format = "[>=0]" + valueFormats[j] + ";[<0]-" + valueFormats[j];
                            row.cells.push({ value: n, format: format });
                        }
                    }

                    return retArray;
                }

                private getFormatDateForTimeResolution(ticks: number, timeResolution: Mesap.Framework.Common.TimeResolution): string {
                    const formatStr = this.getDateFormat(timeResolution);
                    return moment(new Date(ticks)).format(formatStr);
                }

                private getDateFormat(timeResolution: Mesap.Framework.Common.TimeResolution) {
                    if (timeResolution < Mesap.Framework.Common.TimeResolution.Day || timeResolution === Mesap.Framework.Common.TimeResolution.ThirtyMinutes) {
                        return AppKitchen.Strings.MomentDateFormat_DayWithHour;
                    } else if (timeResolution < Mesap.Framework.Common.TimeResolution.Month) {
                        return AppKitchen.Strings.MomentDateFormat_OnlyDay;
                    } else if (timeResolution < Mesap.Framework.Common.TimeResolution.Year) {
                        return AppKitchen.Strings.MomentDateFormat_OnlyMonth;
                    } else {
                        return AppKitchen.Strings.MomentDateFormat_OnlyYear;
                    }
                }


                getColumnConfig(data: Api.Models.ChartData[]): kendo.ui.SpreadsheetSheetColumn[] {
                    var retArr: kendo.ui.SpreadsheetSheetColumn[] = [];
                    retArr.push({ width: 150 });
                    for (var i = 0; i < data.length; i++) {
                        retArr.push({ width: 150 });
                    }

                    return retArr;
                }

                getValueFormats(data: Api.Models.ChartData[]) {
                    return data.AsLinq<Api.Models.ChartData>()
                        .Select(d => {
                            if (this.options.numberFormat && this.options.numberFormat.length>0) {
                                return this.options.numberFormat;
                            }

                            var places = d.decimalsPlaces != null ? d.decimalsPlaces : this.options.defaultDecimalPlaces;
                            var format = "0";
                            for (var i = 0; i < places; i++) {
                                if (i === 0) {
                                    format += ".";
                                }
                                format += "0";
                            }
                            return format;
                        }).ToArray();
                }
            }
        }
    }
}