/// <reference path="ProviderBase.ts" />

namespace AppKitchen {

    export module Data.Provider {

        export class TreeViewProvider extends ProviderBase {

            public load(request: AppKitchen.Api.Models.TreeViewRequest):
                JQueryPromise<AppKitchen.Api.Models.TreeViewData[]> {
                return this.do("TreeView", request);
            }
        }
    }
}