namespace AppKitchen {

    // ReSharper disable InconsistentNaming
    export class ObjIdentifier {
        DatabaseId: string;
        LastTimeUpdated: string;
        type: "Inventory" | "Timeseries";

        constructor(databaseId: string) {
            this.LastTimeUpdated = new Date(Date.now()).toISOString();
            this.DatabaseId = databaseId;
        }
    }

    export class TimeseriesIdentifier extends ObjIdentifier {
        TimeseriesId: string;

        constructor(databaseId: string, timeseriesId: string) {
            super(databaseId);
            this.TimeseriesId = timeseriesId;
            this.type = "Timeseries";
        }
    }

    export class InventoryIdentifier extends ObjIdentifier {
        InventoryId: string;

        constructor(databaseId: string, inventoryId: string)
        {
            super(databaseId);
            this.InventoryId = inventoryId;
            this.type = "Inventory";
        }
    }

    export class ActionHolder {
        identifier: InventoryIdentifier | TimeseriesIdentifier;
        action: () => void;

        constructor(inventoryIdentifier: InventoryIdentifier | TimeseriesIdentifier, action: () => void) {
            this.identifier = inventoryIdentifier;
            this.action = action;
        }
    }

    export class ChangeManager {

        static pollIntervalMs = 5000;
        static trackNumber = 0;
        static trackNumber2action: { [trackNumber: number]: ActionHolder; } = {};

        public static onInventoryChanged(databaseId: string, inventoryId: string, action: () => void) {
            this.trackNumber2action[this.trackNumber] = new ActionHolder(new InventoryIdentifier(databaseId, inventoryId), action);
            return this.trackNumber++;
        }

        public static onTimeseriesChanged(databaseId: string, timeseriesId: string, action: () => void) {
            this.trackNumber2action[this.trackNumber] = new ActionHolder(new TimeseriesIdentifier(databaseId, timeseriesId), action);
            return this.trackNumber++;
        }

        static removeChangeListener(trackNumber: number) {
            delete this.trackNumber2action[trackNumber];
        }

        static getAllInventoryIdentifiers() {
            return <InventoryIdentifier[]>this.getAllIIdentifiers("Inventory");
        }

        static getAllTimeseriesIdentifiers() {
            return <TimeseriesIdentifier[]>this.getAllIIdentifiers("Timeseries");
        }

        static getAllIIdentifiers(type: string) {
            var allInventories: ObjIdentifier[] = [];
            for (var i = 0; i < this.trackNumber; i++) {
                var actionHolder = this.trackNumber2action[i];
                if (actionHolder && actionHolder.identifier.type === type) {
                    allInventories.push(actionHolder.identifier);
                }
            }
            return allInventories;
        }

        static inventoryHasChanged(inventoryIdentifier: InventoryIdentifier) {
            for (var i = 0; i < this.trackNumber; i++) {
                var actionHolder = this.trackNumber2action[i];


                if (actionHolder && actionHolder.identifier.type === "Inventory") {
                    var identifier = <InventoryIdentifier>actionHolder.identifier;

                    if ((identifier.DatabaseId === inventoryIdentifier.DatabaseId || identifier.DatabaseId == null)
                        && identifier.InventoryId === inventoryIdentifier.InventoryId) {
                        identifier.LastTimeUpdated = new Date(Date.now()).toISOString();
                        actionHolder.action();
                    }
                }
            }
        }

        static timeseriesHasChanged(timeseriesIdentifier: TimeseriesIdentifier) {
            for (var i = 0; i < this.trackNumber; i++) {
                var actionHolder = this.trackNumber2action[i];


                if (actionHolder && actionHolder.identifier.type === "Timeseries") {
                    var identifier = <TimeseriesIdentifier>actionHolder.identifier;

                    if ((identifier.DatabaseId === timeseriesIdentifier.DatabaseId || identifier.DatabaseId == null)
                        && identifier.TimeseriesId === timeseriesIdentifier.TimeseriesId) {
                        identifier.LastTimeUpdated = new Date(Date.now()).toISOString();
                        actionHolder.action();
                    }
                }
            }
        }

        static processResult(result: any) {
            window.setTimeout(this.checkForUpdates.bind(this), this.pollIntervalMs);

            if (result.InventoryIdentifiers) {
                result.InventoryIdentifiers.forEach(i => {
                    if (i.HasChanged) {
                        this.inventoryHasChanged(i);
                    }
                });
            }

            if (result.TimeseriesIdentifiers) {
                result.TimeseriesIdentifiers.forEach(i => {
                    if (i.HasChanged) {
                        this.timeseriesHasChanged(i);
                    }
                });
            }
        }

        static checkForUpdates() {
            var allInventories = this.getAllInventoryIdentifiers();
            var allTimeseries = this.getAllTimeseriesIdentifiers();
            if (allInventories.length > 0 || allTimeseries.length > 0) {
                Data.getDataApi("HasChange", { InventoryIdentifiers: allInventories, TimeseriesIdentifiers: allTimeseries }, this.processResult.bind(this));
            } else {
                window.setTimeout(this.checkForUpdates.bind(this), this.pollIntervalMs);
            }
        }

        static checkingForUpdates: boolean = false;
        public static startCheckForUpdates() {
            if (this.checkingForUpdates)
            {
                return;
            }
            this.checkForUpdates();
        }
    }

    //TODO: Verify functionality without session token cookie functionality
    export class ChangeManagerWithWebSockets extends ChangeManager {
        static webSocket: WebSocket;

        public static onInventoryChanged(databaseId: string, inventoryId: string, action: () => void) {
            this.trackNumber2action[this.trackNumber] = new ActionHolder(new InventoryIdentifier(databaseId, inventoryId), action);
            this.webSocket.send(JSON.stringify({ DatabaseId: databaseId, Id: inventoryId, ObjectType: 0}));
            return this.trackNumber++;
        }

        static checkForUpdates() {
            this.webSocket = new WebSocket("ws://" + window.location.hostname + "/Appkitchen.UI/api/Notification");

            this.webSocket.onmessage = evt => {
                var changeMessage = JSON.parse(evt.data);
                if (changeMessage.ObjectType === 0) {
                    this.inventoryHasChanged(new InventoryIdentifier(changeMessage.DatabaseId, changeMessage.Id));
                }
            }
        }
    }
}