function checkBrowser() {
    if (AppKitchen.BrowserHelper.isIE()) {
        var ieVersion = AppKitchen.BrowserHelper.IEVersion();
        if (ieVersion && ieVersion <= 10) {
            throwInternalStartupError("Incompatible browser! App requires Internet Explorer 11 or newer.", `Incompatible IE version ${ieVersion} detected, minimum required version is 11.`);
        }
    }
}

function checkCookies() {
    if (!navigator.cookieEnabled)
        throwInternalStartupError("Cookies deactivated! Please activate cookies for this session.", "Cookies deactivated. Activate cookies or contact your administrator.");
}

function throwInternalStartupError(userFriendlyMessage: string, debugMessage: string) {
    // do not use jQuery! intended to work with old browsers
    var errorInfo = document.createElement('div');
    errorInfo.className = "a-startup-error";
    errorInfo.innerHTML = `App can not be started due to the following reason:<br>${userFriendlyMessage}`;
    document.body.appendChild(errorInfo);
    throw debugMessage;
}

function cleanupStorage() {
    AppKitchen.LocalStorageHelper.cleanUp();
    window.addEventListener("storage", (e) => AppKitchen.LocalStorageHelper.storageEventHandler(e), false);
}

function getBrowserCulture() {
    const nav = window.navigator;
    const userLang = nav.language || (nav.languages ? nav.languages[0] : null);

    const cultures = {
        DE: "de-DE",
        EN: "en-US",
        FR: "fr-FR",
    }

    //map languages used in customer projects to load the respective Resources.xx-YY.cvs files
    const cultureMap = {
        "de-DE": cultures.DE,
        "de": cultures.DE,
        "en-US": cultures.EN,
        "en": cultures.EN,
        "fr-FR": cultures.FR,
        "fr": cultures.FR
    }

    let culture = cultures.EN;

    if (userLang && cultureMap[userLang]) {
        culture = cultureMap[userLang];
    } else {
        const shortLanguage = userLang.slice(0, 2);
        if (cultureMap[shortLanguage]) {
            culture = cultureMap[shortLanguage.toLowerCase()];
        }
    }

    return culture;
}

function loadScript(main: string, fallback: string, success: () => any, fail: (error) => any) {
    $.getScript(main + ".min.js", success).fail(() => {
        $.getScript(main + ".js", success).fail(() => {
            $.getScript(fallback + ".min.js", success).fail(() => {
                $.getScript(fallback + ".js", success).fail(fail);
            });
        });
    });
}

function updateColors() {
    if ($("body").length === 0)
        return;

    var allColors: string[] = [
        "CoalGray",
        "DarkGray",
        "Gray",
        "MediumGray",
        "Silver",
        "LightGray",
        "Gainsboro",
        "WhiteSmoke",
        "Orange",
        "DarkOrange",
        "OrangeRed",
        "Red",
        "Green",
        "LightBlue",
        "DarkBlue",
        "CiColor",
        "CiColorForeground",
        "CiColor2",
        "CiColor2Foreground"
    ];

    allColors.forEach(color => {
        var dummy = $("<div class='a-color-dummy-" + color + "'></div>");
        $("body").append(dummy);
        var c = AppKitchen.UIHelper.Colors.parseColor(dummy.css("color"));
        AppKitchen.Colors[color] = AppKitchen.UIHelper.Colors.rgb2Hex(c);
        dummy.remove();
    });
}

function initGeneralCode()
{
    // init general part (for portal code sharing)
    General.GeneralLogger.init(AppKitchen.log, AppKitchen.logError);
    General.TsChart.Preloadtransformations.Templates.preloadTransformationCombobox = AppKitchen.Templates.PreloadTransformation_Combobox;
    General.GeneralRenderer.init((template, data, container) => {
        AppKitchen.UIHelper.renderTemplateTo(container, template, data);
    });
}

function compareSortNr(nr1: any, nr2: any) {
    var a = Number.POSITIVE_INFINITY;
    var b = Number.POSITIVE_INFINITY;
    if (nr1) {
        a = parseInt(nr1);
    }
    if (nr2) {
        b = parseInt(nr2);
    }
    return a > b ? 1 : -1;
}
