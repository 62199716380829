namespace AppKitchen {

    export module Controls.Charts {
    
    export interface CombinedChartOptions extends AppKitchen.ViewBaseOptions {
        title: string;
        subtitle?: string;
        onClickStackedColumnChart?: (e: any) => void;
        onClickPieChart?: (e: any) => void;
        noDataMessage?: string;
    }

    interface LegendItem {
        id: string;
        name: string;
        color: string;
    }

    interface TemplateData {
        title: string;
        subtitle: string;
        legendItems: LegendItem[];
    }

    interface ChartData {
        category: string;
        value: number;
        color: string;
    }

    export class CombinedChartView extends AppKitchen.ViewBase<Charts.StackedColumnChartModel> {
        options: CombinedChartOptions;

        container: {
            legendContainer: HTMLElement,
            chartLeftContainer: HTMLElement,
            chartRightContainer: HTMLElement,
        }

        pieChardModel: PieChartModel;
        pieChartView: PieChartView;

        pieChartData: { category: string, value: number, color: string, visible: boolean }[];

        stackedColumnChartView: Charts.StackedColumnChartView;

        constructor(model: StackedColumnChartModel, target: HTMLElement, options?: CombinedChartOptions) {
            super(model, target, options);
            this.options = options;

            this.setTemplate(AppKitchen.Templates.CombinedChartApp);

            this.render();
        }

        render() {
            const legendItems = this.getLegenItems();
            this.renderTemplates(legendItems);
            this.setContainers();

            const chartData = this.getChartData();
            this.createPieChart(chartData);
            this.createStackedColumnChart(legendItems);

            return this;
        }

        private createPieChart(chartData: ChartData[]) {
            this.pieChardModel = new PieChartModel();
            this.pieChartView = new PieChartView(this.pieChardModel, this.container.chartLeftContainer, {
                noDataMessage: this.options.noDataMessage,
                onSeriesClick: this.options.onClickPieChart,
                legend: { visible: false }
            });
            this.pieChardModel.data(chartData);
            this.initPieChartLookupTable();
        }

        private createStackedColumnChart(legendItems: LegendItem[]) {
            this.stackedColumnChartView = new StackedColumnChartView(this.model, this.container.chartRightContainer, {
                legend: { visible: false },
                onSeriesClick: this.options.onClickStackedColumnChart
            });

            for (let legendItem of legendItems) {
                var legendButton = this.$el.find("#" + legendItem.id);
                legendButton.bind("click", (e) => {
                    if ($(e.target).hasClass("a-legend-item-label")) {
                        $(e.target).toggleClass("a-legend-item-selected");
                        this.onLegendItemClick(legendItem.name);
                    }
                });
            }
        }

        private renderTemplates(legendItems: LegendItem[]) {
            const templateData = this.getTemplateDaten(legendItems);
            this.renderTemplate(templateData);
            AppKitchen.UIHelper.updateFullHeightGrids(this.el);

            AppKitchen.UIHelper.renderTemplateTo(this.$el.find(".a-combined-chart-container")[0],
                AppKitchen.Templates.CombinedChart);
        }

        private getTemplateDaten(legendItems: LegendItem[]): TemplateData {
            return { title: this.options.title, subtitle: this.options.subtitle, legendItems: legendItems };
        }

        private getLegenItems(): LegendItem[] {
            var legendItems: LegendItem[] = [];
            var dataStock = this.model.get().data;

            let index = 0;
            for (let serie of dataStock.series) {
                legendItems.push({ id: `legendItem_${index++}`, name: serie.name, color: serie.color });
            }

            return legendItems;
        }

        private getChartData(): ChartData[] {
            var data: ChartData[] = [];
            var dataStock = this.model.get().data;

            for (let serie of dataStock.series) {
                var sum = serie.data.reduce((a, b) => a + b, 0);
                data.push({ category: serie.name, value: sum, color: serie.color });
            }

            return data;
        }

        private setContainers() {
            this.container = {
                legendContainer: this.$el.find(".a-combined-chart-legend-container")[0],
                chartLeftContainer: this.$el.find(".a-combined-chart-left-container")[0],
                chartRightContainer: this.$el.find(".a-combined-chart-right-container")[0]
            };
        }

        initPieChartLookupTable() {
            this.pieChartData = [];

            var pieChart = this.pieChartView.chart;

            for (let i = 0; i < pieChart.options.series[0].data.length; i++) {
                var d = pieChart.options.series[0].data[i];
                this.pieChartData.push({ category: d.category, value: d.value, color: d.color, visible: true });
            }
        }

        onLegendItemClick(category: string) {
            var columnChart = this.stackedColumnChartView.chart;
            for (let i = 0; i < columnChart.options.series.length; i++) {
                if (columnChart.options.series[i].name === category) {
                    columnChart.options.series[i].visible = !columnChart.options.series[i].visible;
                    break;
                }
            }
            columnChart.redraw();

            for (let i = 0; i < this.pieChartData.length; i++) {
                if (this.pieChartData[i].category === category) {
                    this.pieChartData[i].visible = !this.pieChartData[i].visible;
                    break;
                }
            }
            var pieChart = this.pieChartView.chart;

            var data: any[] = [];

            for (let i = 0; i < this.pieChartData.length; i++) {
                if (this.pieChartData[i].visible) {
                    data.push(this.pieChartData[i]);
                }
            }

            pieChart.options.series[0].data = data;

            pieChart.redraw();
        }
    }
    }
}