namespace AppKitchen {

    export module Data.Provider {

        export abstract class ProviderBase {

            private request: JQueryXHR;

            protected do<TDataType, TRequestType>(apiName: string, request?: TRequestType): JQueryPromise<TDataType> {
                const deferred = $.Deferred<TDataType>();

                this.request = Data.getData(apiName,
                    request,
                    (items: TDataType) => {
                        deferred.resolve(items);
                    },
                    (err, xhr) => deferred.reject(xhr.responseText));

                return deferred.promise();
            }

            public abort() {
                if (this.request) {
                    this.request.abort();
                    this.request = null;
                }
            }
        }
    }
}