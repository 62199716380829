namespace AppKitchen {

    export module Controls {

        // ReSharper disable once InconsistentNaming
        export interface InteractiveImageAttributes extends AppKitchen.ModelBaseAttributes {
            imageUrl?: string;
            minWidth?: number;
            minHeight?: number;
        }

        export class InteractiveImageModel extends AppKitchen.ModelBase<InteractiveImageAttributes> {
            constructor(imageUrl: string, minWidth?: number, minHeight?: number) {
                super({
                    imageUrl: imageUrl,
                    minWidth: minWidth,
                    minHeight: minHeight
                });
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface InteractiveImageOptions extends AppKitchen.ViewBaseOptions {
            title?: string
        }

        export class InteractiveImage extends AppKitchen.ViewBase<InteractiveImageModel> {
            imgWidth: number;
            imgHeight: number;
            options: InteractiveImageOptions;

            constructor(interactiveImageModel: InteractiveImageModel, targetContainer: HTMLElement, options?: InteractiveImageOptions) {
                super(interactiveImageModel, targetContainer, AppKitchen.OptionsHelper.merge(options, {
                    title: ""
                }));

                this.render();
                this.addDevModeIfNeeded();
            }

            addDevModeIfNeeded() {
                if (!AppKitchen.BrowserHelper.UrlQuery.getParameter("dev")) {
                    return;
                }

                var devBox = $(AppKitchen.Templates.InteractiveImage_DevBox);
                $("body").append(devBox);
                var xContainer = devBox.find(".percentage-label-x");
                var yContainer = devBox.find(".percentage-label-y");

                var imageBox = this.$el.find(".a-interactive-image-content");

                imageBox.mousemove(e => {
                    var parentOffset = imageBox.offset();
                    var relativeXPosition = (e.pageX - parentOffset.left);
                    var relativeYPosition = (e.pageY - parentOffset.top);


                    var x = relativeXPosition / (imageBox.width() / 100);
                    var y = relativeYPosition / (imageBox.height() / 100);

                    xContainer.html(kendo.toString(x, "n1") + " %");
                    yContainer.html(kendo.toString(y, "n1")  + " %");
                });

                imageBox.mouseleave(() => {
                    devBox.addClass("hide");
                });

                imageBox.mouseenter(() => {
                    devBox.removeClass("hide");
                });

            }

            render() {
                AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.InteractiveImage_Frame, { title: this.options.title });
                var img = new Image();
                var me = this;
                img.onload = function () {
                    me.imgWidth = (<any>this).width;
                    me.imgHeight = (<any>this).height;
                    me.$el.find(".a-interactive-image-box").append(img);
                    me.updateSize();
                }

                img.src = this.model.get().imageUrl;

                AppKitchen.BrowserHelper.WindowResize.on(() => this.updateSize(), this.el);
                return this;
            }

            updateSize() {
                AppKitchen.UIHelper.updateFullHeightGrids(this.el);

                var imageWidth = this.imgWidth;
                var imageHeight = this.imgHeight;
                var maxWidth = this.$el.find(".a-interactive-image-box-wrapper").width();
                var maxHeight = this.$el.find(".a-interactive-image-box-wrapper").height();

                if (this.model.get().minWidth && this.model.get().minWidth > maxWidth) {
                    maxWidth = this.model.get().minWidth;
                }

                if (this.model.get().minHeight && this.model.get().minHeight > maxHeight) {
                    maxHeight = this.model.get().minHeight;
                }

                var widthFac = maxWidth / imageWidth;
                var heightFac = maxHeight / imageHeight;

                var fac = heightFac;
                if (widthFac < heightFac) {
                    fac = widthFac;
                }

                this.$el.find(".a-interactive-image-box").width(imageWidth * fac);
                this.$el.find(".a-interactive-image-box").height(imageHeight * fac);
                this.$el.find(".a-interactive-image-box").css("font-size", imageHeight * fac + "px");
                this.$el.find(".a-interactive-image-point-wrapper").each((i, e) => {
                    $(e).width($(e).height());
                });
            }

            createElementForPoint(x: number, y: number, size: number) {
                var element = $('<div class="a-interactive-image-point-wrapper"></div>');
                element.css({
                    "position": "absolute",
                    "top": y + "%",
                    "left": x + "%",
                    "height": size + "%",
                    "font-size": size + "%"
                });
                this.$el.find(".a-interactive-image-content").append(element);
                return element[0];
            }

            createElementForText(x: number, y: number, fontSizeInPercent: number) {
                var element = $("<div></div>");
                element.css({
                    "position": "absolute",
                    "top": y + "%",
                    "left": x + "%",
                    "font-size": fontSizeInPercent + "%"
                });
                this.$el.find(".a-interactive-image-content").append(element);
                return element[0];
            }

            createElementForRectangle(x: number, y: number, width: number, height: number, fontSizeInPercent: number) {
                var element = $("<div></div>");
                element.css({
                    "position": "absolute",
                    "top": y + "%",
                    "left": x + "%",
                    "width": width + "%",
                    "height": height + "%",
                    "font-size": fontSizeInPercent + "%"
                });
                this.$el.find(".a-interactive-image-content").append(element);
                return element[0];
            }


        }
    }
}