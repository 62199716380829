namespace AppKitchen {
    
    export module LocalStorageHelper {

        interface LocalStorageListener {
            key: string;
            callback: (value: string) => void;
        }

        var listeners: LocalStorageListener[] = [];

        export function store(key: string, value: any, options?: any) {
            if (options && options.expires) {
                var storedObject = {
                    value: value,
                    expires: kendo.toString(new Date().addDays(options.expires), "yyyyMMdd HH:mm")
                }
                localStorage.setItem(key, JSON.stringify(storedObject));
            } else {
                localStorage.setItem(key, value.toString());
            }
        }

        export function read(key: string) {
            var storedString = localStorage.getItem(key);
            var storedObject = JSON.parse(storedString);
            if (storedObject && storedObject.value) {
                return storedObject.value;
            }
            return storedString;
        }

        export function cleanUp() {
            // collect expired items
            var expired: string[] = [];
            $.each(localStorage,
                (key, storedString) => {
                    try {
                        var storedObject = JSON.parse(storedString);
                        if (storedObject && storedObject.expires) {
                            var expirationDate = kendo.parseDate(storedObject.expires, "yyyyMMdd HH:mm");
                            if (new Date() >= expirationDate) {
                                expired.push(key.toString());
                            }
                        }
                    } catch (exception) {

                    }
                });
            // remove them
            for (let i = 0; i < expired.length; i++) {
                localStorage.removeItem(expired[i]);
            }
        }
        
        export function storageEventHandler(e) {
            for (let i = 0; i < listeners.length; i++) {
                if (e.key === listeners[i].key) {
                    listeners[i].callback(read(listeners[i].key));
                }
            }
        }

        export function registerListener(key: string, callback: (value: string) => void) {
            listeners.push({
                key: key,
                callback: callback
            });
        }
    }
}