namespace AppKitchen {

    export module Data {
        import TimeseriesDataSaveRequest = AppKitchen.Api.Models.TimeseriesDataSaveRequest;

        export abstract class TimeseriesDataLoaderBase extends AppKitchen.ModelBase<TimeseriesDataLoaderAttributes> {
            abstract loadData(startDate: Date, endDate: Date, timeResolution2?: Mesap.Framework.Common.TimeResolution, startDate2?: Date, endDate2?: Date, options?: TimeseriesDataLoadOptions): void;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesDataLoaderAttributes extends AppKitchen.ModelBaseAttributes {
            data?: AppKitchen.Api.Models.ChartData[];
            startDate?: Date;
            endDate?: Date;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesDataLoadOptions extends LoadOptionsBase {
            success?: (data: AppKitchen.Api.Models.ChartData[]) => void;
            fillUpWithNullValues?: boolean;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesDataSaveOptions extends LoadOptionsBase {
            success?: () => void;
        }

        export class TimeseriesDataLoader extends TimeseriesDataLoaderBase {
            loadRequest: JQueryXHR;
            saveRequest: JQueryXHR;
            timeseries: AppKitchen.Api.Models.TimeseriesSimpleDefinition[];
            timeResolution: Mesap.Framework.Common.TimeResolution;

            constructor(timeseries: AppKitchen.Api.Models.TimeseriesSimpleDefinition[], timeResolution: Mesap.Framework.Common.TimeResolution) {
                super({
                    data: [],
                    loading: false
                });

                this.timeseries = timeseries;
                this.timeResolution = timeResolution;
            }

            loadData(startDate: Date, endDate: Date,
                timeResolution2?: Mesap.Framework.Common.TimeResolution, startDate2?: Date, endDate2?: Date,
                options?: TimeseriesDataLoadOptions) {

                options = AppKitchen.OptionsHelper.merge(options,
                {
                    silent: false,
                    success: () => { },
                    error: () => { },
                    fillUpWithNullValues: true
                });

                // abort previous request
                this.abortLoadRequest();

                if (!options.silent) {
                    this.set({ loading: true });
                }

                var requestApi: (request: any,callback: any, errorCallback: any) => JQueryXHR;

                // start new request
                if (options.api) {
                    requestApi = Data.getData.bind(this, options.api);
                } else {
                    requestApi = Data.Api.getTimeseriesData;
                }

                this.loadRequest = requestApi({
                    TimeseriesSimpleDefinitions: this.timeseries,
                    TimeResolution: this.timeResolution,
                    StartDate: startDate,
                    EndDate: endDate,
                    TimeResolution2: timeResolution2,
                    StartDate2: startDate2,
                    EndDate2: endDate2,
                    FillUpWithNullValues: options.fillUpWithNullValues
                },
                    data => {
                        this.processLoadedData(data, startDate, endDate);
                        options.success(this.get().data);
                    },
                    (request) => {
                        options.error(request);
                    });
            }

            saveData(timeseries: AppKitchen.Api.Models.TimeseriesDataSaveEntity[], options?: TimeseriesDataSaveOptions) {
                options = AppKitchen.OptionsHelper.merge(options, {
                    silent: false,
                    success: () => { },
                    error: () => { }
                });

                // abort previous request
                this.abortSaveRequest();

                if (!options.silent) {
                    this.set({ loading: true });
                }

                var requestApi: (request: TimeseriesDataSaveRequest, callback: any, errorCallback: any) => JQueryXHR;

                // start new request
                if (options.api) {
                    requestApi = Data.getData.bind(this, options.api);
                } else {
                    requestApi = Data.Api.updateTimeseriesData;
                }

                this.saveRequest = requestApi({
                    TimeResolution: this.timeResolution,
                    Timeseries: timeseries
                },
                response => {
                    if (response) {
                        options.success();
                    } else {
                        options.error();
                    }
                },
                error => {
                    options.error(error);
                });
            }

            abortRequests() {
                this.abortLoadRequest();
                this.abortSaveRequest();
            }

            private abortLoadRequest() {
                if (this.loadRequest) {
                    this.loadRequest.abort();
                    this.set({ loading: false });
                }
            }

            private abortSaveRequest() {
                if (this.saveRequest) {
                    this.saveRequest.abort();
                    this.set({ loading: false });
                }
            }

            private processLoadedData(data: AppKitchen.Api.Models.ChartData[], startDate: Date, endDate: Date) {
                this.setData(data, startDate, endDate);
            }

            private setData(data: AppKitchen.Api.Models.ChartData[], startDate: Date, endDate: Date) {
                this.set({
                    data: data,
                    startDate: startDate,
                    endDate: endDate
                });
                this.set({ loading: false });
            }

        }

    }
}
