namespace AppKitchen {

    export module Controls {

        export class RangePickerOptions implements ViewBaseOptions {
            loadingOverlay?: string;
            quickNavigation: boolean;
            availableTimeRanges?: TimeRange[];
        }

        export enum TimeRange {
            Year,
            Quarter,
            Month,
            Day,
            RangeDay,
            RangeMonth,
            RangeYear
        }

        export class DateRange {
            constructor(public startDate: Date, public endDate: Date) {}
        }

        export class DateChangedEvent extends DateRange {
            constructor(public timeRange: TimeRange, startDate: Date, endDate: Date) {
                super(startDate, endDate);
            }
        }

        export enum DateShiftDirection {
            Previous,
            Next
        }

        // ReSharper disable once InconsistentNaming    
        export interface RangeCalculator {
            getDateRange(timeRange: TimeRange, from: Date, to: Date): DateRange;
            shiftDay(dateRange: DateRange, direction: DateShiftDirection): DateRange;
            shiftMonth(dateRange: DateRange, direction: DateShiftDirection): DateRange;
            shiftQuarter(dateRange: DateRange, direction: DateShiftDirection): DateRange;
            shiftYear(dateRange: DateRange, direction: DateShiftDirection): DateRange;
        }

        export class StandardRangeCalculator implements RangeCalculator {

            shiftQuarter(dateRange: DateRange, direction: DateShiftDirection): DateRange {
                switch (direction) {
                    case DateShiftDirection.Next:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth() + 3,
                                1,
                                0,
                                0,
                                0,
                                0),
                            endDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth() + 6,
                                0,
                                0,
                                0,
                                0,
                                0)
                        };
                    case DateShiftDirection.Previous:
                    default:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth() - 3,
                                1,
                                0,
                                0,
                                0,
                                0),
                            endDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth(),
                                0,
                                0,
                                0,
                                0,
                                0)
                        };
                }
            }

            shiftDay(dateRange: DateRange, direction: DateShiftDirection): DateRange {
                switch (direction) {
                    case DateShiftDirection.Next:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth(),
                                dateRange.startDate.getDate() + 1,
                                1,
                                0,
                                0,
                                0),
                            endDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth(),
                                dateRange.startDate.getDate() + 2,
                                0,
                                0,
                                0,
                                0)
                        };
                    case DateShiftDirection.Previous:
                    default:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth(),
                                dateRange.startDate.getDate() - 1,
                                1,
                                0,
                                0,
                                0),
                            endDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth(),
                                dateRange.startDate.getDate(),
                                0,
                                0,
                                0,
                                0)
                        };
                }
            }

            shiftMonth(dateRange: DateRange, direction: DateShiftDirection): DateRange {
                switch (direction) {
                    case DateShiftDirection.Next:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth() + 1,
                                1,
                                0,
                                0,
                                0,
                                0),
                            endDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth() + 2,
                                0,
                                0,
                                0,
                                0,
                                0)
                        };
                    case DateShiftDirection.Previous:
                    default:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth() - 1,
                                1,
                                0,
                                0,
                                0,
                                0),
                            endDate: new Date(dateRange.startDate.getFullYear(),
                                dateRange.startDate.getMonth(),
                                0,
                                0,
                                0,
                                0,
                                0)
                        };
                }
            }

            shiftYear(dateRange: DateRange, direction: DateShiftDirection): DateRange {
                switch (direction) {
                    case DateShiftDirection.Next:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear() + 1, 0, 1, 0, 0, 0, 0),
                            endDate: new Date(dateRange.startDate.getFullYear() + 2, 0, 0, 0, 0, 0, 0)
                        };
                    case DateShiftDirection.Previous:
                    default:
                        return {
                            startDate: new Date(dateRange.startDate.getFullYear() - 1, 0, 1, 0, 0, 0, 0),
                            endDate: new Date(dateRange.startDate.getFullYear(), 0, 0, 0, 0, 0, 0)
                        };
                }
            }

            getDateRange(timeRange: TimeRange, from: Date, to: Date) {
                switch (timeRange) {
                    case TimeRange.Year:
                        return {
                            startDate: new Date(from.getFullYear(), 0, 1, 0, 0, 0, 0),
                            endDate: new Date(from.getFullYear() + 1, 0, 0, 0, 0, 0, 0)
                        };
                    case TimeRange.Quarter:
                        return {
                            startDate: new Date(from.getFullYear(), from.getMonth(), 1, 0, 0, 0, 0),
                            endDate: new Date(from.getFullYear(), from.getMonth() + 3, 0, 0, 0, 0, 0)
                        };
                    case TimeRange.Month:
                        return {
                            startDate: new Date(from.getFullYear(), from.getMonth(), 1, 0, 0, 0, 0),
                            endDate: new Date(from.getFullYear(), from.getMonth() + 1, 0, 0, 0, 0, 0)
                        };
                    case TimeRange.Day:
                        return {
                            startDate: new Date(from.getFullYear(), from.getMonth(), from.getDate(), 1, 0, 0, 0),
                            endDate: new Date(from.getFullYear(), from.getMonth(), from.getDate() + 1, 0, 0, 0, 0)
                        };
                    case TimeRange.RangeDay:
                    case TimeRange.RangeMonth:
                        return {
                            startDate: new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, 0),
                            endDate: new Date(to.getFullYear(), to.getMonth(), to.getDate(), 0, 0, 0, 0)
                        };
                    case TimeRange.RangeYear:
                        return {
                            startDate: new Date(from.getFullYear(), 1, 0, 0, 0, 0, 0),
                            endDate: new Date(to.getFullYear(), 12, 0, 0, 0, 0, 0)
                        };
                    default:
                        throw `Case for TimeRange: ${timeRange} not implemented!`;
                }
            }
        }

        export interface RangePickerAttributes extends ModelBaseAttributes {
            dateRange: DateRange;
            timeRange: TimeRange;
        }

        export class RangePickerModel extends ModelBase<RangePickerAttributes> {

            private dateChanged: Rx.Subject<DateChangedEvent> = new Rx.Subject<DateChangedEvent>();

            private dateRangeCalculator: RangeCalculator;

            constructor(attributes?: RangePickerAttributes, dateRangeCalculator?: RangeCalculator) {
                super(OptionsHelper.merge(attributes,
                    {
                        dateRange: new DateRange(null, null),
                        timeRange: null
                    }));
                this.dateRangeCalculator = dateRangeCalculator ? dateRangeCalculator : new StandardRangeCalculator();
            }

            private equals(a: any, b: any): boolean {
                return JSON.stringify(a) === JSON.stringify(b);
            }

            getCurrentTimeRange(): TimeRange {
                return this.get().timeRange;
            }

            getCurrentRange(): DateRange {
                switch (this.get().timeRange) {
                    case TimeRange.Year:
                    case TimeRange.Quarter:
                    case TimeRange.Month:
                    case TimeRange.Day:
                    case TimeRange.RangeDay:
                    case TimeRange.RangeMonth:
                    case TimeRange.RangeYear:
                        return new DateRange(this.get().dateRange.startDate, this.get().dateRange.endDate);
                    default:
                        return { startDate: null, endDate: null };
                }
            }

            onDateChanged(): Rx.IObservable<DateChangedEvent> {
                return this.dateChanged.asObservable();
            }

            setValues(timeRange: TimeRange, start: Date, end: Date) {
                switch (timeRange) {
                    case TimeRange.Year:
                        this.setYear(start);
                        break;
                    case TimeRange.Quarter:
                        this.setQuarter(start);
                        break;
                    case TimeRange.Month:
                        this.setMonth(start);
                        break;
                    case TimeRange.Day:
                        this.setDay(start);
                        break;
                    case TimeRange.RangeDay:
                        this.setRange(start, end, TimeRange.RangeDay);
                        break;
                    case TimeRange.RangeMonth:
                        this.setRange(start, end, TimeRange.RangeMonth);
                        break;
                    case TimeRange.RangeYear:
                        this.setRange(start, end, TimeRange.RangeYear);
                        break;
                    default:
                        throw `Case for TimeRange: ${timeRange} not implemented!`;
                }
            }

            private setYear(start: Date) {
                const date = new Date(start.getFullYear(), 1);
                const newValues = this.dateRangeCalculator.getDateRange(TimeRange.Year, date, date);
                this.setValuesInternal(TimeRange.Year, newValues);
            }

            private setQuarter(start: Date) {
                const quarter = QuarterHelper.getQuarterFromDate(start);
                const date = new Date(start.getFullYear(), QuarterHelper.getMonthFromQuarter(quarter));
                const newValues = this.dateRangeCalculator.getDateRange(TimeRange.Quarter, date, date);
                this.setValuesInternal(TimeRange.Quarter, newValues);
            }

            private setMonth(start: Date) {
                const date = new Date(start.getFullYear(), start.getMonth());
                const newValues = this.dateRangeCalculator.getDateRange(TimeRange.Month, date, date);
                this.setValuesInternal(TimeRange.Month, newValues);
            }

            private setDay(start: Date) {
                const date = new Date(start.getFullYear(), start.getMonth(), start.getDate());
                const newValues = this.dateRangeCalculator.getDateRange(TimeRange.Day, date, date);
                this.setValuesInternal(TimeRange.Day, newValues);
            }

            private setRange(start: Date, end: Date, range: TimeRange) {
                const newValues = this.dateRangeCalculator.getDateRange(range, start, end);
                this.setValuesInternal(range, newValues);
            }

            shiftValues(timeRange: TimeRange, direction: DateShiftDirection) {
                switch (timeRange) {
                    case TimeRange.Year:
                        this.shiftYear(direction);
                        break;
                    case TimeRange.Quarter:
                        this.shiftQuarter(direction);
                        break;
                    case TimeRange.Month:
                        this.shiftMonth(direction);
                        break;
                    case TimeRange.Day:
                        this.shiftDay(direction);
                        break;
                    default:
                        throw `Case for TimeRange: ${timeRange} not implemented!`;
                }
            }

            private shiftYear(direction: DateShiftDirection) {
                const newValues = this.dateRangeCalculator.shiftYear(this.get().dateRange, direction);
                this.setValuesInternal(TimeRange.Year, newValues);
            }

            private shiftMonth(direction: DateShiftDirection) {
                const newValues = this.dateRangeCalculator.shiftMonth(this.get().dateRange, direction);
                this.setValuesInternal(TimeRange.Month, newValues);
            }

            private shiftDay(direction: DateShiftDirection) {
                const newValues = this.dateRangeCalculator.shiftDay(this.get().dateRange, direction);
                this.setValuesInternal(TimeRange.Day, newValues);
            }

            private shiftQuarter(direction: DateShiftDirection) {
                const newValues = this.dateRangeCalculator.shiftQuarter(this.get().dateRange, direction);
                this.setValuesInternal(TimeRange.Quarter, newValues);
            }

            private setValuesInternal(timeRange: TimeRange, dataRange: DateRange) {
                if (this.equals(this.get().dateRange, dataRange) && this.get().timeRange === timeRange) {
                    return;
                }
                this.set({
                    timeRange: timeRange,
                    dateRange: dataRange
                });
                this.dateChanged.onNext(new DateChangedEvent(timeRange, dataRange.startDate, dataRange.endDate));
            }
        }

        class Dictionary<TKey, TData> {
            private dic: { [key: string]: TData } = {};
            private keyDic: { [key: string]: TKey } = {};

            get(key: TKey) {
                const keyStr = key.toString();
                const value = this.dic[keyStr];
                if (!value) {
                    console.log(`Couldn't find any entry for key: ${keyStr}.`);
                }
                return value;
            }

            add(key: TKey, value: TData) {
                this.dic[key.toString()] = value;
                this.keyDic[key.toString()] = key;
            }

            allValues(): TData[] {
                let values: TData[] = [];
                for (let key in this.dic) {
                    if (this.dic.hasOwnProperty(key)) {
                        values.push(this.dic[key]);
                    }
                }
                return values;
            }

            allKeys(): TKey[] {
                let keys: TKey[] = [];
                for (let key in this.keyDic) {
                    if (this.keyDic.hasOwnProperty(key)) {
                        keys.push(this.keyDic[key]);
                    }
                }
                return keys;
            }
        }

        class RangePickerDomDictionary {

            private containerDictionary: Dictionary<TimeRange, HTMLElement>;
            private datePickerDictionary: Dictionary<TimeRange, JQuery>;
            private radioDictionary: Dictionary<TimeRange, JQuery>;

            private allDataPickers: JQuery;
            private allRadios: JQuery;

            constructor($el: JQuery) {
                this.containerDictionary = new Dictionary<TimeRange, HTMLElement>();
                this.datePickerDictionary = new Dictionary<TimeRange, JQuery>();
                this.radioDictionary = new Dictionary<TimeRange, JQuery>();

                this.init($el);
            }

            private init($el: JQuery) {
                this.containerDictionary.add(TimeRange.Year, $el.find(".a-year-picker")[0]);
                this.containerDictionary.add(TimeRange.Quarter, $el.find(".a-quarter-picker")[0]);
                this.containerDictionary.add(TimeRange.Month, $el.find(".a-month-picker")[0]);
                this.containerDictionary.add(TimeRange.Day, $el.find(".a-day-picker")[0]);
                this.containerDictionary.add(TimeRange.RangeDay, $el.find(".a-range-day-picker")[0]);
                this.containerDictionary.add(TimeRange.RangeMonth, $el.find(".a-range-month-picker")[0]);
                this.containerDictionary.add(TimeRange.RangeYear, $el.find(".a-range-year-picker")[0]);

                this.datePickerDictionary.add(TimeRange.Year, $el.find(".a-year-picker"));
                this.datePickerDictionary.add(TimeRange.Quarter, $el.find(".a-quarter-picker"));
                this.datePickerDictionary.add(TimeRange.Month, $el.find(".a-month-picker"));
                this.datePickerDictionary.add(TimeRange.Day, $el.find(".a-day-picker"));
                this.datePickerDictionary.add(TimeRange.RangeDay, $el.find(".a-range-day-picker"));
                this.datePickerDictionary.add(TimeRange.RangeMonth, $el.find(".a-range-month-picker"));
                this.datePickerDictionary.add(TimeRange.RangeYear, $el.find(".a-range-year-picker"));

                this.radioDictionary.add(TimeRange.Year, $el.find(".a-radio-year"));
                this.radioDictionary.add(TimeRange.Quarter, $el.find(".a-radio-quarter"));
                this.radioDictionary.add(TimeRange.Month, $el.find(".a-radio-month"));
                this.radioDictionary.add(TimeRange.Day, $el.find(".a-radio-day"));
                this.radioDictionary.add(TimeRange.RangeDay, $el.find(".a-radio-range-day"));
                this.radioDictionary.add(TimeRange.RangeMonth, $el.find(".a-radio-range-month"));
                this.radioDictionary.add(TimeRange.RangeYear, $el.find(".a-radio-range-year"));

                this.allDataPickers = $el.find(".a-range-picker-container");
                this.allRadios = $el.find(".a-radio-button");
            }

            getContainer(timeRange: TimeRange): HTMLElement {
                return this.containerDictionary.get(timeRange);
            }

            getDatePickers(): JQuery {
                return this.allDataPickers;
            }

            getDatePicker(timeRange: TimeRange): JQuery {
                return this.datePickerDictionary.get(timeRange);
            }

            getRadios(): JQuery {
                return this.allRadios;
            }

            getRadio(timeRange: TimeRange): JQuery {
                return this.radioDictionary.get(timeRange);
            }

            getSelectedTimeRange(): TimeRange {
                for (let timeRange of this.radioDictionary.allKeys()) {
                    const radio = this.radioDictionary.get(timeRange);
                    if (radio.is(":checked")) {
                        return timeRange;
                    }
                }
                return TimeRange.Year;
            }
        }

        export class RangePickerView extends ViewBase<RangePickerModel> {

            private dateRangePickerModelDictionary: Dictionary<TimeRange, DateRangePickerModel>;
            private rangePickerDomDictionary: RangePickerDomDictionary;
            private modelSubscription: Rx.IDisposable;

            private buttons: {
                navigatePrevious: JQuery;
                navigateNext: JQuery;
            }

            options: RangePickerOptions;

            constructor(model: RangePickerModel, targetContainer: HTMLElement, options: RangePickerOptions) {
                super(model,
                    targetContainer,
                    OptionsHelper.merge<RangePickerOptions>(options, { quickNavigation: false }));

                this.dateRangePickerModelDictionary = new Dictionary<TimeRange, DateRangePickerModel>();
                this.setTemplate(Templates.RangePicker);
                const initialStartDate = model.getCurrentRange().startDate;
                const initialEndDate = model.getCurrentRange().endDate;
                const initialTimeRange = model.getCurrentTimeRange();
                this.initDateRangePickerModelDictionary(initialStartDate, initialEndDate);
                this.subscribeModel();
                this.internalRender(initialTimeRange == null ? TimeRange.RangeDay : initialTimeRange);
                this.initialSelection(initialTimeRange, initialStartDate, initialEndDate);
            }

            private initDateRangePickerModelDictionary(initialStartDate: Date, initialEndDate: Date) {
                for (let timeRange of this.options.availableTimeRanges) {
                    this.dateRangePickerModelDictionary.add(timeRange,
                        new DateRangePickerModel(initialStartDate, initialEndDate));
                }
            }

            private initialSelection(timeRange: TimeRange, startDate: Date, endDate?: Date) {
                if (timeRange == null || startDate == null) return;

                this.model.setValues(timeRange, startDate, endDate);
            }

            private subscribeModel() {
                for (let timeRange of this.options.availableTimeRanges) {
                    const model = this.dateRangePickerModelDictionary.get(timeRange);
                    model.bind('change:startDate change:endDate',
                        m => {
                            var e = m.get();
                            this.model.setValues(timeRange, e.startDate, e.endDate);
                        });
                }

                this.modelSubscription = this.model.onDateChanged().subscribe(e => {
                    this.selectTimeRangeControl(e.timeRange);
                    this.showTimeRangeControl(e.timeRange);
                    this.dateRangePickerModelDictionary.get(e.timeRange)
                        .set({ startDate: e.startDate, endDate: e.endDate });
                });
            }

            render() {
                this.internalRender(this.rangePickerDomDictionary.getSelectedTimeRange());
                return this;
            }

            dispose() {
                this.modelSubscription.dispose();
            }

            private internalRender(timeRange?: TimeRange) {
                const templateData = this.getTemplateData();
                this.renderTemplate(templateData);
                this.initializeContainer();
                this.renderControls();
                if (timeRange != null) {
                    this.selectTimeRangeControl(timeRange);
                    this.showTimeRangeControl(timeRange);
                }
                this.subscribeEvents();
            }

            private getTemplateData() {
                return {
                    showYear: this.lookupTimeRangeAvailable(TimeRange.Year),
                    showQuarter: this.lookupTimeRangeAvailable(TimeRange.Quarter),
                    showMonth: this.lookupTimeRangeAvailable(TimeRange.Month),
                    showDay: this.lookupTimeRangeAvailable(TimeRange.Day),
                    showRangeDay: this.lookupTimeRangeAvailable(TimeRange.RangeDay),
                    showRangeMonth: this.lookupTimeRangeAvailable(TimeRange.RangeMonth),
                    showRangeYear: this.lookupTimeRangeAvailable(TimeRange.RangeYear),
                };
            }

            private lookupTimeRangeAvailable(timeRange: TimeRange) {
                return !this.options.availableTimeRanges
                    ? true
                    : this.options.availableTimeRanges.AsLinq<TimeRange>().Contains(timeRange);
            }

            private subscribeEvents() {
                this.rangePickerDomDictionary.getRadios()
                    .change(() => {
                        const timeRange = this.rangePickerDomDictionary.getSelectedTimeRange();
                        this.showTimeRangeControl(timeRange);

                        const model = this.dateRangePickerModelDictionary.get(timeRange);
                        this.model.setValues(timeRange, model.get().startDate, model.get().endDate);
                    });

                this.buttons.navigatePrevious.click(() => {
                    const timeRange = this.rangePickerDomDictionary.getSelectedTimeRange();
                    this.model.shiftValues(timeRange, DateShiftDirection.Previous);
                });

                this.buttons.navigateNext.click(() => {
                    const timeRange = this.rangePickerDomDictionary.getSelectedTimeRange();
                    this.model.shiftValues(timeRange, DateShiftDirection.Next);
                });
            }

            private initializeContainer() {
                this.buttons = {
                    navigatePrevious: this.$el.find(".a-navigate-left-button"),
                    navigateNext: this.$el.find(".a-navigate-right-button")
                }

                this.rangePickerDomDictionary = new RangePickerDomDictionary(this.$el);
            }

            private renderControls() {
                for (let timeRange of this.options.availableTimeRanges) {
                    let model = this.dateRangePickerModelDictionary.get(timeRange);
                    this.renderControl(timeRange, model);
                }
            }

            private renderControl(timeRange: TimeRange, model: DateRangePickerModel) {
                let container = this.rangePickerDomDictionary.getContainer(timeRange);
                let options = this.getControlOptions(timeRange);
                // ReSharper disable once WrongExpressionStatement
                new DateRangePickerView(model, container, options);
            }

            private getControlOptions(timeRange: TimeRange): DateRangePickerOptions {
                switch (timeRange) {
                    case TimeRange.Year:
                        return {
                            pickerFormat: DateRangePickerFormat.Year,
                            hideEndPicker: true,
                            labels: { from: null, to: null }
                        };
                    case TimeRange.Quarter:
                        return {
                            pickerFormat: DateRangePickerFormat.Quarter,
                            hideEndPicker: true,
                            labels: { from: null, to: null }
                        };
                    case TimeRange.Month:
                        return {
                            pickerFormat: DateRangePickerFormat.Month,
                            hideEndPicker: true,
                            labels: { from: null, to: null }
                        };
                    case TimeRange.Day:
                        return {
                            pickerFormat: DateRangePickerFormat.Date,
                            hideEndPicker: true,
                            labels: { from: null, to: null }
                        };
                    case TimeRange.RangeDay:
                        return {
                            pickerFormat: DateRangePickerFormat.Date
                        };
                    case TimeRange.RangeMonth:
                        return {
                            pickerFormat: DateRangePickerFormat.Month
                        };
                    case TimeRange.RangeYear:
                        return {
                            pickerFormat: DateRangePickerFormat.Year
                        };
                    default:
                        throw `Case for TimeRange: ${timeRange} not implemented!`;
                }
            }

            private selectTimeRangeControl(timeRange: TimeRange) {
                const radio = this.rangePickerDomDictionary.getRadio(timeRange);
                radio.prop('checked', true);
            }

            private showTimeRangeControl(timeRange: TimeRange) {
                this.rangePickerDomDictionary.getDatePickers().hide();
                this.buttons.navigatePrevious.hide();
                this.buttons.navigateNext.hide();
                const datePicker = this.rangePickerDomDictionary.getDatePicker(timeRange);
                datePicker.show();
                if (this.options.quickNavigation &&
                (timeRange === TimeRange.Year ||
                    timeRange === TimeRange.Quarter ||
                    timeRange === TimeRange.Month ||
                    timeRange === TimeRange.Day)) {
                    this.buttons.navigatePrevious.show();
                    this.buttons.navigateNext.show();
                }
            }
        }
    }
}