namespace AppKitchen {
    
    export module OptionsHelper {
        export function merge<TOptions>(customOptions: TOptions, defaultOptions: TOptions, deep?: boolean): TOptions {
            if (customOptions == null && defaultOptions == null) {
                return {} as TOptions;
            }
            if (deep == null) {
                deep = false;
            }
            if (customOptions) {
                return $.extend(deep, {}, defaultOptions, customOptions);
            }
            return defaultOptions;
        }
    }
}