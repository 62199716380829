namespace AppKitchen {

    export module Controls {

        export module Job {

            // ReSharper disable once InconsistentNaming
            export interface JobTile {
                title?: string;
                jobId: string;
            }

            // ReSharper disable once InconsistentNaming
            export interface JobStarterOptions {
                title?: string;
            }

            export type JobStartStatuses = "initial" | "starting" | "started" | "failed";

            export type LastJobRunStatuses = "Ok" | "Warning" | "Error" | "Running";

            // ReSharper disable once InconsistentNaming
            export interface JobStarterAttributes extends AppKitchen.ModelBaseAttributes {
                title?: string;
                jobId?: string;
                jobNr?: number;
                jobName?: string;
                latestRunBegin?: Date;
                latestRunEnd?: Date;
                latestRunSchedule?: Date;
                latestRunResult?: LastJobRunStatuses;
                latestRunLogNr?: number;
                configError?: string;
                startStatus?: JobStartStatuses;
            }

            export class JobStarterModel extends AppKitchen.ModelBase<JobStarterAttributes>{
                constructor(jobId: string, options?: JobStarterOptions) {
                    options = AppKitchen.OptionsHelper.merge(options, {
                        title: ""
                    });

                    super({
                        title: options.title,
                        jobId: jobId,
                        jobNr: 0,
                        jobName: jobId,
                        latestRunBegin: null,
                        latestRunEnd: null,
                        latestRunSchedule: null,
                        latestRunResult: null,
                        latestRunLogNr: null,
                        configError: null,
                        startStatus: "initial"
                    });

                    this.loadJobInfo();
                }

                updateLatestRun(onEnd?: () => void) {

                    var jobLogRequest = {
                        LatestRun: true,
                        LatestRunByBegin: true,
                        LatestRunIncludeRunning: true,
                        FilterSettings: {
                            JobNrs: [this.get().jobNr]
                        }
                    };

                    AppKitchen.Data.Api.getJobLog(jobLogRequest, jobLogs => {
                        if (jobLogs && jobLogs.length > 0) {
                            var latestRun = jobLogs[0];

                            var latestRunEnd = kendo.parseDate(latestRun["RunEnd"], "yyyy-MM-ddTHH:mm:ss");
                            if (latestRunEnd < new Date(1900, 1)) {
                                latestRunEnd = null;
                                if (latestRun["ResultLevel"] === "Unknown") {
                                    latestRun["ResultLevel"] = "Running";
                                }
                            }

                            var resetStartStatus = this.get().latestRunResult === "Running" && latestRun["ResultLevel"] !== "Running";

                            this.set({
                                latestRunBegin: kendo.parseDate(latestRun["RunBegin"], "yyyy-MM-ddTHH:mm:ss"),
                                latestRunEnd: latestRunEnd,
                                latestRunSchedule: kendo.parseDate(latestRun["PlannedSchedule"], "yyyy-MM-ddTHH:mm:ss"),
                                latestRunResult: <LastJobRunStatuses>latestRun["ResultLevel"],
                                latestRunLogNr: latestRun["PrimaryKey"],
                                startStatus: resetStartStatus ? "initial" : this.get().startStatus
                            });
                        } else {
                            this.set({
                                latestRunBegin: null,
                                latestRunEnd: null,
                                latestRunSchedule: null,
                                latestRunResult: null,
                                latestRunLogNr: null
                            });
                        }
                        this.set({ loading: false });
                        if (onEnd) {
                            onEnd();
                        }
                    });
                }

                loadJobInfo() {
                    this.set({ loading: true });

                    var jobId = this.get().jobId;
                    AppKitchen.Data.Api.getJobs({ Ids: [jobId] }, jobs => {
                        if (!jobs || jobs.length === 0) {
                            this.set({
                                configError: "Der Job mit ID <b>" + jobId + "</b> wurde nicht gefunden",
                                loading: false
                            });
                            throw "Job with ID '" + jobId + "' not found";
                        }

                        this.set({
                            jobName: jobs[0].Name,
                            jobNr: jobs[0].PrimaryKey
                        });

                        this.updateLatestRun();
                        this.set({ loading: false });
                    });
                }

                startJob(error: (requestData, xhr, statusText) => void) {
                    AppKitchen.Data.Api.startJob({ JobId: this.get().jobId },
                        result => {
                            AppKitchen.UIHelper.hideTooltips();
                            if (result) {
                                this.set({ startStatus: "started" });
                                this.updateLatestRun();
                            } else {
                                this.set({ startStatus: "failed" });
                            }
                        },
                        (requestData, xhr, statusText) => {
                            AppKitchen.UIHelper.hideTooltips();
                            this.set({ startStatus: "failed" });
                            error(requestData, xhr, statusText);
                        });
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface JobStarterViewOptions extends AppKitchen.ViewBaseOptions {
                tooltipPosition?: "top" | "right";
                showTaskLog?: boolean;
            }

            export class JobStarterView extends AppKitchen.ViewBase<JobStarterModel> {
                model: JobStarterModel;
                options: JobStarterViewOptions;

                containers: {
                    tile: JQuery,
                    content: JQuery,
                }

                buttons: {
                    jobStart: JQuery,
                }

                icons: {
                    jobStart: JQuery,
                    lastRun: JQuery,
                }

                private tileModel: Controls.Tile.StatusTileModel;
                private tileView: Controls.Tile.StatusTileView;

                constructor(model: JobStarterModel, targetContainer: HTMLElement, options?: JobStarterViewOptions) {
                    super(model, targetContainer);

                    this.options = AppKitchen.OptionsHelper.merge<JobStarterViewOptions>(options,
                        {
                            showTaskLog: false,
                            tooltipPosition: "top"
                        });
                    this.setTemplate(AppKitchen.Templates.Tile);

                    this.render();
                    this.model.bind("change", () => this.render());

                    this.activateLatestRunUpdate();

                }

                render() {
                    var lastRun = kendo.toString(this.model.get().latestRunBegin, "g");
                    var status = AppKitchen.Strings["JobLogGrid_ResultLevel_" + this.model.get().latestRunResult];
                    var statusIcon = this.getStatusIconFromStatus(this.model.get().startStatus);
                    var lastJobRunIcon = this.getLastJobRunStatus(this.model.get().latestRunResult);

                    this.tileModel = new Controls.Tile.StatusTileModel({
                        icon: statusIcon,
                        title: "Job Starter",
                        description: "Id: " + this.model.get().jobId,
                        status: `${AppKitchen.Strings.JobLogGrid_LastRun} ${lastRun} ${status}`,
                        statusIcon: lastJobRunIcon,
                        options: {
                            click: this.startJob
                        }
                    });

                    this.tileView = new Controls.Tile.StatusTileView(this.tileModel, this.el);

                    this.containers = {
                        tile: this.$el.find(".a-tile"),
                        content: this.$el.find(".a-tile-content")
                    }

                    this.buttons = {
                        jobStart: this.$el.find(".a-tile-icon")
                    }

                    this.icons = {
                        jobStart: this.$el.find(".a-tile-icon > .mesap-icon"),
                        lastRun: this.$el.find(".a-tile-status > .mesap-icon")
                    }

                    this.containers.tile.addClass("a-job-starter");

                    this.icons.jobStart.addClass(this.model.get().startStatus);
                    this.icons.lastRun.addClass(this.model.get().latestRunResult);

                    if (this.model.get().startStatus !== "failed") {
                        this.buttons.jobStart.attr("title", this.getJobStatusTooltip(this.model.get().startStatus));

                        this.buttons.jobStart.kendoTooltip({
                            position: "top",
                            show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                        });
                    }

                    if (this.model.get().configError) {
                        this.$el.append(AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.JobStarterConfigurationErrorOverlay, {
                            error: this.model.get().configError
                        }));
                        AppKitchen.UIHelper.updateFullHeightGrids(this.el);
                        return this;
                    }

                    if (this.model.get().latestRunBegin) {
                        this.containers.content.kendoTooltip({
                            position: this.options.tooltipPosition,
                            content: AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.JobStarterLatestRunTooltip,
                                {
                                    planned: kendo.toString(this.model.get().latestRunSchedule, "g"),
                                    begin: kendo.toString(this.model.get().latestRunBegin, "g"),
                                    end: kendo.toString(this.model.get().latestRunEnd, "g")
                                })
                        });

                        if (this.options.showTaskLog) {
                            var jobNr = this.model.get().jobNr;
                            var latestRun = this.model.get().latestRunBegin;
                            if (jobNr != null && latestRun != null) {
                                this.containers.content.css("cursor", "pointer");
                                this.containers.content.click(() => this.showTaskLog(jobNr, latestRun));
                            }
                        }
                    }

                    return this;
                }

                getJobStatusTooltip(jobStatus: JobStartStatuses) {
                    switch (this.model.get().startStatus) {
                        case "starting":
                            return "Job wird gestartet";
                        case "started":
                            return "Job wird gestartet";
                        case "failed":
                            return "Job fehlgeschlagen";
                        case "initial":
                        default:
                            return "Job starten";
                    }
                }

                getStatusIconFromStatus(jobStatus: JobStartStatuses) {
                    switch (jobStatus) {
                        case "starting":
                            return "icon-status-in-progress";
                        case "started":
                            return "icon-status-ok";
                        case "failed":
                            return "icon-status-fail";
                        case "initial":
                        default:
                            return "icon-playback-circled-play";
                    }
                }

                getLastJobRunStatus(lastJobRunStatus: LastJobRunStatuses) {
                    switch (lastJobRunStatus) {
                        case "Ok":
                            return "icon-status-ok";
                        case "Warning":
                            return "icon-status-warning";
                        case "Error":
                            return "icon-status-fail";
                        case "Running":
                            return "icon-playback-circled-play";
                        default:
                            return "icon-status-unknown";
                    }
                }

                showTaskLog(jobNr: number, latestRun: Date) {
                    new AppKitchen.FrameManager.AppHolder(MiniProcessMonitoringApp)
                        .startInWindow({
                            width: "80%",
                            height: "80%",
                            easyClose: true,
                            modal: true
                        }, {
                            jobName: this.model.get().jobName,
                            jobNr: jobNr,
                            latestRun: latestRun
                        });
                }

                startJob() {
                    AppKitchen.UIHelper.hideTooltips();

                    if (this.model.get().startStatus !== "initial") {
                        return;
                    }

                    this.model.set({ startStatus: "starting" });

                    this.model.updateLatestRun(() => {
                        if (this.model.get().latestRunResult === "Running") {
                            AppKitchen.UIHelper.DialogBoxes.confirm("Der Job <b>" + this.model.get().jobName + "</b> ist gerade in Arbeit. Wollen Sie trotzdem eine neue Instanz starten?", () => {
                                this.model.startJob(this.handleJobStartError.bind(this));
                            },
                                {
                                    cancel: () => this.model.set({ startStatus: "initial" })
                                });
                        } else {
                            this.model.startJob(this.handleJobStartError.bind(this));
                        }
                    });
                }

                handleJobStartError(requestData?: any, xhr?: JQueryXHR, statusText?: string) {
                    this.buttons.jobStart
                        .kendoTooltip({
                            position: "top",
                            content: xhr && xhr.statusText === "APPKITCHEN_ERROR"
                                ? xhr.responseText
                                : "Ein technischer Fehler ist aufgetreten. Weitere Informationen in der Konsole des Browsers (F12)."
                        });
                }

                activateLatestRunUpdate() {
                    var uid = AppKitchen.StringHelper.generateRandom(40);
                    this.$el.attr("data-uid", uid);
                    var handler = setInterval(() => {
                        if (this.$el.closest("body").length === 0 || this.$el.attr("data-uid") !== uid) {
                            clearInterval(handler);
                        } else {
                            this.model.updateLatestRun();
                        }
                    }, 5000);
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface MiniProcessMonitoringAppOptions extends AppKitchen.AppBaseOptions {
                jobNr: number;
                jobName: string;
                latestRun: Date;
            }

            export class MiniProcessMonitoringApp extends AppKitchen.AppBase<MiniProcessMonitoringAppOptions> {
                processMonitoringGrid: ProcessMonitoringGrid;
                datePickerModel: Controls.DateRangePickerModel;

                start() {
                    AppKitchen.UIHelper.renderLoader(this.el);

                    setTimeout(() => {
                        AppKitchen.UIHelper.renderTemplateTo(this.el,
                            AppKitchen.Templates.JobStarterProcessMonitoringApp,
                            {
                                title: this.options.jobName
                            });
                        var datePickerContainer = this.$el.find(".a-job-starter-pm-date-picker")[0];
                        var processMonitoringGridContainer = this.$el.find(".a-job-starter-pm-grids-container")[0];
                        this.$el.find(".a-job-starter-pm-filter-picker").remove();
                        $(processMonitoringGridContainer).css("margin", "0 25px 25px 25px");

                        this.datePickerModel = new Controls.DateRangePickerModel();
                        // ReSharper disable once UnusedLocals
                        var datePickerView = new Controls.DateRangePickerView(this.datePickerModel,
                            datePickerContainer,
                            {
                                dayPickers: [],
                                labels: {
                                    from: "Begonnen zwischen:",
                                    to: "und:"
                                }
                            });

                        this.processMonitoringGrid = new ProcessMonitoringGrid(processMonitoringGridContainer,
                            {
                                field: "RunBegin",
                                dir: "desc"
                            });

                        this.datePickerModel.bind("change:startDate change:endDate", () => this.updateJobLog());
                        this.datePickerModel.set({
                            startDate: this.options.latestRun.onlyDate().addDays(-6),
                            endDate: new Date().onlyDate()
                        });

                        AppKitchen.BrowserHelper.triggerWindowResizeEvent();
                    },
                        500);
                }

                updateJobLog() {

                    var filterSettings: AppKitchen.Api.Models.JobLogFilterSettings = {
                        JobNrs: [this.options.jobNr]
                    }

                    var startDate = this.datePickerModel.get().startDate;
                    var endDate = this.datePickerModel.get().endDate;

                    if (startDate && endDate) {
                        endDate = endDate.addDays(2).addMinutes(-1); // addDays(2) as Workaround for Framework bug

                        filterSettings.RunBeginDynFrom = kendo.toString(startDate, "yyyy-MM-ddTHH:mm:ss");
                        filterSettings.RunBeginDynTo = kendo.toString(endDate, "yyyy-MM-ddTHH:mm:ss");

                        this.processMonitoringGrid.updateJobLog(filterSettings);
                    }
                }

                dispose() {
                    if (this.processMonitoringGrid) {
                        this.processMonitoringGrid.dispose();
                    }
                }
            }

            export class ProcessMonitoringGrid {
                $el: JQuery;
                el: HTMLElement;
                jobLogLoader: AppKitchen.Data.JobLogLoader;
                taskLogLoader: AppKitchen.Data.TaskLogLoader;
                jobLogGridModel: Controls.Grids.JobLogGridModel;
                jobLogGridView: Controls.Grids.GridView;
                taskLogGridModel: Controls.Grids.TaskLogGridModel;

                constructor(targetContainer: HTMLElement, initialSort?: { field: string; dir: string; }) {
                    this.el = targetContainer;
                    this.$el = $(targetContainer);

                    AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.JobStarterProcessMonitoringGrid);

                    var jobLogGridContainer = this.$el.find(".a-job-starter-pm-joblog-grid")[0];
                    var taskLogGridContainer = this.$el.find(".a-job-starter-pm-tasklog-grid")[0];

                    this.jobLogLoader = new AppKitchen.Data.JobLogLoader();
                    this.jobLogGridModel = new Controls.Grids.JobLogGridModel(this.jobLogLoader);
                    this.jobLogGridView = new Controls.Grids.GridView(this.jobLogGridModel, jobLogGridContainer,
                        {
                            filterable: true,
                            fillHeight: true,
                            sortable: true,
                            lazyShow: {
                                initial: 100,
                                more: 100
                            },
                            initialSort: initialSort || {
                                field: "PlannedSchedule",
                                dir: "desc"
                            },
                            noRecordsMessage: "Keine Jobs in der aktuellen Ansicht"
                        });

                    this.taskLogLoader = new AppKitchen.Data.TaskLogLoader();
                    this.taskLogGridModel = new Controls.Grids.TaskLogGridModel(this.taskLogLoader);
                    // ReSharper disable once UnusedLocals
                    var taskLogGridView = new Controls.Grids.GridView(this.taskLogGridModel, taskLogGridContainer,
                        {
                            fillHeight: true,
                            initialSort: {
                                field: "RunBegin",
                                dir: "asc"
                            },
                            noRecordsMessage: "Keine Tasks für den ausgewählten Job"
                        });

                    var splitter = this.$el.find(".a-job-starter-pm-grids-wrapper").kendoSplitter({
                        orientation: "vertical",
                        panes: [
                            { collapsible: false, min: "30%" },
                            { collapsible: false, min: "20%" }
                        ],
                        layoutChange: () => AppKitchen.UIHelper.updateFullHeightGrids()
                    }).data("kendoSplitter");

                    splitter.size(".k-pane:first", "70%");

                    this.jobLogGridView.grid.bind("change", () => this.updateTaskLog());
                }

                updateJobLog(filterSettings: AppKitchen.Api.Models.JobLogFilterSettings) {
                    if (filterSettings) {
                        this.taskLogGridModel.set({ loading: true });
                        this.jobLogLoader.loadLogs({
                            FilterSettings: filterSettings
                        },
                            {
                                success: data => {
                                    if (!data || data.length === 0) {
                                        this.taskLogGridModel.set({ loading: false });
                                        this.taskLogGridModel.setGridData([]);
                                    }
                                    this.jobLogGridView.selectRow(0, true);
                                }
                            });
                    }
                }

                updateTaskLog() {
                    var selectedData = this.jobLogGridView.grid.dataItem(this.jobLogGridView.grid.select());
                    if (selectedData) {
                        var jobLogNr = selectedData["PrimaryKey"];
                        if (jobLogNr) {
                            this.taskLogLoader.loadLogs({ JobLogNr: jobLogNr });
                        }
                    }
                }

                dispose() {
                    if (this.jobLogLoader) {
                        this.jobLogLoader.abortRequest();
                    }
                    if (this.taskLogLoader) {
                        this.taskLogLoader.abortRequest();
                    }
                }
            }
        }
    }
}
