
namespace AppKitchen {

    export module FrameManager {

        var mainHeader: HTMLElement;

        export function hideHeader() {
            $(mainHeader).hide(0);
            $("body").css("min-width", "auto");
        }

        export enum UserMenuStyle {
            Hidden,
            Simple,
            Extended
        }

        // ReSharper disable once InconsistentNaming
        export interface UserMenuOptions extends AppKitchen.AppBaseOptions {
            userMenuStyle?: UserMenuStyle;
            /**
             * @deprecated Use userMenuStyle: 'AppKitchen.FrameManager.UserMenuStyle.Simple' instead.
             */
            simpleMenu?: boolean;
            changePassword?: boolean;
            /**
             * @deprecated Use userMenuStyle: 'AppKitchen.FrameManager.UserMenuStyle.Extended' instead.
             */
            extendedUserInfo?: boolean;
            customItems?: MenuItem[];
        }

        // ReSharper disable once InconsistentNaming
        export interface HelpMenuOptions {
            hide?: boolean;
            aboutAppName?: string;
            customItems?: MenuItem[];
        }

        // ReSharper disable once InconsistentNaming
        export interface MainAppOptions extends AppBaseOptions {
            apps: RoutedApp<AppBaseOptions>[];
            mainMenu?: MenuItem;
            userMenu?: UserMenuOptions;
            helpMenu?: HelpMenuOptions;
            footerTemplate?: string;
            template?: string;
            started?: () => void;
        }

        export class MainApp extends AppKitchen.AppBase<MainAppOptions> {
            appContainer: HTMLElement;
            mainMenuContainer: HTMLElement;
            burgerMenuContainer: HTMLElement;
            pageTitleContainer: HTMLElement;
            footerContainer: HTMLElement;

            constructor(targetContainer: HTMLElement, options?: MainAppOptions) {
                options = AppKitchen.OptionsHelper.merge(options, MainApp.getDefaultOptions(options), true);

                super(targetContainer, options);
            }

            private static getDefaultOptions(options: MainAppOptions): MainAppOptions {
                return {
                    apps: [],
                    mainMenu: undefined,
                    userMenu: {
                        userMenuStyle: MainApp.checkDeprecatedUserMenuStyleOptions(options),
                        changePassword: true,
                        customItems: []
                    },
                    helpMenu: {
                        hide: false,
                        aboutAppName: AppKitchen.Strings.About +
                            " " +
                            (options ? (options.title || options.mainMenu ? options.mainMenu.label : "") : ""),
                        customItems: []
                    },
                    template: AppKitchen.Templates.DefaultHeader,
                    title: options && options.mainMenu ? options.mainMenu.label : "",
                    started: undefined
                };
            }

            private static checkDeprecatedUserMenuStyleOptions(options: MainAppOptions): UserMenuStyle {
                if (!options.userMenu) {
                    return UserMenuStyle.Simple;
                }
                if (options.userMenu.simpleMenu) {
                    return UserMenuStyle.Simple;
                }
                if (options.userMenu.extendedUserInfo) {
                    return UserMenuStyle.Extended;
                }
                return UserMenuStyle.Simple;
            }

            start() {
                AppKitchen.UIHelper.Transitions.introduce(this.$el[0], 300, () => {
                        this.render();
                        if (this.options.started) {
                            this.options.started();
                        }
                    });
            }

            setMenu(mainMenu: MenuItem) {
                this.createMainMenu(mainMenu, this.mainMenuContainer);
                this.createBurgerMenu(mainMenu, this.burgerMenuContainer);
            }

            private createMainMenu(mainMenu: MenuItem, menuContainer: HTMLElement) {
                var menu: kendo.ui.Menu = $(menuContainer).kendoMenu({
                    dataSource: mainMenu.getMenuDefinition().items,
                    direction: $(menuContainer).attr("data-direction") || undefined,
                    close: this.handleMenuCloseEvent.bind(this),
                    select: this.handleMenuSelectEvent.bind(this),
                    open: this.handleMenuOpenEvent.bind(this)
                }).data("kendoMenu");

                this.updateTabindex(menuContainer, 1);

                // open in new window links
                $(menuContainer).find(".a-menu-new-window > a").attr("target", "_blank");

                // force navigate links
                $(menuContainer).find(".a-menu-force-navigate a").click((e) => {
                    var href = $(e.target).attr("href") || "";
                    if (href.beginsWith("#")) {
                        e.preventDefault();
                        AppKitchen.getRouter().navigate(href, true);
                    }
                });

                // highlight selected items with highlightOnNavigate option
                this.highlightMenuItemForRoute($(this.appContainer).attr("app-route"), menuContainer);

                // open selected items with keepOpenOnSelected option
                if (menu) {
                    menu.open($(menuContainer).find(".a-menu-selected.a-menu-keep-on-selected"));

                    $(this.appContainer).on("app-starting", () => {
                            this.highlightMenuItemForRoute($(this.appContainer).attr("app-route"), menuContainer);
                            menu.close($(menuContainer).find(".a-menu-keep-on-selected"));
                            menu.open($(menuContainer).find(".a-menu-selected.a-menu-keep-on-selected"));
                        });
                }

                AppKitchen.UIHelper.updateFullHeightGrids();
            }

            private createBurgerMenu(mainMenu: MenuItem, menuContainer: HTMLElement) {

                var burgerMenuDataSource = [];

                burgerMenuDataSource.push(this.getBurgerMenuDefinition());

                $(menuContainer).kendoMenu({
                    dataSource: burgerMenuDataSource,
                    direction: $(menuContainer).attr("data-direction") || undefined,
                    openOnClick: true
                });

                let dropDownContainer = $(menuContainer).find(".a-burger-menu ul.k-menu-group")[0];
                let burgerMenuContainer =
                    $('<div></div>', { 'class': 'a-burger-menu-container' }).appendTo(dropDownContainer);

                this.createBurgerMenuList(burgerMenuContainer, mainMenu, menuContainer);

                AppKitchen.UIHelper.updateFullHeightGrids();

                $(window).on("resize load", () => {
                        let height = $(dropDownContainer).height();
                        let screenHight = $(window).height() - 60;
                        if (height > screenHight) {
                            $(burgerMenuContainer).height(screenHight);
                        }
                    }).resize();

                this.createMenuIcon($(menuContainer).find(".a-burger-menu"), "menu-32px");


                this.highlightBurgerMenuItemForRoute($(this.appContainer).attr("app-route"), menuContainer);
            }

            private createBurgerMenuList(burgerMenuContainer: JQuery, mainMenu: MenuItem, menuContainer: HTMLElement) {
                burgerMenuContainer.empty();
                let ul = $('<ul></ul>', { 'class': 'a-menu-list' }).appendTo(burgerMenuContainer);
                for (let child of mainMenu.children) {
                    let li = this.renderBurgerMenuItem(ul, child, "a-menu-first-items", menuContainer);

                    if (child.children) {
                        let subUl = $('<ul></ul>').appendTo(li);
                        for (let subChild of child.children) {
                            let subLi = this.renderBurgerMenuItem(subUl,
                                subChild,
                                "a-menu-second-items",
                                menuContainer);

                            if (subChild.children) {
                                let subSubUl = $('<ul></ul>').appendTo(subLi);
                                for (let subSubChild of subChild.children) {
                                    this.renderBurgerMenuItem(subSubUl,
                                        subSubChild,
                                        "a-menu-third-items",
                                        menuContainer);
                                }
                            }
                        }
                    }
                }
            }

            private renderBurgerMenuItem(menuLayerUl: JQuery,
                menuItem: MenuItem,
                cssClass: string,
                menuContainer: HTMLElement): JQuery {
                let menuItemLi = $('<li></li>', { 'class': `${cssClass} a-menu-highlight-on-navigate` }).appendTo(menuLayerUl);
                if (menuItem.options.route) {
                    $(`<a href="#/${menuItem.options.route}/">${menuItem.label}</a>`).appendTo(menuItemLi);
                    menuItemLi.click(() => this.highlightBurgerMenuItemForRoute(menuItem.options.route, menuContainer));
                } else if (menuItem.options.url) {
                    let urlAnchor = $(`<a href="${menuItem.options.url}">${menuItem.label}</a>`);
                    if (menuItem.options.newWindow) {
                        urlAnchor.attr("target", "_blank");
                    }
                    urlAnchor.appendTo(menuItemLi);
                } else {
                    $('<p></p>', { text: menuItem.label }).appendTo(menuItemLi);
                }
                return menuItemLi;
            }

            private updateTabindex(menuContainer: HTMLElement, offset: number) {
                if (menuContainer == null) {
                    return;
                }
                menuContainer.removeAttribute("tabindex");
                $(menuContainer).find("> .k-item").each((i, e) => {
                    $(e).prop("tabindex", i + offset);
                });
            }

            private handleMenuCloseEvent(event: kendo.ui.MenuCloseEvent) {
                var $el = $(event.item);
                if ($el.hasClass("a-menu-keep-on-selected") && $el.hasClass("a-menu-selected")) {
                    event.preventDefault();
                }
                this.updateSubmenuOpenedFlag(event.sender.element[0]);
            }

            private handleMenuOpenEvent(event: kendo.ui.MenuCloseEvent) {
                this.updateSubmenuOpenedFlag(event.sender.element[0]);
            }

            private updateSubmenuOpenedFlag(menuContainer: HTMLElement) {
                if ($(menuContainer).find(".a-menu-keep-on-selected.a-menu-selected").length > 0) {
                    $(mainHeader).addClass("a-submenu-open");
                } else {
                    $(mainHeader).removeClass("a-submenu-open");
                }
            }

            private updatePageTilte(name: string) {
                if (this.pageTitleContainer) {
                    this.pageTitleContainer.innerText = name;
                }
            }

            private handleMenuSelectEvent(event: kendo.ui.MenuSelectEvent) {
                // highlight on navigate
                var hrefParser = /#\/(.+)\//.exec($(event.item).find("> a").attr("href"));
                if (hrefParser && hrefParser.length > 1) {
                    var route = hrefParser[1];
                    this.highlightMenuItemForRoute(route, event.sender.element[0]);

                    // close other items with keepOpenOnSelected option
                    event.sender.close($(event.sender.element).find(".a-menu-keep-on-selected"));
                }
            }

            highlightBurgerMenuItemForRoute(route: string, menuContainer: HTMLElement) {
                $(menuContainer).find(".a-menu-selected").removeClass("a-menu-selected");
                $(menuContainer).find(".a-menu-highlight-on-navigate").each((i, e) => {
                    var href = $(e).find("> a").attr("href") || "";
                    if (href.beginsWith("#/" + route + "/")) {
                        $(e).addClass("a-menu-selected");

                        this.updatePageTilte($(e).find("> a").text());
                    }
                });
                if (route === "") {
                    this.updatePageTilte("");
                }
            }

            highlightMenuItemForRoute(route: string, menuContainer: HTMLElement) {
                $(menuContainer).find(".a-menu-selected").removeClass("a-menu-selected");
                $(menuContainer).find(".a-menu-highlight-on-navigate").each((i, e) => {
                    var href = $(e).find("> a").attr("href") || "";
                    if (href.beginsWith("#/" + route + "/")) {
                        $(e).addClass("a-menu-selected");
                        $(e).parents(".k-item[role=menuitem]").addClass("a-menu-selected");
                    }
                });

                this.highlightBurgerMenuItemForRoute(route, this.burgerMenuContainer);
            }

            registerRoutedApp(app: RoutedApp<AppBaseOptions>, targetContainer: HTMLElement) {
                var router = AppKitchen.getRouter();
                var appRoute = (app.route === "") ? "*path" : app.route + "/*path";
                router.route(appRoute, app.route, () => {
                        AppKitchen.UIHelper.hideTooltips();
                        document.title = (app.defaultOptions.title ? app.defaultOptions.title + " - " : "") + this.options.title;
                        app.startRoutedApp(targetContainer, { parent: this });
                    });
            }

            private render() {
                AppKitchen.UIHelper.renderTemplateTo(this.$el[0], this.options.template, {
                        title: this.options.title
                    });

                document.title = this.options.title;

                mainHeader = this.$el.find(".a-main > .a-header")[0];
                this.appContainer = this.$el.find(".a-main > .a-content")[0];
                this.mainMenuContainer = this.$el.find(".a-main > .a-header .a-header-menu")[0];
                var userMenuContainer = this.$el.find(".a-main > .a-header .a-header-user-menu")[0];
                this.burgerMenuContainer = this.$el.find(".a-main > .a-header .a-header-burger-menu")[0];
                this.pageTitleContainer = this.$el.find(".a-main > .a-header .a-header-page-title")[0];
                this.footerContainer = this.$el.find(".a-main > .a-footer")[0];


                // Routing
                this.options.apps.forEach(app => {
                    this.registerRoutedApp(app, this.appContainer);
                });

                Backbone.history.stop();
                Backbone.history.start();

                // Menus
                if (this.options.mainMenu) {
                    this.createMainMenu(this.options.mainMenu, this.mainMenuContainer);

                    this.createBurgerMenu(this.options.mainMenu, this.burgerMenuContainer);
                }

                if (this.getUserName()) {

                    var userMenuDataSource = [];
                    if (!this.options.helpMenu.hide) {
                        userMenuDataSource.push(this.getHelpMenuDefinition());
                    }
                    if (this.options.userMenu.userMenuStyle !== UserMenuStyle.Hidden) {
                        userMenuDataSource.push(this.getUserMenuDefinition());
                    }

                    if (!(this.options.helpMenu.hide && this.options.userMenu.userMenuStyle === UserMenuStyle.Hidden)) {

                        $(userMenuContainer).kendoMenu(<kendo.ui.MenuOptions>{
                            dataSource: userMenuDataSource,
                            direction: $(userMenuContainer).attr("data-direction") || undefined,
                            openOnClick: true
                        });

                        this.updateTabindex(userMenuContainer, 8);

                        // open in new window links
                        $(userMenuContainer).find(".a-menu-new-window > a").attr("target", "_blank");

                        if (this.options.userMenu.userMenuStyle !== UserMenuStyle.Hidden) {
                            this.configureUserMenu($(userMenuContainer).find(".a-user-menu"));
                        }

                        if (!this.options.helpMenu.hide) {
                            this.configureHelpMenu($(userMenuContainer).find(".a-help-menu"));
                        }
                    }
                }

                // Footer
                if (AppKitchen.GlobalSettings.showFooter) {
                    this.createFooter();
                }

                // update full height grids
                AppKitchen.UIHelper.updateFullHeightGrids(this.el);

                // navigate event on browser navigation
                window.onpopstate = () => {
                    $("body").trigger("navigate");
                }
            }

            private getBurgerMenuDefinition() {
                var burgerMenu = new MenuItem("Menu", {}, []);
                var menuDefinition = burgerMenu.getMenuDefinition();

                menuDefinition.cssClass = "a-burger-menu";

                return menuDefinition;
            }

            private getUserMenuDefinition() {
                var userMenu = new MenuItem(this.getUserName(), {}, this.options.userMenu.customItems || []);
                var menuDefinition = userMenu.getMenuDefinition();

                menuDefinition.cssClass = "a-user-menu";

                // add logout item
                menuDefinition["items"].push({ text: AppKitchen.Strings.Logout, cssClass: "a-logout-link" });

                return menuDefinition;
            }

            private getHelpMenuDefinition() {
                var helpMenu = new MenuItem(AppKitchen.Strings.Help, {}, this.options.helpMenu.customItems || []);
                var menuDefinition = helpMenu.getMenuDefinition();

                menuDefinition.cssClass = "a-help-menu";

                // add logout item
                menuDefinition["items"].push({ text: this.options.helpMenu.aboutAppName, cssClass: "a-about-link" });
                if (AppKitchen.GlobalSettings.userManualEnabled)
                    menuDefinition["items"].push(
                        { text: AppKitchen.Strings.UserManual, cssClass: "a-user-manual-link" });

                if (AppKitchen.GlobalSettings.contextSensitiveHelpEnabled && !AppKitchen.BrowserHelper.isIE())
                    menuDefinition["items"].push({
                        text: AppKitchen.Strings.ContextSensitiveHelp,
                        cssClass: "a-assistant-link"
                    });

                return menuDefinition;
            }

            private configureUserMenu(menuContainer: JQuery) {
                // user icon with tooltip
                this.createMenuIcon(menuContainer, "user");

                // menu app
                if (this.options.userMenu.userMenuStyle === UserMenuStyle.Extended) {
                    // ReSharper disable once WrongExpressionStatement
                    new AppKitchen.Apps.ExtendedUserMenuApp(menuContainer.find("ul.k-menu-group")[0],
                        this.options.userMenu);
                    menuContainer.find("ul.k-menu-group").addClass("a-extended-menu-wrapper");
                }

                // logout action
                menuContainer.find(".a-logout-link").click(e => {
                    e.preventDefault();
                    this.$el.fadeOut(400, () => {
                            setTimeout(() => this.logout(), 300);
                        });
                });
            }

            private configureHelpMenu(menuContainer: JQuery) {
                // icon with tooltip
                this.createMenuIcon(menuContainer, "help");

                // about action
                menuContainer.find(".a-about-link").click(e => {
                    e.preventDefault();
                    new AppHolder(AppKitchen.Apps.CreditsApp).startInWindow({
                        maxWidth: "53em",
                        width: "100%",
                        maxHeight: "30em",
                        height: "calc(100% - 48px)",
                        title: AppKitchen.Strings.About,
                        buttons: [
                            {
                                name: Strings.Close,
                                icon: "close",
                                action: (window: kendo.ui.Window) => { window.close() }
                            }
                        ],
                        modal: true,
                        easyClose: true,
                        center: true,
                        windowCssClass: "a-about-window"
                    });
                });
                // about action
                menuContainer.find(".a-user-manual-link").click(e => {
                    e.preventDefault();
                    window.open(AppKitchen.GlobalSettings.userManualUrl, "_blank");
                });

                if (AppKitchen.GlobalSettings.contextSensitiveHelpEnabled && !AppKitchen.BrowserHelper.isIE()) {

                    let model = new AppKitchen.Controls.ContextSensitiveHelpModel(RoutedAppHolder.activeApp.route);
                    let view = new AppKitchen.Controls.ContextSensitiveHelpView(model, $('body').find(".a-help")[0]);
                    menuContainer.find(".a-assistant-link").click(e => { e.preventDefault(); model.set({ show: true }); });
                }
            }

            private createMenuIcon(menuContainer: JQuery, icon: string) {
                var menu: kendo.ui.Menu = menuContainer.closest(".k-menu").data("kendoMenu");

                var menuLabel = menuContainer.find(" > span.k-link").text();
                menuContainer.find("> span.k-link").html('<i class="mesap-icon icon-' + icon + '"></i>');

                var tooltip: kendo.ui.Tooltip = menuContainer.find("> span.k-link").css("padding-left", "36px")
                    .kendoTooltip({
                        content: menuLabel,
                        position: "bottom",
                        show: e => {
                            if (menuContainer.find(".k-animation-container").is(":visible")) {
                                e.sender.hide();
                            }
                            AppKitchen.UIHelper.hideTooltips(e.sender);
                        },
                        showAfter: 300
                    }).data("kendoTooltip");

                if (menu) {
                    // hack: do not show tooltip when menu is open
                    menu.bind("open",
                        () => {
                            if (tooltip && tooltip.popup) {
                                tooltip.hide();
                                tooltip.popup.wrapper.css("opacity", 0);
                            }
                        });

                    menu.bind("close",
                        () => {
                            if (tooltip && tooltip.popup) {
                                tooltip.popup.wrapper.css("opacity", "");
                            }
                        });
                }
            }

            private createFooter() {
                if (this.options.footerTemplate) {
                    AppKitchen.UIHelper.renderTemplateTo(this.footerContainer, this.options.footerTemplate);
                } else {
                    AppKitchen.UIHelper.renderTemplateTo(this.footerContainer,
                        Templates.Footer,
                        {
                            copyright: AppKitchen.GlobalSettings.applicationCopyright,
                            cookieSettings: `${AppKitchen.GlobalSettings.securityTokenServiceOAuthUrl}CookieSettings`,
                            privacy: `${AppKitchen.GlobalSettings.securityTokenServiceOAuthUrl}Privacy`,
                            imprint: `${AppKitchen.GlobalSettings.securityTokenServiceOAuthUrl}Imprint`
                        });
                }
            }

            getUserName(): string {
                return LoginManager.getCurrentUser();
            }

            logout() {
                LoginManager.logout(true);
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface MenuItemOptions {
            route?: string;
            parameters?: { [key: string]: string };
            url?: string;
            newWindow?: boolean;
            forceNavigate?: boolean;
            highlightOnNavigate?: boolean;
            keepOpenOnSelected?: boolean;
            cssClass?: string;
        }

        export class MenuItem {
            label: string;
            children: MenuItem[];
            options: MenuItemOptions;

            constructor(label: string, options?: MenuItemOptions, children?: MenuItem[]) {
                this.label = label;
                this.children = children;
                this.options = AppKitchen.OptionsHelper.merge<MenuItemOptions>(options, {
                        route: undefined,
                        parameters: undefined,
                        url: undefined,
                        forceNavigate: false,
                        newWindow: false,
                        keepOpenOnSelected: false,
                        highlightOnNavigate: false,
                        cssClass: ""
                    });
            }

            visitChildren(visitor: (item: MenuItem) => void, includeSelf: boolean): void {
                if (includeSelf) {
                    visitor(this);
                }

                if (!this.children) {
                    return;
                }

                this.children.forEach(child => {
                    visitor(child);
                    child.visitChildren(visitor, false);
                });
            }

            getMenuDefinition() {
                let definition: { [key: string]: any } = { text: this.label }

                if (this.options.url) {
                    definition["url"] = this.options.url;
                } else {
                    if (this.options.route != null) {
                        definition["url"] = this.options.route ? "#/" + this.options.route + "/" : "#";
                        if (this.options.parameters) {
                            definition["url"] += "?" + $.param(this.options.parameters);
                        }
                    }
                }

                if (this.children) {
                    var items = [];
                    this.children.forEach((item) => items.push(item.getMenuDefinition()));
                    definition["items"] = items;
                }

                definition["cssClass"] = this.options.cssClass || "";

                if (this.options.newWindow) {
                    definition["cssClass"] += " a-menu-new-window";
                } else if (this.options.forceNavigate) {
                    definition["cssClass"] += " a-menu-force-navigate";
                }

                if (this.options.highlightOnNavigate) {
                    definition["cssClass"] += " a-menu-highlight-on-navigate";
                }

                if (this.options.keepOpenOnSelected) {
                    definition["cssClass"] += " a-menu-keep-on-selected";
                }

                return definition;
            }
        }

        module RoutedAppHolder {
            export var activeApp: RoutedApp<AppBaseOptions> = null;
        }

        export class RoutedApp<TAppOptions extends AppBaseOptions> {
            appClass: IAppBase<TAppOptions>;
            route: string;
            defaultOptions: TAppOptions;

            protected isSingleton;
            singletonBackup: { app: AppBase<TAppOptions>, container: JQuery };
            runningApp: AppBase<TAppOptions>;

            constructor(appClass: IAppBase<TAppOptions>, route: string, defaultOptions?: TAppOptions) {
                this.appClass = appClass;
                this.route = route;
                this.defaultOptions = defaultOptions || {} as TAppOptions;
                this.isSingleton = false;
            }

            startRoutedApp(container: HTMLElement, customOptions?: TAppOptions) {
                var containerEmpty = $(container).children().length === 0;
                AppKitchen.UIHelper.Transitions.swap(container, containerEmpty ? 0 : 150, 300, () => {

                        if (RoutedAppHolder.activeApp && RoutedAppHolder.activeApp.isSingleton) {
                            this.backupPreviousApp(container);
                        } else {
                            this.disposePreviousApp();
                        }

                        $(container).empty();
                        $(container).attr("app-route", this.route);
                        $(container).trigger("app-starting");
                        $("body").trigger("navigate");

                        if (!this.tryRestoreApp(container)) {
                            this.runningApp = this.startAppInternal(container, customOptions);
                        }

                        RoutedAppHolder.activeApp = this;

                        AppKitchen.UIHelper.updateFullHeightGrids(container.parentElement);
                        AppKitchen.UIHelper.clearSelection();
                    });
            }

            startInWindow(windowOptions?: AppKitchen.UIHelper.Windows.WindowOptions, customOptions?: TAppOptions): kendo.ui.Window {
                var appContainer = $('<div class="a-app-window"></div>').appendTo("body");

                // hack to start app in correct window height
                var wOptions = AppKitchen.OptionsHelper.merge(windowOptions, {
                        height: "80%",
                        minHeight: "auto"
                    });

                appContainer.css({
                    "width": wOptions.width,
                    "height": wOptions.height,
                    "min-width": wOptions.minWidth,
                    "min-height": wOptions.minHeight
                });

                this.runningApp = this.startAppInternal(appContainer[0], customOptions);

                appContainer.css({
                    "width": "",
                    "height": "",
                    "min-width": "",
                    "min-height": ""
                });

                var popupWindow = AppKitchen.UIHelper.Windows.openWindow(appContainer[0], windowOptions);
                appContainer.parent().append("<div class='a-app-window-outside'></div>");

                popupWindow.bind("deactivate", () => this.runningApp.dispose());

                appContainer.focus();


                return popupWindow;
            }

            private startAppInternal(targetContainer: HTMLElement, customOptions?: TAppOptions): AppBase<TAppOptions> {
                // ReSharper disable once InconsistentNaming
                return new this.appClass(targetContainer,
                    AppKitchen.OptionsHelper.merge(customOptions, this.defaultOptions));
            }

            private backupPreviousApp(appContainer: HTMLElement) {
                if ($(appContainer).attr("app-route") === RoutedAppHolder.activeApp.route &&
                    $(appContainer).children().length > 0) {
                    RoutedAppHolder.activeApp.runningApp.suspend();
                    RoutedAppHolder.activeApp.singletonBackup = {
                        app: RoutedAppHolder.activeApp.runningApp,
                        container: $(appContainer).children()
                    };
                    $(appContainer).children().detach();
                }
            }

            private disposePreviousApp() {
                if (RoutedAppHolder.activeApp && RoutedAppHolder.activeApp.runningApp) {
                    RoutedAppHolder.activeApp.runningApp.dispose();
                }
            }

            private tryRestoreApp(targetContainer: HTMLElement) {
                if (this.isSingleton && this.singletonBackup && this.singletonBackup.container.length > 0) {
                    $(targetContainer).append(this.singletonBackup.container);
                    this.singletonBackup.app.continue();
                    return true;
                }
                return false;
            }
        }

        export class SingeltonRoutedApp<TAppOptions extends AppBaseOptions> extends RoutedApp<TAppOptions> {
            constructor(app: IAppBase<TAppOptions>, route: string, defaultOptions?: TAppOptions) {
                super(app, route, defaultOptions);
                this.isSingleton = true;
            }
        }

        export class AppHolder<TAppOptions> {
            AppClass: IAppBase<TAppOptions>;
            defaultOptions: TAppOptions;
            runningApp: AppBase<TAppOptions>;

            constructor(appClass: IAppBase<TAppOptions>, defaultOptions?: TAppOptions) {
                this.AppClass = appClass;
                this.defaultOptions = defaultOptions || {} as TAppOptions;
            }

            startInContainer(container: HTMLElement, customOptions?: TAppOptions) {
                var containerEmpty = $(container).children().length === 0;
                AppKitchen.UIHelper.Transitions.swap(container, containerEmpty ? 0 : 150, 300, () => {
                        $(container).empty();
                        this.startAppInternal(container, customOptions);
                        AppKitchen.UIHelper.updateFullHeightGrids(container);
                    });
            }

            startInWindow(windowOptions?: AppKitchen.UIHelper.Windows.WindowOptions, customOptions?: TAppOptions): kendo.ui.Window {
                var appContainer = $('<div class="a-app-window"></div>').appendTo("body");

                // hack to start app in correct window height
                var wOptions = AppKitchen.OptionsHelper.merge(windowOptions, {
                        height: "80%",
                        minHeight: "auto"
                    });

                appContainer.css({
                    "width": wOptions.width,
                    "height": wOptions.height,
                    "min-width": wOptions.minWidth,
                    "min-height": wOptions.minHeight
                });

                this.startAppInternal(appContainer[0], customOptions);

                appContainer.css({
                    "width": "",
                    "height": "",
                    "min-width": "",
                    "min-height": ""
                });

                var popupWindow = AppKitchen.UIHelper.Windows.openWindow(appContainer[0], windowOptions);
                appContainer.parent().append("<div class='a-app-window-outside'></div>");

                popupWindow.bind("deactivate", () => this.runningApp.dispose());

                appContainer.focus();

                return popupWindow;
            }

            private startAppInternal(targetContainer: HTMLElement, customOptions?: TAppOptions): void {
                if (this.runningApp) {
                    this.runningApp.dispose();
                }
                this.runningApp = new this.AppClass(targetContainer, AppKitchen.OptionsHelper.merge(customOptions, this.defaultOptions));

            }
        }
    }
}