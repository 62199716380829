namespace AppKitchen {

    export module Data.Provider {

        export abstract class ProviderMapCachedBase {

            private data: { [key: string]: any } = {};
            private request: JQueryXHR;

            protected do<TDataType, TRequestType>(apiName: string, request?: TRequestType, reloadRequest?: boolean):
                JQueryPromise<TDataType> {
                const deferred = $.Deferred<TDataType>();
                const key = this.getKey(request);

                if (!reloadRequest && this.data[key]) {
                    deferred.resolve(this.data[key]);
                } else {
                    this.request = Data.getData(apiName,
                        request,
                        (items: TDataType) => {
                            this.data[key] = items;
                            deferred.resolve(items);
                        },
                        (err, xhr) => deferred.reject(xhr.responseText));
                }

                return deferred.promise();
            }

            private getKey<TRequestType>(request: TRequestType): string {
                return this.getHashCode(request).toString();
            }

            private getHashCode<TRequestType>(request: TRequestType): number {
                return request ? Object.GetHashCode(JSON.stringify(request)) : 0;
            }

            public abort() {
                if (this.request) {
                    this.request.abort();
                    this.request = null;
                }
            }
        }
    }
}