namespace AppKitchen {
    export module Data {

        // ReSharper disable InconsistentNaming
        export interface JobLog {
            PrimaryKey: number;
            PlannedSchedule: Date;
            ResultLevel: string;
            RunBegin: Date;
            RunEnd: Date;
            JobId: string;
            JobNr: number;
            RunUserId: string;
            RunComputer: string;
            TechnicalError: string;
        }
        // ReSharper restore InconsistentNaming

        // ReSharper disable once InconsistentNaming
        export interface JobLogLoaderAttributes {
            data?: JobLog[];
            loading?: boolean;
        }

        // ReSharper disable once InconsistentNaming
        export interface JobLogLoadOptions extends LoadOptionsBase {
            success?: (data: JobLog[]) => void;
        }

        export class JobLogLoader extends AppKitchen.ModelBase<JobLogLoaderAttributes> {
            request: JQueryXHR;

            constructor() {
                super({
                    data: [],
                    loading: false
                });
            }

            loadLogs(request: AppKitchen.Api.Models.JobLogRequest, options?: JobLogLoadOptions) {
                options = AppKitchen.OptionsHelper.merge(options, {
                    silent: false,
                    success: () => {}
                });

                // abort previous request
                this.abortRequest();

                if (!options.silent) {
                    this.set({ loading: true });
                }

                // start new request
                this.request = Data.Api.getJobLog(request, data => {
                    this.processLoadedData(data);
                    options.success(this.get().data);
                }, (request) => {
                    options.error(request);
                });
            }
            
            abortRequest() {
                if (this.request) {
                    this.request.abort();
                    this.set({ loading: false });
                }
            }

            private processLoadedData(data: AppKitchen.Api.Models.JobLog[]) {
                var jobLogs: JobLog[] = [];
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        var jobLog: JobLog = <any>data[i];
                        jobLog.PlannedSchedule = kendo.parseDate(data[i].PlannedSchedule, "yyyy-MM-ddTHH:mm:ss");
                        jobLog.RunBegin = kendo.parseDate(data[i].RunBegin, "yyyy-MM-ddTHH:mm:ss");
                        jobLog.RunEnd = kendo.parseDate(data[i].RunEnd, "yyyy-MM-ddTHH:mm:ss");
                        if (jobLog.RunEnd <= new Date(1900, 0, 1)) {
                            jobLog.RunEnd = null;
                        }
                        jobLogs.push(jobLog);
                    }
                }

                this.setData(jobLogs);
            }

            private setData(data: JobLog[]) {
                this.set({ data: data });
                this.set({ loading: false });
            }

        }


        // ReSharper disable InconsistentNaming
        export interface TaskLog {
            PrimaryKey: number;
            PlannedSchedule: Date;
            ResultLevel: string;
            RunBegin: Date;
            RunEnd: Date;
            JobId: string;
            JobNr: number;
            RunUserId: string;
            RunComputer: string;
            TechnicalError: string;
        }
        // ReSharper restore InconsistentNaming

        // ReSharper disable once InconsistentNaming
        export interface TaskLogLoaderAttributes {
            data?: JobLog[];
            loading?: boolean;
        }

        // ReSharper disable once InconsistentNaming
        export interface TaskLogLoadOptions extends LoadOptionsBase {
            success?: (data: JobLog[]) => void;
        }

        export class TaskLogLoader extends AppKitchen.ModelBase<TaskLogLoaderAttributes> {
            request: JQueryXHR;

            constructor() {
                super({
                    data: [],
                    loading: false
                });
            }

            loadLogs(request: AppKitchen.Api.Models.TaskLogRequest, options?: TaskLogLoadOptions) {
                options = AppKitchen.OptionsHelper.merge(options, {
                    silent: false,
                    success: () => { }
                });

                // abort previous request
                this.abortRequest();

                if (!options.silent) {
                    this.set({ loading: true });
                }

                // start new request
                this.request = Data.Api.getTaskLog(request, data => {
                    this.processLoadedData(data);
                    options.success(this.get().data);
                }, (request) => {
                    options.error(request);
                });
            }

            abortRequest() {
                if (this.request) {
                    this.request.abort();
                    this.set({ loading: false });
                }
            }

            private processLoadedData(data: AppKitchen.Api.Models.TaskLog[]) {
                var taskLogs: TaskLog[] = [];
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        var taskLog: TaskLog = <any>data[i];
                        taskLog.RunBegin = kendo.parseDate(data[i]["RunBegin"], "yyyy-MM-ddTHH:mm:ss");
                        taskLog.RunEnd = kendo.parseDate(data[i]["RunEnd"], "yyyy-MM-ddTHH:mm:ss");
                        if (taskLog.RunEnd <= new Date(1900, 0, 1)) {
                            taskLog.RunEnd = null;
                        }
                        taskLogs.push(taskLog);
                    }
                }

                this.setData(taskLogs);
            }

            private setData(data: TaskLog[]) {
                this.set({ data: data });
                this.set({ loading: false });
            }

        }

    }
}
