interface Date {
    addDays(days: number): Date;
    addHours(hours: number): Date;
    addMinutes(minutes: number): Date;
    onlyDate(): Date;
    toLocalISOString(): string;
}

interface String {
    format(): string;
    truncate(length: number): string;
    nl2br(): string;
    endsWith(searchString: string): boolean;
    beginsWith(searchString: string): boolean;
    escapeHTML(): string;
    toDate(): Date;
    replaceAll(searchValue: string, replaceValue: string): string;
}

String.prototype.toDate = function () {
    return kendo.parseDate(this, "yyyy-MM-ddTHH:mm:ss");
}

Date.prototype.addDays = function (days: number): Date {
    var oldDate = new Date(this.valueOf());
    var newDate = new Date(this.valueOf() + days * 86400000);
    return new Date(newDate.valueOf() + (newDate.getTimezoneOffset() - oldDate.getTimezoneOffset()) * 60000);
};

Date.prototype.addHours = function (hours: number): Date {
    return new Date(this.valueOf() + hours * 3600000);
};

Date.prototype.addMinutes = function (minutes: number): Date {
    return new Date(this.valueOf() + minutes * 60000);
};

Date.prototype.onlyDate = function (): Date {
    var date = new Date(this.valueOf());
    date.setHours(0, 0, 0, 0);
    return date;
};

Date.prototype.toLocalISOString = function (): string {
    return new Date(this.valueOf() - this.getTimezoneOffset() * 60000).toISOString().slice(0, -1);
};

Date.prototype.toString = function (): string {
    return this.toISOString();
};

// do not change signature! This is used in Portal as well!
//TODO: verify changes!
String.prototype.format = function () {
    var s = arguments[0];
    for (var i = 0; i < arguments.length - 1; i++) {
        var reg = new RegExp("\\{" + i + "\\}", "gm");
        s = s.replace(reg, arguments[i + 1]);
    }

    return s;
};

String.prototype.endsWith = function (searchString) {
    var subjectString = this.toString();
    var position = subjectString.length - searchString.length;
    var lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex === position;
};

String.prototype.beginsWith = function (searchString) {
    var subjectString = this.toString();
    return subjectString.substr(0, searchString.length) === searchString;
};

String.prototype.truncate = function (length): string {
    if (length === 0) return "...";
    if (this.length > length) {
        return this.substr(0, length).trim() + " ...";
    }
    return this;
}

String.prototype.nl2br = function (): string {
    return this.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>');
}

String.prototype.escapeHTML = function (): string {
    return this.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

String.prototype.replaceAll = function (searchValue, replaceValue): string {
    var str = this;
    return str.replace(new RegExp(searchValue, "g"), replaceValue);
}