namespace AppKitchen {
    export module Data {
        export module Descriptors {

            export interface IDescriptorProvider {
                load(dimensions: string[], onLoadComplete: () => void);
                getDescriptors(dimensionId: string): AppKitchen.Api.Models.Descriptor[];
            }

            export class DescriptorProvider implements IDescriptorProvider {
                private dimensions: string[];
                private descriptors: {} = {};

                load(dimensions: string[], onLoadComplete: () => void) {
                    this.dimensions = dimensions || [];

                    const length = this.dimensions.length;
                    if (length === 0) {
                        onLoadComplete();
                    }

                    var dimensionLoaded: number = 0;

                    for (let i = 0; i < length; i++) {
                        const dimensionId = this.dimensions[i];

                        Data.Api.getDescriptors({ DimensionId: dimensionId },
                        (result => {
                            this.descriptors[dimensionId] = result;

                            if (++dimensionLoaded === this.dimensions.length) {
                                onLoadComplete();
                            }
                        }));
                    }
                }

                getDescriptors(dimensionId: string): AppKitchen.Api.Models.Descriptor[] {
                    return this.descriptors[dimensionId];
                }
            }

        }
    }
}
