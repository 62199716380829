namespace AppKitchen {
    export module Data {

        export interface TextPoolItemWithLabel extends AppKitchen.Api.Models.EventTextPoolItem {
            Label: string;
        }

        export type FieldToEventItemInfoDictionary = { [field: string]: AppKitchen.Api.Models.EventItemInfo };
        export type IdToTextPoolItemDictionary = { [id: string]: TextPoolItemWithLabel };
        export type FieldToTestPoolDictionary = { [field: string]: IdToTextPoolItemDictionary};
        export type DataEntries = { [property: string]: any };

        export interface EventDataLoadOptions extends LoadOptionsBase {
            success?: (data: { [key: string]: any }[]) => void;
            error?: (request: JQueryXHR) => void;
        }

        export interface EventDataSaveOptions extends LoadOptionsBase {
            success?: (result: boolean | Object) => void;
            error?: (request: JQueryXHR) => void;
        }

        export interface EventDataLoaderOptions {
            eventDataApi?: string;
            eventDataSaveApi?: string;
            startupFailed?: (request: JQueryXHR) => void;
            cacheEventItemRequest?: boolean;
        }

        class ChangeEvents {
            data: RxEx.SubjectWithPrevious<DataEntries[]>;
            items: RxEx.Subject<AppKitchen.Api.Models.EventItemInfo[]>;
            config: RxEx.Subject<GridConfig>;
            ready: RxEx.Subject<boolean>;
            loading: RxEx.Subject<boolean>;
        }

        export interface EventDataLoaderAttributes {
            data?: DataEntries[];
            items?: AppKitchen.Api.Models.EventItemInfo[];
            ready?: boolean;
            loading?: boolean;
        }

        export interface ColumnConfig extends AppKitchen.Api.Models.EventItemSpecification {
            SortNr?: number,
            Metadata?: string,
            Type?: any,
            Width?: number,
            Visibility?: boolean,
            RestrictFilterSelection?: boolean,
            GridOptions?: Controls.Grids.GridColumnOptions,
            FieldOptions?: Controls.Forms.FieldOptions,
        }

        export interface GridConfig extends AppKitchen.Api.Models.EventDataRequest {
            EventItemSpecs: ColumnConfig[];
        }

        export interface IEventDataLoaderDataConverter {
            updateItemDictionary(items: AppKitchen.Api.Models.EventItemInfo[]): void;
            getItemDictionary(): FieldToEventItemInfoDictionary;
            getTextPoolItemDictionary(field: string): IdToTextPoolItemDictionary;
            convertDataFromServer(data: DataEntries[]): DataEntries[];
            convertDataToServer(data: DataEntries[]): AppKitchen.Api.Models.EventDataSaveRequest[];
        }

        export class DefaultEventDataLoaderDataConverter implements IEventDataLoaderDataConverter {

            private itemDictionary: FieldToEventItemInfoDictionary;
            private textPoolItemDictionary: FieldToTestPoolDictionary;
            private config: GridConfig;

            constructor(config: GridConfig) {
                this.config = config;
            }

            getItemDictionary(): FieldToEventItemInfoDictionary {
                return this.itemDictionary;
            }

            getTextPoolItemDictionary(field: string): IdToTextPoolItemDictionary {
                return this.textPoolItemDictionary[field];
            }

            updateItemDictionary(eventItemInfos: AppKitchen.Api.Models.EventItemInfo[]): void {
                this.itemDictionary = {};
                this.textPoolItemDictionary = {};
                if (!eventItemInfos) {
                    return;
                }
                if (!this.config) {
                    console.log("No grid config was set!");
                    return;
                }

                for (let eventItemInfo of eventItemInfos) {
                    const field = eventItemInfo.InventoryId + "_" + eventItemInfo.Id;
                    this.itemDictionary[field] = eventItemInfo;

                    const columnConfig = this.config.EventItemSpecs.AsLinq<ColumnConfig>().FirstOrDefault(g => `${g.InventoryId}_${g.ItemId}` === field);
                    if (columnConfig && columnConfig.RestrictFilterSelection) {
                        const textPoolItemDictionary: IdToTextPoolItemDictionary = {};
                        for (let textPoolItem of eventItemInfo.TextPool) {
                            textPoolItemDictionary[textPoolItem.Id] = {
                                Id: textPoolItem.Id,
                                Text: textPoolItem.Text,
                                SortNr: textPoolItem.SortNr,
                                StatusColor: textPoolItem.StatusColor,
                                Label: eventItemInfo.TypeSpecification.UseId ? textPoolItem.Id : textPoolItem.Text,
                            };
                        }
                        this.textPoolItemDictionary[field] = textPoolItemDictionary;
                    }
                }
            }

            convertDataFromServer(data: DataEntries[]): DataEntries[] {
                if (!data) {
                    console.log("No data!");
                    return [];
                }
                if (!this.itemDictionary) {
                    console.log("No item dictionary was set!");
                    return [];
                }

                for (let entry of data) {
                    for (let property in entry) {
                        if (entry.hasOwnProperty(property)) {
                            if (this.itemDictionary.hasOwnProperty(property)) {
                                const type = this.itemDictionary[property].Type;
                                entry[property] = this.convertStringToType(type, entry[property]);
                            }
                        }
                    }
                }

                return data;
            }

            private convertStringToType(type: string, value: string): string | number | boolean | Date {
                switch (type) {
                    case "String":
                    case "Memo":
                    case "TextPool":
                    case "Descriptor":
                    case "Binary":
                    case "MasterDataCopy":
                    case "User":
                    case "Group":
                    case "MultiDescriptor":
                    case "EventKeyField":
                        return value || "";
                    case "Date":
                    case "DateTimeOffset":
                    case "PeriodNr":
                        return kendo.parseDate(value, "yyyy-MM-ddTHH:mm:ss");
                    case "Time":
                        return kendo.parseDate(value, "HH:mm:ss");
                    case "Number":
                    case "Calculated":
                    case "Decimal":
                        return kendo.parseFloat(value, "de-DE"); // REST API has fixed "de-DE" culture
                    case "Int32":
                    case "Int64":
                        return kendo.parseInt(value, "de-DE"); // REST API has fixed "de-DE" culture
                    case "Boolean":
                        if (value != null && !(typeof value == "boolean"))
                            return value === "true";
                    default:
                        AppKitchen.logWarning(`Not supported type: '${type}'. Value: '${value}' will not converted.`);
                        return value || "";
                }
            }

            convertDataToServer(data: DataEntries[]): AppKitchen.Api.Models.EventDataSaveRequest[] {
                if (!data) {
                    console.log("No data!");
                    return [];
                }

                let eventDataSaveRequests: AppKitchen.Api.Models.EventDataSaveRequest[] = [];

                for (let entry of data) {
                    var saveData = [];
                    for (let property in entry) {
                        if (entry.hasOwnProperty(property)) {
                            saveData.push(this.getSaveRequestData(property, entry[property]));
                        }
                    }
                    eventDataSaveRequests.push({
                        EventItemSpecs: this.config ? this.config.EventItemSpecs : null,
                        EventValues: saveData
                    });
                }

                return eventDataSaveRequests;
            }

            private getSaveRequestData(property: string, value: any): { Key: string, Value: any } {
                if (value == null) {
                    value = "";
                } else if (value instanceof Date) {
                    value = value.toLocalISOString();
                } else if (typeof value === "boolean") {
                    value = value ? "true" : "false";
                } else if (typeof value === "number") {
                    value = value.toString();
                } else if (typeof value !== "string") {
                    throw "Event value with key '" + property + "' has invalid type";
                }
                return { Key: property, Value: value };
            }
        }

        export class EventDataLoader {
            private config: GridConfig;
            private options: EventDataLoaderOptions;
            private attributes: EventDataLoaderAttributes;
            private changeEvents: ChangeEvents;
            private dataConverter: IEventDataLoaderDataConverter;

            private eventItemsProvider: Provider.EventItemsProvider;
            private eventDataProvider: Provider.EventDataProvider;
            private eventDataSaveProvider: Provider.EventDataSaveProvider;

            private subscriptions: {
                items: Rx.IDisposable;
                ready: Rx.IDisposable;
            }

            constructor(config: GridConfig, onReady: (loader: EventDataLoader) => void, options?: EventDataLoaderOptions, dataConverter?: IEventDataLoaderDataConverter) {
                this.config = config;
                this.dataConverter = dataConverter ? dataConverter : new DefaultEventDataLoaderDataConverter(config);

                this.eventItemsProvider = new Provider.EventItemsProvider();
                this.eventDataProvider = new Provider.EventDataProvider();
                this.eventDataSaveProvider = new Provider.EventDataSaveProvider();

                this.changeEvents = {
                    data: new RxEx.SubjectWithPrevious<DataEntries[]>(),
                    items: new RxEx.Subject<AppKitchen.Api.Models.EventItemInfo[]>(),
                    config: new RxEx.Subject<GridConfig>(),
                    ready: new RxEx.Subject<boolean>(),
                    loading: new RxEx.Subject<boolean>(),
                };

                this.attributes = {
                    data: [],
                    items: [],
                    loading: false,
                    ready: false
                };

                this.options = AppKitchen.OptionsHelper.merge(options, {
                    eventDataApi: "EventData",
                    eventDataSaveApi: "EventDataMultiSave",
                    cacheEventItemRequest: false
                });

                this.subscriptions = {
                    items: this.onChangeReady().subscribe(event => {
                        if (event.data) {
                            onReady(this);
                        }
                    }),
                    ready: this.onChangeItems().subscribe(event => this.dataConverter.updateItemDictionary(event.data))
                }
 
                this.loadItems();
            }

            getData(): DataEntries[] {
                return this.attributes.data;
            }

            setData(data: DataEntries[], sender: object) {
                const previous = this.attributes.data;
                this.attributes.data = data;
                this.changeEvents.data.onNext({ sender: sender, data: data, previous: previous });
            }

            onChangeData(): RxEx.ObservableWithPrevious<DataEntries[]> {
                return this.changeEvents.data.asObservable();
            }

            getItems(): AppKitchen.Api.Models.EventItemInfo[] {
                return this.attributes.items;
            }

            setItems(items: AppKitchen.Api.Models.EventItemInfo[], sender: object) {
                this.attributes.items = items;
                this.attributes.ready = true;
                this.changeEvents.items.onNext({ sender: sender, data: items });
                this.changeEvents.ready.onNext({ sender: sender, data: true });
            }

            onChangeItems(): RxEx.Observable<AppKitchen.Api.Models.EventItemInfo[]> {
                return this.changeEvents.items.asObservable();
            }

            onChangeReady(): RxEx.Observable<boolean> {
                return this.changeEvents.ready.asObservable();
            }

            getLoading(): boolean {
                return this.attributes.loading;
            }

            setLoading(loading: boolean, sender: object) {
                this.attributes.loading = loading;
                this.changeEvents.loading.onNext({ sender: sender, data: loading });
            }

            onChangeLoading(): RxEx.Observable<boolean> {
                return this.changeEvents.loading.asObservable();
            }

            getConfig(): GridConfig {
                return this.config;
            }

            setConfig(config: GridConfig, sender: object) {
                this.config = config;
                this.changeEvents.config.onNext({ sender: sender, data: config });
            }

            onChangeConfig(): RxEx.Observable<GridConfig> {
                return this.changeEvents.config.asObservable();
            }

            loadItems() {
                this.eventItemsProvider.load(this.config, !this.options.cacheEventItemRequest)
                    .then(items => {
                        this.setItems(items, this);
                    })
                    .fail(request => {
                        if (this.options.startupFailed) {
                            this.options.startupFailed(request);
                        }
                    });
            }

            loadData(options?: EventDataLoadOptions) {
                options = AppKitchen.OptionsHelper.merge(options, {
                    silent: false,
                    success: () => {},
                    error: () => {}
                });

                this.abortDataRequest();

                if (!options.silent) {
                    this.setLoading(true, this);
                }

                const api = options.api || this.options.eventDataApi;
                this.eventDataProvider.load(this.config, api)
                    .then(data => {
                        this.processLoadedData(data);
                        options.success(data);
                    })
                    .fail(request => {
                        options.error(request);
                    })
                    .always(() => this.setLoading(false, this));
            }

            saveData(data: { [key: string]: any }[], options?: EventDataSaveOptions) {
                if (!data || data.length === 0) {
                    throw "no data to save";
                }

                options = AppKitchen.OptionsHelper.merge(options, {
                    silent: false,
                    success: () => { },
                    error: () => { }
                });

                this.abortDataSaveRequest();

                if (!options.silent) {
                    this.setLoading(true, this);
                }

                var api = options.api || this.options.eventDataSaveApi;
                this.eventDataSaveProvider.save(this.getMultiSaveRequestData(data), api)
                    .then(saveResult => {
                        options.success(saveResult);
                    })
                    .fail(request => {
                        options.error(request);
                    })
                    .always(() => this.setLoading(false, this));
            }

            getMultiSaveRequestData(data: DataEntries[]): AppKitchen.Api.Models.EventDataMultiSaveRequest {
                return {
                    EventDataSaveRequests: this.dataConverter.convertDataToServer(data)
                }
            }

            abortRequests() {
                this.eventItemsProvider.abort();

                this.abortDataRequest();
                this.abortDataSaveRequest();
            }

            protected abortDataRequest() {
                this.eventDataProvider.abort();
                this.setLoading(false, this);
            }

            protected abortDataSaveRequest() {
                this.eventDataSaveProvider.abort();
                this.setLoading(false, this);
            }

            protected processLoadedData(data: DataEntries[]): void {
                var convertedData = this.dataConverter.convertDataFromServer(data);
                this.parseData(convertedData);
                this.setData(convertedData, this);
            }
            protected parseData(data: DataEntries[]): void {}

            getSaveRequestData(data: DataEntries): AppKitchen.Api.Models.EventDataSaveRequest {
                return this.dataConverter.convertDataToServer([data])[0];
            }

            getItemsDict(): FieldToEventItemInfoDictionary {
                return this.dataConverter.getItemDictionary();
            }

            getEventFieldInfo(field: string): AppKitchen.Api.Models.EventItemInfo {
                return this.dataConverter.getItemDictionary()[field];
            }

            getTextPoolItemDict(field: string): IdToTextPoolItemDictionary {
                return this.dataConverter.getTextPoolItemDictionary(field);
            }

            dispose(): void {
                if (!this.subscriptions) return;
                for (let key in this.subscriptions) {
                    if (this.subscriptions[key]) {
                        this.subscriptions[key].dispose();
                    }
                }
            }
        }
    }
}
