/// <reference types="openlayers"/>
namespace AppKitchen {

    export module Controls {

        export class MapModel extends AppKitchen.ModelBase<AppKitchen.ModelBaseAttributes> {
            constructor() {
                super();
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface MapViewOptions extends AppKitchen.ViewBaseOptions {
            extent?: ol.Extent,
            center?: ol.Coordinate,

            /** For bing always use your own API Key !!  */
            backgroundLayerType?: "osm" | "bing" | "bingAerial" | "stamenWatercolor" | "stamenWatercolorAndLabels" | "stamenToner" | "localosm";

            minZoom?: number;
            maxZoom?: number;

            /** Api Key for bing maps. Can be generated here: www.bingmapsportal.com. !!!DO NOT use bing maps without a key in production!!! */
            bingMapsKey?: string;
            /** The higher the number, the more detail you will see */
            zoom?: number;
            /** The reset zoom button will zoom back to the initial zoom level and center  */
            showResetZoomButton?: boolean;

            showMapTypeButton?:boolean;
        }

        // ReSharper disable once InconsistentNaming
        export interface MarkerOptions {
            icon?: string,
            image?: string,
            iconDetailLeft?: string,
            iconDetailRight?: string,
            tooltipDetailLeft?: string,
            tooltipDetailRight?: string,
            label?: string,
            onClickMarker?: (e: any) => void,
            onClickDetailLeft?: (e: any) => void,
            onClickDetailRight?: (e: any) => void,
        }

        export class MapView extends AppKitchen.ViewBase<MapModel> {
            options: MapViewOptions;
            map: ol.Map;
            resetZoomHandlers: (() => void)[];
            markerCounter: number = 0;
            currentBackgroundLayerType: string;

            constructor(mapModel: MapModel, targetContainer: HTMLElement, options?: MapViewOptions) {
                super(mapModel, targetContainer, OptionsHelper.merge(options,
                    <MapViewOptions>{
                        bingMapsKey: "ApNj3yaTwQ6MeX9xd1s_MK50-tf8lDPDB5oMGyCTPwmFFxRWkgDzlAYT2dRi_PuK", // Development Key created by Matthias Schallenmüller
                        backgroundLayerType: "osm",
                        minZoom: 7,
                        showResetZoomButton: false,
                        showMapTypeButton: false,
                        maxZoom: 17,
                        zoom: 12,
                        center: [8.404537, 49.014010], //Karlsruhe
                        extent: [-15.357039, 58.913061, 54.249133, 36.793348] //Europa
                    }));

                this.options.extent = ol.proj.transformExtent(this.options.extent, 'EPSG:4326', 'EPSG:3857');
                this.options.center = ol.proj.transform(this.options.center, 'EPSG:4326', 'EPSG:3857');
                this.currentZoomLevel = -1;
                this.resetZoomHandlers = [];
                this.render();
            }

            currentZoomLevel: number;

            render() {
                var layers: ol.layer.Layer[] = [];

                this.addBackgroundLayer(layers);

                var view = new ol.View({
                    projection: 'EPSG:3857',
                    extent: this.options.extent,
                    maxZoom: this.options.maxZoom,
                    minZoom: this.options.minZoom,
                    center: this.options.center,
                    zoom: this.options.zoom
                });

                var attribution = new ol.control.Attribution({
                    collapsible: false
                });
                this.map = new ol.Map({
                    layers: layers,
                    target: this.el,
                    controls: ol.control.defaults({ attribution: false }).extend([attribution]),
                    view: view
                });


                if (this.options.showResetZoomButton) {
                    this.addCustomControl("<div class='mesap-icon icon-map-reset-view'></div>", "Reset View", () => {
                        this.resetZoomAndCenter();
                        this.resetZoomHandlers.AsLinq<()=>void>().ForEach(handler => handler());
                    });
                }

                if (this.options.showMapTypeButton) {
                    this.addCustomControl("<div class='mesap-icon icon-matlab'></div>", "Switch Map", () => {
                        this.changeMapType();
                    });
                }

                this.createTooltips();

                return this;
            }

            showChangeMapTypeMenu() {
                var dropdownMenu = this.$el.find(".ol-map-type-change-dropdown");
                if (dropdownMenu.is(":visible")) {
                    dropdownMenu.hide();
                } else {
                    dropdownMenu.show();
                }
            }

            changeMapType() {
                if (this.currentBackgroundLayerType === "osm" || !this.currentBackgroundLayerType) {
                    this.currentBackgroundLayerType = "bingAerial";
                } else if (this.currentBackgroundLayerType === "bingAerial") {
                    this.currentBackgroundLayerType = "osm";
                }

                var layer;
                if (this.currentBackgroundLayerType === "bingAerial") {
                    layer = new ol.layer.Tile({ source: new ol.source.BingMaps({ key: this.options.bingMapsKey, imagerySet: 'AerialWithLabels' }) });
                } else {
                    layer = new ol.layer.Tile({ source: new ol.source.OSM() });
                }
                this.map.getLayers().setAt(0, layer);
            }

            createTooltips() {
                this.$el.find(".ol-zoom").find(":button").each((i, e) => this.createTooltip($(e), "right"));
            }

            createTooltip(e: any, position: string) {
                if (e.attr("title")) {
                    e.kendoTooltip({ position: position });

                    e.bind("mouseleave", () => {
                        AppKitchen.UIHelper.hideTooltips();
                    });
                }
            }

           
            resetZoom(handler: () => void) {
                this.resetZoomHandlers.push(handler);
            }

            /**
             * Calculates the coordinate based on the given [coordinate] and the x and y offset in pixels.
             * THIS ONLY WORKS IF THE MAP IS RENDERED. DO NOT SET THE ZOOM LEVEL AND THEN CALL THIS METHOD. WAIT UNTILL THE MAP IS RENDERED!!!
             * @param xPixelOffset offset in pixels
             * @param yPixelOffset offset in pixels
             * @param coordinate The coordinate in EPSG:4326 
             */
            getCoordinateWithOffset(xPixelOffset: number, yPixelOffset: number, coordinate: ol.Coordinate) {
                var coord = ol.proj.transform(coordinate, 'EPSG:4326', 'EPSG:3857');
                var pixelCoord = this.map.getPixelFromCoordinate(coord);
                var newPixelCoord: [number, number] = [pixelCoord[0] - xPixelOffset, pixelCoord[1] - yPixelOffset];
                var newCoord = this.map.getCoordinateFromPixel(newPixelCoord);
                return ol.proj.transform(newCoord, 'EPSG:3857', 'EPSG:4326');
            }


            /**
             * Sets the maps center and zoom level to initial values
             */
            resetZoomAndCenter(duration?: number) {
                duration = duration && duration >= 0 ? duration : 1000;

                if (duration > 0) {
                    this.map.getView().animate({ duration: duration, zoom: this.options.zoom });
                    this.map.getView().animate({ duration: duration, center: this.options.center });
                } else {
                    this.map.getView().setZoom(this.options.zoom);
                    this.map.getView().setCenter(this.options.center);
                }
            }

            /**
             * Adds a custom control to the map the control will have the same style as the zoom-in/zoom-out buttons of openlayers
             * @param html the html rendered within the button
             * @param title the title that will be used to create the tooltip
             * @param onClick the handler for the click event
             */
            addCustomControl(html: string, title: string, onClick: () => void) {
                var titleArg = "";
                if (title) {
                    titleArg = ` title = '${title}'`;
                }
                var button = $(`<button class='ol-custom-tool' type='button'${titleArg}></button>`);
                button.html(html);
                button.click(onClick);
                this.$el.find(".ol-zoom").append(button);
            }

            refreshAll() {
                this.map.updateSize();
            }

            /**
             * Centers the map
             * @param coordinate  The coordinate in EPSG:4326 (google projection)
             * @param animate animate the zoom action (the action will need 250ms to finish)
             */
            setCenter(coordinate: ol.Coordinate, animate?: boolean) {
                var transformedCoordinate = ol.proj.transform(coordinate, 'EPSG:4326', 'EPSG:3857');

                if (animate) {
                    this.map.getView().animate({
                        duration: 250,
                        center: transformedCoordinate,
                        easing: ol.easing.inAndOut
                    });
                } else {
                    this.map.getView().setCenter(transformedCoordinate);
                }
            }

            /**
             * Sets the zoom level
             * @param zoom number between 1 and 19 .. the higher the number the more details will be shown
             * @param animate animate the zoom action (the action will need 250ms to finish)
             */
            setZoomLevel(zoom: number, animate?: boolean) {
                if (animate) {
                    this.map.getView().animate({ duration: 250, zoom: zoom, easing: ol.easing.inAndOut });
                } else {
                    this.map.getView().setZoom(zoom);
                }

            }

            /**
             * Zooms out to [zoomOuter] then moves to [newCenter] and zooms in to [zoom]
             * @param zoom the new zoom level,  number between 1 and 19 .. the higher the number the more details will be shown
             * @param zoomOuter first the map will zoom to this zoom level,  number between 1 and 19 .. the higher the number the more details will be shown
             * @param newCenter the new center point in EPSG:4326 (google projection)
             */
            setCenterAndZoomAnimated(zoom: number, zoomOuter: number, newCenter: ol.Coordinate) {


                this.map.getView().animate({ duration: 700, zoom: zoomOuter, easing: ol.easing.inAndOut });
                //this.map.getView().setZoom(zoomOuter);

                var transformedCoordinate = ol.proj.transform(newCenter, 'EPSG:4326', 'EPSG:3857');

                this.map.getView().animate({ center: transformedCoordinate, duration: 1400, easing: ol.easing.inAndOut });
                
                window.setTimeout(() => {
                    this.map.getView().animate({ duration: 700, zoom: zoom, easing: ol.easing.inAndOut });
                    //this.map.getView().setZoom(zoom);
                }, 700);
            }


            /**
             * Adds an Overlay to the map. The Overlay will stay in its dimensions and will not
             * shrink or grow when zoomlevel is changing
             * 
             * @param overlay The html element that should be placed on the map
             * @param coordinate The coordinate in EPSG:4326 (google projection)
             * @param offset Offsets in pixels used when positioning the overlay. The fist element in the array is the horizontal offset. A positive value shifts the overlay right. The second element in the array is the vertical offset. A positive value shifts the overlay down. Default is [0, 0].
             */
            addOverlay(overlay: HTMLElement, coordinate: ol.Coordinate, offset?: Array<number>) {
                var olOverlay = new ol.Overlay({
                    position: ol.proj.transform(coordinate,'EPSG:4326','EPSG:3857'),
                    offset: offset,
                    positioning: "top-left",
                    element: overlay
                });

                this.map.addOverlay(olOverlay);
            }

            addMarker(coordinate: ol.Coordinate, options: MarkerOptions) {
                var marker = $(AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.MapMarker,
                    {
                        id: `Marker_${this.markerCounter++}`,
                        icon: options.icon,
                        image: options.image,
                        label: options.label,
                        iconDetailLeft: options.iconDetailLeft,
                        iconDetailRight: options.iconDetailRight,
                        tooltipDetailLeft: options.tooltipDetailLeft,
                        tooltipDetailRight: options.tooltipDetailRight
                    }));
                this.addOverlay(marker[0], coordinate);

                if (options && options.onClickMarker) {
                    marker.find(".a-map-marker").click((e) => options.onClickMarker(e));
                }
                if (options && options.onClickDetailLeft) {
                    let detail = marker.find(".a-marker-detail-left");
                    detail.click((e) => options.onClickDetailLeft(e));
                    if (options.tooltipDetailLeft) {
                        this.createTooltip(detail, "top");
                    }
                }
                if (options && options.onClickDetailRight) {
                    let detail = marker.find(".a-marker-detail-right"); 
                    detail.click((e) => options.onClickDetailRight(e));
                    if (options.tooltipDetailRight) {
                        this.createTooltip(detail, "top");
                    }
                }
            }

            addBackgroundLayer(layers: ol.layer.Layer[]) {
                var requestedType = this.options.backgroundLayerType;


                // bing 
                if (requestedType === "bing") {
                   layers.push(new ol.layer.Tile({ source: new ol.source.BingMaps({
                       key: this.options.bingMapsKey,
                       imagerySet: 'Road'
                   }) }));
                }

                // bing with sattelite imagery
                else if (requestedType === "bingAerial") {
                    layers.push(new ol.layer.Tile({ source: new ol.source.BingMaps({ key: this.options.bingMapsKey, imagerySet: 'AerialWithLabels' }) }));
                }

                // whatercolor 
                else if (requestedType === "stamenWatercolor" || requestedType === "stamenWatercolorAndLabels") {
                    layers.push(new ol.layer.Tile({ source: new ol.source.Stamen({ layer: 'watercolor' }) }));
                    if (requestedType === "stamenWatercolorAndLabels") {
                        layers.push(new ol.layer.Tile({ source: new ol.source.Stamen({ layer: 'terrain-labels' }) }));
                    }
                }

                // toner
                else if (requestedType === "stamenToner") {
                    layers.push(new ol.layer.Tile({ source: new ol.source.Stamen({ layer: 'toner' }) }));
                }

                // local osm tiles
                else if (requestedType === "localosm") {
                    layers.push(new ol.layer.Tile({source: new ol.source.OSM({url: 'osmtiles/{z}/{x}/{y}.png'})}));
                }

                // default osm tiles
                else{
                    layers.push(new ol.layer.Tile({ source: new ol.source.OSM() }));
                }

                
            }

        }
    }
}