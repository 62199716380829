/// <reference path="ProviderBase.ts" />

namespace AppKitchen {

    export module Data.Provider {

        export class EventDataProvider extends ProviderBase {

            public load(request: AppKitchen.Api.Models.EventDataRequest, api?: string): JQueryPromise<DataEntries[]> {
                return this.do(api || "EventData", request);
            }
        }
    }
}