namespace AppKitchen {
    export module Controls {
        export module Panel {

            export class AccordionModel extends AppKitchen.ModelBase<ModelBaseAttributes> {}

            // ReSharper disable once InconsistentNaming
            export interface AccordionViewOptions extends AppKitchen.ViewBaseOptions {
                expandMode?: string,
                fieldTitles: string[],
            }

            // ReSharper disable once InconsistentNaming
            export interface Field {
                id: string,
                title: string,
            }

            export class AccordionView extends AppKitchen.ViewBase<AccordionModel> {
                options: AccordionViewOptions;

                fields: Field[];

                constructor(model: AccordionModel, target: HTMLElement, options?: AccordionViewOptions) {
                    super(model, target, options);
                    this.options = options;

                    this.setTemplate(AppKitchen.Templates.Accordion);

                    this.render();
                }

                render() {
                    this.renderTemplate({ fieldTitles: this.options.fieldTitles });

                    this.fields = [];

                    for (let i=0; i<this.options.fieldTitles.length; i++) {
                        this.fields.push({ id: "#accordionFieldContainer_" + i, title: this.options.fieldTitles[i] });
                    }

                    $("#accordion").kendoPanelBar({
                        expandMode: this.options.expandMode
                    });

                    return this;
                }

                getFields(): HTMLElement[] {
                    var accordionFields: HTMLElement[] = [];
                    for (let field of this.fields) {
                        accordionFields.push(this.$el.find(field.id)[0]);
                    }
                    return accordionFields;
                }

                getField(fieldTitle: string): HTMLElement {
                    return this.$el.find(this.fields.filter(a => a.title === fieldTitle)[0].id)[0];
                }

            }
        }
    }
}