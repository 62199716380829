namespace AppKitchen {

    export module Controls.Grids {

        export interface EventGridOptions {
            getColumnHeader?: GetColumnHeader;
        }

        export class EventGridModel extends GridModel {
            options: EventGridOptions;
            dataLoader: AppKitchen.Data.EventDataLoader;

            private subscriptions: {
                data: Rx.IDisposable;
                loading: Rx.IDisposable;
            }

            constructor(dataLoader: AppKitchen.Data.EventDataLoader, options?: EventGridOptions) {
                options = OptionsHelper.merge(options,
                    {
                        getColumnHeader: EventGridHelper.getColumnHeader
                    });

                var columnsConfig = EventGridHelper.getColumnsConfig(dataLoader.getConfig(),
                    dataLoader.getItemsDict(),
                    options.getColumnHeader);

                super(columnsConfig.columns, columnsConfig.schema, options);

                this.dataLoader = dataLoader;

                this.subscriptions = {
                    data: this.dataLoader.onChangeData().subscribe(event => this.setGridData(event.data)),
                    loading: this.dataLoader.onChangeLoading().subscribe(event => this.set({ loading: event.data }))
                };

                if (this.dataLoader.getData()) {
                    this.setGridData(this.dataLoader.getData());
                }
                this.set({ loading: this.dataLoader.getLoading() });
            }

            getTextPool(field: string, data: AppKitchen.Data.DataEntries[]): { value: string, text: string }[] {
                const textPool: { value: string, text: string, sortNr: number }[] = [];

                const columnConfig = this.dataLoader.getConfig().EventItemSpecs.AsLinq<AppKitchen.Data.ColumnConfig>()
                    .FirstOrDefault(g => `${g.InventoryId}_${g.ItemId}` === field);
                if (columnConfig && columnConfig.RestrictFilterSelection) {
                    const distinctData = data.AsLinq<AppKitchen.Data.DataEntries>().Select(d => d[field]).Distinct()
                        .ToArray();

                    const textPoolItemDic = this.dataLoader.getTextPoolItemDict(field);
                    for (let d of distinctData) {
                        const item = textPoolItemDic[d];
                        textPool.push({ value: d, text: item.Label, sortNr: item.SortNr });
                    }
                }
                textPool.sort(this.sortTextPoolItems);
                return textPool;
            }

            private sortTextPoolItems(a, b) {
                if (a.text > b.text) {
                    return 1;
                }

                if (a.text < b.text) {
                    return -1;
                }

                return 0;
            }

            dispose(): void {
                let subscriptions = this.subscriptions;
                if (!subscriptions) return;
                for (let key in subscriptions) {
                    if (subscriptions.hasOwnProperty(key)) {
                        if (subscriptions[key]) {
                            subscriptions[key].dispose();
                        }
                    }
                }
            }
        }
    }
}