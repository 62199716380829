namespace AppKitchen {
    export module Data {
        export module Import {

            export type CsvDataType = "string" | "number" | "date" | "boolean";
            export type ExcelDataType = "string" | "number" | "date" | "time" | "time24" | "dateTime";

            // ReSharper disable once InconsistentNaming
            export interface CsvImportOptions {
                firstDataRowIndex?: number;
                columnDelimiter?: string;
                rowDelimiter?: string;
                culture?: string;
                encoding?: string;
            }

            // ReSharper disable once InconsistentNaming
            export interface CsvImportColumnDefinition {
                field: string;
                type: CsvDataType;
                format?: string;
                required?: boolean;
            } 

            // ReSharper disable once InconsistentNaming
            export interface ExcelImportColumnDefinition {
                field: string;
                type?: ExcelDataType;
                required?: boolean;
            } 

            export function readExcelFile(file: File,
                columns: ExcelImportColumnDefinition[],
                loaded: (data: any[]) => void,
                error: (message: string) => void) {

                if (!columns || columns.length === 0) {
                    throw "No fields for excel import defined!";
                }

                var reader = new FileReader();
                reader.onload = e => {
                    try {
                        var fileData = (<any>e.target).result;
                        var workbook = XLSX.read(btoa(fix(fileData)), { type: 'base64' });

                        if (!workbook)
                            throw AppKitchen.Strings.Import_InvalidFile;

                        if (workbook.SheetNames.length === 0)
                            throw AppKitchen.Strings.Import_InvalidFile;

                        var sheet = workbook.Sheets[workbook.SheetNames[0]];

                        // set sheet headers to field names
                        for (let i = 0; i < columns.length; i++) {
                            var columnName = String.fromCharCode(65 + i);
                            if (sheet[columnName + "1"]) {
                                sheet[columnName + "1"].w = columns[i].field;
                            } else {
                                throw AppKitchen.Strings.Import_MissingColumn + " " + columnName;
                            }
                        };

                        // create object array
                        var rawData = XLSX.utils.sheet_to_row_object_array(sheet);

                        // parse object array and convert data
                        var data = [];
                        for (let row = 0; row < rawData.length; row++) {
                            var parsedRow = {};
                            for (let column = 0; column < columns.length; column++) {
                                var cellName = String.fromCharCode(65 + column) + (row + 2);
                                var cellValue = rawData[row][columns[column].field];

                                if (columns[column].required && !cellValue) {
                                    throw AppKitchen.Strings.Import_MissingValueAt + " " + cellName;
                                }

                                switch (columns[column].type) {
                                case "number":
                                    parsedRow[columns[column].field] = tryParseNumber(cellValue, "en-US", cellName);
                                    break;
                                case "date":
                                    parsedRow[columns[column].field] = tryParseDate(cellValue, cellName, ["M/d/yy", "d"]);
                                    break;
                                case "time":
                                    parsedRow[columns[column].field] = tryParseTime(cellValue, cellName, false);
                                    break;
                                case "time24":
                                    parsedRow[columns[column].field] = tryParseTime(cellValue, cellName, true);
                                    break;
                                case "dateTime":
                                    parsedRow[columns[column].field] = tryParseExcelDateTime(cellValue, cellName, ["d/M/yy HH:mm", "g"]);
                                    break;
                                default:
                                    parsedRow[columns[column].field] = cellValue;
                                    break;
                                }
                            }
                            data.push(parsedRow);
                        }

                        loaded(data);
                    } catch (exception) {
                        error(exception);
                    }
                };

                reader.readAsArrayBuffer(file);
            }

            export function readCsvFile(file: File,
                columns: CsvImportColumnDefinition[],
                loaded: (data: any[]) => void,
                error: (message: string) => void,
                options?: CsvImportOptions) {

                if (!columns || columns.length === 0) {
                    throw "No columns for csv import defined!";
                }

                options = AppKitchen.OptionsHelper.merge<CsvImportOptions>(options, {
                    firstDataRowIndex: 1, //assuming header
                    columnDelimiter: ";",
                    rowDelimiter: "\r\n",
                    culture: AppKitchen.GlobalSettings.culture,
                    encoding: "utf-8"
                });

                var reader = new FileReader();
                reader.onload = e => {
                    try {
                        var fileData: string = (<any>e.target).result;
                        var lines = fileData.split(options.rowDelimiter);
                        if (lines.length < options.firstDataRowIndex + 1) {
                            return;
                        }
                        var data = [];
                        for (let row = options.firstDataRowIndex; row < lines.length; row++) {
                            if (!lines[row]) {
                                continue;
                            }
                            var fields = lines[row].split(options.columnDelimiter);
                            var parsedRow = {};
                            for (let column = 0; column < columns.length; column++) {
                                var cellName = "row: " + (row + 1) + ", column: " + (column + 1);
                                var value = fields[column];
                                if (!value) {
                                    if (columns[column].required) {
                                        throw AppKitchen.Strings.Import_MissingValueAt + " " + cellName; //todo: correct string
                                    } else {
                                        parsedRow[columns[column].field] = value;
                                        continue;
                                    }
                                }
                                switch (columns[column].type) {
                                case "number":
                                    parsedRow[columns[column].field] = tryParseNumber(value, options.culture, cellName);
                                    break;
                                case "date":
                                    parsedRow[columns[column].field] = tryParseDate(value, cellName, [columns[column].format || "g"], options.culture);
                                    break;
                                case "boolean":
                                    parsedRow[columns[column].field] = tryParseBoolean(value, cellName);
                                    break;
                                default:
                                    parsedRow[columns[column].field] = value;
                                    break;
                                }
                            }
                            data.push(parsedRow);
                        }
                        loaded(data);
                    } catch (exception) {
                        error(exception);
                    }
                }
                reader.readAsText(file, options.encoding);
            }

            function fix(data) {
                var o = "", l = 0, w = 10240;
                for (; l < data.byteLength / w; ++l)
                    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
                o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
                return o;
            }

            function tryParseExcelDateTime(value: string, cell: string, formats?: string[], culture?: string): Date {
                var date;
                try {
                    date = kendo.parseDate(value, formats, culture);
                    if (date) {
                        return date;
                    }
                } catch (e) {}
                
                var number = parseFloat(value);
                if (!value || !number) {
                    throw AppKitchen.Strings.Import_InvalidDateAt + " " + cell;
                }

                var localTimestamp = (number - (25567 + 2)) * 86400 * 1000;
                var roundCoeff = 1000 * 60;
                localTimestamp = Math.round(localTimestamp / roundCoeff) * roundCoeff;

                var utcTimetamp = localTimestamp + new Date().getTimezoneOffset() * 60000;

                date = new Date(utcTimetamp);

                return date;
            }

            function tryParseTime(value: string, cell: string, allow24: boolean): string {
                if (allow24) {
                    if (value === "24:00") {
                        return "24:00";
                    }
                }

                var time = kendo.parseDate(value, "HH:mm");
                if (!time) {
                    throw AppKitchen.Strings.Import_InvalidTimeAt + " " + cell;
                }

                var strTime = kendo.toString(time, "HH:mm");

                if (allow24 && strTime === "00:00") {
                    throw AppKitchen.Strings.Import_InvalidTimeAt + " " + cell;
                }

                return strTime;
            }

            function tryParseNumber(value: string, culture: string, cell: string): number {
                if (value == null) {
                    return null;
                }

                var number = kendo.parseFloat(value, culture);
                if (number == null) {
                    throw AppKitchen.Strings.Import_InvalidNumberAt + " " + cell;
                }

                return number;
            }

            function tryParseDate(value: string, cell: string, formats?: string[], culture?: string): Date {
                var date = kendo.parseDate(value, formats, culture);
                if (!date) {
                    throw AppKitchen.Strings.Import_InvalidDateAt + " " + cell;
                }

                return date;
            }

            function tryParseBoolean(value: string, cell: string): boolean {
                if (value) {
                    if (value.toLowerCase() === "true") {
                        return true;
                    } else if (value.toLowerCase() === "false") {
                        return false;
                    } else {
                        throw AppKitchen.Strings.Import_InvalidBooleanAt + " " + cell; //todo: correct string
                    }
                } else {
                    return null; //or undefined?
                }
            }
        }
    }
}