namespace AppKitchen {
    export module LoginManager {

        var loginEnsured = false;
        var intervalId = -1;

        function getParameterFromQueryString(key: string, queryString: string): string {
            var queries = queryString.split("&");
            for (var i = 0; i < queries.length; i++) {
                if (queries[i].indexOf(key) === 0 && queries[i][key.length] === "=") {
                    return queries[i].substring(key.length + 1);
                }
            }
            return undefined;
        }

        function redirectToOAuth(logout: boolean): void {
            var tokenUrl = getOAuthUrl(logout);
            removeCookie(AppKitchen.GlobalSettings.currentUserCookieName);
            AppKitchen.BrowserHelper.setUrl(tokenUrl, {refresh: true, push: false});
        }

        function getOAuthUrl(logout: boolean) {
            var tokenserviceUrl = AppKitchen.GlobalSettings.securityTokenServiceOAuthUrl;
            var welcomeMessage = AppKitchen.GlobalSettings.welcomeMessage;
            var loginTheme = AppKitchen.GlobalSettings.loginTheme;
            var tokenUrl = tokenserviceUrl + "?returnurl=" + encodeURIComponent(document.location.href) + (loginTheme ? "&theme=" + loginTheme : "") + (logout ? "&donotlogin=true" : "") + (welcomeMessage ? "&message="+welcomeMessage : "");
            return tokenUrl;
        }

        function sanitize(input: string): string {
            const escaped = encodeURIComponent(input);
            const decoded = decodeURIComponent(escaped);
            const temp = document.createElement('div');
            temp.textContent = decoded;
            return temp.innerHTML;
        }

        function setPingInterval(): void {
            if (AppKitchen.GlobalSettings.pingEnabled) {
                intervalId = window.setInterval(() =>
                    AppKitchen.Data.ping(AppKitchen.GlobalSettings.pingTimeout * 1000, (res) => { }, (err) => { redirectToOAuth(false); }),
                    AppKitchen.GlobalSettings.pingInterval * 1000);
            }
        }

        export function ensureLogin(start: () => void) {
            if (loginEnsured) {
                start();
            } else {
                ensureLoginAndStart(start);
            }
        }

        function ensureLoginAndStart(start: () => void) {
            if (AppKitchen.BrowserHelper.UrlQuery.getParameter("claim") != null && loginEnsured === false) {

                const claim = window.atob(AppKitchen.BrowserHelper.UrlQuery.getParameter("claim"));
                const token = getParameterFromQueryString("token", claim);
                const username = sanitize(getParameterFromQueryString("username", claim));

                AppKitchen.BrowserHelper.UrlQuery.removeParameter("claim", { refresh: false, push: true });

                AppKitchen.Data.setClaim(token, username, (res) => {
                        finishLoginProcedure();
                        start();
                    },(err) => {
                        AppKitchen.UIHelper.renderTemplateTo($("body")[0], AppKitchen.Templates.OAuthLink, { href: getOAuthUrl(false), text: err.responseText });
                    });

            } else if (AppKitchen.Data.hasSessionToken()) {

                AppKitchen.Data.ping(AppKitchen.GlobalSettings.pingTimeout * 1000, (result) => {
                    if (result) {
                        finishLoginProcedure();
                        start();
                    } else {
                        redirectToOAuth(false);
                    }
                }, (e) => {
                    AppKitchen.UIHelper.renderTemplateTo($("body")[0], AppKitchen.Templates.OAuthLink, { href: getOAuthUrl(false), text: e.responseText });
                });

            } else {
                redirectToOAuth(false);
                return;
            }
        }

        function finishLoginProcedure() {
            setPingInterval();
            loginEnsured = true;
            checkPasswordValidity();
        }

        export function logout(redirect: boolean) {
            Data.Provider.LogoutProvider.logout().then(logoutResult => {
                if (logoutResult && logoutResult.successfullyLoggedOut) {
                    // stop interval checker
                    if (intervalId !== -1) {
                        window.clearInterval(intervalId);
                    }

                    loginEnsured = false;

                    if (redirect) {
                        redirectToOAuth(true);
                    }
                } else {
                    AppKitchen.UIHelper.Notifications.notify(`Can't logout. Error message: '${logoutResult.errorMessage}'`, "warning");
                }
            });
        }

        export function getCurrentUser(): string {
            if (loginEnsured) {
                var user = Cookies.get(AppKitchen.GlobalSettings.currentUserCookieName);
                if (user) {
                    return decodeURIComponent(user);
                }
            }
            return "-";
        }

        /**
        * @deprecated Both cookies are deleted by LogoutController.cs 
        */
        function removeCookie(name: string) {
            const domain = window.location.hostname;
            Cookies.remove(name);
            Cookies.remove(name, <Cookies.CookieAttributes>{ domain: domain });
            Cookies.remove(name, <Cookies.CookieAttributes>{ domain: `.${domain}` });
        }

        function checkPasswordValidity() {
            AppKitchen.Data.Api.getPasswordChangeInformation(passwordChangeInfo => {
                if (passwordChangeInfo.showPasswordChangeNotification) {
                    const day = passwordChangeInfo.daysBeforeExpiring === 1 
                        ? Strings.CheckPasswordValidity_PasswordExpireInViewDays_Day 
                        : Strings.CheckPasswordValidity_PasswordExpireInViewDays_Days;

                    const daysBeforeExpiring = `${passwordChangeInfo.daysBeforeExpiring} ${day}`;
                    setTimeout(() => renderPasswordExpireNotification(daysBeforeExpiring), 1000);
                }
            });
        }

        function renderPasswordExpireNotification(remainingDays: string) {
            const template = AppKitchen.UIHelper.renderTemplate(Templates.PasswordValidityNotification,
                {
                    message: Strings.CheckPasswordValidity_PasswordExpireInViewDays_Message,
                    subMessage: remainingDays
                });
            const notification = AppKitchen.UIHelper.Notifications.notify(template, "info", { title: Strings.CheckPasswordValidity_PasswordExpireInViewDays_Title });

            $(".a-password-validity-notification-button").on("click", () => {
                notification.close();
                Apps.ChangePasswordApp.startChangePasswordWindow();
            });
        }
    }
}