namespace AppKitchen {

    export module Controls.TreeView {

        export interface TreeViewAttributes extends ModelBaseAttributes {}

        export interface TreeViewOptions<T> extends ViewBaseOptions {
            loadingOverlay?: string;
            template?: (item: TreeViewItem<T>) => string;
            hasCheckboxes?: boolean;
            checkParents?: boolean;
            routingParameter?: string;
        }

        export class TreeViewItem<T> {
            id: string;
            text: string;
            imageUrl?: string;
            items?: TreeViewItem<T>[];
            expanded?: boolean;
            data?: T;
        }

        export class ItemsChangedArgs<T> {
            items: TreeViewItem<T>[];
            sender: any;
        }

        export class TreeViewHelper {
            static getItemsIds<T>(items: TreeViewItem<T>[]): string[] {
                return items.map(i => i.id);
            }

            static flattenItems<T>(items?: TreeViewItem<T>[]): TreeViewItem<T>[] {
                if (items == null) {
                    return [];
                }

                let result: TreeViewItem<T>[] = [];
                for (const item of items) {
                    result.push(item);
                    result = result.concat(TreeViewHelper.flattenItems(item.items));
                }

                return result;
            }
        }

        class Events<T> {
            itemsChanged: Rx.Subject<ItemsChangedArgs<T>>;
            selectedItemsChanged: Rx.Subject<ItemsChangedArgs<T>>;
        }

        export class TreeViewModel<T> extends ModelBase<TreeViewAttributes> {

            private items: TreeViewItem<T>[] = [];
            private flattenItems: TreeViewItem<T>[] = [];
            private selectedItems: TreeViewItem<T>[] = [];
            private events: Events<T> = {
                itemsChanged: new Rx.Subject<ItemsChangedArgs<T>>(),
                selectedItemsChanged: new Rx.Subject<ItemsChangedArgs<T>>()
            };
            private lookupTreeViewItem: { [id: string]: TreeViewItem<T> } = {};

            getFlattenItems(): TreeViewItem<T>[] {
                return this.flattenItems;
            }

            getItems(): TreeViewItem<T>[] {
                return this.items;
            }

            getSelectedItems(): TreeViewItem<T>[] {
                return this.selectedItems;
            }

            onItemsChanged(): Rx.IObservable<ItemsChangedArgs<T>> {
                return this.events.itemsChanged.asObservable();
            }

            onSelectedItemsChanged(): Rx.IObservable<ItemsChangedArgs<T>> {
                return this.events.selectedItemsChanged.asObservable();
            }

            setTreeViewDomainData(treeViewDomainData: T[], convert: (t: T[]) => TreeViewItem<T>[]): void {
                this.setData(convert(treeViewDomainData), this);
            }

            setData(items: TreeViewItem<T>[], sender?: any): void {
                this.flattenItems = TreeViewHelper.flattenItems(items);
                this.items = items;
                this.setUpLookup();
                this.events.itemsChanged.onNext({ sender: sender, items: this.items });
            }

            selectTreeViewItems(items: TreeViewItem<T>[], sender?: any): void {
                this.selectedItems = TreeViewHelper.flattenItems(items);
                this.events.selectedItemsChanged.onNext({ sender: sender, items: this.selectedItems });
            }

            private setUpLookup(): void {
                this.flattenItems.AsLinq<TreeViewItem<T>>().ForEach((item => {
                    this.lookupTreeViewItem[item.id] = item;
                }));
            }

            getItemsByIds(ids: string[]): TreeViewItem<T>[] {
                const items: TreeViewItem<T>[] = [];
                for (let id of ids) {
                    let item = this.getItemById(id);
                    if (item) {
                        items.push(item);
                    }
                }
                return items;
            }

            getItemById(id: string): TreeViewItem<T> {
                return this.lookupTreeViewItem[id];
            }

            getItemsIds(): string[] {
                return this.items.map(i => i.id);
            }

            getSelectedItemsIds(): string[] {
                return this.selectedItems.map(i => i.id);
            }
        }

        export class TreeView<T> extends ViewBase<TreeViewModel<T>> {
            options: TreeViewOptions<T>;
            $el: JQuery<HTMLElement>;

            private kendoTreeControl: kendo.ui.TreeView;
            private itemsChangedSubscription: Rx.IDisposable;
            private selectedItemsChangedSubscription: Rx.IDisposable;
            private modelLookup: { [id: string]: kendo.data.Model };

            private elements: {
                treeView: HTMLElement
            };

            constructor(model: TreeViewModel<T>, targetContainer: HTMLElement, options?: TreeViewOptions<T>) {
                super(model, targetContainer, options);
                this.setTemplate(Templates.TreeView);
                this.render();
                this.internalBind();
                this.setKendoTreeSelectedNodes(this.model.getSelectedItemsIds());
            }

            render() {
                this.renderTemplate({});
                this.setContainers();
                this.initializeKendoTree();
                return this;
            }

            private setContainers(): void {
                this.elements = {
                    treeView: this.$el.find(".a-tree-view")[0]
                };
            }

            private internalBind(): void {
                if (!this.itemsChangedSubscription) {
                    this.itemsChangedSubscription = this.model.onItemsChanged().subscribe(e => {
                        if (e.sender === this) {
                            return;
                        }
                        this.setDataSource(e.items);
                    });
                }
                if (!this.selectedItemsChangedSubscription) {
                    this.selectedItemsChangedSubscription = this.model.onSelectedItemsChanged().subscribe(
                        e => {
                            this.updateSelection(e.items);
                        });
                }
            }

            private updateSelection(selectedItems: TreeViewItem<T>[]): void {
                this.updateItemUrlParameter(selectedItems);
                this.setKendoTreeSelectedNodes(TreeViewHelper.getItemsIds(selectedItems));
            }

            private internalUnbind(): void {
                if (this.itemsChangedSubscription) {
                    this.itemsChangedSubscription.dispose();
                    this.itemsChangedSubscription = null;
                }
                if (this.selectedItemsChangedSubscription) {
                    this.selectedItemsChangedSubscription.dispose();
                    this.selectedItemsChangedSubscription = null;
                }
            }

            private updateItemUrlParameter(items: TreeViewItem<T>[]): void {
                const routingKey = this.options.routingParameter;
                if (routingKey && items.length > 0) {
                    const url = TreeViewHelper.getItemsIds(items).join(";");
                    BrowserHelper.UrlQuery.setParameter(routingKey, url);
                    return;
                } else {
                    BrowserHelper.UrlQuery.removeParameter(routingKey);
                }
            }

            private updateTreeSelectionFromUrl(treeModel: TreeViewModel<T>): void {
                const routingKey = this.options.routingParameter;
                if (!routingKey) {
                    return;
                }
                let itemsParam = BrowserHelper.UrlQuery.getParameter(routingKey);
                if (!itemsParam) {
                    BrowserHelper.UrlQuery.removeParameter(routingKey);
                    return;
                }
                let ids = itemsParam.split(";");
                let items = treeModel.getItemsByIds(ids);
                treeModel.selectTreeViewItems(items, this);
            }

            dispose(): void {
                this.internalUnbind();
            }

            private itemsToKendoDataSource(items: TreeViewItem<T>[]): kendo.data.HierarchicalDataSource {
                const result = new kendo.data.HierarchicalDataSource({
                    data: items
                });
                return result;
            }

            setDataSource(items: TreeViewItem<T>[]): void {
                let dataSource = this.itemsToKendoDataSource(items);
                this.kendoTreeControl.setDataSource(dataSource);
                this.updateTreeSelectionFromUrl(this.model);

                this.updateDomElement();
            }

            private updateDomElement() {
                this.modelLookup = {}

                this.visitTreeChildrenAndAddToLookup(this.kendoTreeControl.dataSource.view());
                this.addDataIdToTreeItemDomElements();
            }

            private addDataIdToTreeItemDomElements() {
                let lookup = this.modelLookup;
                for (let key in lookup) {
                    if (lookup.hasOwnProperty(key)) {
                        const node = this.modelLookup[key];
                        const uid = node.uid;
                        const id = node.id;
                        this.$el.find(`li[data-uid=${uid}]`).attr("data-id", id);
                    }
                }
            }

            private visitTreeChildrenAndAddToLookup(nodes: kendo.data.ObservableArray) {
                for (var i = 0; i < nodes.length; i++) {
                    const node = nodes[i];
                    this.modelLookup[node.id] = node;
                    if (node.hasChildren) {
                        this.visitTreeChildrenAndAddToLookup(node.children.view());
                    }
                }
            }

            initializeKendoTree() {
                const options: kendo.ui.TreeViewOptions = {
                    check: () => { this.onKendoTreeCheck(); },
                    select: e => { e.preventDefault(); },
                    dataSource: [],
                    template: this.options.template
                        ? (data) => {
                            return this.options.template(data.item);
                        }
                        : (data) => {
                            return data.item.text;
                        }
                };
                if (this.options.hasCheckboxes && ! this.options.checkParents) {
                    options.checkboxes = true;
                }
                if (this.options.hasCheckboxes && this.options.checkParents) {
                    options.checkboxes = {
                        checkChildren: this.options.checkParents
                    }
                }
                this.kendoTreeControl = $(this.elements.treeView).kendoTreeView(options).data("kendoTreeView");
            }

            private onKendoTreeCheck(): void {
                const selectedNodeIds = this.getKendoSelectedNodeIds();
                const selectedItems = this.model.getItemsByIds(selectedNodeIds);
                this.model.selectTreeViewItems(selectedItems, this);
            }

            private getKendoSelectedNodeIds(): string[] {
                let selectedNodeIds: string[] = [];
                this.getKendoSelectedNodesRecursive(this.kendoTreeControl.dataSource.view(), selectedNodeIds);
                return selectedNodeIds;
            }

            private getKendoSelectedNodesRecursive(nodes: kendo.data.ObservableArray, selectedNodeIds: string[]): void {
                for (let i = 0; i < nodes.length; i++) {
                    if (nodes[i].hasChildren) {
                        this.getKendoSelectedNodesRecursive(nodes[i].children.view(), selectedNodeIds);
                    } else if (nodes[i].checked) {
                        selectedNodeIds.push(nodes[i].id);
                    }
                }
            }

            private setKendoTreeSelectedNodes(ids: string[]): void {
                const nodes = this.kendoTreeControl.dataSource.view();
                this.setKendoTreeSelectedNodesRecursive(nodes, ids);
            }

            private setKendoTreeSelectedNodesRecursive(nodes: kendo.data.ObservableArray, ids: string[]): void {
                if (nodes == null) {
                    return;
                }

                for (let i = 0; i < nodes.length; i++) {
                    let node = nodes[i];
                    if (ids.indexOf(node.id) > -1) {
                        node.set("checked", true);
                    } else {
                        node.set("checked", false);
                    }

                    if (node.hasChildren) {
                        this.setKendoTreeSelectedNodesRecursive(node.children.view(), ids);
                    }
                }
            }
        }
    }
}