namespace AppKitchen {

    export module Starter {
        
        export function startup(targetContainer: HTMLElement, ressourcesLocations: string[], apiUrl: string, appKitchenApiUrl: string, startApp: (container: HTMLElement) => any) {
            var appStarted = false;
            var startupError = false;
            var globalSettingsLoaded = false;
            var loadedRessourcesCount = 0;
            
            // check browser version immediately and throw error if incompatible.
            checkBrowser();
            // check cookies immediately and throw error if deactivated.
            checkCookies();
            // cleanup storage immediately
            cleanupStorage();
            // update colors immediately
            updateColors();

            AppKitchen.Data.setAPIs(apiUrl, appKitchenApiUrl);
            
            // set culture to browser culture if no culture in GlobalSettings
            if (!AppKitchen.GlobalSettings.culture) {
                AppKitchen.GlobalSettings.culture = getBrowserCulture();
            }

            // set kendo culture
            kendo.culture(AppKitchen.GlobalSettings.culture);

            var tryToStartApp = () => {
                if (loadedRessourcesCount === ressourcesLocations.length && globalSettingsLoaded) {
                    appStarted = true;
                    initGeneralCode();
                    AppKitchen.UIHelper.removeLoader(targetContainer, {
                        fadeOut: 300,
                        complete: () => startApp(targetContainer)
                    });
                }
            }

            var throwStartupError = (userFriendlyMessage: string, debugMessage: string) => {
                startupError = true;
                AppKitchen.UIHelper.removeLoader(targetContainer, {fadeOut: 0});
                throwInternalStartupError(userFriendlyMessage, debugMessage);
            }

            for (let i = 0; i < ressourcesLocations.length; i++) {
                var currentFolder = ressourcesLocations[i];
                var mainFile = currentFolder + "/Resources." + AppKitchen.GlobalSettings.culture;
                var fallBack = currentFolder + "/Resources.en-US";
                loadScript(mainFile, fallBack, () => {
                    loadedRessourcesCount++;
                    tryToStartApp();
                }, error => {
                    throwStartupError("Configuration error", "Resource scripts at following locations could not be found:\r\n" + ressourcesLocations.join("\r\n") + "\r\n" + error.message);
                });
            }

            AppKitchen.Data.Api.getGlobalSettings(
                (serverSettings) => {
                    if (serverSettings.RequiresAuthentication) {
                        AppKitchen.GlobalSettings.pingInterval = serverSettings.PingInterval;
                        AppKitchen.GlobalSettings.pingTimeout = serverSettings.PingTimeout;
                        AppKitchen.GlobalSettings.pingEnabled = serverSettings.PingEnabled;
                        AppKitchen.GlobalSettings.securityTokenServiceOAuthUrl = serverSettings.SecurityTokenServiceOAuthUrl;
                        AppKitchen.GlobalSettings.welcomeMessage = serverSettings.WelcomeMessage;
                        AppKitchen.GlobalSettings.loginTheme = serverSettings.LoginTheme;
                        AppKitchen.GlobalSettings.sessionTokenCookieName = serverSettings.SessionTokenCookieName;
                        AppKitchen.GlobalSettings.currentUserCookieName = serverSettings.CurrentUserCookieName;
                    } else {
                        AppKitchen.GlobalSettings.requiresLogin = serverSettings.RequiresAuthentication;
                    }
                    AppKitchen.GlobalSettings.contextSensitiveHelpEnabled = serverSettings.ContextSensitiveHelpEnabled;
                    AppKitchen.GlobalSettings.userManualEnabled = serverSettings.UserManualEnabled;
                    AppKitchen.GlobalSettings.userManualUrl = serverSettings.UserManualUrl;

                    AppKitchen.GlobalSettings.applicationName = serverSettings.ApplicationName;
                    AppKitchen.GlobalSettings.applicationVersion = serverSettings.ApplicationVersion;
                    AppKitchen.GlobalSettings.applicationCopyright = serverSettings.ApplicationCopyright;
                    AppKitchen.GlobalSettings.customConfig = serverSettings.CustomConfig;
                    AppKitchen.GlobalSettings.showFooter = serverSettings.ShowFooter;
                    globalSettingsLoaded = true;
                    tryToStartApp();
                },
                (error) => throwStartupError("Server not reachable", "Failed to read global settings from server: " + error.message));

            if (AppKitchen.GlobalSettings.requiresLogin) {
                AppKitchen.ChangeManager.startCheckForUpdates();
            }
        }
    }
}