namespace AppKitchen {
    export module Controls {
        export module TimeseriesDataViews {

            export class ColumnBasedSpreadsheetView extends TimeseriesDataSpreadsheetBase {
                
                private headerRowsCount: number;

                render() {
                    this.headerRowsCount = 2;

                    const isValid = this.checkTimeseriesDataViewData(this.model.get().timeseries);
                    if (!isValid) {
                        return null;
                    }

                    this.maxLengthTimeseries = this.getMaxLengthTimeseries(this.model.get().timeseries);

                    const sheet = this.getSpreadsheet(this.model.get().timeseries);

                    const spreadsheetOptions: kendo.ui.SpreadsheetOptions = {
                        sheets: [sheet],
                        toolbar: false,
                        sheetsbar: false,
                        columns: sheet.columns.length,
                        rows: sheet.rows.length
                    }; 

                    this.spreadSheet = this.spreadsheetContainer.kendoSpreadsheet(spreadsheetOptions).data("kendoSpreadsheet");
                    
                    if (this.options.editable) {
                        this.spreadSheet.bind("change", () => {
                            this.dirty = true;
                            this.options.onEdit();
                        });
                    }

                    this.dirty = false;
                    return this;
                }

                updateData() {
                    var data = this.model.get().timeseries;
                    if (!data || data.length === 0 || data[0].data.length === 0) {
                        return;
                    }

                    const sheet = this.spreadSheet.sheetByIndex(0);
                    const rowsCount = (<any>sheet)._rows._count;
                    const columnsCount = (<any>sheet)._columns._count;

                    for (var column = 1, tsIndex = 0; column < columnsCount; column++ , tsIndex++) {
                        let ts = data[tsIndex];
                        for (var row = sheet.frozenRows(), dataIndex = 0; row < rowsCount; row++ , dataIndex++) {
                            let value = (<any>sheet).range(row, column).values()[0][0];
                            ts.data[dataIndex][1] = value;
                        }
                    }

                    this.model.trigger("change:timeseries", this.model);
                    this.dirty = false;
                }

                getSpreadsheet(timeseriesDataViewDataList: TimeseriesDataViewData[]): kendo.ui.SpreadsheetSheet {
                    const range: Date[] = this.getRange(this.maxLengthTimeseries.timeseries);
                    
                    let columns: kendo.ui.SpreadsheetSheetColumn[] = [];
                    var cellsList: kendo.ui.SpreadsheetSheetRowCell[][] = this.getInitCellsList(range.length);

                    this.addRangeColumn(range, columns, cellsList);
                    this.addTimeseriesDataColumns(timeseriesDataViewDataList, columns, cellsList);

                    let rows = this.getRows(cellsList);
                    let frozenColumns = rows[0].cells.length > 1 ? 1 : 0;

                    return {
                        rows: rows,
                        columns: columns,
                        frozenRows: 2,
                        frozenColumns: frozenColumns
                    };
                }

                private getRows(cellsList: kendo.ui.SpreadsheetSheetRowCell[][]): kendo.ui.SpreadsheetSheetRow[] {
                    let rows: kendo.ui.SpreadsheetSheetRow[] = [];
                    for (let cells of cellsList) {
                        rows.push({ cells: cells });
                    }
                    return rows;
                }

                private addTimeseriesDataColumns(timeseriesDataViewDataList: TimeseriesDataViewData[], columns: kendo.ui.SpreadsheetSheetColumn[],
                    cellsList: kendo.ui.SpreadsheetSheetRowCell[][]) {
                    timeseriesDataViewDataList.AsLinq<TimeseriesDataViewData>()
                        .ForEach(i => {
                            const data = i.data.AsLinq<number[]>().Select((e) => { return e[1] }).ToArray();
                            const cellType = this.options.editable ? CellType.DataEdit : CellType.DataReadOnly;
                            this.addDataColumn(columns,
                                cellsList,
                                [i.label, i.unit],
                                data, 150,
                                [cellType, CellType.BorderLeft],
                                [CellType.Header, CellType.BorderLeft]
                            );
                        });
                }

                private addRangeColumn(range: Date[], columns: kendo.ui.SpreadsheetSheetColumn[], cellsList: kendo.ui.SpreadsheetSheetRowCell[][]) {
                    this.addDataColumn(columns, cellsList, ["", Strings.TimeseriesDataView_Spreadsheet_Timestamp_Label], range, 150, [CellType.Range], [CellType.Header]);
                }

                private addDataColumn(columns: kendo.ui.SpreadsheetSheetColumn[], cellsList: kendo.ui.SpreadsheetSheetRowCell[][],
                    columnHeaders: string[], data: number[] | Date[], columnWidth: number, cellTypes: CellType[], cellHeaderTypes: CellType[]) {
                    columns.push({ width: columnWidth });
                    let index = 0;
                    for (let columnHeader of columnHeaders) {
                        if (index === columnHeaders.length - 1) {
                            cellHeaderTypes.push(CellType.BorderBottom);
                        }
                        this.insert(cellsList, index++, columnHeader, cellHeaderTypes);
                    }
                    for (let d of data) {
                        this.insert(cellsList, index++, d, cellTypes);
                    }
                }

                private insert(cellsList: kendo.ui.SpreadsheetSheetRowCell[][], index: number, value: string | number | boolean | Date, cellTypes: CellType[]) {
                    let cell: kendo.ui.SpreadsheetSheetRowCell = {};
                    cell.value = value;
                    cell.validation = this.getValidator();
                    this.updateCellStyle(cell, cellTypes);
                    cellsList[index].push(cell);
                }

                private updateCellStyle(cell: kendo.ui.SpreadsheetSheetRowCell, cellTypes: CellType[]) {
                    for (let cellType of cellTypes) {
                        switch (cellType) {
                            case CellType.Header:
                                cell.color = AppKitchen.Colors.Gray;
                                cell.background = AppKitchen.Colors.Gainsboro;
                                cell.enable = false;
                                break;
                            case CellType.BorderLeft:
                                cell.borderLeft = {
                                    color: AppKitchen.Colors.DarkGray,
                                };
                                break;
                            case CellType.ThickBorderLeft:
                                cell.borderLeft = {
                                    color: AppKitchen.Colors.DarkGray,
                                    size: "3"
                                };
                                break; 
                            case CellType.BorderBottom:
                                cell.borderBottom = {
                                    color: AppKitchen.Colors.DarkGray,
                                };
                                break;
                            case CellType.Range:
                                cell.format = kendo.culture().calendar.patterns.g;
                                cell.background = AppKitchen.Colors.Gainsboro;
                                cell.color = AppKitchen.Colors.Gray;
                                cell.enable = false;
                                break;
                            case CellType.DataReadOnly:
                                cell.color = AppKitchen.Colors.Gray;
                                cell.enable = false;
                                break;
                            case CellType.DataEdit:
                                cell.enable = true;
                                break;
                            default:
                        }
                    }
                }

                private getInitCellsList(rowsCount: number): kendo.ui.SpreadsheetSheetRowCell[][] {
                    let cellsList: kendo.ui.SpreadsheetSheetRowCell[][] = [];
                    for (let i = 0; i < rowsCount + this.headerRowsCount; i++) {
                        cellsList.push([]);
                    }
                    return cellsList;
                }
            }
        }
    }
}