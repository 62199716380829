namespace General {
    export module TsChart {

        export module Transformations {

            export function FormatSeriesName(formatString, value, parentSeriesLabel) {
                var result = parentSeriesLabel + " (" + value + ")";

                if (formatString) {
                    if (formatString.startsWith('###js###')) {
                        var procedure = General.convertToFunction(formatString);
                        if (procedure) {
                            result = procedure(value, parentSeriesLabel);
                        } else {
                            GeneralLogger.logError("Failed to format series label, using fallback. (failed procedure: '" + formatString + "')");
                        }
                    } else {
                        try {
                            result = Highcharts.dateFormat(formatString, value);
                        } catch (e) {
                            GeneralLogger.logError("Failed to format series label, using fallback. (failed to parse format string: '" + formatString + "' or date value: '" + value + "')");
                        }
                    }
                }

                return result;
            }

            export function FormatSeriesNameUseOnlyParentSeriesLabel(value, parentSeriesLabel) {
                return parentSeriesLabel;
            }

            export function ChangeTimestampToGroupingFormat(data, groupingFormat) {
                if (groupingFormat === "Hour") {
                    for (var i in data) {
                        var hour = new Date(data[i][0]).getHours();
                        data[i][0] = Date.UTC(1970, 0, 1, hour, 0, 0);
                    }
                }
            }


            export function LatestDatapointTransformation(serie, transformationData) {
                var result = [];
                var latestDataPoints = [];

                var newSerie = {
                    name: serie.name,
                    data: latestDataPoints
                };

                result.push(newSerie);

                if (serie.data && serie.data.length > 0) {

                    var groupingIndex = transformationData.groupingIndex;
                    var xAxisIndex = transformationData.xAxisIndex;
                    var yAxisIndex = transformationData.yAxisIndex;

                    var maxIndex = Math.max(groupingIndex, Math.max(xAxisIndex, yAxisIndex));

                    if (serie.data[0].length > maxIndex) {

                        var dataBin = {};
                        var xAxisValues = [];

                        for (var i in serie.data) {
                            if (!serie.data.hasOwnProperty(i)) {
                                continue;
                            }
                            var xValue = serie.data[i][xAxisIndex];
                            var yValue = serie.data[i][yAxisIndex];
                            var groupingValue = serie.data[i][groupingIndex];


                            if (!dataBin[xValue]) {
                                dataBin[xValue] = [];
                                xAxisValues.push(xValue);
                            }

                            dataBin[xValue].push([groupingValue, yValue]);
                        }

                        xAxisValues.sort();

                        for (var j in xAxisValues) {
                            if (!xAxisValues.hasOwnProperty(j)) {
                                continue;
                            }
                            var xAxisValue = xAxisValues[j];
                            var possibleTuples = dataBin[xAxisValue];
                            possibleTuples.sort(function(a, b) {
                                if (a[0] < b[0]) {
                                    return 1;
                                }
                                if (a[0] > b[0]) {
                                    return -1;
                                }
                                return 0;
                            });

                            var tupleWithLatestGroupingDate = possibleTuples[0];
                            var yAxisValue = tupleWithLatestGroupingDate[1];
                            var groupingDate = tupleWithLatestGroupingDate[0];

                            latestDataPoints.push([xAxisValue, yAxisValue, groupingDate]);
                        }
                        latestDataPoints = latestDataPoints.sort(function(a, b) {
                            return a[0]- b[0];
                        });


                    } else {
                        GeneralLogger.logDebug("data tuples contain not enough dimensions, aborting transformation: "+ serie);
                    }

                } else {
                    GeneralLogger.logDebug("no data found, aborting transformation:"+ serie);
                }
                return result;
            }

            export function StackDataTransformation(serie, transformationData) {

                if (serie.data.length > 0) {
                    var groupingType = transformationData.groupingType;

                    if (groupingType.toUpperCase() === "YEAR") {
                        return Transformations._stackDataByYear(serie, transformationData);
                    }

                }
                return null;
            }

            export function _stackDataByYear(serie, transformationData) {

                var groupingArr = [];
                var hash = [];
                for (var i in serie.data) {
                    var data = serie.data[i];
                    var currentDate = new Date(data[0]);
                    var currentYear = currentDate.getUTCFullYear();


                    if (!groupingArr[currentYear]) {
                        groupingArr[currentYear] = {
                            name: serie.name + currentYear,
                            data: []
                        };
                        hash = [];
                    }

                    //var x = currentDate.setFullYear(2006);
                    var x = Date.UTC(1970,
                        currentDate.getMonth(),
                        currentDate.getDate(),
                        currentDate.getHours(),
                        currentDate.getSeconds());
                    var y = data[1];

                    if (hash[x]) {
                        continue;
                    } else if (hash.length > 0 && hash[hash.length - 1] > x) {
                        continue;
                    }
                    hash[x] = 1;

                    /*if (x != data[0]) {
                        var a = 0;
                    }*/

                    groupingArr[currentYear].data.push([x, y]);
                }

                var result = [];
                for (var k in groupingArr) {
                    result.push(groupingArr[k]);
                }
                return result;
            }

            export function TimeaxisToSeriesTransformation(serie, transformationData) {
                var result = [];

                if (serie.data.length > 0) {

                    var groupingIndex = transformationData.groupingIndex;
                    var xAxisIndex = transformationData.xAxisIndex;
                    var yAxisIndex = transformationData.yAxisIndex;

                    var maxIndex = Math.max(groupingIndex, Math.max(xAxisIndex, yAxisIndex));

                    if (serie.data[0].length > maxIndex) {

                        var dataBin = {};

                        for (var i in serie.data) {
                            if (!serie.data.hasOwnProperty(i))
                                continue;
                            var data = serie.data[i];
                            var x = data[xAxisIndex];
                            var key = data[groupingIndex];
                            var y = data[yAxisIndex];

                            if (!dataBin[key]) {
                                dataBin[key] = [];
                            }

                            dataBin[key].push([x, y]);
                        }

                        for (var j in dataBin) {
                            var groupedValue = parseFloat(j);

                            var newSerie = {
                                name: Transformations.FormatSeriesName(transformationData.labelFormat, groupedValue, serie.name),
                                data: dataBin[j]
                            };

                            result.push(newSerie);
                        }

                    } else {
                        result = [serie];
                        GeneralLogger.logDebug("data tuples contain not enough dimensions, aborting transformation: " + serie);
                    }
                } else {
                    result = [serie];
                    GeneralLogger.logDebug("no data found, aborting transformation: "+ serie);
                }

                return result;
            }

            /*
            *  Use this prase in chart configuration:
            * 
            *  "datatransformation": {
            *	"procedure": "###js###Portal.Chart.Transformations.OHLCTransformation",
            *	"data": {
            *		"o": "NEU_A_IW",
            *		"h": "NEU_C_IW",
            *		"l": "NEU_D_IW",
            *		"c": "NEU_B_IW"
            *	}
            *  },
            * 
            *  - "o" "h" "l" and "c" are datasourceids! 
            *  - Style will be used from series "o". 
            *  - All other series should be disabled via "enabled": false in the chartseries object!
            */
            export function OHLCTransformation(dataList, options) {

                var getTs = function(id, list) {
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].datasourceId == id) {
                            return list[i];
                        }
                    }
                    return null;
                }

                var setData = function(list, targetList, index) {
                    for (var i = 0; i < list.length; i++) {
                        var timestamp = list[i][0];
                        var value = list[i][1];
                        if (targetList[timestamp]) {
                            targetList[timestamp][index] = value;
                        }
                    }
                }

                var retData = [];
                var dataTmp = {};

                var openTs = getTs(options.o, dataList);
                var highTs = getTs(options.h, dataList).data;
                var lowTs = getTs(options.l, dataList).data;
                var closeTs = getTs(options.c, dataList).data;

                for (var i = 0; i < openTs.data.length; i++) {
                    var timestamp = openTs.data[i][0];
                    var value = openTs.data[i][1];
                    var data = [timestamp, value, null, null, null];
                    retData.push(data);
                    dataTmp[timestamp] = data;
                }

                setData(highTs, dataTmp, 2);
                setData(lowTs, dataTmp, 3);
                setData(closeTs, dataTmp, 4);


                openTs.data = retData;

                return [openTs];
            }

            export function CombineSeries(dataList, transformationData) {

                for (var k = 0; k < transformationData.length; k++) {
                    var tData = transformationData[k];
                    var series1 = null;
                    var series2 = null;

                    for (var i = 0; i < dataList.length; i++) {
                        var currData = dataList[i];
                        if (tData.seriesid1 == currData.datasourceId) {
                            series1 = currData;
                        }
                        if (tData.seriesid2 == currData.datasourceId) {
                            series2 = currData;
                        }
                    }

                    if (series1 && series2) {
                        var dataDictionary = {};
                        var size = series2.data.length;

                        for (i = 0; i < size; i++) {
                            var date = series2.data[i];
                            dataDictionary[date[0]] = date[1];
                        }

                        size = series1.data.length;
                        for (i = 0; i < size; i++) {
                            date = series1.data[i];
                            var val = dataDictionary[date[0]];
                            date.push(val);
                        }
                    }
                }

                return dataList;
            }
        }
    }
}


/**
 * This is for legacy purposes only.
 */
namespace Portal {
    export module Chart {
       export class Transformations {
           static FormatSeriesName(formatString, value, parentSeriesLabel) {
               return General.TsChart.Transformations.FormatSeriesName(formatString, value, parentSeriesLabel);
           }
           static FormatSeriesNameUseOnlyParentSeriesLabel(value, parentSeriesLabel) {
               return parentSeriesLabel;
           }
           static LatestDatapointTransformation(serie, transformationData) {
               return General.TsChart.Transformations.LatestDatapointTransformation(serie, transformationData);
           }
           static StackDataTransformation(serie, transformationData) {
               return General.TsChart.Transformations.StackDataTransformation(serie, transformationData);
           }
           static TimeaxisToSeriesTransformation(serie, transformationData) {
               return General.TsChart.Transformations.TimeaxisToSeriesTransformation(serie, transformationData);
           }
           static OHLCTransformation(dataList, options) {
               return General.TsChart.Transformations.OHLCTransformation(dataList, options);
           }
           static CombineSeries(dataList, options) {
               return General.TsChart.Transformations.CombineSeries(dataList, options);
           }
       }
    }
}


