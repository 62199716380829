namespace AppKitchen {

    export module StringHelper {

        export function getString(key: string, resources: any) {
            if (resources) {
                var string = resources[key];
                if (string) {
                    return string;
                }
            }
            return key;
        }

        export function generateRandom(length: number): string {
            var str = "";
            var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < length; i++) {
                str += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            return str;
        }

        export function isValidIdentifier(key: string): boolean {
            // reserved keywords ECMA6
            if ([
                    "do", "if", "in", "for", "let", "new", "try", "var", "case", "else",
                    "enum", "eval", "null", "this", "true", "void", "with", "break", "catch",
                    "class", "const", "false", "super", "throw", "while", "yield", "delete",
                    "export", "import", "public", "return", "static", "switch", "typeof",
                    "default", "extends", "finally", "package", "private", "continue",
                    "debugger", "function", "arguments", "interface", "protected",
                    "implements", "instanceof"
                ].indexOf(key) !==
                -1) {
                return false;
            }

            // illegal special characters
            if (/[\|&;\s\$%@"'<>\(\)\+,\-\\/]/.exec(key) != null) {
                return false;
            }

            // beginning with number
            if (/^[0-9]/.exec(key) != null) {
                return false;
            }

            return true;
        }
    }
}