namespace AppKitchen {

    export module Controls {

        // ReSharper disable once InconsistentNaming
        export interface SearchBoxAttributes extends AppKitchen.ModelBaseAttributes {
            query?: string;
        }

        export class SearchBoxModel extends AppKitchen.ModelBase<SearchBoxAttributes> {
            onChange(changed: (query: string) => void) {
                changed(this.get().query);
                this.bind("change:query", () => {
                    changed(this.get().query);
                });
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface SearchBoxViewOptions extends AppKitchen.ViewBaseOptions{
            placeholder?: string;
        }

        export class SearchBoxView extends AppKitchen.ViewBase<SearchBoxModel> {
            input: JQuery;
            options: SearchBoxViewOptions;

            constructor(model: SearchBoxModel, targetContainer: HTMLElement, options?: SearchBoxViewOptions) {
                super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {
                    placeholder: Strings.SearchBox_Search
                }));
                this.render();
            }

            render() {
                AppKitchen.UIHelper.renderTemplateTo(this.el, Templates.SearchBox, {
                    placeholder: this.options.placeholder,
                    query: this.model.get().query
                });

                var clearButton = this.$el.find(".a-searchbox-clear");
                this.input = this.$el.find("input.a-searchbox-input");

                this.model.onChange(query => clearButton.toggle(!!query));

                clearButton.click(() => this.clearInput());

                var timeOutHandler: number = null;
                this.input.keyup(() => {
                    if (timeOutHandler != null) {
                        clearTimeout(timeOutHandler);
                    }
                    timeOutHandler = window.setTimeout(() => {
                        this.model.set({ query: this.input.val() });
                        timeOutHandler = null;
                    }, 300);
                });

                return this;
            }

            clearInput() {
                this.model.set({ query: "" });
                this.input.val("");
            }
        }

    }
}