/// <reference types="highcharts/highstock" />

namespace AppKitchen {

    export module Controls {

        export module Charts {

            export module HighchartsHelper {

                var highchartsInitialized = false;

                export function initializeHighcharts() {
                    if (!highchartsInitialized) {
                        highchartsInitialized = true;

                        Highcharts.setOptions({
                            global: {
                                useUTC: false
                            },
                            lang: <any>{
                                decimalPoint: kendo.culture().numberFormat["."],
                                thousandsSep: kendo.culture().numberFormat[","],
                                months: kendo.culture().calendar.months.names,
                                shortMonths: kendo.culture().calendar.months.namesAbbr,
                                weekdays: kendo.culture().calendar.days.names,
                                shortWeekdays: kendo.culture().calendar.days.namesAbbr,
                                loading: AppKitchen.Strings.Chart_Loading,
                                numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
                                printChart: AppKitchen.Strings.Chart_PrintChart,
                                rangeSelectorFrom: AppKitchen.Strings.Chart_Button_From,
                                rangeSelectorTo: AppKitchen.Strings.Chart_Button_To,
                                rangeSelectorZoom: AppKitchen.Strings.Chart_Button_Zoom,
                                resetZoom: AppKitchen.Strings.Chart_Button_ResetZoom,
                                resetZoomTitle: AppKitchen.Strings.Chart_Button_ResetZoomTooltip,
                                noData: AppKitchen.Strings.Chart_NoData
                            }
                        });
                    }
                }
            }

            // ReSharper disable once InconsistentNaming
            export class HighchartsData {
                seriesData: number[] | [number, number][] | [string, number][] | Highcharts.DataPoint[];
                id: string;
                constructor(seriesData: number[] | [number, number][] | [string, number][] | Highcharts.DataPoint[], id: string) {
                    this.seriesData = seriesData;
                    this.id = id;
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface HighchartsSeriesConfig {
                id: string;
                styles: Highcharts.SeriesOptions[];
            }

            export function createHighchartSeriesModels(seriesData: HighchartsData[], seriesConfigs: any[]) {

                var doLookup = id => {
                    var config = null;
                    seriesConfigs.forEach(seriesConfig => {
                        if (seriesConfig.id === id) {
                            config = seriesConfig.styles[0];
                            config.id = id;
                        }
                    });
                    return config;
                }

                var highchartSeriesModels: HighchartsSeriesModel[] = [];
                seriesData.forEach(sd => {
                    var seriesConfig = doLookup(sd.id);
                    if (seriesConfig) {
                        highchartSeriesModels.push(new HighchartsSeriesModel(sd.seriesData, seriesConfig));
                    } else {
                        AppKitchen.logWarning("The series with id " + sd.id + " has no config.");
                    }
                });
 

                return highchartSeriesModels;
            }

            // ReSharper disable once InconsistentNaming
            export interface HighchartsSeriesAttributes extends AppKitchen.ModelBaseAttributes {
                data?: number[] | number[][] | [number, number][] | [string, number][] | Highcharts.DataPoint[];
                config?: Highcharts.SeriesOptions;
            }

            export class HighchartsSeriesModel extends AppKitchen.ModelBase<HighchartsSeriesAttributes> {
                constructor(seriesData: number[] | number[][] | [number, number][] | [string, number][] | Highcharts.DataPoint[], config: Highcharts.SeriesOptions) {
                    super({
                        data: seriesData,
                        config: config
                    });
                }

                getConfig(): Highcharts.SeriesOptions {
                    return this.get().config;
                }

                getData() {
                    //TODO: Verify other possible data types.
                    return <Array<number>>this.get().data;
                }

                getSeries() {

                    var config = <Highcharts.SeriesOptions>$.extend({},this.getConfig());
                    config.data = this.getData();
                    return config;
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface HighchartsSeriesCollectionAttributes extends AppKitchen.ModelBaseAttributes {
                seriesCollection?: HighchartsSeriesModel[];
                config?: Highstock.Options;
                defaultConfig?: Highstock.Options;
            }
           
            export class HighchartsSeriesCollectionModel extends AppKitchen.ModelBase<HighchartsSeriesCollectionAttributes> {

                constructor(seriesCollection: HighchartsSeriesModel[]) {
                    super({
                        seriesCollection: seriesCollection,
                        config: undefined,
                        defaultConfig: undefined
                    });
                }

                getAllSeries() {
                    var seriesCollection = this.get().seriesCollection;
                    var retList: Highcharts.SeriesOptions[] = [];
                    seriesCollection.forEach(s => retList.push(s.getSeries()));
                    return retList;
                }
            }

            export class HighchartsView extends AppKitchen.ViewBase<HighchartsSeriesCollectionModel> {
                model: HighchartsSeriesCollectionModel;
                initializedSeriesModels: HighchartsSeriesModel[];
                chart: Highcharts.ChartObject;

                constructor(model: HighchartsSeriesCollectionModel, targetContainer: HTMLElement, options?: AppKitchen.ViewBaseOptions) {
                    HighchartsHelper.initializeHighcharts();
                    super(model, targetContainer, options);

                    this.model.bind("change:seriesCollection change:config", () => this.render());
                    this.initializedSeriesModels = [];
                    this.render();
                }

                initNewSeriesModels() {
                    var seriesModels = this.model.get().seriesCollection;

                    for (var i = 0; i < seriesModels.length; i++) {
                        var sm = seriesModels[i];
                        if ($.inArray(sm, this.initializedSeriesModels) < 1) {
                            this.initializedSeriesModels.push(sm);
                            sm.bind("change:data", this.seriesDataChanged.bind(this, sm));
                        }
                    }

                }

                seriesDataChanged(seriesModel: HighchartsSeriesModel) {
                    var existingSeries = <Highcharts.SeriesObject>this.chart.get(seriesModel.getConfig().id);
                    
                    if (existingSeries) {
                        existingSeries.setData(seriesModel.getData());
                    }
                }

                setExtremesX(extremes: {min?: number, max?: number}) {
                    this.chart.xAxis[0].setExtremes(extremes.min, extremes.max);
                }

                setExtremesY(extremes: { min?: number, max?: number }) {
                    this.chart.yAxis[0].setExtremes(extremes.min, extremes.max);
                }

                getExtremesX(): Highcharts.Extremes {
                    return this.chart.xAxis[0].getExtremes();
                }

                getExtremesY(): Highcharts.Extremes {
                    return this.chart.yAxis[0].getExtremes();
                }

                render() {

                    AppKitchen.UIHelper.renderTemplateTo(this.el, Templates.Highchart);

                    if (!this.model.get().seriesCollection || !this.model.get().config) {
                        return this;
                    }

                    this.initNewSeriesModels();

                    var container = this.$el.find(".a-highcharts-container");

                    var chartConfig = AppKitchen.OptionsHelper.merge(this.model.get().config, this.model.get().defaultConfig, true);

                    if (!chartConfig.tooltip) {
                        chartConfig.tooltip = {};
                    }
                    if (!chartConfig.tooltip.positioner) {
                        chartConfig.tooltip.positioner = (boxWidth, boxHeight, point) => {
                            var xPos = point.plotX - boxWidth - 5;
                            xPos = xPos < 0 ? point.plotX + 70 : xPos;
                            return { x: xPos, y: 32 };
                        };
                    }

                    chartConfig.series = this.model.getAllSeries();
                    container.highcharts('StockChart', chartConfig, (c) => {
                        this.chart = c;
                    });
                    //this.chart = container.highcharts();
                    return this;
                }

                resize() {
                    this.chart.reflow();
                }
            }

        }
    }
}


