///<reference path="../../Portal/InteractiveGraphic/InteractiveGraphic.ts"/>

namespace AppKitchen {

    export module Controls {

        export module InteractiveItem {

            // ReSharper disable once InconsistentNaming
            export interface TsValue {
                key: { [dim: string]: string };
                hypothesis: string;
                aggregationRule?: string;
                date: Date;
                sourceTimeResolution: number;
                targetTimeResolution: number;
                format?: string;
                label?: string;
                unit?: string;
                absolute?: boolean;
            }

            // ReSharper disable once InconsistentNaming
            export interface InteractiveItem {
                x: number;
                y: number;
                color: string;
                title: string;
                type?: "circle" | "line" | "speedometer";
                icon?: string;
                tsValues: TsValue[];
                showLabels: boolean;
                showTooltips: boolean;
                action?: {
                    type: "navigate" | "popup" | "custom";
                    configName: string;
                    configPath: string;
                    callback: () => void;
                };
            }

            // ReSharper disable once InconsistentNaming
            export interface InteractiveItemModelOptions {
                configName: string;
                configPath: string;
            }

            export class InteractiveItemController {
                private models: InteractiveItemModel[];

                constructor(target: HTMLElement, configName: string, configPath: string, modify?: (interactiveitem: InteractiveItem) => InteractiveItem) {
                    this.models = [];

                    this.renderInteractiveItems(target, configName, configPath, modify);
                }

                private renderInteractiveItems(target: HTMLElement, configName: string, configPath: string, modify?: (interactiveitem: InteractiveItem) => InteractiveItem) {

                    var itemsContainer = this.renderInteractionItemsContainer(target);

                    AppKitchen.Data.getDocument(configName,
                        configPath,
                        json => {
                            var j = JSON.parse(json);
                            let interactiveItemModelOptions = <InteractiveItemModelOptions[]>j.interactiveItems;
                            if (!interactiveItemModelOptions) {
                                throw "Could not found config " + configPath + "\\" + configName + ".";
                            }
                            for (let interactiveItemModelOption of interactiveItemModelOptions) {

                                let configName = interactiveItemModelOption.configName;
                                let configPath = interactiveItemModelOption.configPath;

                                this.readConfig(configName,
                                    configPath,
                                    (interactiveItem) => {
                                        let itemContainer = this.appendInteractiveItemContainer(itemsContainer);

                                        let model = new InteractiveItemModel(interactiveItem);

                                        this.createInteractiveItemInstance(interactiveItem, model, itemContainer);

                                        this.models.push(model);
                                    }, modify);                                
                            }
                        });
                }

                private appendInteractiveItemContainer(target: JQuery): HTMLElement {
                    return $('<div class="a-interactive-item-container"></div>')
                        .appendTo(target)[0];
                }

                private renderInteractionItemsContainer(target: HTMLElement): JQuery {
                    return AppKitchen.UIHelper
                        .renderTemplateTo(target, '<div class="a-interactive-items-container"></div>');
                }

                private createInteractiveItemInstance(interactiveItem: InteractiveItem, model: InteractiveItemModel, target: HTMLElement): InteractiveItemView {
                    if (interactiveItem.type === "line") {
                        return new LineInteractiveItemView(model, target);
                    }
                    if (interactiveItem.type === "circle") {
                        return new CircleInteractiveItemView(model, target);
                    }
                    if (interactiveItem.type === "speedometer") {
                        return new SpeedometerInteractiveItemView(model, target);
                    }
                    throw `${interactiveItem.type} not supported!`;
                }

                changeSelectedRange(date, targetTimeResolution) {
                    for (let model of this.models) {
                        model.changeSelectedRange(date, targetTimeResolution);
                    }
                }

                readConfig(configName: string, configPath: string, callback: (interativeItem: InteractiveItem) => void, modify?: (interactiveitem: InteractiveItem) => InteractiveItem) {
                    AppKitchen.Data.getDocument(configName, configPath,
                        json => {
                            let config = JSON.parse(json);
                            if (config) {
                                let interactiveitem = <InteractiveItem>config.interactiveItem;
                                if (interactiveitem) {
                                    if (modify) {
                                        interactiveitem = modify(interactiveitem);
                                    }
                                    callback(interactiveitem);
                                }
                            }
                        });
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface InteractiveItemAttributes extends AppKitchen.ModelBaseAttributes {
                value1?: any[];
                value2?: any[];
                value3?: any[];
                config?: InteractiveItem;
                startDate: Date;
                endDate: Date;
            }

            export class InteractiveItemModel extends AppKitchen.ModelBase<InteractiveItemAttributes> {
                interactiveItem: InteractiveItem;

                constructor(interactiveItem: InteractiveItem) {
                    super();

                    this.interactiveItem = interactiveItem;

                    let configValue = {};
                    configValue["config"] = this.interactiveItem;
                    this.set(configValue);

                    this.changeSelectedRange(this.getDefaultDateForDay(), 5);
                }

                changeSelectedRange(date: any, targetTimeResolution: number) {
                    for (let tsValue of this.interactiveItem.tsValues) {
                        tsValue.targetTimeResolution = targetTimeResolution;
                        tsValue.date = date;
                    }
                    this.loadData();
                }

                private loadData() {
                    
                    let tsValues = this.interactiveItem.tsValues;

                    var loads = [];
                    var funcs = [];

                    for (let i = 0; i <= 3; i++) {
                        loads[i] = () => {
                            let loadingProperty = {};
                            loadingProperty["loading" + (i + 1)] = true;
                            this.set(loadingProperty);
                        };
                        funcs[i] = data => {
                            let valueProperty = {};
                            valueProperty["value" + (i + 1)] = data;

                            let loadingProperty = {}
                            loadingProperty["loading" + (i + 1)] = false;

                            this.set(valueProperty);
                            this.set(loadingProperty);
                        };
                    }

                    let i = 0;
                    for (let tsValue of tsValues) {
                        let loadingProperty = {}
                        loadingProperty["loading" + (i + 1)] = true;
                        this.set(loadingProperty);
                        AppKitchen.Data.getData("TsValue",
                            {
                                AggregationRule: tsValue.aggregationRule,
                                Hypothesis: tsValue.hypothesis,
                                Key: this.convertKey(tsValue.key),
                                SourceTimeResolution: tsValue.sourceTimeResolution,
                                TargetTimeResolution: tsValue.targetTimeResolution,
                                Date: tsValue.date,
                                Unit: tsValue.unit,
                                Absolute: tsValue.absolute
                            },
                            funcs[i],
                            (requestData, xhr) => {
                                AppKitchen.UIHelper.Notifications.notify(xhr.responseText, "warning");
                            });
                        i++;
                    }
                }

                convertKey(baseKey: { [dim: string]: string }): System.Collections.Generic.KeyValuePair<string, string>[
                    ] {
                    var key: System.Collections.Generic.KeyValuePair<string, string>[] = [];
                    for (let dim in baseKey) {
                        key.push({ Key: dim, Value: baseKey[dim] });
                    }
                    return key;
                }

                getDefaultDateForDay() {
                    return new Date().addDays(-1);
                }

                getDefaultDateForMonth() {
                    var today = new Date();
                    return today.addDays(-today.getDate() + 1);
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface InteractiveItemOptions extends AppKitchen.ViewBaseOptions {
            }

            export abstract class InteractiveItemView extends AppKitchen.ViewBase<InteractiveItemModel> {
                options: InteractiveItemOptions;

                containers: {
                    item: JQuery;
                    title: JQuery;
                    content: JQuery;
                }

                constructor(model: InteractiveItemModel, target: HTMLElement, options?: InteractiveItemOptions) {
                    super(model,
                        target,
                        AppKitchen.OptionsHelper.merge(options,
                        {
                    
                        }));

                    this.render();

                    for (let i = 0; i <= 3; i++) {
                        this.model.bind("change:value" + (i + 1), this.update, this);
                        this.model.bind("change:loading" + (i + 1), this.handleLoadingAnimation, this);
                    }
                }

                protected abstract update();

                protected abstract setTemplateFromConfig();

                protected abstract handleLoadingAnimation();

                protected setLoadingAnimation(i: number) {
                    var element = this.containers.content
                        .find(".a-interactive-item-value-text")
                        .get(i);
                    element.innerHTML = "-";
                    AppKitchen.UIHelper.renderLoader(element, { size: "S", type: "concentric" });
                }

                render() {
                    let config = this.model.get().config;
                    let labels: string[] = [];
                    for (let tsValue of config.tsValues) {
                        labels.push(tsValue.label);
                    }
                    this.setTemplateFromConfig();

                    this.renderTemplate({ title: config.title, labels: labels, defaultValue: "-", icon: config.icon, showLabels: config.showLabels, showTooltips: config.showTooltips });

                    this.containers = {
                        item: this.$el.find(".a-interactive-item"),
                        title: this.$el.find(".a-interactive-item-title"),
                        content: this.$el.find(".a-interactive-item-content")
                    }

                    if (config.showTooltips) {
                        let values = this.containers.content.find(".a-interactive-item-value");
                        for (let i = 0; i <= values.length; i++) {
                            if (labels[i] && labels[i] !== "") {
                                $(values[i])
                                    .kendoTooltip({
                                        position: "top",
                                        show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                                    });
                            }
                        }
                    }

                    this.containers.item.css("left", `${config.x}vw`);
                    this.containers.item.css("top", `${config.y}vh`);

                    this.containers.title.css("background-color", config.color);
                    this.containers.content.css("background-color", config.color);

                    if (config.tsValues.length > 2) {
                        this.containers.content.css("font-size", "1.25vw");
                    } else {
                        this.containers.content.css("font-size", "1.5vw");
                    }

                    if (config.action) {
                        this.addClickAction(this.containers.item, config);
                    }

                    return this;
                }

                addClickAction(button: JQuery, interactiveItem: InteractiveItem) {
                    button.unbind("click");

                    button
                        .click(() => {
                            if (interactiveItem.action.type === "popup") {

                                var buttons = [
                                    {
                                        name: "close",
                                        action: (window) => window.close()
                                    }
                                ];

                                var windowOptions: AppKitchen.UIHelper.Windows.WindowOptions = {
                                    width: "90%",
                                    height: "83%",
                                    position: { top: "5%", left: "3%" },
                                    animate: true,
                                    buttons: buttons,
                                    modal: true,
                                    easyClose: true,
                                    center: true
                                }

                                new AppKitchen.FrameManager
                                    .AppHolder(ConfigLauncherApp)
                                    .startInWindow(windowOptions,
                                        <any>{
                                            configFile: this.getConfigFile(interactiveItem),
                                            startDate: this.getStartDate(),
                                            endDate: this.getEndDate()
                            });
                            } else if (interactiveItem.action.type === "navigate") {
                                var route = "#/View/?f=" + encodeURIComponent(this.getConfigFile(interactiveItem));

                                AppKitchen.BrowserHelper.setUrl(route);
                            } else if (interactiveItem.action.type === "custom") {
                                interactiveItem.action.callback();
                            }
                        });
                }

                private getConfigFile(interactiveItem: InteractiveItem): string {
                    let configFile = `Config\\`;
                    if (interactiveItem.action.configPath && interactiveItem.action.configPath !== "") {
                        configFile += `${interactiveItem.action.configPath}`;
                    }
                    configFile += `\\${interactiveItem.action.configName}.json`;
                    return configFile;
                }

                private getStartDate(): Date {
                    return this.model.get().startDate;
                }

                private getEndDate(): Date {
                    return this.model.get().endDate;
                }

                protected convertValue(data: any, format?: string): string {
                    let value = kendo.parseFloat(data);
                    if (!value) {
                        return data;
                    }
                    return kendo.toString(value, format);
                }

            }

            export class LineInteractiveItemView extends InteractiveItemView {

                constructor(model: InteractiveItemModel, target: HTMLElement, options?: InteractiveItemOptions) {
                    super(model,
                        target,
                        AppKitchen.OptionsHelper.merge(options, {}));
                }

                protected setTemplateFromConfig() {
                    this.setTemplate(AppKitchen.Templates.InteractiveItemLine);
                }

                protected update() {
                    for (let i = 0; i <= 3; i++) {
                        let data = <any>this.model.get("value" + (i + 1));
                        let element = this.containers.content
                            .find(".a-interactive-item-value-text")
                            .get(i);
                        if (data) {
                            AppKitchen.UIHelper.removeLoader(element);
                            element.innerHTML = `${this.convertValue(data.Value, "n0")} ${data.Unit}`;
                        }
                    }
                }

                protected handleLoadingAnimation() {
                    for (let i = 0; i <= 3; i++) {
                        let loading = <any>this.model.get("loading" + (i + 1));
                        if (loading === true) {
                            super.setLoadingAnimation(i);
                        }
                    }
                }
            }

            export class CircleInteractiveItemView extends InteractiveItemView {

                constructor(model: InteractiveItemModel, target: HTMLElement, options?: InteractiveItemOptions) {
                    super(model,
                        target,
                        AppKitchen.OptionsHelper.merge(options, {}));
                }

                protected setTemplateFromConfig() {
                    this.setTemplate(AppKitchen.Templates.InteractiveItemCircle);
                }

                protected update() {
                    for (let i = 0; i <= 3; i++) {
                        let data = <any>this.model.get("value" + (i + 1));
                        let element = this.containers.content
                            .find(".a-interactive-item-value-text")
                            .get(i);
                        if (data) {
                            AppKitchen.UIHelper.removeLoader(element);
                            element.innerHTML = `${this.convertValue(data.Value, "n0")} ${data.Unit}`;
                        }
                    }
                }

                protected handleLoadingAnimation() {
                    for (let i = 0; i <= 3; i++) {
                        let loading = <any>this.model.get("loading" + (i + 1));
                        if (loading === true) {
                            super.setLoadingAnimation(i);
                        }
                    }
                }
            }

            export class SpeedometerInteractiveItemView extends InteractiveItemView {

                constructor(model: InteractiveItemModel, target: HTMLElement, options?: InteractiveItemOptions) {
                    super(model,
                        target,
                        AppKitchen.OptionsHelper.merge(options, {}));
                }

                protected setTemplateFromConfig() {
                    this.setTemplate(AppKitchen.Templates.InteractiveItemSpeedometer);
                }

                protected update() {
                    let data = <any>this.model.get("value1");
                    let element = this.containers.content
                        .find(".a-interactive-item-value-text")
                        .get(0);
                    if (data) {
                        AppKitchen.UIHelper.removeLoader(element);
                        element.innerHTML = `${this.convertValue(data.Value, "n0")} ${data.Unit}`;

                        let prozent = this.calculatingSpeedometerProzent(data.Value);
                        this.containers.content.find(".a-speedometer-needle")
                            .css("transform", `rotate(${prozent}deg)`);
                    }
                }

                protected handleLoadingAnimation() {
                    let loading = <any>this.model.get("loading1");
                    if (loading === true) {
                        super.setLoadingAnimation(0);
                    }
                }

                private calculatingSpeedometerProzent(value: any): number {
                    let cutValue = kendo.parseFloat(value);
                    if (cutValue > 100) {
                        cutValue = 100;
                    }
                    if (cutValue < 0) {
                        cutValue = 0;
                    }
                    let prozent = cutValue * 1.8;
                    
                    return prozent;
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface ConfigLauncherAppOptions extends AppKitchen.Apps.ConfigFileStarterAppOptions {
                configLoaded?: (config: any) => void;
                integrated?: boolean;
                startDate?: Date;
                endDate?: Date;
            }

            export class ConfigLauncherApp extends AppKitchen.Apps.ConfigFileStarterApp {
                options: ConfigLauncherAppOptions;

                start() {
                    if (this.options.urlParameter) {
                        this.beautifyUrl();
                    }
                    super.start();
                }

                processConfig(config) {
                    if (this.options.configLoaded) {
                        this.options.configLoaded(config);
                    }

                    if (this.options.startDate) {
                        config.timeaxis[0].begin.date = kendo.toString(this.options.startDate, "s");
                    }
                    if (this.options.endDate) {
                        config.timeaxis[0].end.date = kendo.toString(this.options.endDate, "s");
                    }

                    this.app = new CustomConfigTsChartApp(this.el, { config: config });

                }

                protected getFileUrl() {
                    var url = this.options.configFile;

                    if (!url) {
                        throw "undefined config file";
                    }

                    if (this.options.baseUrl && !url.beginsWith(this.options.baseUrl)) {
                        url = this.options.baseUrl + url;
                    }

                    if (!url.endsWith(".json")) {
                        url += ".json";
                    }

                    return encodeURIComponent(url);
                }

                beautifyUrl() {
                    var url: string = AppKitchen.BrowserHelper.UrlQuery.getParameters()[this.options.urlParameter];
                    if (url) {
                        if (url.endsWith(".json")) {
                            url = url.substr(0, url.length - ".json".length);
                        }
                        if (url.beginsWith(this.options.baseUrl)) {
                            url = url.substr(this.options.baseUrl.length);
                        }
                        AppKitchen.BrowserHelper.UrlQuery
                            .setParameter(this.options.urlParameter, url, { push: false, refresh: false });
                    }
                }

                protected handleRequestError(xhr: JQueryXHR, statusText: string, errorText: string) {
                    if (xhr.status === 404) {
                        this.$el.append(AppKitchen.UIHelper
                            .renderTemplate(AppKitchen.Templates.ConfigurationErrorOverlay,
                            {
                                error: "Die Datei <b>" +
                                    decodeURIComponent(this.getFileUrl()) +
                                    "</b> wurde nicht gefunden"
                            }));

                        AppKitchen.UIHelper.updateFullHeightGrids(this.el);
                    }
                }
            }

            export class CustomConfigTsChartApp extends AppKitchen.Apps.ConfigTsChartApp {
                start() {
                    this.tsChartView = new CustomTimeseriesChartView(this.options.config, this.el);
                }
            }

            export class CustomTimeseriesChartView extends AppKitchen.Portal.InteractiveGraphic {
                getDefaults(): Highcharts.Options {
                    return Charts.Defaults.get();
                }
            }

            export module Charts {
                export class Defaults {
                    static get(): Highcharts.Options {
                        return <any>{
                            chart: {
                                backgroundColor: "#FFFFFF",
                                borderColor: "#F00",
                                animation: false,
                                marginLeft: 70,
                                zoomType: "xy",
                                borderRadius: 0
                            },
                            plotOptions: {
                                series: {
                                    point: {
                                        events: {
                                    
                                        }
                                    }
                                }
                            },
                            exporting: {
                                buttons: {
                                    contextButton: {
                                        enabled: false
                                    }
                                }
                            },
                            credits: {
                                enabled: false
                            },
                            navigator: {
                                maskFill: "rgba(255, 255, 255, 0.5)",
                                series: {
                                    color: 'rgba(125, 125, 125, 0.4)',
                                    fillOpacity: 0.1,
                                    lineColor: 'rgba(125, 125, 125, 0.6)'
                                },
                                outlineColor: '#CCC',
                                enabled: true,
                                maskInside: false
                            },
                            scrollbar: {
                                barBackgroundColor: '#F3F3F3',
                                barBorderColor: '#CCC',
                                barBorderRadius: 0,
                                buttonArrowColor: '#999',
                                buttonBackgroundColor: '#FFF',
                                buttonBorderColor: '#CCC',
                                trackBackgroundColor: '#FFF',
                                trackBorderColor: '#CCC',
                                buttonBorderRadius: 0,
                                enabled: false
                            },
                            xAxis: {
                                title: {
                                    style: {
                                        color: '#555',
                                        fontFamily: 'OpenSansProLight',
                                        fontWeight: 'normal'
                                    }
                                }
                            },
                            yAxis: {
                                title: {
                                    style: {
                                        color: '#555'
                                    }
                                }
                            },
                            title: {
                                style: {
                                    display: 'none'
                                }
                            },
                            subtitle: {
                                style: {
                                    display: 'none'
                                }
                            },
                            tooltip: {
                                borderColor: '#B7B7B7',
                                backgroundColor: 'rgba(255, 255, 255, 0.93)',
                                borderRadius: 0,
                                borderWidth: 1,
                                valueDecimals: 2,
                                hideDelay: 10,
                                crosshairs: true,
                                shared: true,
                                shadow: false,
                                positioner:
                                    "###js###function (boxWidth, boxHeight, point){ var xPos = point.plotX-boxWidth-5; xPos=xPos<0?point.plotX+70:xPos; return { x:  xPos, y: 32 }; }"
                            },
                            rangeSelector: {
                                inputDateFormat: "%e. %b, %Y",
                                enabled: false,
                                buttonTheme: {
                                    fill: 'none',
                                    stroke: 'none',
                                    states: {
                                        hover: {
                                            stroke: '#EEE',
                                            fill: 'none'
                                        },
                                        select: {
                                            fill: 'none',
                                            stroke: '#444'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
