namespace AppKitchen {

    export module Data.Provider {

        export abstract class ProviderCachedBase {

            private static data: any;
            private static request: JQueryXHR;

            protected static do<TDataType, TRequestType>(apiName: string,
                request?: TRequestType,
                reloadRequest?: boolean): JQueryPromise<TDataType> {
                const deferred = $.Deferred<TDataType>();

                if (!reloadRequest && this.data) {
                    deferred.resolve(this.data);
                } else {
                    this.request = Data.getData(apiName,
                        request,
                        (items: TDataType) => {
                            this.data = items;
                            deferred.resolve(items);
                        },
                        (err, xhr) => deferred.reject(xhr.responseText));
                }

                return deferred.promise();
            }

            public static abort() {
                if (this.request) {
                    this.request.abort();
                    this.request = null;
                }
            }
        }
    }
}