///<reference path="../../Controls/Forms/Forms.ts"/>

namespace AppKitchen {

    export module Apps {
        import Forms = Controls.Forms;
        import UserInfo = Api.Models.UserInfo;

        class UserInfoFormModel extends AppKitchen.Controls.Forms.FormModel {
            constructor(userInfo: AppKitchen.Api.Models.UserInfo) {
                var fields = [
                    new AppKitchen.Controls.Forms.FieldModel("Nr", AppKitchen.Controls.Forms.FieldType.Number, AppKitchen.Strings.UserInfo_Nr, { format: "n0" }),
                    new AppKitchen.Controls.Forms.FieldModel("Id", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_Id, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("Name", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_Name, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("MailAddress", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_MailAddress, { pattern: Forms.FieldPatterns.Email }),
                    new AppKitchen.Controls.Forms.FieldModel("Phone", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_Phone, { pattern: Forms.FieldPatterns.Phone }),
                    new AppKitchen.Controls.Forms.FieldModel("WindowsId", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_WindowsId, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("Description", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_Description, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("Division", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_Division, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("DefaultDatabaseId", AppKitchen.Controls.Forms.FieldType.Text, AppKitchen.Strings.UserInfo_DefaultDatabaseId, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("IsAdmin", AppKitchen.Controls.Forms.FieldType.Checkbox, AppKitchen.Strings.UserInfo_IsAdmin, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("IsSysAdmin", AppKitchen.Controls.Forms.FieldType.Checkbox, AppKitchen.Strings.UserInfo_IsSysAdmin, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("IsUserManager", AppKitchen.Controls.Forms.FieldType.Checkbox, AppKitchen.Strings.UserInfo_IsUserManager, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("IsUserApprover", AppKitchen.Controls.Forms.FieldType.Checkbox, AppKitchen.Strings.UserInfo_IsUserApprover, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("IsUserAuthenticator", AppKitchen.Controls.Forms.FieldType.Checkbox, AppKitchen.Strings.UserInfo_IsUserAuthenticator, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("IsServerCertApplier", AppKitchen.Controls.Forms.FieldType.Checkbox, AppKitchen.Strings.UserInfo_IsServerCertApplier, { readOnly: true }),
                    new AppKitchen.Controls.Forms.FieldModel("IsServerCertApprover", AppKitchen.Controls.Forms.FieldType.Checkbox, AppKitchen.Strings.UserInfo_IsServerCertApprover, { readOnly: true })

                ];
                super(fields);
                this.setFieldsData(userInfo);
            }
        }

        export class UserInfoApp extends AppKitchen.AppBase<AppKitchen.AppBaseOptions>  {

            private formModel: UserInfoFormModel;
            private formView: AppKitchen.Controls.Forms.TemplatedFormView;

            private containers: {
                content: HTMLElement,
            }

            private buttons: {
                save: JQuery,
                cancel: JQuery,
                cancelEdit: JQuery,
                edit: JQuery,
            }

            private elements: {
                adminBox: JQuery,
                sysadminBox: JQuery,
            }

            start() {
                AppKitchen.Data.Api.getUserInfo(userInfo => {

                    AppKitchen.UIHelper.renderTemplateTo(this.el,
                        AppKitchen.Templates.UserInfoExtendedTile,
                        {
                            name: userInfo.Name,
                            info: `${AppKitchen.Strings.UserInfo_LastLogin}: ${kendo.toString(userInfo.LogonDate, "F")}`
                        });

                    this.containers = {
                        content: this.$el.find(".a-userinfo-extended-tile-content")[0]
                    };

                    this.buttons = {
                        save: this.$el.find(".a-userinfo-extended-save"),
                        cancel: this.$el.find(".a-userinfo-extended-cancel"),
                        cancelEdit: this.$el.find(".a-userinfo-extended-cancel-edit"),
                        edit: this.$el.find(".a-userinfo-extended-edit")
                    }

                    this.elements = {
                        adminBox: this.$el.find(".is-admin-box"),
                        sysadminBox: this.$el.find(".is-sysadmin-box"),
                    }

                    this.formModel = new UserInfoFormModel(userInfo);

                    this.formView = new AppKitchen.Controls.Forms.TemplatedFormView(this.formModel, this.containers.content, 
                        AppKitchen.Templates.UserInfoForm, { editable: true });

                    this.setButtonAction(userInfo);

                    if (userInfo.IsAdmin) {
                        this.elements.adminBox.removeClass("hide");
                    }

                    if (userInfo.IsSysAdmin) {
                        this.elements.sysadminBox.removeClass("hide");
                    }
                });
            }

            private setButtonAction(userInfo: UserInfo) {

                this.buttons.edit.click(() => {
                    this.enableEdit();
                });

                this.buttons.save.click(() => {
                    this.updateUserInfo(userInfo);
                });
                this.buttons.cancel.click(() => {
                    this.canselEdit(userInfo);
                });
                this.buttons.cancelEdit.click(() => {
                    this.closeWindow();
                });

                this.formView.preview();

                this.buttons.save.hide();
                this.buttons.cancel.hide();
            }

            private enableEdit() {
                this.formView.backToEdit();

                this.buttons.edit.hide();
                this.buttons.cancelEdit.hide();
                this.buttons.save.show();
                this.buttons.cancel.show();
            }

            private canselEdit(userInfo: UserInfo) {
                this.formModel.setValue("MailAddress", userInfo.MailAddress);
                this.formModel.setValue("Phone", userInfo.Phone);

                this.formModel.getField("MailAddress").set({ error: null });
                this.formModel.getField("Phone").set({ error: null });

                this.formView.preview();


                this.buttons.edit.show();
                this.buttons.cancelEdit.show();
                this.buttons.save.hide();
                this.buttons.cancel.hide();
            }

            private updateUserInfo(userInfo: UserInfo) {
                if (this.formView.validateFields()) {
                    let email = this.formModel.getValue("MailAddress");
                    let phone = this.formModel.getValue("Phone");

                    userInfo.MailAddress = email;
                    userInfo.Phone = phone;

                    this.formModel.set({ loading: true });
                    AppKitchen.Data.Api.updateUserInfo({
                        UserInfo: userInfo
                    }, (response) => {
                        this.formModel.set({ loading: false });
                        if (response.SuccessfullyUpdated) {
                            this.canselEdit(userInfo);
                            //this.closeWindow();
                        }
                    });
                }
            }

            private closeWindow() {
                this.$el.closest(".a-app-window").data("kendoWindow").close();
            }

            public static startUserInformationWindow() {
                new AppKitchen.FrameManager.AppHolder(UserInfoApp).startInWindow({
                    width: "35em",
                    height: "37em",
                    modal: true,
                    easyClose: false,
                    overflow: true,
                    center: true
                });
            }
        }
    }
}