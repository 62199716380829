/// <reference path="ProviderBase.ts" />

namespace AppKitchen {

    export module Data.Provider {

        export class LogoutProvider extends ProviderBase {

            public logout(): JQueryPromise<AppKitchen.Api.Models.LogoutResult> {
                return this.do("Logout");
            }

            public static logout(): JQueryPromise<AppKitchen.Api.Models.LogoutResult> {
                return new LogoutProvider().logout();
            }
        }
    }
}