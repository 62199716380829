///<reference types="backbone"/>

namespace AppKitchen {

    // ReSharper disable once InconsistentNaming
    export var NotificationHelper: ChangeManager;

    export module GlobalSettings {
        export var currentUserCookieName: string;
        export var sessionTokenCookieName: string;
        export var securityTokenServiceOAuthUrl: string;
        export var requiresLogin: boolean = true;
        export var pingInterval: number;
        export var pingTimeout: number;
        export var pingEnabled: boolean = true;
        export var culture: string;
        export var loginTheme: string;
        export var welcomeMessage: string;
        export var contextSensitiveHelpEnabled: boolean = false;
        export var userManualEnabled: boolean = false;
        export var userManualUrl: string;
        export var welcomeMessage: string;
        export var applicationName: string;
        export var applicationVersion: string;
        export var applicationCopyright: string;
        export var customConfig: any;
        export var devMode: boolean;
        export var showFooter: boolean;
    }

    export module Templates {}

    export module Data {}

    export module Controls {}

    var router: Backbone.Router;
    export function getRouter() {
        if (!router) {
            router = new Backbone.Router();
        }
        return router;
    }

    export interface AppBaseOptions {
        title?: string;
        requiresLogin?: boolean;
        parent?: AppBase<AppBaseOptions>;
    }

    export interface IAppBase<TAppOptions extends AppBaseOptions> {
        new (targetContainer: HTMLElement, options: TAppOptions): AppBase<TAppOptions>;
    }

    /**
     * The base class for creating Apps. Optionally ensures Mesap login
     */
    export abstract class AppBase<TAppOptions extends AppBaseOptions> {
        $el: JQuery;
        el: HTMLElement;
        options: TAppOptions;

        /**
         * @param {HTMLElement} targetContainer - Target HTML container for the App
         * @param {TAppOptions} options - App options
         */
        constructor(targetContainer: HTMLElement, options: TAppOptions) {
            this.options = OptionsHelper.merge(options, this.defaultOptions());

            this.el = targetContainer;
            this.$el = $(targetContainer);

            Data.init();

            if (this.options.requiresLogin) {
                LoginManager.ensureLogin(() => this.start());
            } else {
                this.start();
            }
        }

        /**
         * Starts the App
         */
        abstract start(): void;

        /**
         * Defines the default App options. To be overridden in derivates
         * @returns Default App options
         */
        defaultOptions(): TAppOptions {
            return <any>{ requiresLogin: GlobalSettings.requiresLogin };
        }

        /**
         * Suspends the App. Is called e.g. for singleton Apps. To be overridden in derivates
         */
        suspend(): void {
            
        }

        /**
         * Contiunes the App. Is called e.g. for singleton Apps. To be overridden in derivates
         */
        continue(): void {

        }

        /**
         * Disposes the App. Is called e.g. when navigating away from routed Apps. To be overridden in derivates
         */
        dispose(): void {

        }

    }

    export interface SetAttributeOptions extends Backbone.ModelSetOptions {
        silent?: boolean;
    }

    export interface ModelBaseAttributes {
        loading?: boolean;
    }

    /**
     * The base class for Models, should be extended using an Interface describing the Model attribues
     */
    export class ModelBase<TAttributes extends ModelBaseAttributes> extends Backbone.Model {

        /**
         * @param {TAttributes} attributes? - Initial Model attribute values
         */
        constructor(attributes?: TAttributes) {
            super(attributes);
        }

        /**
         * Sets model attribute values
         * @param {TAttributes} attributes - Attribute values to be set
         * @param {SetAttributeOptions} options? - Set attribute options
         */
        set(attributes: any, options?: SetAttributeOptions): this {
            super.set(attributes, options);
            return this;
        }

        /**
         * Gets model attributes
         * @param {string} attribute? - [Only for compatibility with Backbone] Attribute name
         * @return Model attribute values
         */
        get(attribute?: string): TAttributes {
            if (attribute) {
                return super.get(attribute);
            }
            return this.attributes as TAttributes;
        }

        bind(eventName: string | Backbone.EventMap, callback: (model: ModelBase<TAttributes>) => void, context?: any) {
            super.bind(<string>eventName, callback, context);
            return this;
        }

        on(event: string | Backbone.EventMap, callback: (model: ModelBase<TAttributes>) => void, context?: any): any {
            super.on(<string>event, callback, context);
            return this;
        }

        getPrevious(): any {
            return this.previousAttributes();
        }
    }

    export class CollectionBase<TModel extends ModelBase<ModelBaseAttributes>> extends Backbone.Collection<TModel> {}

    export interface ViewBaseOptions {
        loadingOverlay?: string;
    }

    /**
     * The base class for Views, should be extended using an Class Type extending ModelBase
     */
    export class ViewBase<TModel extends ModelBase<ModelBaseAttributes>> extends Backbone.View<ModelBase<ModelBaseAttributes>> {
        model: TModel;
        template: (data: any) => string;
        loadingOverlay: JQuery;

        options: ViewBaseOptions;
        /**
         * @param {TModel} model - Model for the View or ViewModel
         * @param {HTMLElement} targetContainer - Target HTML container for the View
         * @param {ViewBaseOptions} options - View options
         */
        constructor(model: TModel, targetContainer: HTMLElement, options?: ViewBaseOptions) {
            super({
                model: model,
                el: targetContainer
            });

            this.options = OptionsHelper.merge<ViewBaseOptions>(options, {
                loadingOverlay: UIHelper.renderTemplate(`<div class="a-loading-overlay">${Templates.LoadingConcentricSpinner}</div>`, { size: "M" })
            });

            this.loadingOverlay = $([]);

            if (this.model.get().loading) {
                this.refreshLoadingOverlay();
            }
            this.model.bind("change:loading", () => this.refreshLoadingOverlay());
        }

        private refreshLoadingOverlay() {
            if (this.model.get().loading) {

                if (this.$el.css("position") === "static") {
                    this.$el.css("position", "relative");
                }
                if (this.$el.has(this.loadingOverlay[0]).length === 0) {
                    this.loadingOverlay = $(this.options.loadingOverlay).appendTo(this.$el);
                }
                this.loadingOverlay.css("display", "block");
                this.loadingOverlay.css("opacity");
                this.loadingOverlay.addClass("a-visible");

            } else {

                if (this.loadingOverlay.length === 0)
                    return;

                this.loadingOverlay.css("opacity");
                this.loadingOverlay.removeClass("a-visible");
                setTimeout(() => {
                    if (this.loadingOverlay.css("opacity") === "0") {
                        this.loadingOverlay.css("display", "none");
                    }
                }, 500);

            }
        }

        protected setTemplate(template: string) {
            this.template = _.template(template);
        }

        protected renderTemplate(data: any) {
            this.$el.html(this.template(data));
            this.refreshLoadingOverlay();
        }
    }

    export interface CollectionViewBaseOptions {}

    export class CollectionViewBase<TCollection extends CollectionBase<ModelBase<ModelBaseAttributes>>> extends Backbone.View<ModelBase<ModelBaseAttributes>> {
        collection: TCollection;
        options: CollectionViewBaseOptions;

        constructor(collection: TCollection, targetContainer: HTMLElement, options?: CollectionViewBaseOptions) {
            super({
                collection: collection,
                el: targetContainer
            });

            this.options = OptionsHelper.merge<CollectionViewBaseOptions>(options, {
                // default options here
            });
        }
    }

    export interface ConfigDocumentBase {
        documenttype: string;
        minHeight?: number;
        minWidth?: number;
        content: any;
    }
}