/// <reference path="ProviderBase.ts" />

namespace AppKitchen {

    export module Data.Provider {

        export class EventDataSaveProvider extends ProviderBase {

            public save(request: AppKitchen.Api.Models.EventDataMultiSaveRequest, api?: string):
                JQueryPromise<boolean> {
                return this.do(api || "EventDataMultiSave", request);
            }
        }
    }
}