namespace AppKitchen {
    export module Controls {
        export module Charts {

            // ReSharper disable once InconsistentNaming
            export interface PieChartDataItem {
                category: string;
                value: number;
                color: string;
            }

            // ReSharper disable once InconsistentNaming
            export interface PieChartAttributes extends AppKitchen.ModelBaseAttributes {
                data?: PieChartDataItem[];
            }

            export class PieChartModel extends AppKitchen.ModelBase<PieChartAttributes> {

                constructor(data?: PieChartDataItem[]) {
                    super({
                        data: data || []
                    });
                }

                data(data: PieChartDataItem[]) {
                    this.set({ data: data });
                }
            }

            export type LegendLabel = {
                color?: string;
                font?: string;
                template?: string | Function;
            }

            export type SeriesLabel = {
                visible?: boolean,
                background?: string,
                template?: string
            }

            // ReSharper disable once InconsistentNaming
            export interface PieChartOptions extends AppKitchen.ViewBaseOptions {
                size?: number;
                noDataMessage?: string;
                //position: "top" | "bottom" | "left" | "right" | "custom"
                //align: "start" | "center" | "end"
                legend?: {
                    visible?: boolean,
                    position?: string,
                    align?: string,
                    offsetX?: number,
                    offsetY?: number,
                    labels?: LegendLabel
                },
                onLegendItemClick?: any;
                onSeriesClick?: any;
                seriesDefaults?: {
                    labels?: SeriesLabel
                };
            }

            export class PieChartView extends AppKitchen.ViewBase<PieChartModel> {
                options: PieChartOptions;
                chart: kendo.dataviz.ui.Chart;

                rendered: boolean;

                constructor(model: PieChartModel, targetContainer: HTMLElement, options?: PieChartOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge<PieChartOptions>(options, {
                        size: undefined,
                        noDataMessage: AppKitchen.Strings.PieChart_NoData,
                        legend: AppKitchen.OptionsHelper.merge(options ? options.legend : {},
                            {
                                visible: true,
                                position: "top",
                                align: "end",
                                labels: {},
                            }),
                        seriesDefaults: AppKitchen.OptionsHelper.merge(options ? options.seriesDefaults : {}, { labels: { visible: false } }, true)
                    }));

                    this.setTemplate(Templates.PieChart);

                    this.rendered = false;

                    this.model.bind("change:data", this.render, this);
                    this.render();
                }

                private configureWrapper(size?: number) {
                    var wrapper = this.$el.find(".a-piechart-wrapper");
                    var container = this.$el.find(".a-piechart-container");

                    var chartSize = size || this.options.size || Math.min(this.$el.height(), this.$el.width()) || 300;

                    $(wrapper).css({
                        "height": chartSize,
                        "width": chartSize
                    });
                    $(container).css({
                        "height": chartSize * 1.4,
                        "width": chartSize * 1.4,
                        "margin-top": - chartSize * 0.2,
                        "margin-left": - chartSize * 0.2,
                        "font-size": chartSize / 17
                    });
                }

                resize(size?: number) {
                    this.configureWrapper(size);
                    if (this.chart) {
                        this.chart.resize();
                    }
                }

                render() {
                    this.renderTemplate({});

                    this.configureWrapper();

                    var container = this.$el.find(".a-piechart-container");
                    
                    var chartData = this.removeEmptyData(this.model.get().data);

                    if (chartData.length > 0) {
                        this.chart = container.kendoChart({
                            legend: {
                                visible: this.options.legend.visible,
                                position: this.options.legend.position,
                                align: this.options.legend.align,
                                offsetX: this.options.legend.offsetX,
                                offsetY: this.options.legend.offsetY,
                                labels: this.options.legend.labels
                            },
                            chartArea: {
                                background: ""
                            },
                            seriesDefaults: {
                                labels: this.options.seriesDefaults.labels
                            },
                            series: [
                                {
                                    type: "pie",
                                    startAngle: 90,
                                    data: chartData,
                                    overlay: {
                                        gradient: null
                                    }
                                }
                            ],
                            tooltip: {
                                visible: true,
                                template: "#= category #: #= value #"
                            },
                            transitions: !this.rendered,
                            legendItemClick: this.options.onLegendItemClick,
                            seriesClick: this.options.onSeriesClick
                        }).data("kendoChart");

                        this.rendered = true;
                    } else {
                        this.rendered = false;
                        if (this.options.noDataMessage) {
                            this.chart = container.kendoChart({
                                legend: {
                                    visible: false
                                },
                                chartArea: {
                                    background: ""
                                },
                                seriesDefaults: {
                                    labels: {
                                        visible: false
                                    }
                                },
                                series: [
                                    {
                                        type: "pie",
                                        startAngle: 90,
                                        data: [{
                                            category: "",
                                            value: 100,
                                            color: AppKitchen.Colors.LightGray
                                        }],
                                        overlay: {
                                            gradient: null
                                        },
                                        highlight: {
                                            visible: false
                                        }
                                    }
                                ],
                                tooltip: { visible: false },
                                transitions: !this.rendered
                            }).data("kendoChart");
                            container.find("> svg").css("opacity", 0.5);
                            container.append('<div class="a-piechart-norecords">' + this.options.noDataMessage + '</div>');
                        }
                    }


                    return this;
                }

                private removeEmptyData(input: PieChartDataItem[]): any {
                    var output = [];
                    if (input) {
                        for (var i = 0; i < input.length; i++) {
                            if (input[i].value > 0) {
                                output.push(input[i]);
                            }
                        }
                    }
                    return output;
                }

            }
            
        }
    }
}
