namespace AppKitchen {

    export module Apps {

        export class ExtendedUserMenuApp extends AppKitchen.AppBase<AppKitchen.FrameManager.UserMenuOptions> {
            start() {
                AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.ExtendedUserMenu);
                AppKitchen.UIHelper.renderLoader(this.$el.find(".a-user-info")[0], { size: "S" });
                AppKitchen.Data.Api.getUserInfo(userInfo => {
                    AppKitchen.Data.Api.getPasswordChangeInformation(passwordChangeInfo => {

                        AppKitchen.UIHelper.renderTemplateTo(this.$el.find(".a-user-info")[0], AppKitchen.Templates.ExtendedUserMenuUserInfo, {
                            name: userInfo.Name,
                            email: userInfo.MailAddress,
                            passwordChangeInfo: passwordChangeInfo.showPasswordChangeNotification,
                            remainingDays: passwordChangeInfo.daysBeforeExpiring
                        });

                        this.$el.find(".a-user-btn-logout").click((e) => this.logout(e));

                        if (this.options.changePassword) {
                            this.$el.find(".a-user-btn-changepwd").click(() => ChangePasswordApp.startChangePasswordWindow());
                        } else {
                            this.$el.find(".a-user-btn-changepwd").remove();
                        }

                        if (this.options.extendedUserInfo) {
                            this.$el.find(".a-user-btn-profile").click(() => UserInfoApp.startUserInformationWindow());
                        } else {
                            this.$el.find(".a-user-btn-profile").remove();
                        }
                    
                    });

                });

            }

            private logout(e: any) {
                e.preventDefault();
                this.$el.fadeOut(400, () => {
                    setTimeout(() => AppKitchen.LoginManager.logout(true), 300);
                });
            }
        }
    }
}