/// <reference path="ProviderBase.ts" />

namespace AppKitchen {

    export module Data.Provider {
        /**
        * @deprecated Use AppKitchen.Data.Api.getPasswordChangeInformation method
        */
        export class PasswordChangeInfoProvider extends ProviderBase {

            public load(): JQueryPromise<AppKitchen.Api.Models.PasswordChangeInfo> {
                return this.do("PasswordChangeInfo");
            }

            public static load(): JQueryPromise<AppKitchen.Api.Models.PasswordChangeInfo> {
                return new PasswordChangeInfoProvider().load();
            }
        }
    }
}