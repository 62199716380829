namespace AppKitchen {
    export module Portal {
        export module Defaults {
            export function interactiveGraphic(): Highstock.Options 
            {
                return <any>{
                        chart: {
                            backgroundColor: "#F9F9F9",
                            borderColor: "#F00",
                            animation: false,
                            marginLeft: 70,
                            zoomType: "xy",
                            borderRadius: 0
                        },
                        plotOptions: {
                            series: {
                                point: {
                                    events: {
                                
                                    }
                                }
                            }
                        },
                        exporting: {
                            buttons: {
                                contextButton: {
                                    enabled: false
                                }
                            }
                        },
                        credits: {
                            enabled: false
                        },
                        navigator: {
                            maskFill: "rgba(255, 255, 255, 0.5)",
                            series: {
                                color: 'rgba(125, 125, 125, 0.4)',
                                fillOpacity: 0.1,
                                lineColor: 'rgba(125, 125, 125, 0.6)'
                            },
                            outlineColor: '#CCC',
                            enabled: true,
                            maskInside: false
                        },
                        scrollbar: {
                            barBackgroundColor: '#F3F3F3',
                            barBorderColor: '#CCC',
                            barBorderRadius: 0,
                            buttonArrowColor: '#999',
                            buttonBackgroundColor: '#FFF',
                            buttonBorderColor: '#CCC',
                            trackBackgroundColor: '#FFF',
                            trackBorderColor: '#CCC',
                            buttonBorderRadius: 0,
                            enabled: false
                        },
                        xAxis: {
                            title: {
                                style: {
                                    color: '#555',
                                    fontFamily: 'OpenSansSemiBold',
                                    fontWeight: 'normal'
                                }
                            }
                        },
                        yAxis: {
                            title: {
                                style: {
                                    color: '#555'
                                }
                            }
                        },
                        title: {
                            style: {
                                display: 'none'
                            }
                        },
                        subtitle: {
                            style: {
                                display: 'none'
                            }
                        },
                        tooltip: {
                            borderColor: '#B7B7B7',
                            backgroundColor: 'rgba(255, 255, 255, 0.93)',
                            borderRadius: 0,
                            borderWidth: 1,
                            valueDecimals: 2,
                            hideDelay: 10,
                            crosshairs: true,
                            shared: true,
                            shadow: false,
                            positioner: "###js###function (boxWidth, boxHeight, point){ var xPos = point.plotX-boxWidth-5; xPos=xPos<0?point.plotX+70:xPos; return { x:  xPos, y: 32 }; }"
                        },
                        rangeSelector: {
                            inputDateFormat: AppKitchen.Strings.ChartInputDateFormat,
                            enabled: false,
                            buttonTheme: {
                                fill: 'none',
                                stroke: 'none',
                                states: {
                                    hover: {
                                        stroke: '#EEE',
                                        fill: 'none'
                                    },
                                    select: {
                                        fill: 'none',
                                        stroke: '#444'
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }
}
