namespace AppKitchen {

    export module Apps {

        export class ChangePasswordApp extends AppBase<AppBaseOptions> {
            private formModel: Controls.Forms.FormModel;
            private templatedFormView: Controls.Forms.TemplatedFormView;

            start() {
                this.loadPasswordRestrictionInfo();
            }

            private loadPasswordRestrictionInfo() {
                Data.Provider.PasswordRestrictionInfoProvider.load()
                    .then(passwordRestrictionInfo => this.render(passwordRestrictionInfo));
            }

            private render(passwordRestrictionInfo: Api.Models.PasswordRestrictionInfo) {

                var fields = [
                    new Controls.Forms.FieldModel("OldPassword", Controls.Forms.FieldType.Text, "OldPassword", {
                        password: true,
                        placeholder: Strings.UserInfo_OldPassword,
                        order: 10001
                    }),
                    new Controls.Forms.FieldModel("NewPassword1", Controls.Forms.FieldType.Text, "NewPassword1", {
                        password: true,
                        showPasswordComplexity: { showText: true, showPercent: true, animate: true, minimumLength: passwordRestrictionInfo.minimumPasswordLength, requiresComplexPassword: passwordRestrictionInfo.requireComplexPassword },
                        placeholder: Strings.UserInfo_NewPassword,
                        order: 10002
                    }),
                    new Controls.Forms.FieldModel("NewPassword2", Controls.Forms.FieldType.Text, "NewPassword2", {
                        password: true,
                        placeholder: Strings.UserInfo_ConfirmNewPassword,
                        order: 10003
                    })
                ];

                this.formModel = new Controls.Forms.FormModel(fields);
                this.templatedFormView = new Controls.Forms.TemplatedFormView(this.formModel, this.el, Templates.ChangePassword, { editable: true });

                // submit on enter
                this.templatedFormView.fieldViewDict["OldPassword"].on("keypress-enter", () => this.changePassword(passwordRestrictionInfo));
                this.templatedFormView.fieldViewDict["NewPassword1"].on("keypress-enter", () => this.changePassword(passwordRestrictionInfo));
                this.templatedFormView.fieldViewDict["NewPassword2"].on("keypress-enter", () => this.changePassword(passwordRestrictionInfo));

                // submit on button click
                this.$el.find(".a-btn-change-pwd").click(() => this.changePassword(passwordRestrictionInfo));
                // cancel on button click
                this.$el.find(".a-btn-cancel").click(() => this.closeWindow());
            }

            private changePassword(passwordRestrictionInfo: Api.Models.PasswordRestrictionInfo) {
                var password1 = this.formModel.getValue("NewPassword1");
                var password2 = this.formModel.getValue("NewPassword2");

                if (password1 !== password2) {
                    this.raiseError("PasswordsAreNotIdentical");
                    return;
                }
                if (passwordRestrictionInfo.requireComplexPassword && !password1) {
                    this.raiseError("InsufficientComplexity");
                    return;
                }

                this.formModel.set({ loading: true });
                Data.Api.changePassword({
                    UserId: "",
                    OldPassword: this.formModel.getValue("OldPassword"),
                    NewPassword: password1
                }, (response) => {
                    if (response.SuccessfullyChanged) {
                        this.successfullyChangedPassword();
                    } else {
                        this.formModel.set({ loading: false });
                        this.raiseError(response.ErrorMessage);
                    }
                });
            }

            private raiseError(message: string) {
                var translatedMessage = this.getErrorMessage(message);
                switch (message) {
                    case "WrongPasswordOrUserName":
                    case "OldPasswordIncorrect":
                    case "WrongPassword":
                        this.formModel.setProperty("OldPassword", "error", translatedMessage);
                        break;
                    case "InsufficientComplexity":
                    case "TooShort":
                    case "TooLong":
                    case "InHistory":
                        this.formModel.setProperty("NewPassword1", "error", translatedMessage);
                        break;
                    case "PasswordsAreNotIdentical":
                        this.formModel.setProperty("NewPassword2", "error", translatedMessage);
                        break;
                    default:
                        UIHelper.DialogBoxes.alert(translatedMessage);
                        break;
                }
            }

            private successfullyChangedPassword() {
                this.closeWindow();

                UIHelper.DialogBoxes.alert(Strings.ChangePassword_Message_PasswordChangedSuccessfully);
            }

            private getErrorMessage(plainMessage: string) {
                var translatedMessage = Strings["PasswordError_" + plainMessage];
                if (!translatedMessage) {
                    return plainMessage;
                }
                return translatedMessage;
            }

            private closeWindow() {
                this.$el.closest(".a-app-window").data("kendoWindow").close();
            }

            static startChangePasswordWindow() {
                new FrameManager.AppHolder(ChangePasswordApp).startInWindow({
                    width: "640px",
                    height: "355px",
                    modal: true,
                    easyClose: false,
                    opened: (window) => window.wrapper.find("input").first().focus(),
                    overflow: true,
                    center: true
                });
            }
        }
    }
}