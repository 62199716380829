namespace AppKitchen {

    export module Controls {

        // ReSharper disable once InconsistentNaming
        export interface DateRangePickerRoutingParameters {
            startDate?: string;
            endDate?: string;
            startFilter?: string;
            endFilter?: string;
        }

        export enum Quarter {
            Q1,
            Q2,
            Q3,
            Q4,
        }

        export class QuarterHelper {
            public static getQuarterFromDate(date: Date): Quarter {
                if (!date) return null;
                const month = date.getMonth();
                if (month < 3) {
                    return Quarter.Q1;
                } else if (month < 6) {
                    return Quarter.Q2;
                } else if (month < 9) {
                    return Quarter.Q3;
                }
                return Quarter.Q4;
            }

            public static getMonthFromQuarter(quarter: Quarter): number {
                switch (quarter) {
                    case Quarter.Q1:
                        return 0;
                    case Quarter.Q2:
                        return 3;
                    case Quarter.Q3:
                        return 6;
                    case Quarter.Q4:
                    default:
                        return 9;
                }
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface DateRangeAttributes extends ModelBaseAttributes {
            startDate?: Date;
            startQuarter?: Quarter;
            endDate?: Date;
            startFilter?: string;
            endFilter?: string;
        }

        export class DateRangeModel extends ModelBase<DateRangeAttributes> {
            constructor(startDate?: Date, endDate?: Date) {
                super({
                    startFilter: "",
                    endFilter: "",
                    startDate: startDate,
                    endDate: endDate
                });
            }

            setStartDate(startDate: Date) {
                this.set({ startDate: startDate });
            }

            setEndDate(endDate: Date) {
                this.set({ endDate: endDate });
            }

            setRange(startDate: Date, endDate: Date) {
                this.set({ startDate: startDate, endDate: endDate });
            }
        }

        export class DateRangePickerModel extends DateRangeModel {
            routingParameters: DateRangePickerRoutingParameters;

            constructor(startDate?: Date, endDate?: Date) {
                super(startDate, endDate);
            }


            route(parameters: DateRangePickerRoutingParameters, defaults: DateRangeAttributes) {
                this.routingParameters = parameters;

                var urlQuery = BrowserHelper.UrlQuery.getParameters();
                Object.keys(parameters).forEach(property => {
                    if (urlQuery[parameters[property]]) {
                        this.setPropertyFromRoute(property, urlQuery[parameters[property]]);
                    } else {
                        if (defaults.hasOwnProperty(property)) {
                            this.set({ [property]: defaults[property] });
                        }
                    }
                });

                this.updateRoute();
                this.bind("change", () => this.updateRoute());
            }

            updateRoute() {
                if (this.routingParameters) {
                    Object.keys(this.routingParameters).forEach(property => {
                        BrowserHelper.UrlQuery.setParameter(this.routingParameters[property],
                            this.getPropertyRoute(property),
                            { refresh: false, push: false });
                    });
                }
            }

            private setPropertyFromRoute(property: string, query: string) {
                switch (property) {
                    case "startDate":
                    case "endDate":
                        this.setDatePropertyFromRoute(property, query);
                        break;
                    case "startFilter":
                    case "endFilter":
                        let setFilterValue = {};
                        setFilterValue[property] = query;
                        this.set(setFilterValue);
                        break;
                    default:
                        throw "unknown property";
                }
            }

            private getPropertyRoute(property: string): string {
                switch (property) {
                    case "startDate":
                    case "endDate":
                        return this.getDatePropertyRoute(property);
                    case "startFilter":
                    case "endFilter":
                        return this.get()[property];
                    default:
                        throw "unknown property";
                }
            }

            private setDatePropertyFromRoute(property: string, dateFromQuery: string) {
                if (dateFromQuery.length === 8) {
                    var date = kendo.parseDate(dateFromQuery, "yyyyMMdd");
                    if (date.valueOf()) {
                        var setValue = {};
                        setValue[property] = date;
                        this.set(setValue);
                    }
                }
            }

            private getDatePropertyRoute(property: string) {
                return kendo.toString(this.get()[property], "yyyyMMdd");
            }

            routeStart(parameter: string, defaultStart: Date, filterParameter?: string, defaultFilter?: string) {
                this.route({ startDate: parameter }, { startDate: defaultStart });
            }

            routeEnd(parameter: string, defaultEnd: Date, filterParameter?: string, defaultFilter?: string) {
                this.route({ startDate: parameter }, { startDate: defaultEnd });
            }

            routeRange(parameterPrefix: string, defaultStart: Date, defaultEnd: Date) {
                this.route({ startDate: parameterPrefix + "from", endDate: parameterPrefix + "to" },
                    { startDate: defaultStart, endDate: defaultEnd });
            }

            onStartChange(changed: (startDate: Date) => void) {
                if (this.get().startDate) {
                    changed(this.get().startDate);
                }
                this.on("change:startDate",
                    () => {
                        if (this.get().startDate) {
                            changed(this.get().startDate);
                        }
                    });
            }

            onEndChange(changed: (endDate: Date) => void) {
                if (this.get().endDate) {
                    changed(this.get().endDate);
                }
                this.on("change:endDate",
                    () => {
                        if (this.get().endDate) {
                            changed(this.get().endDate);
                        }
                    });
            }

            onRangeChange(changed: (startDate: Date, endDate: Date) => void) {
                if (this.get().startDate && this.get().endDate) {
                    changed(this.get().startDate, this.get().endDate);
                }
                this.on("change:startDate change:endDate",
                    () => {
                        if (this.get().startDate && this.get().endDate) {
                            changed(this.get().startDate, this.get().endDate);
                        }
                    });
            }
        }

        export enum DateRangePickerFormat {
            Year,
            Quarter,
            Month,
            Date,
        }

        // ReSharper disable once InconsistentNaming
        export interface DateRangePickerOptions extends ViewBaseOptions {
            format?: string;
            pickerFormat?: DateRangePickerFormat;
            hideEndPicker?: boolean;
            dayPickers?: { label: string, days: number }[];
            minDate?: Date;
            maxDate?: Date;
            maxRange?: number;
            labels?: {
                from?: string | { label: string, filter: string }[];
                to?: string | { label: string, filter: string }[];
            };
        }

        export class DateRangePickerView extends ViewBase<DateRangePickerModel> {
            options: DateRangePickerOptions;
            formView: Forms.TemplatedFormView;
            private formModel: Forms.FormModel;
            private previewQuarter: Quarter;

            constructor(model: DateRangePickerModel, targetContainer: HTMLElement, options?: DateRangePickerOptions) {
                super(model,
                    targetContainer,
                    OptionsHelper.merge<DateRangePickerOptions>(options,
                        {
                            format: kendo.culture().calendar.patterns.d,
                            pickerFormat: DateRangePickerFormat.Date,
                            hideEndPicker: false,
                            dayPickers: undefined,
                            minDate: undefined,
                            maxDate: undefined,
                            maxRange: undefined,
                            labels: { from: Strings.TimeSpanPanel_From, to: Strings.TimeSpanPanel_To }
                        },
                        true));

                //this.setTemplate(Templates.DateRangePicker);

                this.delegateEvents({
                    "keypress .a-startContainer": "updateStartOnEnter",
                    "keypress .a-endContainer": "updateEndOnEnter",
                    "blur .a-startContainer": "updateStart",
                    "blur .a-endContainer": "updateEnd",
                    "click .a-tspanel-daychooser": "setNumberOfDays"
                });

                this.createForm();
            }

            private setNumberOfDays(e) {
                var numberOfDays = parseInt($(e.currentTarget).attr("data-numberofdays"));
                if (numberOfDays !== undefined && numberOfDays !== NaN) {
                    this.model.set({ startDate: new Date().onlyDate().addDays(numberOfDays) });
                    this.model.set({ endDate: new Date().onlyDate() });
                }
            }

            disable() {
                if (this.formView) {
                    if (this.options.pickerFormat === DateRangePickerFormat.Quarter) {
                        this.formView.model.disableField("startQuarterDate");
                    }
                    this.formView.model.disableField("startDate");
                    this.formView.model.disableField("endDate");
                }
            }

            enable() {
                if (this.formView) {
                    if (this.options.pickerFormat === DateRangePickerFormat.Quarter) {
                        this.formView.model.enableField("startQuarterDate");
                    }
                    this.formView.model.enableField("startDate");
                    this.formView.model.enableField("endDate");
                }
            }

            createForm() {
                var pickerFormat = this.getFieldType(this.options.pickerFormat);
                var pickerFields = [
                    new Forms.FieldModel("startDate",
                        pickerFormat,
                        "",
                        {
                            format: this.options.format,
                            min: this.options.minDate ? this.options.minDate.valueOf() : undefined,
                            max: this.options.hideEndPicker && this.options.maxDate
                                ? this.options.maxDate.valueOf()
                                : undefined
                        }),
                    new Forms.FieldModel("endDate",
                        pickerFormat,
                        "",
                        {
                            format: this.options.format,
                            min: this.options.minDate ? this.options.minDate.valueOf() : undefined,
                            max: this.options.maxDate ? this.options.maxDate.valueOf() : undefined
                        })
                ];

                if (this.options.pickerFormat === DateRangePickerFormat.Quarter) {
                    pickerFields.push(new Forms.FieldModel("startQuarterDate",
                        Forms.FieldType.Text,
                        "",
                        {
                            pool: {
                                values: [
                                    { value: Quarter.Q1, text: Strings[`DateRangePicker_Quarter_${Quarter.Q1}`] },
                                    { value: Quarter.Q2, text: Strings[`DateRangePicker_Quarter_${Quarter.Q2}`] },
                                    { value: Quarter.Q3, text: Strings[`DateRangePicker_Quarter_${Quarter.Q3}`] },
                                    { value: Quarter.Q4, text: Strings[`DateRangePicker_Quarter_${Quarter.Q4}`] }
                                ],
                                valueField: "value",
                                textField: "text",
                                restrict: true
                            }
                        }));
                }

                if (Array.isArray(this.options.labels.from)) {
                    var fromLabels = <{ filter: string, label: string }[]>this.options.labels.from;
                    pickerFields.push(new Forms.FieldModel("startDateLabel",
                        Forms.FieldType.Text,
                        "",
                        {
                            pool: { values: fromLabels, restrict: true, textField: "label", valueField: "filter" }
                        }));
                }

                if (Array.isArray(this.options.labels.to)) {
                    var toLabels = <{ filter: string, label: string }[]>this.options.labels.from;
                    pickerFields.push(new Forms.FieldModel("endDateLabel",
                        Forms.FieldType.Text,
                        "",
                        {
                            pool: { values: toLabels, restrict: true, textField: "label", valueField: "filter" }
                        }));
                }

                this.formModel = new Forms.FormModel(pickerFields);

                // min endDate / max range
                this.model.onStartChange(startDate => {
                    if (startDate && !this.options.hideEndPicker) {
                        var endDateField = this.formModel.getField("endDate");

                        var endDate = endDateField.get().value;

                        if (endDateField.get().value) {
                            if (endDateField.get().value < startDate) {
                                endDate = startDate;
                            }
                            if (this.options.maxRange &&
                                endDateField.get().value > startDate.addDays(this.options.maxRange)) {
                                endDate = startDate.addDays(this.options.maxRange);
                            }
                        }

                        endDateField.set({
                            min: startDate.valueOf(),
                            max: this.options.maxRange
                                ? startDate.addDays(this.options.maxRange).valueOf()
                                : endDateField.get().max,
                            value: endDate
                        });
                    }
                });

                this.formModel.setFieldsData({
                    startQuarterDate: QuarterHelper.getQuarterFromDate(this.model.get().startDate),
                    startDate: this.model.get().startDate,
                    endDate: this.model.get().endDate
                });

                var formTemplate = UIHelper.renderTemplate(Templates.DateRangePicker,
                    {
                        labelFrom: this.options.labels.from,
                        labelTo: this.options.labels.to,
                        singlePicker: this.options.hideEndPicker,
                        dayPickers: this.options.dayPickers,
                        showQuarter: this.options.pickerFormat === DateRangePickerFormat.Quarter
                    });

                // ReSharper disable once WrongExpressionStatement
                this.formView = new Forms.TemplatedFormView(this.formModel, this.el, formTemplate, { editable: true });

                if (this.options.dayPickers) {
                    this.highlightSelectedDayPicker();
                    this.model.bind("change:startDate change:endDate", () => this.highlightSelectedDayPicker());
                }

                this.setTwoWayBindings();

                if (Array.isArray(this.options.labels.from)) {
                    this.formModel.fieldDict["startDateLabel"].bind("change:value",
                        field => this.model.set({ startFilter: field.get().value }));
                    this.model.bind("change:startFilter",
                        model => this.formModel.setValue("startDateLabel", model.get().startFilter));
                }
                if (Array.isArray(this.options.labels.to)) {
                    this.formModel.fieldDict["endDateLabel"].bind("change:value",
                        field => this.model.set({ endFilter: field.get().value }));
                    this.model.bind("change:endFilter",
                        model => this.formModel.setValue("endDateLabel", model.get().endFilter));
                }
            }

            private setTwoWayBindings() {
                if (this.options.pickerFormat === DateRangePickerFormat.Quarter) {
                    this.formModel.onValueChange("startQuarterDate",
                        (value: string) => this.startQuarterDateFieldChanged(parseInt(value)));
                    this.model.bind("change:startQuarterDate",
                        model => this.formModel.setValue("startQuarterDate",
                            QuarterHelper.getQuarterFromDate(model.get().startDate)));
                }
                this.formModel.fieldDict["startDate"].bind("change:value",
                    field => this.startDateFieldChanged(field.get().value));
                this.formModel.fieldDict["endDate"].bind("change:value",
                    field => this.model.set({ endDate: field.get().value }));
                this.model.bind("change:startDate", model => this.startDateModelChanged(model.get().startDate));
                this.model.bind("change:endDate", model => this.endDateModelChanged(model.get().endDate));
            }

            private endDateModelChanged(date: Date) {
                this.formModel.setValue("endDate", date);
            }

            private startDateModelChanged(date: Date) {
                if (this.formModel.getValue("startDate") === date) {
                    return;
                }
                if (this.options.pickerFormat === DateRangePickerFormat.Quarter) {
                    const quarter = QuarterHelper.getQuarterFromDate(date);
                    this.formModel.setValue("startQuarterDate", quarter);
                }
                this.formModel.setValue("startDate", date);
            }

            private startQuarterDateFieldChanged(quarter: Quarter) {
                if (this.previewQuarter === quarter) {
                    return;
                }
                this.previewQuarter = quarter;
                let date = new Date(this.model.get().startDate.getTime());
                let month = QuarterHelper.getMonthFromQuarter(quarter);
                date.setMonth(month);
                this.formModel.setValue("startDate", date);
            }

            private startDateFieldChanged(date: Date) {
                if (this.model.get().startDate === date) {
                    return;
                }
                if (this.options.pickerFormat === DateRangePickerFormat.Quarter) {
                    const quarter = QuarterHelper.getQuarterFromDate(date);
                    this.formModel.setValue("startQuarterDate", quarter);
                }
                this.model.set({ startDate: date });
            }

            private highlightSelectedDayPicker() {
                this.$el.find(".a-tspanel-daychooser").removeClass("selected");
                if (this.model.get().startDate) {
                    if (this.options.hideEndPicker ||
                    (this.model.get().endDate &&
                        this.model.get().endDate.valueOf() === new Date().onlyDate().valueOf())) {
                        var today = new Date().onlyDate();
                        var dateFrom = this.model.get().startDate;
                        var numberOfDays = Math.round((dateFrom.valueOf() -
                                today.valueOf() -
                                (dateFrom.getTimezoneOffset() - today.getTimezoneOffset()) * 60000) /
                            86400000);
                        this.$el.find(".a-tspanel-daychooser[data-numberofdays=" + numberOfDays + "]")
                            .addClass("selected");
                    }
                }
            }

            private getFieldType(dateRangePickerFormat: DateRangePickerFormat) {
                switch (dateRangePickerFormat) {
                    case DateRangePickerFormat.Date:
                        return Forms.FieldType.Date;
                    case DateRangePickerFormat.Month:
                        return Forms.FieldType.Month;
                    case DateRangePickerFormat.Year:
                        return Forms.FieldType.Year;
                    case DateRangePickerFormat.Quarter:
                        return Forms.FieldType.Year;
                    default:
                        AppKitchen.logWarning("DateRangePickerFormat " + dateRangePickerFormat + " unknown!");
                        return Forms.FieldType.Date;
                }
            }
        }

    }
}