namespace AppKitchen {

    export module PasswordHelper {

        export class PasswordStrength {
            options: PasswordStrengthOptions;
            $object: JQuery;

            constructor($object: JQuery, options: PasswordStrengthOptions) {
                var defaults: PasswordStrengthOptions = {
                    shortPass: Strings.Form_PasswordComplexity_shortPass,
                    badPass: Strings.Form_PasswordComplexity_badPass,
                    goodPass: Strings.Form_PasswordComplexity_goodPass,
                    strongPass: Strings.Form_PasswordComplexity_strongPass,
                    containsUsername: Strings.Form_PasswordComplexity_containsUsername,
                    enterPass: Strings.Form_PasswordComplexity_enterPass,
                    notComplex: Strings.Form_PasswordComplexity_notComplex,
                    showPercent: true,
                    showText: true,
                    animate: true,
                    animateSpeed: 'fast',
                    usernamePartialMatch: true,
                    requiresComplexPassword: false,
                    minimumLength: 4
                };
                this.$object = $object;
                this.options = $.extend({}, defaults, options);

                return this.init.call(this);

            }

            /**
             * Returns strings based on the score given.
             *
             * @param int score Score base.
             * @return string
             */
            scoreText(score) {

                if (score === -3) {
                    return this.options.notComplex;
                }
                if (score === -1) {
                    return this.options.shortPass;
                }
                if (score === -2) {
                    return this.options.containsUsername;
                }

                score = score < 0 ? 0 : score;

                if (score < 34) {
                    return this.options.badPass;
                }
                if (score < 68) {
                    return this.options.goodPass;
                }

                return this.options.strongPass;
            }

            isComplex(password: string): boolean {
                var res = 0;

                if (password.match(/^(?=.*[\d]).*$/)) {
                    res++;
                }
                if (password.match(/^(?=.*[^\w\d\s]).*$/)) {
                    res++;
                }
                if (password.match(/^(?=.*[a-z]).*$/)) {
                    res++;
                }
                if (password.match(/^(?=.*[A-Z]).*$/)) {
                    res++;
                }

                return res < 3 ? false : true;
            }

            /**
             * Returns a value between -2 and 100 to score
             * the user's password.
             *
             * @param  string password The password to be checked.
             * @param  string username The username set (if options.username).
             * @return int
             */
            calculateScore(password: string, username: string): number {
                let score: number = 0;

                // password < options.minimumLength
                if (password.length < this.options.minimumLength) {
                    return -1;
                }

                if (this.options.username) {
                    // password === username
                    if (password.toLowerCase() === username.toLowerCase()) {
                        return -2;
                    }
                    // password contains username (and usernamePartialMatch is set to true)
                    if (this.options.usernamePartialMatch && username.length) {
                        var user = new RegExp(username.toLowerCase());
                        if (password.toLowerCase().match(user)) {
                            return -2;
                        }
                    }
                }

                if (this.options.requiresComplexPassword && !this.isComplex(password)) {
                    return -3;
                }

                // password length
                score += password.length * 4;
                score += this.checkRepetition(1, password).length - password.length;
                score += this.checkRepetition(2, password).length - password.length;
                score += this.checkRepetition(3, password).length - password.length;
                score += this.checkRepetition(4, password).length - password.length;

                // password has 3 numbers
                if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
                    score += 5;
                }

                // password has at least 2 sybols
                if (password.match(/(.*[^\w\d\s].*[^\w\d\s])/)) {
                    score += 5;
                }

                // password has Upper and Lower chars
                if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
                    score += 10;
                }

                // password has number and chars
                if (password.match(/([\w])/) && password.match(/([\d])/)) {
                    score += 15;
                }

                // password has number and symbol
                if (password.match(/([^\w\d\s])/) && password.match(/([\d])/)) {
                    score += 15;
                }

                // password has char and symbol
                if (password.match(/([^\w\d\s])/) && password.match(/([\w])/)) {
                    score += 15;
                }

                // password is just numbers or chars
                if (password.match(/^\w+$/) || password.match(/^\d+$/)) {
                    score -= 10;
                }

                if (score > 100) {
                    score = 100;
                }

                if (score < 0) {
                    score = 0;
                }

                return score;
            }

            /**
             * Checks for repetition of characters in
             * a string
             *
             * @param int rLen Repetition length.
             * @param string str The string to be checked.
             * @return string
             */
            checkRepetition(rLen: number, str: string): string {
                var res = "", repeated = false;
                for (var i = 0; i < str.length; i++) {
                    repeated = true;
                    for (var j = 0; j < rLen && (j + i + rLen) < str.length; j++) {
                        repeated = repeated && (str.charAt(j + i) === str.charAt(j + i + rLen));
                    }
                    if (j < rLen) {
                        repeated = false;
                    }
                    if (repeated) {
                        i += rLen - 1;
                        repeated = false;
                    } else {
                        res += str.charAt(i);
                    }
                }
                return res;
            }

            /**
             * Initializes the plugin creating and binding the
             * required layers and events.
             *
             * @return void
             */
            init() {
                let shown = true;
                let $text: JQuery<HTMLElement>;
                let $percentage: JQuery<HTMLElement>;
                var $graybar: JQuery<HTMLElement> = $('<div>').addClass('pass-graybar');
                var $colorbar: JQuery<HTMLElement> = $('<div>').addClass('pass-colorbar');
                var $insert: JQuery<HTMLElement> =
                    $('<div>').addClass('pass-wrapper').append($graybar.append($colorbar));

                this.$object.parent().addClass('pass-strength-visible');
                if (this.options.animate) {
                    $insert.css('display', 'none');
                    shown = false;
                    this.$object.parent().removeClass('pass-strength-visible');
                }

                if (this.options.showPercent) {
                    $percentage = $('<span>').addClass('pass-percent').text('0%');
                    $insert.append($percentage);
                }

                if (this.options.showText) {
                    $text = $('<span>').addClass('pass-text').html(this.options.enterPass);
                    $insert.append($text);
                }

                this.$object.after($insert);

                this.$object.keyup(() => {
                    var username = '';
                    if (this.options.username) {
                        username = this.options.username.val().toString();
                    }

                    var score = this.calculateScore(this.$object.val().toString(), username);
                    //this.$object.trigger('password.score', [score]);

                    var perc = score < 0 ? 0 : score;
                    $colorbar.css({
                        backgroundColor: (perc < 34) ? 'red' : (perc < 68) ? 'orange' : 'green',
                        width: perc + '%'
                    });

                    if (this.options.showPercent) {
                        $percentage.html(perc + '%');
                    }

                    if (this.options.showText) {
                        var text = this.scoreText(score);
                        if (!this.$object.val().toString().length && score <= 0) {
                            text = this.options.enterPass;
                        }

                        if ($text.html() !== $('<div>').html(text).html()) {
                            $text.html(text);
                            //this.$object.trigger('password.text', [text, score]);
                        }
                    }
                }).keyup();

                if (this.options.animate) {
                    this.$object.focus(() => {
                        if (!shown) {
                            $insert.slideDown(this.options.animateSpeed,
                                () => {
                                    shown = true;
                                    this.$object.parent().addClass('pass-strength-visible');
                                });
                        }
                    });

                    this.$object.blur(() => {
                        if (!this.$object.val().toString().length && shown) {
                            $insert.slideUp(this.options.animateSpeed,
                                () => {
                                    shown = false;
                                    this.$object.parent().removeClass('pass-strength-visible');
                                });
                        }
                    });
                }

                return this;
            }
        }

        // Bind to jquery
        $.fn.extend({
            password: function(options) {
                return this.each(function(el) {
                    new PasswordStrength($(this), options);
                });
            }
        });
    }
}