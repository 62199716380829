
namespace AppKitchen {

    export module Apps {

        // ReSharper disable once InconsistentNaming
        export interface ConfigAppOptions extends AppKitchen.AppBaseOptions {
            config: any;
        }

        export abstract class ConfigApp extends AppKitchen.AppBase<ConfigAppOptions> {
            abstract redraw();
        }

        export class ConfigTsChartApp extends ConfigApp {
            tsChartView: AppKitchen.Portal.InteractiveGraphic;

            start() {
                this.tsChartView = new AppKitchen.Portal.InteractiveGraphic(this.options.config, this.el);
            }

            redraw() {
                this.tsChartView.updateSize();
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface ConfigFileStarterAppOptions extends AppKitchen.AppBaseOptions {
            urlParameter?: string;
            configFile?: string;
            baseUrl?: string;
        }

        export class ConfigFileStarterApp extends AppKitchen.AppBase<ConfigFileStarterAppOptions> {
            app: ConfigTsChartApp;
            view: Backbone.View<Backbone.Model>;

            start() {
                if (!this.options.configFile && this.options.urlParameter) {
                    var urlQuery = AppKitchen.BrowserHelper.UrlQuery.getParameters();
                    this.options.configFile = urlQuery[this.options.urlParameter];
                    if (urlQuery["undocked"]) {
                        AppKitchen.FrameManager.hideHeader();
                    }
                }

                if (!this.options.configFile) {
                    throw "undefined config file";
                }

                $.ajax({
                    dataType: 'json',
                    success: jsonConfig => {
                        this.processConfig(jsonConfig);
                    },
                    error: this.handleRequestError.bind(this),
                    cache: false,
                    url: this.getFileUrl()
                });
            }

            protected getFileUrl() {
                var url = this.options.configFile;
                if (this.options.baseUrl) {
                    url = this.options.baseUrl + url;
                }
                return encodeURIComponent(url);
            }

            protected handleRequestError(xhr: JQueryXHR, statusText: string, errorText: string) {

            }

            redraw() {
                if (this.app) {
                    this.app.redraw();
                }
                if (this.view && typeof this.view.render === "function") {
                    // updateSize not enough for pdf on Internet Explorer
                    this.view.render();
                }
            }

            protected processConfig(config) {
                var documenttype = config.documenttype.toLowerCase();
                if (documenttype === "tschart" || config.chartdefinition) {
                    this.app = new ConfigTsChartApp(this.el, { config: config });
                } else if (documenttype === "layoutgrid") {
                    this.view = new AppKitchen.Controls.LayoutGridView(new AppKitchen.Controls.LayoutGridModel(config), this.el);
                } else if (documenttype === "interactiveimage") {
                    this.view = new AppKitchen.Controls.InteractiveImage(new AppKitchen.Controls.InteractiveImageModel(config.content.image, config.minWidth, config.minHeight), this.el, {title: config.title});
                } else if (documenttype === "filedisplay") {
                    this.view = new AppKitchen.Controls.FileDisplay(new AppKitchen.Controls.FileDisplayModel(config.content.file, config.content.mime, config.minWidth, config.minHeight), this.el, { title: config.title });
                }
            }
        }
    }
}