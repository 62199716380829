namespace AppKitchen {

    export module SpreadsheetHelper {

        export function getColumnName(columnNumber: number): string {
            let dividend = columnNumber;
            let columnName = "";
            let modulo: number;

            while (dividend > 0) {
                modulo = (dividend - 1) % 26;
                columnName = String.fromCharCode(65 + modulo) + columnName;
                dividend = Math.floor((dividend - modulo) / 26);
            }

            return columnName;
        }
    }
}