namespace AppKitchen {
    export module Colors {
        export var CoalGray: string;
        export var DarkGray: string;
        export var Gray: string;
        export var MediumGray: string;
        export var Silver: string;
        export var LightGray: string;
        export var Gainsboro: string;
        export var WhiteSmoke: string;
        export var Orange: string;
        export var DarkOrange: string;
        export var OrangeRed: string;
        export var Red: string;
        export var Green: string;
        export var LightBlue: string;
        export var DarkBlue: string;
        export var CiColor: string;
        export var CiColorForeground: string;
        export var CiColor2: string;
        export var CiColor2Foreground: string;
    }
}
