/// <reference path="../../Controls/Charts/Highcharts/Highcharts.ts" />
/// <reference path="../../general/tschart/general.tschart.ts" />
/// <reference path="../Defaults/Defaults.ts" />

namespace AppKitchen {
    export module Portal {

        //// ReSharper disable once InconsistentNaming
        //export interface InteractiveGraphicConfig extends AppKitchen.ConfigDocumentBase {
        //    documentType: "timeseriesChart";
        //    databaseId: string;
        //    timeseries: any[];
        //    timeaxis: any[];
        //    chartseries: any[];
        //    transformation: any;
        //    chartdefinition: any;
        //}

        export class InteractiveGraphic extends AppKitchen.ViewBase<AppKitchen.ModelBase<AppKitchen.ModelBaseAttributes>> {
            interactiveDefinition: Api.Models.InteractiveData;
            mInitialChartConfig: Highcharts.Options;
            chartContainer: HTMLElement;
            currentUpdateSizeTimer: any;

            constructor(interactiveDefinition: Api.Models.InteractiveData, targetContainer: HTMLElement) {
                AppKitchen.Controls.Charts.HighchartsHelper.initializeHighcharts();
                super(new AppKitchen.ModelBase(), targetContainer);
                this.interactiveDefinition = interactiveDefinition;
                this.setTemplate(Templates.TsChart);
                this.render();
            }

            render() {
                this.renderTemplate({
                    title: this.interactiveDefinition.chartdefinition.title.text,
                    subtitle: this.interactiveDefinition.chartdefinition.subtitle.text
                });

                this.interactiveDefinition = General.TsChart.applyPreloadTransformation(this.interactiveDefinition, this.el, (titleText) => {
                    var title = this.$el.find(".mp-interactive-title");
                    title.html(titleText);
                }, this.onReloadAfterTransformation.bind(this));

                this.loadChart(this.interactiveDefinition);

                AppKitchen.BrowserHelper.WindowResize.on(() => {
                    if (this.currentUpdateSizeTimer) {
                        clearTimeout(this.currentUpdateSizeTimer);
                    }
                    this.currentUpdateSizeTimer = setTimeout(this.updateSize.bind(this), 100);
                }, this.el);

                return this;
            }

            syncTooltip(container, xValue) {
                var i = 0;
                var chartsDomSelectors = $("body").find(".mp-interactive-target-container");

                for (; i < chartsDomSelectors.length; i++) {
                    var chart = <any>$(chartsDomSelectors[i]).highcharts();
                    if (container.id !== chart.container.id) {
                        var points = General.TsChart.getSeriesPointsForXValue(chart.series, xValue);
                        if (points && points.length > 0) {
                            chart.tooltip.refresh(points);
                        } else {
                            chart.tooltip.hide();
                        }
                    }
                }
            }

            updateSize() {
                var domSelector = this.$el.find(".mp-interactive-target-container");
                var chart = domSelector.highcharts();
                if (!chart) {
                    return;
                }

                var chartOptions = chart.options;
                if (General.TsChart.setChartControlsDependingOnSize(chartOptions, this.$el, this.mInitialChartConfig)) {
                        
                        chart.destroy();
                        domSelector.highcharts("StockChart", chartOptions);
                        chart = domSelector.highcharts("StockChart");
                }

                var chartTitleHeight = this.$el.find(".mp-interactive-header").outerHeight(true);
                chart.setSize(this.$el.width()-20, this.$el.height() - chartTitleHeight, false);
            }

            onReloadAfterTransformation(interactiveDefinition: Api.Models.InteractiveData) {
                this.loadChart(interactiveDefinition);

                if (interactiveDefinition.title) {
                    var title = this.$el.find(".mp-interactive-title");
                    title.html(interactiveDefinition.title);
                }
            }

            loadChart(interactiveData: Api.Models.InteractiveData) {
                AppKitchen.Data.getDataApi("TimeseriesData", interactiveData, (dataList: AppKitchen.Api.Models.ChartData[]) => {
                    if (dataList && dataList.length > 0) {

                        General.TsChart.fixDataList(dataList, interactiveData);
                        dataList = General.TsChart.transformDataIfRequired(interactiveData, dataList);
                        var chartOptions = General.TsChart.preprocessInteractiveDefinition(interactiveData, dataList, this.getDefaults());
                        var me = this;
                        chartOptions.plotOptions.series.point.events.mouseOver = function () {
                            me.syncTooltip(this.series.chart.container, this.x);
                        }

                        this.mInitialChartConfig = jQuery.extend(true, {}, chartOptions);
                        General.TsChart.setChartControlsDependingOnSize(chartOptions, this.el, this.mInitialChartConfig);

                        var chartTarget = this.$el.find(".mp-interactive-target-container");
                        chartTarget.empty();
                        chartTarget.removeAttr('data-highcharts-chart');
                        chartTarget.highcharts('StockChart', chartOptions);

                        this.updateSize();
                    }
                });
            }

            getDefaults(): Highstock.Options {
                return Defaults.interactiveGraphic();
            }
        }
    }
}