namespace AppKitchen {
    export module Controls {
        export module Forms {

            export module EventFormHelper {
                // ReSharper disable once InconsistentNaming
                export var EventItemTypeConverter = {
                    String: Forms.FieldType.Text,
                    Memo: Forms.FieldType.Text,
                    TextPool: Forms.FieldType.Text,
                    Date: Forms.FieldType.Date,
                    Boolean: Forms.FieldType.Checkbox,
                    Descriptor: Forms.FieldType.Text,
                    Binary: Forms.FieldType.Text,
                    Number: Forms.FieldType.Number,
                    Calculated: Forms.FieldType.Number,
                    MasterDataCopy: Forms.FieldType.Text,
                    User: Forms.FieldType.Text,
                    Group: Forms.FieldType.Text,
                    MultiDescriptor: Forms.FieldType.Text,
                    EventKeyField: Forms.FieldType.Text,
                    PeriodNr: Forms.FieldType.DateTime,

                    // manual Types in config (not from mesap)
                    DateTime: Forms.FieldType.DateTime,
                    Time: Forms.FieldType.Time
                }

                export function getFieldLabel(inventoryId: string, itemId: string, metadata?: string, eventItem?: AppKitchen.Api.Models.EventItemInfo): string {
                    if (eventItem) {
                        return eventItem.Name;
                    }
                    var label = inventoryId ? inventoryId + "_" + itemId : itemId;
                    if (metadata) {
                        label += "_" + metadata;
                    }
                    return label;
                }

                export function generateFormFields(config: AppKitchen.Data.GridConfig, eventItems: AppKitchen.Data.FieldToEventItemInfoDictionary, getFieldLabel: (inventoryId: string, itemId: string, metadata?: string, eventItem?: AppKitchen.Api.Models.EventItemInfo) => string) {
                    var fields = [];

                    var eventItemSpecs = config.EventItemSpecs;

                    // create form field objects
                    for (let i = 0; i < eventItemSpecs.length; i++) {
                        var fieldConfig = eventItemSpecs[i];

                        var fieldKey = fieldConfig.InventoryId ? (fieldConfig.InventoryId + "_" + fieldConfig.ItemId) : fieldConfig.ItemId;
                        if (fieldConfig.Metadata) {
                            fieldKey += "_" + fieldConfig.Metadata;
                            //enforce readonly on metadata field
                            if (!fieldConfig.FieldOptions) {
                                fieldConfig.FieldOptions = {};
                            }
                            fieldConfig.FieldOptions.readOnly = true;
                        }
                        var eventItem = eventItems[fieldKey];
                        var fieldLabel = getFieldLabel(fieldConfig.InventoryId, fieldConfig.ItemId, fieldConfig.Metadata, eventItem);

                        var eventItemType = fieldConfig.Type
                            ? fieldConfig.Type
                            : (fieldConfig.InventoryId && eventItems[fieldKey] ? eventItems[fieldKey].Type : undefined);

                        if (!eventItemType)
                            throw "undefined field type";

                        var fieldType: Forms.FieldType = EventFormHelper.EventItemTypeConverter[eventItemType];
                        if (eventItem.TypeSpecification &&
                            eventItem.TypeSpecification.TimeResolution &&
                            eventItem.TypeSpecification.TimeResolution >= Mesap.Framework.Common.TimeResolution.Day) {
                            fieldType = EventFormHelper.EventItemTypeConverter.Date;
                        }

                        // create form field
                        var field: Forms.FieldModel;

                        if (fieldConfig.Type) {
                            //custom field type from config
                            field = createGenericField(fieldKey, fieldLabel, fieldType, eventItems[fieldKey], fieldConfig.FieldOptions);
                        } else if (fieldConfig.InventoryId && eventItems[fieldKey]) {
                            switch (fieldType) {
                                case Forms.FieldType.Text:
                                    field = createEventTextField(fieldKey, fieldLabel, eventItems[fieldKey], fieldConfig.FieldOptions);
                                    break;
                                case Forms.FieldType.Date:
                                    field = createEventDateField(fieldKey, fieldLabel, eventItems[fieldKey], fieldConfig.FieldOptions);
                                    break;
                                default:
                                    field = createGenericField(fieldKey, fieldLabel, fieldType, eventItems[fieldKey], fieldConfig.FieldOptions);
                            }
                        } else {
                            throw "undefined field type";
                        }

                        // set order from sortNr (if not set)
                        if (fieldConfig.SortNr && !field.get().order) {
                            field.set({ order: parseInt(`${fieldConfig.SortNr}`) });
                        }

                        // mark binary fields
                        if (eventItemType === "Binary") {
                            field.set(<any>{ binary: true });
                        }

                        fields.push(field);
                    }

                    // sort fields
                    fields = fields.sort((field1: Forms.FieldModel, field2: Forms.FieldModel) => compareSortNr(field1.get().order, field2.get().order));

                    return fields;
                }

                export function createEventTextField(key, label, eventItem, customOptions): Forms.FieldModel {
                    var options = getCommonFieldOptions(eventItem, customOptions);

                    if (["TextPool", "Descriptor", "MultiDescriptor", "User", "Group"].indexOf(eventItem.Type) > -1) {
                        // default pool from mesap
                        options.pool = {
                            multi: eventItem.Type === "MultiDescriptor" || eventItem.TypeSpecification.MultiSelect,
                            restrict: true,
                            values: eventItem.TextPool,
                            valueField: "Id",
                            textField: eventItem.TypeSpecification.UseId ? "Id" : "Text"
                        };
                        if (!options.pool.multi) options.pool.values.unshift({ "Id": "", "Text": "" });

                        // merge with custom pool from config
                        if (customOptions && customOptions.pool) {
                            options.pool = AppKitchen.OptionsHelper.merge(customOptions.pool, options.pool);
                        }
                    }

                    return new Forms.FieldModel(key, Forms.FieldType.Text, label, options);
                }

                export function createEventDateField(key, label, eventItem, customOptions: FieldOptions): Forms.FieldModel {
                    if (!eventItem.TypeSpecification || !eventItem.TypeSpecification.DateFormat)
                        return createGenericField(key, label, FieldType.Date, eventItem, customOptions);

                    var options = getCommonFieldOptions(eventItem, customOptions);

                    switch (eventItem.TypeSpecification.DateFormat) {
                        case 1: // Date
                            return new Forms.FieldModel(key, Forms.FieldType.Date, label, options);
                        case 2: // Time
                            return new Forms.FieldModel(key, Forms.FieldType.Time, label, options);
                        case 3: // DateTime
                            return new Forms.FieldModel(key, Forms.FieldType.DateTime, label, options);
                        case 4: // UserDefined
                            options.format = eventItem.TypeSpecification.UserFormat;
                            return new Forms.FieldModel(key, Forms.FieldType.Date, label, options);
                        default:
                            throw "invalid date item";
                    }
                }

                export function createGenericField(key, label, type, eventItem, customOptions: FieldOptions): Forms.FieldModel {
                    return new Forms.FieldModel(key, type, label, getCommonFieldOptions(eventItem, customOptions));
                }

                export function getCommonFieldOptions(eventItem, customOptions: FieldOptions): Forms.FieldOptions {
                    if (eventItem) {
                        var options = AppKitchen.OptionsHelper.merge<FieldOptions>(customOptions, {
                            description: eventItem.Description,
                            default: eventItem.DefaultValue,
                            mandatory: eventItem.IsRequired
                        });
                        return options;
                    } else {
                        return customOptions || {};
                    }
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface EventFormOptions extends FormOptions {
                dataIndex?: number;
                observeLoader?: boolean;
                getFieldLabel?: (inventoryId: string, itemId: string, metadata?: string, eventItem?: any) => string;
            }

            export class EventFormModel extends FormModel {
                dataLoader: AppKitchen.Data.EventDataLoader;
                options: EventFormOptions;

                private subscriptions: {
                    data: Rx.IDisposable;
                    loading: Rx.IDisposable;
                }

                constructor(dataLoader: AppKitchen.Data.EventDataLoader, options?: EventFormOptions) {
                    options = AppKitchen.OptionsHelper.merge<EventFormOptions>(options, {
                        dataIndex: 0,
                        observeLoader: true,
                        getFieldLabel: EventFormHelper.getFieldLabel
                    });
                    var formfields = EventFormHelper.generateFormFields(dataLoader.getConfig(), dataLoader.getItemsDict(), options.getFieldLabel);

                    super(formfields, options);

                    this.dataLoader = dataLoader;

                    if (this.options.observeLoader) {
                        this.subscriptions = {
                            loading: this.dataLoader.onChangeLoading().subscribe(event => this.set({ loading: event.data })),
                            data: this.dataLoader.onChangeData().subscribe(event => {
                                    this.setFieldsData(event.data[this.options.dataIndex]);
                                    this.setBinaryUrls(event.data[this.options.dataIndex]);
                                    this.triggerDataLoaded(event.previous);
                                })
                        };
                        this.set({ loading: dataLoader.getLoading() });

                        const data = this.dataLoader.getData();
                        if (data && data.length > 0) {
                            this.setFieldsData(data[this.options.dataIndex]);
                            this.setBinaryUrls(data[this.options.dataIndex]);
                            this.triggerDataLoaded();
                        }
                    }
                }

                private setBinaryUrls(data: any) {
                    if (!data)
                        return;

                    this.get().fields.each(field => {
                        if (field.get("binary") && data.hasOwnProperty(field.get().key)) {
                            var inventoryId = field.get().key.substr(0, field.get().key.indexOf("_"));
                            var itemId = field.get().key.substr(field.get().key.indexOf("_") + 1);
                            var eventId = data[inventoryId + "_Id"];

                            if (inventoryId && itemId && eventId) {
                                field.set({ url: AppKitchen.Data.UrlHelper.getEventBinaryUrl(inventoryId, itemId, eventId) });
                            } else {
                                field.set({ url: undefined });
                            }
                        }
                    });
                }

                getEventWriteData(): AppKitchen.Api.Models.DataObject[] {
                    if (AppKitchen.GlobalSettings.devMode) {
                        AppKitchen.logWarning("EventForm.getEventWriteData() is deprecated! User EventDataLoader.getSaveRequestData() instead.");
                    }
                    return this.dataLoader.getSaveRequestData(this.getFieldsData()).EventValues;
                }

                submit(submitOptions: FormSubmitOptions) {
                    var fieldsData = this.getFieldsData();

                    if (submitOptions.parse) {
                        submitOptions.parse(fieldsData);
                    }

                    this.dataLoader.saveData([fieldsData],
                    {
                        api: submitOptions.api,
                        success: submitOptions.success,
                        error: (request) => submitOptions.error(request.statusText)
                    });
                }

                onDataLoaded(callback: (newData: any, oldData: any) => void) {
                    this.dataLoader.onChangeData().subscribe(event => {
                        callback(event.data ? event.data[this.options.dataIndex] : undefined,
                            event.previous ? event.previous[this.options.dataIndex] : undefined);

                    });

                    const data = this.dataLoader.getData();
                    if (data && data.length > 0) {
                        callback(data, data);
                    }
                }

                triggerDataLoaded(oldData?: any) {
                    this.trigger("dataLoaded", this.dataLoader, oldData || this.dataLoader.getData());
                }

                restoreData() {
                    this.dataLoader.setData(this.dataLoader.getData(), this);
                }

                dispose(): void {
                    if (!this.subscriptions) return;
                    for (let key in this.subscriptions) {
                        if (this.subscriptions.hasOwnProperty(key)) {
                            this.subscriptions[key].dispose();
                        }
                    }
                }
            }

        }
    }
}