namespace AppKitchen {
    
    export module ExportHelper {

        export interface ExportChartDataToExcelDefinition {
            timeseriesId: string;
            hypothesis: string;
            header: string;
            color: string;
        }

        export function exportChartDataToExcel(chartsData: AppKitchen.Api.Models.ChartData[],
            definitions: ExportChartDataToExcelDefinition[],
            sheetTitle: string,
            filename: string) {
            var exportChartsData = [];
            definitions.forEach(def => {
                var chartData = chartsData.AsLinq<AppKitchen.Api.Models.ChartData>()
                    .FirstOrDefault(cd => cd.timeseriesId === def.timeseriesId && cd.hypothesis === def.hypothesis);
                if (chartsData) {
                    exportChartsData.push({
                        data: chartData.data,
                        header: def.header,
                        color: def.color
                    });
                }
            });

            var excelData = [];
            var firstChartData = exportChartsData[0];
            for (let dataIndex = 0; dataIndex < firstChartData.data.length; dataIndex++) {
                var tsData = firstChartData.data[dataIndex];
                var rowData = {
                    timepoint: new Date(tsData[0]),
                    "#1": tsData[1]
                }

                if (exportChartsData.length > 1) {
                    for (let tsIndex = 1; tsIndex < exportChartsData.length; tsIndex++) {
                        rowData["#" + (tsIndex + 1)] = exportChartsData[tsIndex].data[dataIndex][1];
                    }
                }

                excelData.push(rowData);
            }

            var columnDefinitions: Data.Export.ExcelExportColumnDefinition[] = [
                {
                    field: "timepoint",
                    header: AppKitchen.Strings.ExcelExport_DateTimeColumnHeader,
                    format: "dd.mm.yyyy hh:mm",
                    width: 150,
                    styles: {
                        headerBorder: <any>{ color: Colors.LightGray, size: 3 },
                        headerBackground: "#FFFFFF",
                        headerForeground: Colors.Gray
                    }
                }
            ];

            for (let tsIndex = 0; tsIndex < exportChartsData.length; tsIndex++) {
                columnDefinitions.push({
                    header: exportChartsData[tsIndex].header.toUpperCase(),
                    field: "#" + (tsIndex + 1),
                    width: 150,
                    styles: {
                        headerBorder: <any>{ color: exportChartsData[tsIndex].color, size: 3 },
                        headerBackground: "#FFFFFF",
                        headerForeground: exportChartsData[tsIndex].color,
                        background: exportChartsData[tsIndex].color
                            ? UIHelper.Colors.lightenHex(exportChartsData[tsIndex].color, .9)
                            : undefined
                    }
                });
            }

            Data.Export.saveAsExcel(excelData, columnDefinitions, sheetTitle, filename);
        }

        // ReSharper disable once InconsistentNaming
        export interface ExportChartDataToCsvDefinition {
            timeseriesId: string;
            header: string;
        }

        export function exportChartDataToCsv(chartsData: AppKitchen.Api.Models.ChartData[],
            definitions: ExportChartDataToCsvDefinition[],
            filename: string,
            dateFormat?: string,
            options?: Data.Export.CsvExportOptions) {
            var exportChartsData = [];
            definitions.forEach(def => {
                var chartData = chartsData.AsLinq<AppKitchen.Api.Models.ChartData>()
                    .FirstOrDefault(cd => cd.timeseriesId === def.timeseriesId);
                if (chartsData) {
                    exportChartsData.push({
                        data: chartData.data,
                        header: def.header
                    });
                }
            });

            var csvData = [];
            var firstChartData = exportChartsData[0];
            for (let dataIndex = 0; dataIndex < firstChartData.data.length; dataIndex++) {
                var tsData = firstChartData.data[dataIndex];
                var rowData = {
                    timepoint: new Date(tsData[0]),
                    "#1": tsData[1]
                }

                if (exportChartsData.length > 1) {
                    for (let tsIndex = 1; tsIndex < exportChartsData.length; tsIndex++) {
                        rowData["#" + (tsIndex + 1)] = exportChartsData[tsIndex].data[dataIndex][1];
                    }
                }

                csvData.push(rowData);
            }

            var columnDefinitions: Data.Export.CsvExportColumnDefinition[] = [
                {
                    header: AppKitchen.Strings.ExcelExport_DateTimeColumnHeader,
                    field: "timepoint",
                    format: dateFormat || "g"
                }
            ];

            for (let tsIndex = 0; tsIndex < exportChartsData.length; tsIndex++) {
                columnDefinitions.push({
                    header: exportChartsData[tsIndex].header.toUpperCase(),
                    field: "#" + (tsIndex + 1)
                });
            }

            Data.Export.saveAsCsv(csvData, columnDefinitions, filename, options);
        }
    }
}