namespace AppKitchen {

    export module Controls {

        export module Menus {

            export interface IMenuItem {
                id: string;
                label: string;
                reference?: any;
                description?: string;
            }

            export class MenuItem implements IMenuItem {
                id: string;
                label: string;
                description?: string;
                reference?: any;

                constructor(public guid: string, menuItem: IMenuItem) {
                    this.id = menuItem.id;
                    this.label = menuItem.label;
                    this.description = menuItem.description;
                    this.reference = menuItem.reference;
                }
            }

            export interface VerticalMenuOptions extends AppKitchen.ViewBaseOptions {
                routingParameter?: string;
                routingHistory?: boolean;
                tooltip?: boolean | ((menuItem: IMenuItem) => string);
                documentTitleBase?: string;
                customScroller?: boolean;
                autoSelect?: boolean;
                noResultsMessage?: string;
                searchBoxEnabled?: boolean;
                searchBoxPlaceholder?: string;
                actionEnabled?: boolean;
                actionIcon?: string;
                actionClick?: (e) => void;
            }

            export class VerticalMenuEvents {
                selectedMenuItem: RxEx.Subject<MenuItem>;
                menuItems: RxEx.Subject<MenuItem[]>;
                visibleMenuItems: RxEx.Subject<MenuItem[]>;
            }

            export interface VerticalMenuModelOptions {
                sort: boolean;
                menuItems: IMenuItem[];
            }

            export enum FilterOperator {
                And,
                Or
            }

            export interface VerticalMenuFilterOptions {
                operator?: FilterOperator;
                caseSensitive?: boolean;
            }

            export class VerticalMenuModel extends AppKitchen.ModelBase<AppKitchen.ModelBaseAttributes> {

                private selectedMenuItem: MenuItem;
                private menuItems: MenuItem[];
                private visibleMenuItems: MenuItem[];

                private events: VerticalMenuEvents;

                private menuItemsDict: { [guid: string]: MenuItem };

                constructor(menuItems: IMenuItem[], sort?: boolean) {
                    super({});

                    this.events = {
                        selectedMenuItem: new RxEx.Subject<MenuItem>(),
                        menuItems: new RxEx.Subject<MenuItem[]>(),
                        visibleMenuItems: new RxEx.Subject<MenuItem[]>(),
                    }

                    this.menuItems = this.convertToMenuItemsInteral(menuItems, sort);
                    this.visibleMenuItems = this.menuItems;
                    this.updateMenuItemsDict();
                }

                private convertToMenuItemsInteral(menuItems: IMenuItem[], sort?: boolean): MenuItem[] {
                    if (sort) {
                        menuItems.sort((a, b) => a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1);
                    }
                    let menuItemsInteral: MenuItem[] = [];
                    for (let item of menuItems) {
                        let guid = AppKitchen.GuidHelper.newGuid();
                        let itemItern = new MenuItem(guid, item);
                        menuItemsInteral.push(itemItern);
                    }
                    return menuItemsInteral;
                }

                protected updateMenuItemsDict(): void {
                    this.menuItemsDict = {};
                    if (this.menuItems) {
                        this.menuItems.forEach(item => {
                            this.menuItemsDict[item.guid] = item;
                        });
                    }
                }

                getMenuItems(): MenuItem[] {
                    return this.menuItems;
                }

                setMenuItems(m: IMenuItem[], sort: boolean, sender: any): void {
                    let menuItems = this.convertToMenuItemsInteral(m, sort);
                    this.menuItems = menuItems;
                    this.visibleMenuItems = menuItems;
                    this.updateMenuItemsDict();
                    this.events.menuItems.onNext({ sender: sender, data: menuItems });
                }

                onMenuItemsChanged(): RxEx.Observable<MenuItem[]> {
                    return this.events.menuItems.asObservable();
                }

                onVisibleMenuItemsChanged(): RxEx.Observable<MenuItem[]> {
                    return this.events.visibleMenuItems.asObservable();
                }

                getVisibleMenuItems(): MenuItem[] {
                    return this.visibleMenuItems;
                }

                setVisibleMenuItems(visibleMenuItems: MenuItem[], sender: any) {
                    this.visibleMenuItems = visibleMenuItems;
                    this.events.visibleMenuItems.onNext({ sender: sender, data: visibleMenuItems });
                }

                onSelectedMenuItemChanged(): RxEx.Observable<MenuItem> {
                    return this.events.selectedMenuItem.asObservable();
                }

                getSelectedMenuItem(): MenuItem {
                    return this.selectedMenuItem;
                }

                setSelectedMenuItem(selectedMenuItem: MenuItem, sender: any) {
                    this.selectedMenuItem = selectedMenuItem;
                    this.events.selectedMenuItem.onNext({ sender: sender, data: selectedMenuItem });
                }

                tryToSelectMenuItemByGuid(guid: string, sender: any): boolean {
                    let selectedMenuItem = this.menuItemsDict[guid];
                    if (selectedMenuItem) {
                        this.setSelectedMenuItem(selectedMenuItem, sender);
                        return true;
                    }
                    return false;
                }

                tryToSelectMenuItemById(id: string, sender: any): boolean {
                    let selectedMenuItem = this.tryToFindMenuItemById(id);
                    if (selectedMenuItem) {
                        this.setSelectedMenuItem(selectedMenuItem, sender);
                        return true;
                    }
                    return false;
                }

                private tryToFindMenuItemById(id: string): MenuItem {
                    let menuItemsDict = this.menuItemsDict;
                    for (let key in menuItemsDict) {
                        if (menuItemsDict.hasOwnProperty(key)) {
                            let menuItem = this.menuItemsDict[key];
                            if (menuItem.id === id) {
                                return menuItem;
                            }
                        }
                    }
                    return null;
                }

                filter(query: string, options?: VerticalMenuFilterOptions): void {
                    var filterOptions = AppKitchen.OptionsHelper.merge<VerticalMenuFilterOptions>(options,
                        {
                            operator: FilterOperator.And,
                            caseSensitive: false
                        });

                    var queries = query.split(" ").AsLinq<string>()
                        .Where(q => !!q)
                        .Select(q => filterOptions.caseSensitive ? q : q.toUpperCase())
                        .ToArray();

                    let visibleMenuItems: MenuItem[] = [];
                    for (let menuItem of this.menuItems) {
                        let label = filterOptions.caseSensitive ? menuItem.label : menuItem.label.toUpperCase();
                        let check = filterOptions.operator === FilterOperator.And ? this.andQuery : this.orQuery;
                        if (check(queries, label)) {
                            visibleMenuItems.push(menuItem);
                        }
                    }
                    this.setVisibleMenuItems(visibleMenuItems, this);
                }

                private andQuery(queries: string[], value: string): boolean {
                    for (let query of queries) {
                        if (!VerticalMenuModel.isPartOfIf(value, query)) {
                            return false;
                        }
                    }
                    return true;
                }

                private orQuery(queries: string[], value: string): boolean {
                    let result = false;
                    for (let query of queries) {
                        if (!VerticalMenuModel.isPartOfIf(value, query)) {
                            result = true;
                        }
                    }
                    return result;
                }

                static isPartOfIf(value: string, part: string) {
                    return value.indexOf(part) !== -1;
                }
            }

            export class VerticalMenuView extends AppKitchen.ViewBase<VerticalMenuModel> {
                options: VerticalMenuOptions;
                private scroller: HTMLElement;

                private searchBoxModel: Controls.SearchBoxModel;

                private selectedMenuItemSubscription: Rx.IDisposable;
                private visibleMenuItemsSubscription: Rx.IDisposable;
                private menuItemsSubscription: Rx.IDisposable;

                private containers: {
                    searchBox: HTMLElement,
                    verticalMenuContent: HTMLElement,
                    verticalMenuList: HTMLElement,
                }

                private buttons: {
                    action: JQuery,
                }

                private elements: {
                    menuItems: JQuery,
                    noResultsMessage: JQuery,
                }

                constructor(model: VerticalMenuModel, target: HTMLElement, options?: VerticalMenuOptions) {
                    super(model, target, AppKitchen.OptionsHelper.merge<VerticalMenuOptions>(options, {
                        customScroller: true,
                        tooltip: null,
                        routingParameter: null,
                        routingHistory: options && !!options.routingParameter,
                        documentTitleBase: null,
                        autoSelect: true,
                        searchBoxEnabled: false,
                        searchBoxPlaceholder: Strings.SearchBox_Search,
                        actionEnabled: false,
                        actionIcon: null,
                        actionClick: null
                    }));

                    this.setTemplate(AppKitchen.Templates.VerticalMenu);
                    this.render();
                    this.menuItemsSubscription = this.model.onMenuItemsChanged().subscribe(r => this.render());
                }

                render() {

                    this.renderTemplate(
                        {
                            menuItems: this.model.getVisibleMenuItems(),
                            actionEnabled: this.options.actionEnabled,
                            actionIcon: this.options.actionIcon,
                            searchBoxEnable: this.options.searchBoxEnabled,
                            noResultsMessage: this.options.noResultsMessage
                        });
                    this.setContainers();
                    this.renderSearchBox();
                    this.updateScroller();
                    this.updateTooltips();
                    this.internalBind();
                    this.initSelectedMenuItem();
                    this.addEvents();

                    return this;
                }

                private setContainers() {
                    this.containers = {
                        searchBox: this.$el.find(".a-vertical-menu-searchbox-container")[0],
                        verticalMenuContent: this.$el.find(".a-vertical-menu-content")[0],
                        verticalMenuList: this.$el.find(".a-vertical-menu-list")[0],
                    }
                    this.buttons = {
                        action: this.$el.find(".action-btn")
                    }
                    this.elements = {
                        noResultsMessage: this.$el.find(".a-vertical-menu-noresults-message"),
                        menuItems: this.$el.find(".a-vertical-menu-entry:not(.group-item)")
                    }
                }

                private renderSearchBox() {
                    this.searchBoxModel = new Controls.SearchBoxModel({ query: "" });
                    // ReSharper disable once WrongExpressionStatement
                    new Controls.SearchBoxView(this.searchBoxModel,
                        this.containers.searchBox,
                        {
                            placeholder: this.options.searchBoxPlaceholder
                        });
                }

                private addEvents(): void {
                    this.$el.find("li[data-guid]").click((e) => this.onClick(e));

                    this.searchBoxModel.onChange(query => this.model.filter(query));

                    if (this.options.actionEnabled) {
                        this.buttons.action.click((e) => this.options.actionClick(e));
                    }
                    $(this.containers.searchBox).keydown((e) => this.onKeyDown(e));

                }

                public onKeyDown(e: JQuery.TriggeredEvent<HTMLElement, null>): void {
                    if (this.isKey(e, "Enter")) {
                        let visibleMenuItems = this.model.getVisibleMenuItems();
                        if (visibleMenuItems.length === 1) {
                            this.model.setSelectedMenuItem(visibleMenuItems[0], this);
                        }
                    }
                }

                private isKey(e: JQuery.TriggeredEvent<HTMLElement, null>, key: string): boolean {
                    return (<any>e.originalEvent).key.toUpperCase() === key.toUpperCase();
                }

                private initSelectedMenuItem() {
                    if (this.options.autoSelect) {
                        if (!this.trySetSelectedItemFromUrlQuery()) {
                            var firstItem = this.$el.find("li[data-guid]").first();
                            if (firstItem && firstItem.length !== 0) {
                                let dataId = firstItem.attr("data-guid");
                                if (dataId) {
                                    let guid = dataId.toString();
                                    this.model.tryToSelectMenuItemByGuid(guid, this);
                                }
                            }
                        }
                    }
                }

                private internalBind() {
                    if (!this.selectedMenuItemSubscription) {
                        this.selectedMenuItemSubscription = this.model.onSelectedMenuItemChanged().subscribe(e => {
                            //if (e.sender === this) return;
                            this.selectedItemChanged(e.data);
                        });
                    }
                    if (!this.visibleMenuItemsSubscription) {
                        this.visibleMenuItemsSubscription = this.model.onVisibleMenuItemsChanged().subscribe(e => {
                            //if (e.sender === this) return;
                            this.visibleMenuItemsChanged(e.data);
                        });
                    }
                }

                private internalUnbind() {
                    if (!this.selectedMenuItemSubscription) {
                        this.selectedMenuItemSubscription.dispose();
                        this.selectedMenuItemSubscription = null;
                    }
                    if (!this.visibleMenuItemsSubscription) {
                        this.visibleMenuItemsSubscription.dispose();
                        this.visibleMenuItemsSubscription = null;
                    }
                    if (!this.menuItemsSubscription) {
                        this.menuItemsSubscription.dispose();
                        this.menuItemsSubscription = null;
                    }
                }

                dispose() {
                    this.internalUnbind();
                }

                private selectedItemChanged(selectedMenuItem: MenuItem): void {
                    this.unhighlightSelected();
                    if (!selectedMenuItem) {
                        this.clearRoute();
                    } else {
                        this.updateRoute(selectedMenuItem.id, true);
                        this.highlightSelected(selectedMenuItem.guid);
                    }
                }

                private updateScroller(): void {
                    if (this.options.customScroller) {
                        this.scroller = AppKitchen.UIHelper.Scrolling.customScroller(this.containers.verticalMenuContent)[0];
                    } else {
                        this.scroller = this.containers.verticalMenuContent;
                    }
                }

                private updateTooltips(): void {
                    if (this.options.tooltip) {
                        let menuItems = this.model.getMenuItems();
                        this.$el.find("li").each((i, e) => {
                            if (typeof this.options.tooltip === "function") {
                                var tooltipContent = (<(menuItem: IMenuItem) => string>this.options.tooltip)(menuItems[i]);
                                if (tooltipContent) {
                                    $(e).kendoTooltip({
                                        position: "right",
                                        content: tooltipContent,
                                        show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                                    });
                                }
                            } else if (this.options.tooltip === true && menuItems[i].description) {
                                $(e).kendoTooltip({
                                    position: "right",
                                    content: menuItems[i].description,
                                    show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                                });
                            }
                        });
                    }
                }

                private updateHighlightSelected(): void {
                    let selectedItem = this.model.getSelectedMenuItem();
                    this.unhighlightSelected();
                    if (selectedItem) {
                        this.highlightSelected(selectedItem.guid);
                    }
                }

                private unhighlightSelected(): void {
                    this.$el.find("li").removeClass("a-selected");
                }

                private highlightSelected(guid: string): void {
                    if (guid) {
                        var el = this.$el.find('li[data-guid="' + guid + '"]');
                        if (el.length === 1) {
                            el.addClass("a-selected");
                            setTimeout(() => AppKitchen.UIHelper.Scrolling.scrollIntoViewport(el[0], this.scroller), 100);
                        }
                    }
                }

                private trySetSelectedItemFromUrlQuery(): boolean {
                    var id = AppKitchen.BrowserHelper.UrlQuery.getParameter(this.options.routingParameter);
                    if (id) {
                        return this.model.tryToSelectMenuItemById(id, this);
                    }
                    return false;
                }

                private clearRoute(options?: AppKitchen.BrowserHelper.RedirectOptions): void {
                    if (this.options.routingParameter) {
                        AppKitchen.BrowserHelper.UrlQuery.removeParameter(this.options.routingParameter,
                            AppKitchen.OptionsHelper.merge(options,
                                {
                                    push: false,
                                    refresh: false
                                }));
                    }
                }

                private updateRoute(selectedId: string, history: boolean): void {
                    if (this.options.routingParameter &&
                        AppKitchen.BrowserHelper.UrlQuery.getParameters()[this.options.routingParameter] !==
                        selectedId) {
                        AppKitchen.BrowserHelper.UrlQuery.setParameter(this.options.routingParameter,
                            selectedId,
                            { push: history && this.options.routingHistory });
                    }
                    if (this.options.documentTitleBase) {
                        var selectedLabel = this.$el.find("li.a-selected").text();
                        document.title = selectedLabel + " - " + this.options.documentTitleBase;
                    }
                }

                private onClick(e: any): void {
                    let menuItemElement = $(e.target);
                    if (menuItemElement && menuItemElement.length !== 0) {
                        let dataId = menuItemElement.attr("data-guid");
                        if (dataId) {
                            var guid = dataId.toString();
                            this.model.tryToSelectMenuItemByGuid(guid, this);
                        }
                    }
                }

                private visibleMenuItemsChanged(visibleMenuItems: MenuItem[]): void {
                    var menuItemsElements = this.elements.menuItems;

                    let lookup = this.createMenuItemsLookup(visibleMenuItems);

                    if (!visibleMenuItems || visibleMenuItems.length === 0) {
                        menuItemsElements.addClass("a-hidden");
                        if (this.options.noResultsMessage) {
                            this.elements.noResultsMessage.show();
                        }
                        $(this.containers.verticalMenuList).hide();
                    } else {
                        menuItemsElements.each((i, e) => {
                            let menuItemElement = $(e);
                            if (menuItemElement && menuItemElement.length !== 0) {
                                let dataId = menuItemElement.attr("data-guid");
                                if (dataId) {
                                    var guid = dataId.toString();
                                    if (this.isMenuItemVisibile(lookup, guid)) {
                                        menuItemElement.removeClass("a-hidden");
                                    } else {
                                        menuItemElement.addClass("a-hidden");
                                    }
                                }
                            }
                        });
                        this.elements.noResultsMessage.hide();
                        $(this.containers.verticalMenuList).show();
                    }
                    this.updateScroller();
                    this.updateHighlightSelected();
                }

                private createMenuItemsLookup(visibleMenuItems: MenuItem[]): { [key: string]: MenuItem } {
                    let lookup: { [key: string]: MenuItem } = {};
                    for (var visibleMenuItem of visibleMenuItems) {
                        lookup[visibleMenuItem.guid] = visibleMenuItem;
                    }
                    return lookup;
                }

                private isMenuItemVisibile(lookup: { [key: string]: MenuItem }, guid: string): boolean {
                    if (lookup[guid]) {
                        return true;
                    }
                    return false;
                }
            }
        }
    }
}