namespace AppKitchen {

    export module Controls {

        // ReSharper disable once InconsistentNaming
        export interface FileDisplayAttributes extends AppKitchen.ModelBaseAttributes {
            fileUrl?: string;
            mimeType?: string;
            minWidth?: number;
            minHeight?: number;
        }

        export class FileDisplayModel extends AppKitchen.ModelBase<FileDisplayAttributes> {
            constructor(fileUrl: string, mimeType: string, minWidth?: number, minHeight?: number) {
                super({
                    fileUrl: fileUrl,
                    mimeType: mimeType,
                    minWidth: minWidth,
                    minHeight: minHeight
                });
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface FileDisplayOptions extends AppKitchen.ViewBaseOptions {
            title?: string
        }

        export class FileDisplay extends AppKitchen.ViewBase<FileDisplayModel> {
            imgWidth: number;
            imgHeight: number;
            options: FileDisplayOptions;

            constructor(fileDisplayModel: FileDisplayModel,
                targetContainer: HTMLElement,
                options?: FileDisplayOptions) {
                super(fileDisplayModel,
                    targetContainer,
                    AppKitchen.OptionsHelper.merge(options,
                    {
                        title: ""
                    }));

                this.render();
            }

            render() {
                AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.FileDisplay_Frame, { title: this.options.title });
                var me = this;
                var mainType = this.model.get().mimeType.split("/")[0];
                switch (mainType) {
                    case "image":
                    {
                        var img = new Image();
                        img.onload = function (this: HTMLImageElement) {
                            me.imgWidth = this.width;
                            me.imgHeight = this.height;
                            me.$el.find(".a-interactive-image-box").append(img);
                            me.updateSize();
                        }
                        img.src = this.model.get().fileUrl;
                        break;
                    }
                    case "audio":
                    {
                        var audio = document.createElement("audio");
                        audio.setAttribute("autoplay", "autoplay");
                        //audio.setAttribute("loop", "loop");
                        audio.setAttribute("controls", "controls");
                        audio.src = this.model.get().fileUrl;
                        $(this.$el.find(".a-interactive-image-box")[0]).addClass("file-container");
                        this.$el.find(".a-interactive-image-box").append(audio);
                        break;
                    }
                    case "video":
                    {
                        var video = document.createElement("video");
                        //video.setAttribute("autoplay", "autoplay");
                        //video.setAttribute("loop", "loop");
                        video.setAttribute("controls", "controls");
                        video.src = this.model.get().fileUrl;
                        $(this.$el.find(".a-interactive-image-box")[0]).addClass("file-container");
                        this.$el.find(".a-interactive-image-box").append(video);
                        break;
                    }
                    //case "application":
                    //case "text":
                    default:
                    {
                        var obj = document.createElement("embed");
                        obj.src = this.model.get().fileUrl;
                        $(this.$el.find(".a-interactive-image-box")[0]).addClass("file-container");
                        this.$el.find(".a-interactive-image-box").append(obj);
                        
                        break;
                    }
                }

                AppKitchen.BrowserHelper.WindowResize.on(() => this.updateSize(), this.el);

                return this;
            }

            updateSize() {
                AppKitchen.UIHelper.updateFullHeightGrids(this.el);

                var imageWidth = this.imgWidth;
                var imageHeight = this.imgHeight;
                var maxWidth = this.$el.find(".a-interactive-image-box-wrapper").width();
                var maxHeight = this.$el.find(".a-interactive-image-box-wrapper").height();

                if (this.model.get().minWidth && this.model.get().minWidth > maxWidth) {
                    maxWidth = this.model.get().minWidth;
                }

                if (this.model.get().minHeight && this.model.get().minHeight > maxHeight) {
                    maxHeight = this.model.get().minHeight;
                }

                var widthFac = maxWidth / imageWidth;
                var heightFac = maxHeight / imageHeight;

                var fac = heightFac;
                if (widthFac < heightFac) {
                    fac = widthFac;
                }

                this.$el.find(".a-interactive-image-box").width(imageWidth * fac);
                this.$el.find(".a-interactive-image-box").height(imageHeight * fac);
                this.$el.find(".a-interactive-image-box").css("font-size", imageHeight * fac + "px");
                this.$el.find(".a-interactive-image-point-wrapper").each((i, e) => {
                    $(e).width($(e).height());
                });
            }

        }
    }
}