namespace AppKitchen {

    export module Controls.Grids {
        export class EventGridView extends GridView {
            model: EventGridModel;

            protected setGridData(data: any[]) {
                if (this.grid) {
                    const gridConfig = this.model.dataLoader.getConfig();
                    for (let column of this.grid.columns) {
                        const columnConfig = gridConfig.EventItemSpecs.AsLinq<AppKitchen.Data.ColumnConfig>()
                            .FirstOrDefault(g => `${g.InventoryId}_${g.ItemId}` === column.field);
                        if (columnConfig && columnConfig.RestrictFilterSelection) {
                            let dataSource = this.model.getTextPool(column.field, data);
                            (<any>column.filterable).dataSource = dataSource;
                            (<any>column.filterable).itemTemplate = () => {
                                return `<li class="k-item"><label class="k-label"><input type="checkbox" class="" value="#= data.value || data.all#">#= data.text || data.all#</label></li>`;
                            }
                        }
                    }
                }
                super.setGridData(data);
            }
        }
    }
}