namespace AppKitchen {
    
    export function handleError(xhr: JQueryXHR, statusText: string, errorText: string, hideErrorPopup?: boolean) {
        if (xhr.statusText === "APPKITCHEN_ERROR") {
            logError("AppKitchen Error: " + xhr.responseText);
        } else {
            logError("Error (" + status + ") occured:\n" + errorText + "\n\nDetails:\n" + xhr.responseText);
            if (!hideErrorPopup) {
                UIHelper.DialogBoxes.alert(Strings.Messages_ErrorOccured);
            }
        }
    }

    export function log(message: string) {
        if (console) {
            console.log(message);
        }
    }

    export function logError(message: string) {
        if (console) {
            console.error(message);
        }
    }

    export function logInfo(message: string) {
        if (console) {
            console.info(message);
        }
    }

    export function logWarning(message: string) {
        if (console) {
            console.warn(message);
        }
    }
}