/// <reference path="ProviderMapCachedBase.ts" />

namespace AppKitchen {

    export module Data.Provider {

        export class EventItemsProvider extends ProviderMapCachedBase {

            public load(request: AppKitchen.Api.Models.EventDataRequest, reloadRequest?: boolean):
                JQueryPromise<AppKitchen.Api.Models.EventItemInfo[]> {
                return this.do("EventItems", request, reloadRequest);
            }
        }
    }
}