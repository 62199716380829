/// <reference path="../../Data/TimeseriesDataLoader.ts"/>

namespace AppKitchen {

    export module Controls.TimeseriesDataViews {

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesDataViewStyles {
            label?: string;
            color?: string;
            columnWidth?: number;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesDataViewData extends TimeseriesDataViewStyles {
            timeseriesId: string;
            hypothesis: string;
            unit: string;
            data: number[][];
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesDataViewModelAttributes extends AppKitchen.ModelBaseAttributes {
            timeseries?: TimeseriesDataViewData[];
            startDate?: Date;
            endDate?: Date;
        }

        export class TimeseriesDataViewModel extends AppKitchen.ModelBase<TimeseriesDataViewModelAttributes> {
            loader: AppKitchen.Data.TimeseriesDataLoaderBase;

            constructor(loader?: AppKitchen.Data.TimeseriesDataLoaderBase,
                styles?: { [tsId: string]: TimeseriesDataViewStyles }) {
                super({
                    timeseries: [],
                    startDate: null,
                    endDate: null
                });

                if (loader) {
                    this.bindToLoader(loader, styles);
                    this.loader = loader;
                }
            }

            protected bindToLoader(loader: AppKitchen.Data.TimeseriesDataLoaderBase,
                styles?: { [tsId: string]: TimeseriesDataViewStyles }) {

                this.set({
                    timeseries: this.convertChartData(loader.get().data, styles),
                    startDate: loader.get().startDate,
                    endDate: loader.get().endDate
                });

                loader.on("change:startDate change:endDate",
                    () => this.set({
                        startDate: loader.get().startDate,
                        endDate: loader.get().endDate
                    }));

                loader.on("change:loading",
                    () => this.set({
                        loading: loader.get().loading
                    }));

                loader.on("change:data",
                    () => this.set({
                        timeseries: this.convertChartData(loader.get().data, styles),
                        startDate: loader.get().startDate,
                        endDate: loader.get().endDate
                    }));
            }

            protected convertChartData(chartData: Api.Models.ChartData[],
                styles?: { [tsId: string]: TimeseriesDataViewStyles }): TimeseriesDataViewData[] {
                if (!chartData) {
                    return [];
                }
                return chartData.AsLinq<Api.Models.ChartData>().Select(cd => {

                    var chartData = <TimeseriesDataViewData>{
                        timeseriesId: cd.timeseriesId,
                        label: (styles && styles[cd.timeseriesId]) ? styles[cd.timeseriesId].label : cd.timeseriesName,
                        color: (styles && styles[cd.timeseriesId]) ? styles[cd.timeseriesId].color : undefined,
                        hypothesis: cd.hypothesis,
                        unit: cd.unit,
                        data: cd.data
                    }

                    if (styles && styles[cd.timeseriesId]) {
                        $.extend(true, chartData, styles[cd.timeseriesId]);
                    }
                    return chartData;
                }).ToArray();
            }

            getDataSaveEntities(): AppKitchen.Api.Models.TimeseriesDataSaveEntity[] {
                return this.get().timeseries.AsLinq<TimeseriesDataViewData>().Select(ts => {
                    return <AppKitchen.Api.Models.TimeseriesDataSaveEntity>{
                        TimeseriesId: ts.timeseriesId,
                        Hypothesis: ts.hypothesis,
                        Data: ts.data
                    };
                }).ToArray();
            }
        }
    }
}