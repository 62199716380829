namespace AppKitchen {
    export module Data {
        var documentMap: { [documentId: string]: any } = [];

        // ReSharper disable once InconsistentNaming
        export interface LoadOptionsBase {
            api?: string;
            silent?: boolean;
            success?: Function;
            error?: Function;
        }

        var apiUrl: string;
        var appKitchenApiUrl: string;

        // ReSharper disable once InconsistentNaming
        export function setAPIs(url: string, appKitchenUrl: string) {
            apiUrl = url;
            appKitchenApiUrl = appKitchenUrl;
            Data.init();
        }

        //For the strict, full datetime, including milliseconds, per the W3C's take on the spec.:
        // and additional variations allowed by the actual ISO 8601:2004(E) doc
        // https://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
        var reDate = /^((\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d))$/;

        function convertJsonDates(value, index, parent) {
            if (value === null) {
                return null;
            }
            if (typeof value === "string" && reDate.test(value)) {
                if (parent)
                    parent[index] = parseDateString(value);
                else
                    return parseDateString(value);
            } else if (value instanceof Array) {
                for (var i = 0; i < value.length; i++)
                    convertJsonDates(value[i], i, value);
            } else if (value instanceof Object) {
                for (var p in value)
                    if (value.hasOwnProperty(p))
                        convertJsonDates(value[p], p, value);
            }
            return parent || value;
        }

        function parseDateString(dateString: string): Date {
            const parsedWithMoment = moment(dateString);
            return parsedWithMoment.toDate();
        }

        export function init() {
            $.ajaxSetup({
                converters: {
                    "text json"(json) {
                        return convertJsonDates($.parseJSON(json), null, null);
                    }
                }
            });
        }

        export function setClaim(token: string, username: string, success: (result: any) => void, error: (err: any) => void): void {
            
            $.ajax({
                type: "POST",
                url: appKitchenApiUrl + "/claim",
                data: {
                    Token: token,
                    Username: username
                },
                success: success,
                error: (xhr: JQueryXHR) => error(xhr.responseText),
                cache: false
            });
        }

        export function hasSessionToken(): boolean {
            return Cookies.get(AppKitchen.GlobalSettings.currentUserCookieName) != null;
        }

        export function ping(timeout: number, success: (result: boolean) => void, error: (err: any) => void): void {
            //AppKitchen.Data.init();
            $.ajax({
                type: "GET",
                url: appKitchenApiUrl + "/ping",
                success: success,
                error: (e) => {
                    if (e.status !== 200) {
                        AppKitchen.logInfo("SessionToken is invalid");
                        error(e);
                    }
                },
                cache: false,
                timeout: timeout
            });
        }

        export function getDataInternal(method: string, apiNameWithUrl: string, requestData: any, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            return $.ajax({
                dataType: "json",
                type: method,
                url: apiNameWithUrl,
                data: requestData,
                success: callback,
                error: (xhr: JQueryXHR, textStatus: string, errorThrown: string) => {
                    if (textStatus === "abort")
                        return; // do not callback on abort
                    AppKitchen.handleError(xhr, textStatus, textStatus, errorCallback != null);
                    if (errorCallback) {
                        errorCallback(requestData, xhr, textStatus, errorThrown);
                    }
                },
                cache: false
            });
        }

        export function getDataApiUrl(apiName: string) {
            return appKitchenApiUrl + "/" + apiName;
        }

        export function getDataApi(apiName: string, requestData: any, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            return getDataInternal("POST",appKitchenApiUrl + "/" + apiName, requestData, callback, errorCallback);
        }

        export function getDataByGetRequestApi(apiName: string, requestData: any, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            return getDataInternal("GET", appKitchenApiUrl + "/" + apiName, requestData, callback, errorCallback);
        }

        export function getData(apiName: string, requestData: any, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            return getDataInternal("POST", apiUrl + "/" + apiName, requestData, callback, errorCallback);
        }

        export function getDataByGetRequest(apiName: string, requestData: any, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            return getDataInternal("GET", apiUrl + "/" + apiName, requestData, callback, errorCallback);
        }

        export function getDocument(documentName: string, documentType: string, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            return getDataInternal("GET", apiUrl + "/Document", { filename: documentName, type: documentType }, callback, errorCallback);
        }

        export function getDocumentCached(documentName: string, documentType: string, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            var documentId = documentType + "__" + documentName;

            if (documentMap[documentId]) {
                window.setTimeout(() => callback(documentMap[documentId]), 1);
            } else {
                return this.getDataInternal("GET",apiUrl + "/Document",{ filename: documentName, type: documentType },documentData => {
                        documentMap[documentId] = documentData;
                        callback(documentData);
                    },
                    errorCallback);
            }
        }

        export function getFile(fileName: string, rootFolder: string, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            return getDataByGetRequest("File", { fileName: fileName, rootFolder: rootFolder }, callback, errorCallback);
        }

        export function getFileCached(fileName: string, rootFolder: string, callback: any, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
            var documentId = rootFolder + "__" + fileName;

            if (documentMap[documentId]) {
                window.setTimeout(() => callback(documentMap[documentId]), 1);
            } else {
                return getDataByGetRequest("File", { fileName: fileName, rootFolder: rootFolder }, callback, errorCallback);
            }
        }

        export module UrlHelper {
            export function getEventBinaryUrl(inventoryId: string, itemId: string, eventId: string, databaseId?: string) {
                var inventoryPart = "inventoryId=" + encodeURIComponent(inventoryId);
                var fieldIdPart = "itemId=" + encodeURIComponent(itemId);
                var idPart = "eventId=" + encodeURIComponent(eventId);
                var databaseIdPart = "";
                if (databaseId) {
                    databaseIdPart = "&databaseId=" + encodeURIComponent(databaseId);
                }
                return appKitchenApiUrl + "/EventBinary?" + inventoryPart + "&" + fieldIdPart + "&" + idPart + databaseIdPart;
            }

            export function getIconUrl(iconId: string, databaseId?: string) {
                return appKitchenApiUrl + "/IconFile?" + "id=" + encodeURIComponent(iconId) + (databaseId ? "&databaseId=" + encodeURIComponent(databaseId) : "");
            }
        }

        export module Api {
            import EventDataRequest = AppKitchen.Api.Models.EventDataRequest;
            import EventDataSaveRequest = AppKitchen.Api.Models.EventDataSaveRequest;
            import EventItemInfo = AppKitchen.Api.Models.EventItemInfo
            import EventDeleteRequest = AppKitchen.Api.Models.EventDeleteRequest;
            import UserInfo = AppKitchen.Api.Models.UserInfo;
            import PasswordChangeRequest = AppKitchen.Api.Models.PasswordChangeRequest;
            import PasswordChangeResult = AppKitchen.Api.Models.PasswordChangeResult;
            import JobLogRequest = AppKitchen.Api.Models.JobLogRequest;
            import TaskLogRequest = AppKitchen.Api.Models.TaskLogRequest;
            import JobLogFilterRequest = AppKitchen.Api.Models.JobLogFilterRequest;
            import JobRequest = AppKitchen.Api.Models.JobRequest;
            import Job = AppKitchen.Api.Models.Job;
            import HypothesisRequest = AppKitchen.Api.Models.HypothesisRequest;
            import Hypothesis = AppKitchen.Api.Models.Hypothesis;
            import AboutInfo = AppKitchen.Api.Models.AboutInfo;
            import JobStartRequest = AppKitchen.Api.Models.JobStartRequest;
            import DescriptorRequest = AppKitchen.Api.Models.DescriptorRequest;
            import DimensionRequest = AppKitchen.Api.Models.DimensionRequest;
            import Descriptor = AppKitchen.Api.Models.Descriptor;
            import Dimension = AppKitchen.Api.Models.Dimension;
            import EventInventoryRequest = AppKitchen.Api.Models.EventInventoryRequest;
            import EventInventory = AppKitchen.Api.Models.EventInventory;
            import GlobalSettings = AppKitchen.Api.Models.GlobalSettingsResponse;
            import JobLogFilter = AppKitchen.Api.Models.JobLogFilter;
            import JobLog = AppKitchen.Api.Models.JobLog;
            import TaskLog = AppKitchen.Api.Models.TaskLog;
            import EventDataMultiSaveRequest = AppKitchen.Api.Models.EventDataMultiSaveRequest;
            import TimeseriesRequest = AppKitchen.Api.Models.TimeseriesRequest;
            import Timeseries = AppKitchen.Api.Models.Timeseries;
            import TimeseriesInfoRequest = AppKitchen.Api.Models.TimeseriesInfoRequest;
            import TimeseriesDataSimpleRequest = AppKitchen.Api.Models.TimeseriesSimpleDataRequest;
            import TimeseriesDataSaveRequest = AppKitchen.Api.Models.TimeseriesDataSaveRequest;
         
            import TimeseriesInfo = AppKitchen.Api.Models.TimeseriesInfo;
            import ChartData = AppKitchen.Api.Models.ChartData;
            import TextPoolItemRequest = AppKitchen.Api.Models.TextPoolItemRequest;
            import TextPoolItem = AppKitchen.Api.Models.TextPoolItem;
            import TreeRequest = AppKitchen.Api.Models.TreeRequest;
            import Tree = AppKitchen.Api.Models.Tree;
            import UserInfoUpdateRequest = AppKitchen.Api.Models.UserInfoUpdateRequest;
            import UserInfoUpdateResult = AppKitchen.Api.Models.UserInfoUpdateResult;
            import PasswordChangeInfo = AppKitchen.Api.Models.PasswordChangeInfo;

            export function getTextPoolItems(request: TextPoolItemRequest, callback: (result: TextPoolItem[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("TextPoolItems", request, callback, errorCallback);
            }

            export function getGlobalSettings(callback: (result: GlobalSettings) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataByGetRequest("globalsettings", null, callback, errorCallback);
            }

            export function getEventInventories(request: EventInventoryRequest, callback: (result: EventInventory[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("EventInventories", request, callback, errorCallback);
            }

            export function getEventItemInfos(request: EventDataRequest, callback: (result: EventItemInfo[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("EventItems", request, callback, errorCallback);
            }

            export function getEventData(request: EventDataRequest, callback: (result: { [key: string]: any }[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("EventData", request, callback, errorCallback);
            }

            export function getTimeseries(request: TimeseriesRequest, callback: (result: Timeseries[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("Timeseries", request, callback, errorCallback);
            }

            export function getTimeseriesInfo(request: TimeseriesInfoRequest, callback: (result: TimeseriesInfo[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("TimeseriesInfo", request, callback, errorCallback);
            }

            export function getTimeseriesData(request: TimeseriesDataSimpleRequest, callback: (result: ChartData[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("TimeseriesDataSimple", request, callback, errorCallback);
            }

            export function updateTimeseriesData(request: TimeseriesDataSaveRequest, callback: (result: boolean) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("TimeseriesDataSave", request, callback, errorCallback);
            }

            export function getJobLog(request: JobLogRequest, callback: (result: JobLog[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("JobLog", request, callback, errorCallback);
            }

            export function startJob(request: JobStartRequest, callback: (result: boolean) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("JobStart", request, callback, errorCallback);
            }

            export function getTaskLog(request: TaskLogRequest, callback: (result: TaskLog[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("TaskLog", request, callback, errorCallback);
            }

            export function getJobLogFilters(request: JobLogFilterRequest, callback: (result: JobLogFilter[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("JobLogFilter", request, callback, errorCallback);
            }

            export function getJobs(request: JobRequest, callback: (result: Job[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("Job", request, callback, errorCallback);
            }

            export function getHypotheses(request: HypothesisRequest, callback: (result: Hypothesis[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("Hypotheses", request, callback, errorCallback);
            }

            export function updateEventData(request: EventDataSaveRequest, callback: (result?: string) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("EventDataSave", request, callback, errorCallback);
            }

            export function updateEventMultiData(request: { EventDataSaveRequests: EventDataSaveRequest[]}, callback: (result?: string) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("EventDataMultiSave", request, callback, errorCallback);
            }

            export function deleteEventData(request: EventDeleteRequest, callback: (result: boolean) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getData("EventDelete", request, callback, errorCallback);
            }

            export function getDimensions(request: DimensionRequest, callback: (result: Dimension[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("Dimensions", request, callback, errorCallback);
            }

            export function getDescriptors(request: DescriptorRequest, callback: (result: Descriptor[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("Descriptors", request, callback, errorCallback);
            }

            export function getTrees(request: TreeRequest, callback: (result: Tree[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("Trees", request, callback, errorCallback);
            }

            export function getPasswordChangeInformation(callback: (result: PasswordChangeInfo) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("PasswordChangeInfo", {}, (result: PasswordChangeInfo) => callback(result), errorCallback);
            }

            export function getUserInfo(callback: (result: UserInfo) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("UserInfo", {}, (result: UserInfo) => {
                    result.MailAddress = DOMPurify.sanitize(result.MailAddress);
                    result.Description = DOMPurify.sanitize(result.Description);
                    result.Division = DOMPurify.sanitize(result.Division);
                    result.Name = DOMPurify.sanitize(result.Name);
                    result.Phone = DOMPurify.sanitize(result.Phone);
                    callback(result);
                }, errorCallback);
            }

            export function updateUserInfo(request: UserInfoUpdateRequest, callback: (result: UserInfoUpdateResult) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("UserInfoUpdate", request, callback, errorCallback);
            }

            export function changePassword(passwordChangeRequest: PasswordChangeRequest, callback: (result: PasswordChangeResult) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("PasswordChange", passwordChangeRequest, callback, errorCallback);
            }

            export function getAboutInfo(callback: (result: AboutInfo) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataByGetRequestApi("About", {}, callback, errorCallback);
            }

            export function getContextSensitiveHelp(ctx: string, callback: (result: any) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataByGetRequestApi("ContextSensitiveHelp", { context: ctx }, callback, errorCallback);
            }

            export function getUserRoles(callback: (result: string[]) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataApi("UserRole", {}, callback, errorCallback);
            }

            export function getIconBase64(iconId: string, callback: (iconBase64: string) => void, errorCallback?: (requestData?: any, xhr?: JQueryXHR, statusText?: string, errorText?: string) => void): JQueryXHR {
                return Data.getDataByGetRequestApi("IconBase64", { id: iconId }, callback, errorCallback);
            }

        }

    }
}
