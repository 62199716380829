namespace AppKitchen {

    export module Controls {

        export module Popups {

            export enum NotificationIcon {
                Loading,
                Ok,
                Error,
                Info
            }

            export type NotificationType = "info" | "warning" | "success" | "loading";

            // ReSharper disable once InconsistentNaming
            export interface NotificationOptions {
                title?: string;
                type?: NotificationType;
                icon?: NotificationIcon;
                closable?: boolean;
                hideable?: boolean;
                keepOnNavigate?: boolean;
                stack?: HTMLElement;
                autoClose?: boolean;
                closeAfter?: number;
            }

            export class Notification {
                $el: JQuery;
                el: HTMLElement;
                options: NotificationOptions;
                private implicitStack: boolean;

                constructor(content: string, options?: NotificationOptions) {

                    // compatibility with old notification
                    if (options && options.icon >= 0 && !options.type) {
                        switch (options.icon) {
                            case NotificationIcon.Loading:
                                options.type = "loading";
                                break;
                            case NotificationIcon.Ok:
                                options.type = "success";
                                break;
                            case NotificationIcon.Error:
                                options.type = "warning";
                                break;
                            case NotificationIcon.Info:
                                options.type = "info";
                                break;
                        }
                    }

                    this.options = AppKitchen.OptionsHelper.merge<NotificationOptions>(options, {
                        type: "info",
                        closable: options? !options.hideable : true,
                        hideable: false,
                        keepOnNavigate: false,
                        stack: undefined,
                        autoClose: options? !!options.closeAfter : false,
                        closeAfter: 5000
                    });

                    this.implicitStack = !this.options.stack;
                    if (this.implicitStack) {
                        this.options.stack = $(AppKitchen.Templates.DefaultNotificationStack).appendTo("body")[0];
                    }

                    this.$el = $(AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.NotificationBox, {
                        type: this.options.type,
                        title: this.options.title,
                        content: content,
                        closable: this.options.closable,
                        hideable: this.options.hideable
                    })).prependTo(this.options.stack);

                    AppKitchen.UIHelper.Scrolling.customScroller(this.$el.find(".a-notification-content")[0]);


                    this.el = this.$el[0];
                    this.$el.css("z-index", AppKitchen.UIHelper.getHighestZIndex() + 1);

                   
                    this.$el.find(".a-notification-close").click(() => {
                        this.close();
                    });

                    this.$el.find(".a-notification-hide").click(() => {
                        this.hide();
                    });

                    this.$el.find(".a-notification-show").click(() => {
                        this.show();
                    });
                    
                    if (!this.options.keepOnNavigate) {
                        $("body").on("navigate", () => this.close());
                    }

                    if (this.options.autoClose) {
                        this.closeAfterTimeout(this.options.closeAfter);
                    }
                }

                close() {
                    // set automatic height to current computed height in order to activate CSS transition animation
                    this.$el.height(this.$el.height());

                    this.$el.addClass("a-notification-closed");
                    this.$el.height(0);

                    setTimeout(() => {
                        if (this.implicitStack && $(this.options.stack).children().length === 1) {
                            $(this.options.stack).remove();
                        } else {
                            this.$el.remove();
                        }
                    }, 500);
                }

                hide() {
                    this.$el.addClass("a-notification-hidden");
                }
                
                show() {
                    this.$el.removeClass("a-notification-hidden");
                }

                setClosable(closeable: boolean) {
                    this.$el.toggleClass("a-notification-closable", closeable);
                }

                setHideable(hideable: boolean) {
                    this.$el.toggleClass("a-notification-hideable", hideable);
                }

                setContent(content: string, title?: string) {
                    if (title != null) {
                        this.$el.find(".a-notification-title").html(title);
                    }
                    this.$el.find(".a-notification-text").html(content);

                    this.show();
                }

                setType(type: NotificationType) {
                    this.$el.attr("data-notification-type", type);
                    this.show();
                }

                /**
                 * Sets icon to 'error', shows the manual closing button and displays the given content
                 * @param content the content to display
                 */
                setError(content: string, title?: string) {
                    this.setType("warning");
                    this.setContent(content, title);
                    this.setClosable(true);
                }

                 /**
                 * Sets icon to 'ok', displays the given content and sets the closing interval to 5s -> The
                 * notification will disapear in 5s
                 * @param content the content to display
                 */
                setOk(content: string, title?: string) {
                    this.setType("success");
                    this.setContent(content, title);
                    this.closeAfterTimeout(5000);
                }

                setIcon(icon: NotificationIcon) {
                    // compatibility with old notification
                    switch (icon) {
                        case NotificationIcon.Loading:
                            this.setType("loading");
                            break;
                        case NotificationIcon.Ok:
                            this.setType("success");
                            break;
                        case NotificationIcon.Error:
                            this.setType("warning");
                            break;
                        case NotificationIcon.Info:
                            this.setType("info");
                            break;
                    }
                }

                closeAfterTimeout(timeout?: number) {
                    window.setTimeout(() => {
                        this.close();
                    }, timeout || this.options.closeAfter);
                }
            }

            export class LoadingNotification extends Notification {
                errorMsg: string;
                successMsg: string;

                constructor(loadingMsg: string, successMsg: string,  errorMsg: string) {
                    super(loadingMsg, { closable: false, icon: NotificationIcon.Loading });
                    this.errorMsg = errorMsg;
                    this.successMsg = successMsg;
                }

                ok() {
                    this.setOk(this.successMsg);
                }

                error() {
                    this.setError(this.errorMsg);
                }
            }

        }
    }
}