namespace AppKitchen {
    export module Data {

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesLoadOptions extends LoadOptionsBase {
            success?: (timeseries: AppKitchen.Api.Models.Timeseries[]) => void;
            error?: (request: JQueryXHR) => void;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesInfoLoadOptions extends LoadOptionsBase {
            success?: (timeseriesInfo: AppKitchen.Api.Models.TimeseriesInfo[]) => void;
            error?: (request: JQueryXHR) => void;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesDataLoaderOptions {
            includeKeys?: boolean;
            timeseriesApi?: string;
            timeseriesInfoApi?: string;
        }

        // ReSharper disable once InconsistentNaming
        export interface TimeseriesLoaderAttributes {
            timeseries?: AppKitchen.Api.Models.Timeseries[];
            dimensions?: AppKitchen.Api.Models.Dimension[];
            loading?: boolean;
        }

        export class TimeseriesLoader extends AppKitchen.ModelBase<TimeseriesLoaderAttributes> {
            timeseriesRequest: JQueryXHR;
            timeseriesInfoRequest: JQueryXHR;
            options: TimeseriesDataLoaderOptions;

            protected timeseriesInfoCache: { [tsIds: string]: AppKitchen.Api.Models.TimeseriesInfo };

            constructor(ready: (loader: TimeseriesLoader) => void, options?: TimeseriesDataLoaderOptions) {
                super({
                    timeseries: [],
                    dimensions: [],
                    loading: false
                });

                this.options = AppKitchen.OptionsHelper.merge(options, {
                    includeKeys: false,
                    timeseriesApi: "Timeseries",
                    timeseriesInfoApi: "TimeseriesInfo"
                });

                if (this.options.includeKeys) {
                    this.loadDimensions(() => ready(this));
                } else {
                    ready(this);
                }
            }

            private loadDimensions(ready: () => void) {
                Data.Api.getDimensions({}, dimensions => {
                    this.set({ dimensions: dimensions });
                    ready();
                });
            }

            loadAll(options?: TimeseriesLoadOptions) {
                this.load({
                    IncludeKeys: this.options.includeKeys
                }, options);
            }

            loadByDescriptors(descriptors: AppKitchen.Api.Models.DescriptorKey[], exactMatch?: boolean, options?: TimeseriesLoadOptions) {
                this.load({
                    IncludeKeys: this.options.includeKeys,
                    Keys: descriptors,
                    ExactMatch: exactMatch
                }, options);
            }

            load(requestData: AppKitchen.Api.Models.TimeseriesRequest, options?: TimeseriesLoadOptions) {
                options = AppKitchen.OptionsHelper.merge(options, {
                    silent: false,
                    success: () => {},
                    error: () => {}
                });

                // abort previous request
                this.abortTimeseriesRequest();

                if (!options.silent) {
                    this.set({ loading: true });
                }

                // start new request
                var api = options.api || this.options.timeseriesApi;
                if (api === "Timeseries") {
                    this.timeseriesRequest = Data.Api.getTimeseries(requestData, timeseries => {
                        this.set({ timeseries: timeseries });
                        this.set({ loading: false });
                        options.success(timeseries);
                    }, (request) => {
                        this.set({ loading: false });
                        options.error(request);
                    });
                } else {
                    this.timeseriesRequest = Data.getData(api, requestData, timeseries => {
                        this.set({ timeseries: timeseries });
                        this.set({ loading: false });
                        options.success(timeseries);
                    }, (request) => {
                        this.set({ loading: false });
                        options.error(request);
                    });
                }
            }

            loadInfo(requestData: AppKitchen.Api.Models.TimeseriesInfoRequest, options?: TimeseriesInfoLoadOptions) {
                options = AppKitchen.OptionsHelper.merge(options, {
                    success: () => { },
                    error: () => { }
                });

                // abort previous request
                this.abortTimeseriesInfoRequest();


                // start new request
                var api = options.api || this.options.timeseriesInfoApi;
                if (api === "TimeseriesInfo") {
                    this.timeseriesRequest = Data.Api.getTimeseriesInfo(requestData, timeseriesInfo => {
                        this.set({ timeseries: timeseriesInfo });
                        options.success(timeseriesInfo);
                    }, (request) => {
                        options.error(request);
                    });
                } else {
                    this.timeseriesRequest = Data.getData(api, requestData, timeseries => {
                        this.set({ timeseries: timeseries });
                        options.success(timeseries);
                    }, (request) => {
                        options.error(request);
                    });
                }
            }

            getAdditionalInfo(tsIds: string[], loaded: (timeseriesInfo: { [tsIds: string]: AppKitchen.Api.Models.TimeseriesInfo }) => void, error?: (message: string) => void) {
                var anyMissing = this.timeseriesInfoCache == null;

                this.timeseriesInfoCache = this.timeseriesInfoCache || {};

                for (let i = 0; i < tsIds.length; i++) {
                    if (!this.timeseriesInfoCache[tsIds[i]]) {
                        anyMissing = true;
                        break;
                    }
                }

                if (!anyMissing) {
                    loaded(this.timeseriesInfoCache);
                } else {
                    this.loadInfo({
                        Ids: tsIds
                    },
                    {
                        success: timeseriesInfo =>  {
                            timeseriesInfo.forEach(info => {
                                this.timeseriesInfoCache[info.Id] = info;
                            });
                            loaded(this.timeseriesInfoCache);
                        },
                        error: request => {
                            if (error) {
                                error(request.responseText);
                            }
                        }
                    });
                }
            }

            abortRequests() {
                this.abortTimeseriesRequest();
                this.abortTimeseriesInfoRequest();
            }

            protected abortTimeseriesRequest() {
                if (this.timeseriesRequest) {
                    this.timeseriesRequest.abort();
                    this.set({ loading: false });
                }
            }

            protected abortTimeseriesInfoRequest() {
                if (this.timeseriesInfoRequest) {
                    this.timeseriesInfoRequest.abort();
                }
            }

        }

    }
}
