namespace AppKitchen {

    export module Apps {

        export class CreditsApp extends AppKitchen.AppBase<AppKitchen.AppBaseOptions> {
            start() {
                AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.AboutWindow,
                {
                    applicationName: AppKitchen.GlobalSettings.applicationName,
                    applicationVersion: AppKitchen.GlobalSettings.applicationVersion,
                    copyrightInfo: AppKitchen.GlobalSettings.applicationCopyright
                });

                AppKitchen.Data.Api.getAboutInfo(aboutInfo => {
                    AppKitchen.UIHelper.renderTemplateTo(this.$el.find(".a-about-window-infos")[0], AppKitchen.Templates.AboutWindow_Info, aboutInfo);
                    AppKitchen.UIHelper.Scrolling.customScroller(this.$el.find(".a-about-window-infos > .content-scroll")[0]);
                    this.$el.find("a[data-license]").each((i, el) => {
                        var licenseFile = "Licenses/" + $(el).attr("data-license") + ".html";
                        $.ajax({
                            url: licenseFile,
                            success: licence => {
                                $(el).kendoTooltip({
                                    position: "left",
                                    content: `<div class="a-about-license">${licence}</div>`,
                                    show: evt => AppKitchen.UIHelper.hideTooltips(evt.sender)
                                });
                            }
                        });
                    });
                });
            }
        }
    }
}