/// <reference path="../Grids.ts"/>
namespace AppKitchen {
    export module Controls {

        export module Grids {

            module JobLogGridHelper {
                
                export function getColumnsConfig(gridOptions: JobLogGridOptions, runningPseudoState: boolean) {
                    var schema: kendo.data.DataSourceSchemaModelField[] = [
                        {
                            field: "PlannedSchedule",
                            type: "date"
                        },
                        {
                            field: "ResultLevel",
                            type: "string"
                        },
                        {
                            field: "RunBegin",
                            type: "date"
                        },
                        {
                            field: "RunEnd",
                            type: "date"
                        },
                        {
                            field: "JobId",
                            type: "string"
                        },
                        {
                            field: "RunUserId",
                            type: "string"
                        },
                        {
                            field: "RunComputer",
                            type: "string"
                        },
                        {
                            field: "TechnicalError",
                            type: "string"
                        }
                    ];

                    var columns: kendo.ui.GridColumn[] = [
                        {
                            field: "PrimaryKey",
                            hidden: true
                        },
                        {
                            field: "PlannedSchedule",
                            title: AppKitchen.Strings.JobLogGrid_PlannedSchedule,
                            width: gridOptions.columnWidthes.plannedSchedule,
                            filterable: { multi: false },
                            format: "{0:" + kendo.culture().calendar.patterns.g + "}"
                        },
                        {
                            field: "ResultLevel",
                            title: AppKitchen.Strings.JobLogGrid_ResultLevel,
                            width: gridOptions.columnWidthes.resultLevel,
                            filterable: { multi: true },
                            template: resultLevelTemplate,
                            values: $.merge(
                                runningPseudoState ? [{ value: "Running", text: AppKitchen.Strings.JobLogGrid_ResultLevel_Running }] : [],
                                [
                                    { value: "Unknown", text: AppKitchen.Strings.JobLogGrid_ResultLevel_Unknown},
                                    { value: "OK", text: AppKitchen.Strings.JobLogGrid_ResultLevel_Ok},
                                    { value: "Warning", text: AppKitchen.Strings.JobLogGrid_ResultLevel_Warning},
                                    { value: "Error", text: AppKitchen.Strings.JobLogGrid_ResultLevel_Error}
                                ]
                            )
                        },
                        {
                            field: "RunBegin",
                            title: AppKitchen.Strings.JobLogGrid_RunBegin,
                            width: gridOptions.columnWidthes.runBegin,
                            filterable: { multi: false },
                            format: "{0:" + kendo.culture().calendar.patterns.g + "}"
                        },
                        {
                            field: "RunEnd",
                            title: AppKitchen.Strings.JobLogGrid_RunEnd,
                            width: gridOptions.columnWidthes.runEnd,
                            filterable: { multi: false },
                            format: "{0:" + kendo.culture().calendar.patterns.g + "}"
                        },
                        {
                            field: "JobId",
                            title: AppKitchen.Strings.JobLogGrid_Job,
                            width: gridOptions.columnWidthes.job,
                            filterable: <any>{
                                multi: true,
                                search: true
                            }
                        },
                        {
                            field: "RunUserId",
                            title: AppKitchen.Strings.JobLogGrid_RunUser,
                            width: gridOptions.columnWidthes.runUser,
                            filterable: <any>{
                                multi: true,
                                search: true
                            }
                        },
                        {
                            field: "RunComputer",
                            title: AppKitchen.Strings.JobLogGrid_RunComputer,
                            width: gridOptions.columnWidthes.runComputer,
                            filterable: <any>{
                                multi: true,
                                search: true
                            }
                        },
                        {
                            field: "TechnicalError",
                            title: AppKitchen.Strings.JobLogGrid_TechnicalError,
                            width: gridOptions.columnWidthes.technicalError,
                            filterable: { multi: false }
                        }
                    ];
                    
                    return {
                        columns: columns,
                        schema: schema
                    };
                }

                function resultLevelTemplate(data): string {
                    return AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.JobLogGrid_ResultLevelCell,
                    {
                        resultLevel: data["ResultLevel"],
                        resultLevelLabel: AppKitchen.Strings["JobLogGrid_ResultLevel_" + data["ResultLevel"]]
                    });
                }
            }

            module TaskLogGridHelper {

                export function getColumnsConfig(gridOptions: TaskLogGridOptions) {
                    var schema: kendo.data.DataSourceSchemaModelField[] = [
                        {
                            field: "RunBegin",
                            type: "date"
                        },
                        {
                            field: "RunEnd",
                            type: "date"
                        },
                        {
                            field: "TaskId",
                            type: "string"
                        },
                        {
                            field: "Module",
                            type: "string"
                        },
                        {
                            field: "ResponsibleId",
                            type: "string"
                        },
                        {
                            field: "TaskState",
                            type: "string"
                        },
                        {
                            field: "RunAgent",
                            type: "string"
                        },
                        {
                            field: "RunUserId",
                            type: "string"
                        },
                        {
                            field: "ExecState",
                            type: "string"
                        }
                    ];

                    var columns: kendo.ui.GridColumn[] = [
                        {
                            field: "RunBegin",
                            title: AppKitchen.Strings.TaskLogGrid_RunBegin,
                            width: gridOptions.columnWidthes.runBegin,
                            filterable: { multi: false },
                            format: "{0:" + kendo.culture().calendar.patterns.g + "}"
                        },
                        {
                            field: "RunEnd",
                            title: AppKitchen.Strings.TaskLogGrid_RunEnd,
                            width: gridOptions.columnWidthes.runEnd,
                            filterable: { multi: false },
                            format: "{0:" + kendo.culture().calendar.patterns.g + "}"
                        },
                        {
                            field: "TaskId",
                            title: AppKitchen.Strings.TaskLogGrid_Task,
                            width: gridOptions.columnWidthes.task,
                            filterable: { multi: true }
                        },
                        {
                            field: "Module",
                            title: AppKitchen.Strings.TaskLogGrid_Module,
                            width: gridOptions.columnWidthes.module,
                            filterable: { multi: true }
                        },
                        {
                            field: "ResponsibleId",
                            title: AppKitchen.Strings.TaskLogGrid_Responsible,
                            width: gridOptions.columnWidthes.responsible,
                            filterable: { multi: true }
                        },
                        {
                            field: "TaskState",
                            title: AppKitchen.Strings.TaskLogGrid_TaskState,
                            width: gridOptions.columnWidthes.taskState,
                            filterable: { multi: true },
                            values: [
                                { value: "Unknown", text: AppKitchen.Strings.TaskLogGrid_TaskState_Unknown },
                                { value: "Due", text: AppKitchen.Strings.TaskLogGrid_TaskState_Due },
                                { value: "Started", text: AppKitchen.Strings.TaskLogGrid_TaskState_Started },
                                { value: "Completed", text: AppKitchen.Strings.TaskLogGrid_TaskState_Completed }
                            ]
                        },
                        {
                            field: "RunAgent",
                            title: AppKitchen.Strings.TaskLogGrid_RunAgent,
                            width: gridOptions.columnWidthes.runAgent,
                            filterable: <any>{ multi: true, search: true }
                        },
                        {
                            field: "RunUserId",
                            title: AppKitchen.Strings.TaskLogGrid_RunUser,
                            width: gridOptions.columnWidthes.runUser,
                            filterable: <any>{ multi: true, search: true }
                        },
                        {
                            field: "ExecState",
                            title: AppKitchen.Strings.TaskLogGrid_ExecState,
                            width: gridOptions.columnWidthes.execState,
                            filterable: { multi: true },
                            template: excecStateTemplate,
                            values: [
                                { value: "Aborted", text: AppKitchen.Strings.TaskLogGrid_ExecState_Aborted },
                                { value: "ErrAddinInit", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrAddinInit },
                                { value: "ErrAppRestart", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrAppRestart },
                                { value: "ErrCodeExecError", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrCodeExecError },
                                { value: "ErrHolidayRead", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrHolidayRead },
                                { value: "ErrLogin", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrLogin },
                                { value: "ErrNoExecuteRight", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrNoExecuteRight },
                                { value: "ErrNoLicence", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrNoLicence },
                                { value: "ErrNoReadRight", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrNoReadRight },
                                { value: "ErrOpenDb", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrOpenDb },
                                { value: "ErrTaskLogWrite", text: AppKitchen.Strings.TaskLogGrid_ExecState_ErrTaskLogWrite },
                                { value: "ModuleResultDelayedReSchedule", text: AppKitchen.Strings.TaskLogGrid_ExecState_ModuleResultDelayedReSchedule },
                                { value: "ModuleResultErr", text: AppKitchen.Strings.TaskLogGrid_ExecState_ModuleResultErr },
                                { value: "ModuleResultOk", text: AppKitchen.Strings.TaskLogGrid_ExecState_ModuleResultOk },
                                { value: "ModuleResultUnspecified", text: AppKitchen.Strings.TaskLogGrid_ExecState_ModuleResultUnspecified },
                                { value: "ModuleResultWarning", text: AppKitchen.Strings.TaskLogGrid_ExecState_ModuleResultWarning },
                                { value: "NotExecutedOnHoliday", text: AppKitchen.Strings.TaskLogGrid_ExecState_NotExecutedOnHoliday },
                                { value: "NotExecutedOnNoHoliday", text: AppKitchen.Strings.TaskLogGrid_ExecState_NotExecutedOnNoHoliday },
                                { value: "Provided", text: AppKitchen.Strings.TaskLogGrid_ExecState_Provided },
                                { value: "Unknown", text: AppKitchen.Strings.TaskLogGrid_ExecState_Unknown }
                            ]
                        }
                    ];

                    return {
                        columns: columns,
                        schema: schema
                    };
                }

                function excecStateTemplate(data): string {
                    return AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.TaskLogGrid_ExcecStateCell,
                        {
                            execState: data["ExecState"],
                            execStateLabel: AppKitchen.Strings["TaskLogGrid_ExecState_" + data["ExecState"]]
                        });
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface JobLogGridOptions {
                runningTimeout?: number;
                columnWidthes?: {
                    plannedSchedule?: string;
                    resultLevel?: string;
                    runBegin?: string;
                    runEnd?: string;
                    job?: string;
                    runUser?: string;
                    runComputer?: string;
                    technicalError?: string;
                }
            }

            export class JobLogGridModel extends GridModel {
                options: JobLogGridOptions;
                dataLoader: AppKitchen.Data.JobLogLoader;

                constructor(dataLoader: AppKitchen.Data.JobLogLoader, options?: JobLogGridOptions) {

                    options = AppKitchen.OptionsHelper.merge<JobLogGridOptions>(options, {
                        runningTimeout: 24 * 60, // 1 Day
                        columnWidthes: {
                            plannedSchedule: "120px",
                            runBegin: "120px",
                            runEnd: "120px",
                            resultLevel: "150px"
                        }
                    }, true);

                    var columnsConfig = JobLogGridHelper.getColumnsConfig(options, options.runningTimeout > 0);

                    super(columnsConfig.columns, columnsConfig.schema, options);

                    this.dataLoader = dataLoader;

                    if (this.dataLoader.get().data) {
                        this.setGridData(this.dataLoader.get().data);
                    }
                    this.dataLoader.bind("change:data", () => this.setGridData(this.dataLoader.get().data));

                    this.set({ loading: this.dataLoader.get().loading });
                    this.dataLoader.bind("change:loading", () => this.set({ loading: this.dataLoader.get().loading }));
                }

                setGridData(data: any[]) {
                    if (this.options.runningTimeout > 0) {
                        super.setGridData(data, dataItem => {
                            if (!dataItem["RunEnd"] &&
                                dataItem["RunBegin"] &&
                                dataItem["ResultLevel"] === "Unknown" &&
                                new Date().valueOf() - dataItem["RunBegin"].valueOf() < this.options.runningTimeout * 60000) {
                                dataItem["ResultLevel"] = "Running";
                            }
                            return dataItem;
                        });
                    } else {
                        super.setGridData(data);
                    }
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface TaskLogGridOptions {
                columnWidthes?: {
                    runBegin?: string;
                    runEnd?: string;
                    task?: string;
                    module?: string;
                    responsible?: string;
                    taskState?: string;
                    runAgent?: string;
                    runUser?: string;
                    execState?: string;
                }
            }

            export class TaskLogGridModel extends GridModel {
                options: TaskLogGridOptions;
                dataLoader: AppKitchen.Data.TaskLogLoader;

                constructor(dataLoader: AppKitchen.Data.TaskLogLoader, options?: TaskLogGridOptions) {

                    options = AppKitchen.OptionsHelper.merge<TaskLogGridOptions>(options,
                    {
                        columnWidthes: {}
                    });

                    var columnsConfig = TaskLogGridHelper.getColumnsConfig(options);

                    super(columnsConfig.columns, columnsConfig.schema, options);

                    this.dataLoader = dataLoader;

                    if (this.dataLoader.get().data) {
                        this.setGridData(this.dataLoader.get().data);
                    }
                    this.dataLoader.bind("change:data", () => this.setGridData(this.dataLoader.get().data));

                    this.set({ loading: this.dataLoader.get().loading });
                    this.dataLoader.bind("change:loading", () => this.set({ loading: this.dataLoader.get().loading }));
                }
            }
        }

        // ReSharper disable once InconsistentNaming
        export interface JobLogFilterPickerAttributes extends AppKitchen.ModelBaseAttributes {
            filter?: string;
            filterSettings?: AppKitchen.Api.Models.JobLogFilterSettings;
        }

        export class JobLogFilterPicker extends AppKitchen.ModelBase<JobLogFilterPickerAttributes> {
            el: HTMLElement;
            $el: JQuery;
            filterSettingsDict: { [filterId: string]: AppKitchen.Api.Models.JobLogFilterSettings };

            constructor(targetContainer: HTMLElement, initial?: string) {
                super({
                    filter: initial,
                    filterSettings: {}
                });

                this.el = targetContainer;
                this.$el = $(targetContainer);

                var fieldModel = new Forms.FieldModel("JobLogFilter", Forms.FieldType.Text, "", {
                    pool: {
                        values: [],
                        multi: false,
                        restrict: true,
                        search: false,
                        textField: "Name",
                        valueField: "Id"
                    }
                });

                fieldModel.set({ value: initial });
                fieldModel.on("change:value", () => {
                    this.set({
                        filter: fieldModel.get().value,
                        filterSettings: this.filterSettingsDict[fieldModel.get().value]
                    });
                });

                AppKitchen.UIHelper.renderTemplateTo(this.el, '<div class="a-form a-joblog-filter-picker"><table><tr><td class="a-picker-container"></td></tr></div>');
                var fieldView = new Forms.TextFieldView(fieldModel, { editable: true });
                this.$el.find(".a-picker-container").append(fieldView.render().el);

                AppKitchen.Data.Api.getJobLogFilters({}, filters => {
                    fieldModel.get().pool.values = filters;
                    this.filterSettingsDict = {};
                    filters.forEach(filter => this.filterSettingsDict[filter["Id"]] = filter["Settings"]);
                    if (filters.length > 0) {
                        if (fieldModel.get().value) {
                            this.set({ filterSettings: this.filterSettingsDict[fieldModel.get().value] });
                        } else {
                            fieldModel.set({ value: filters[0]["Id"] });
                        }
                        fieldView.render();
                    }
                });
            }
        }
    }
}