namespace AppKitchen {
    export module Controls {

        export module Grids {

            module TimeseriesGridHelper {
                
                export function getColumnsConfig(gridOptions: TimeseriesGridOptions, dimensions: AppKitchen.Api.Models.Dimension[]) {

                    var schema: kendo.data.DataSourceSchemaModelField[] = [
                        {
                            field: "Id",
                            type: "string"
                        },
                        {
                            field: "Name",
                            type: "string"
                        },
                        {
                            field: "TimeAxisType",
                            type: "string"
                        }
                    ];

                    if (dimensions) {
                        dimensions.forEach(dim => schema.push({
                            field: dim.Id,
                            type: "string"
                        }));
                    }
                    
                    var columns: kendo.ui.GridColumn[] = [
                        {
                            field: "Id",
                            title: AppKitchen.Strings.TimeseriesGrid_Id,
                            width: gridOptions.columnWidthes.id,
                            filterable: { multi: true, search: true }
                        },
                        {
                            field: "Name",
                            title: AppKitchen.Strings.TimeseriesGrid_Name,
                            width: gridOptions.columnWidthes.name,
                            filterable: { multi: true, search: true }
                        },
                        {
                            field: "TimeAxisType",
                            title: AppKitchen.Strings.TimeseriesGrid_AxisType,
                            width: gridOptions.columnWidthes.axisType,
                            filterable: { multi: true },
                            values: [
                                { value: "Vector", text: AppKitchen.Strings.TimeseriesGrid_TimeAxisType_Vector },
                                { value: "MatrixTimeTime", text: AppKitchen.Strings.TimeseriesGrid_TimeAxisType_MatrixTimeTime },
                                { value: "MatrixTimeTimeNumber", text: AppKitchen.Strings.TimeseriesGrid_TimeAxisType_MatrixTimeTimeNumber },
                                { value: "MatrixTimeTimeTime", text: AppKitchen.Strings.TimeseriesGrid_TimeAxisType_MatrixTimeTimeTime }
                            ]
                        }
                    ];

                    if (dimensions) {
                        dimensions.forEach(dim => columns.push({
                            field: dim.Id,
                            title: dim.Name,
                            filterable: { multi: true, search: true },
                            template: (ts: any) => {
                                if (ts.Keys) {
                                    var keys: AppKitchen.Api.Models.DescriptorKey[] = ts.Keys.toJSON();
                                    var key = keys.AsLinq<AppKitchen.Api.Models.DescriptorKey>()
                                                  .FirstOrDefault(k => k.DimensionId === dim.Id);
                                    if (key) {
                                        return key.DescriptorId;
                                    }
                                }
                                return "";
                            }
                        }));
                    }

                    return {
                        columns: columns,
                        schema: schema
                    };
                }

            }

            // ReSharper disable once InconsistentNaming
            export interface TimeseriesGridOptions {
                columnWidthes?: {
                    id?: string;
                    name?: string;
                    axisType?: string;
                }
            }

            export class TimeseriesGridModel extends GridModel {
                options: TimeseriesGridOptions;
                timeseriesLoader: AppKitchen.Data.TimeseriesLoader;

                constructor(timeseriesLoader: AppKitchen.Data.TimeseriesLoader, options?: TimeseriesGridOptions) {

                    options = AppKitchen.OptionsHelper.merge<TimeseriesGridOptions>(options, {
                        columnWidthes: {
                            id: "200px",
                            name: "200px",
                            axisType: "120px"
                        }
                    }, true);

                    var columnsConfig = TimeseriesGridHelper.getColumnsConfig(options, timeseriesLoader.get().dimensions);

                    super(columnsConfig.columns, columnsConfig.schema, options);

                    this.timeseriesLoader = timeseriesLoader;

                    this.setGridData(this.timeseriesLoader.get().timeseries || []);
                    this.timeseriesLoader.bind("change:timeseries", () => this.setGridData(this.timeseriesLoader.get().timeseries));

                    this.set({ loading: this.timeseriesLoader.get().loading });
                    this.timeseriesLoader.bind("change:loading", () => this.set({ loading: this.timeseriesLoader.get().loading }));
                }
            }
            
        }
    }
}