namespace AppKitchen {

    export module Controls.Grids {

        export type GridDefiniton = {
            columns: kendo.ui.GridColumn[],
            schema: kendo.data.DataSourceSchemaModelField[],
        };

        export type GetColumnHeader =(inventoryId: string, itemId: string, eventItem: Api.Models.EventItemInfo) => string;

        export class EventGridHelper {
            // ReSharper disable once InconsistentNaming
            private static EventItemTypeConverter = {
                String: ColumnType.Text,
                Memo: ColumnType.Text,
                TextPool: ColumnType.Text,
                Date: ColumnType.Date,
                DateTimeOffset: ColumnType.Date,
                Boolean: ColumnType.Boolean,
                Descriptor: ColumnType.Text,
                Binary: ColumnType.Text,
                Number: ColumnType.Number,
                Int32: ColumnType.Number,
                Int64: ColumnType.Number,
                Decimal: ColumnType.Number,
                Calculated: ColumnType.Number,
                MasterDataCopy: ColumnType.Text,
                User: ColumnType.Text,
                Group: ColumnType.Text,
                MultiDescriptor: ColumnType.Text,
                EventKeyField: ColumnType.Text,
                PeriodNr: ColumnType.DateTime,

                // additional types for virtual fields
                DateTime: ColumnType.DateTime,
                Time: ColumnType.Time
            }

            static getColumnHeader(inventoryId: string, itemId: string, eventItem: Api.Models.EventItemInfo): string {
                if (eventItem) {
                    return eventItem.Name;
                }
                return itemId;
            }

            static isColumnEditable(inventoryId: string, itemId: string) {
                return true;
            }

            private static validateKey(key: string): string {
                if (!StringHelper.isValidIdentifier(key)) {
                    const message =
                        `'${key}' is an invalid string for grid column keys. Only valid JavaScript identifiers allowed.`;
                    AppKitchen.logError(message);
                    throw message;
                }
                return key;
            }

            private static getDefaultFieldOptions(type: ColumnType, customOptions: Forms.FieldOptions):
                Forms.FieldOptions {
                var fieldType: Forms.FieldType;
                switch (type) {
                    case ColumnType.Date:
                        fieldType = Forms.FieldType.Date;
                        break;
                    case ColumnType.Boolean:
                        fieldType = Forms.FieldType.Checkbox;
                        break;
                    case ColumnType.DateTime:
                        fieldType = Forms.FieldType.DateTime;
                        break;
                    case ColumnType.Text:
                        fieldType = Forms.FieldType.Text;
                        break;
                    case ColumnType.Number:
                        fieldType = Forms.FieldType.Number;
                        break;
                    case ColumnType.Time:
                        fieldType = Forms.FieldType.Time;
                        break;

                    default:
                        fieldType = null;
                        AppKitchen.logError(`Unable to map column type: '${type}' to field type.`);
                }

                return Forms.FieldModel.getDefaultOptions(fieldType, customOptions);
            }

            static getColumnsConfig(gridConfig: AppKitchen.Data.GridConfig,
                eventItems: AppKitchen.Data.FieldToEventItemInfoDictionary,
                getColumnHeader: GetColumnHeader): GridDefiniton {
                const gridDefinition: GridDefiniton = {
                    columns: [],
                    schema: [],
                }

                var columnConfigs = this.getColumnConfigs(gridConfig);

                for (let columnConfig of columnConfigs) {
                    const columnKey = this.getColumnKey(columnConfig);
                    const eventItemInfo = this.tryGetEventItemInfo(columnKey, eventItems);
                    const columnType = this.tryGetColumnType(columnConfig, eventItemInfo);
                    const columnOptions = this.getColumnOptions(columnConfig, columnType);
                    const fieldOptions = this.getFieldOptions(columnConfig, columnType);

                    if (!fieldOptions.hidden) {
                        gridDefinition.columns.push(this.createColumnDefinition(columnKey,
                            columnConfig,
                            columnOptions,
                            columnType,
                            eventItemInfo,
                            fieldOptions,
                            getColumnHeader));
                        gridDefinition.schema.push(this.createSchemaDefinition(columnKey, columnType, fieldOptions));
                    }
                }

                return gridDefinition;
            }

            private static getColumnConfigs(gridConfig: AppKitchen.Data.GridConfig): AppKitchen.Data.ColumnConfig[] {
                return gridConfig.EventItemSpecs.sort((col1, col2) => compareSortNr(col1.SortNr, col2.SortNr));
            }

            private static getColumnKey(columnConfig: AppKitchen.Data.ColumnConfig) {
                let columnKey = columnConfig.InventoryId
                    ? (this.validateKey(columnConfig.InventoryId) + "_" + this.validateKey(columnConfig.ItemId))
                    : this.validateKey(columnConfig.ItemId);
                if (columnConfig.Metadata) {
                    columnKey += "_" + this.validateKey(columnConfig.Metadata);
                }
                return columnKey;
            }

            private static tryGetEventItemInfo(columnKey: string,
                eventItems: AppKitchen.Data.FieldToEventItemInfoDictionary): Api.Models.EventItemInfo {
                const eventItemInfo = eventItems[columnKey];
                if (!eventItemInfo) {
                    AppKitchen.logError(`No event item found for colum: '${columnKey}'.`);
                }
                return eventItemInfo;
            }

            private static tryGetColumnType(columnConfig: AppKitchen.Data.ColumnConfig,
                eventItemInfo: Api.Models.EventItemInfo): ColumnType {
                var columnType: ColumnType;
                if (eventItemInfo) {
                    columnType = this.getColumnType(eventItemInfo);
                } else {
                    columnType = EventGridHelper.EventItemTypeConverter[columnConfig.Type];
                }
                if (columnType === undefined) {
                    const message =
                        `Unsupported column type in column config: '${columnConfig.Type}' and in event item info: '${
                            eventItemInfo.Type}' for field: '${eventItemInfo.InventoryId}.${eventItemInfo.Id}'.`;
                    AppKitchen.logError(message);
                    columnType = ColumnType.Text;
                }
                //else {
                //    const message = `Supported column type in column config: '${columnConfig.Type}' and in event item info: '${
                //        eventItemInfo.Type}' for field: '${eventItemInfo.InventoryId}.${eventItemInfo.Id}' match to: '${ColumnType[columnType]}'.`;
                //    AppKitchen.logInfo(message);
                //}
                return columnType;
            }

            private static getColumnOptions(columnConfig: AppKitchen.Data.ColumnConfig, columnType: ColumnType): Grids.
                GridColumnOptions {
                return OptionsHelper.merge<GridColumnOptions>(columnConfig.GridOptions,
                    {
                        multiFilter: [ColumnType.Text, ColumnType.Date, ColumnType.Boolean].indexOf(columnType) > -1
                            ? { search: false }
                            : null,
                        align: columnType === ColumnType.Number ? "right" : "left",
                        multiline: false
                    });
            }

            private static getFieldOptions(columnConfig: AppKitchen.Data.ColumnConfig, columnType: ColumnType): Forms.FieldOptions {
                return OptionsHelper.merge(columnConfig.FieldOptions,
                    this.getDefaultFieldOptions(columnType, columnConfig.FieldOptions));
            }

            private static createColumnDefinition(columnKey: string,
                columnConfig: AppKitchen.Data.ColumnConfig,
                columnOptions: Grids.GridColumnOptions,
                columnType: ColumnType,
                eventItemInfo: Api.Models.EventItemInfo,
                fieldOptions: Forms.FieldOptions,
                getColumnHeader: GetColumnHeader): kendo.ui.GridColumn {
                const column: kendo.ui.GridColumn = {
                    field: columnKey,
                    title: getColumnHeader(columnConfig.InventoryId, columnConfig.ItemId, eventItemInfo),
                    width: columnOptions.width || columnConfig.Width,
                    hidden: columnConfig.Visibility === false,
                    filterable: {
                        multi: columnOptions.multiFilter ? true : false,
                        search: columnOptions.multiFilter && columnOptions.multiFilter.search,
                        ui: GridHelper.columnFilterUI[columnType]
                    },
                    format: fieldOptions.format ? "{0:" + fieldOptions.format + "}" : undefined,
                    attributes: {
                        style: (["right", "left", "center"].indexOf(columnOptions.align) !== -1
                                ? "text-align: " + columnOptions.align + ";"
                                : "") +
                            (columnOptions.multiline ? "white-space: normal;" : "")
                    },
                    template: GridHelper.gridColumnTemplate(columnType, columnKey, columnOptions)
                };

                if (this.isPoolItem(eventItemInfo)) {
                    var textPool = eventItemInfo.TextPool;
                    if (textPool && textPool.length > 0) {
                        fieldOptions.pool = {
                            multi: eventItemInfo.Type === "MultiDescriptor" ||
                                eventItemInfo.TypeSpecification.MultiSelect,
                            restrict: true,
                            values: eventItemInfo.TextPool,
                            valueField: "Id",
                            textField: eventItemInfo.TypeSpecification.UseId ? "Id" : "Text"
                        }

                        if (eventItemInfo.Type !== "MultiDescriptor" && !eventItemInfo.TypeSpecification.MultiSelect) {
                            column.values = [];
                            for (let textPoolItems of textPool) {
                                column.values.push({
                                    text: eventItemInfo.TypeSpecification.UseId ? textPoolItems.Id : textPoolItems.Text,
                                    value: textPoolItems.Id
                                });
                            }
                        }
                    }
                }

                if (fieldOptions.pool) {
                    if (fieldOptions.pool.multi) {
                        column.editor =
                            (container, options) => FieldOptionsBindings.getMultiSelectEditor(container,
                                options,
                                fieldOptions);
                    } else {
                        column.editor =
                            (container, options) => FieldOptionsBindings.getDropDownEditor(container,
                                options,
                                fieldOptions);
                    }
                }

                if (fieldOptions.suggest) {
                    column.editor =
                        (container, options) => FieldOptionsBindings.getAutoCompleteEditor(container,
                            options,
                            fieldOptions);
                }

                if (columnType === ColumnType.Date) {
                    column.editor =
                        (container, options) => FieldOptionsBindings.getDateEditor(container, options, fieldOptions);
                    fieldOptions.default = fieldOptions.default || new Date().onlyDate();
                }

                if (columnType === ColumnType.DateTime) {
                    column.editor =
                        (container, options) =>
                        FieldOptionsBindings.getDateTimeEditor(container, options, fieldOptions);
                }

                if (columnType === ColumnType.Time) {
                    column.editor =
                        (container, options) => FieldOptionsBindings.getTimeEditor(container, options, fieldOptions);
                    if (fieldOptions.textMode) {
                        column.template =
                            (dataItem) => ColumnBindings.timeColumnTextModeTemplate(dataItem, fieldOptions, column);
                        column.sortable = {
                            compare: (left, right) => ColumnBindings.timeColumnTextModeCompare(left, right, column)
                        }
                    }
                }

                if (columnType === ColumnType.Number) {
                    column.editor =
                        (container, options) => FieldOptionsBindings.getNumericEditor(container, options, fieldOptions);
                }

                if (columnType === ColumnType.Boolean) {
                    column.editor =
                        (container, options) => FieldOptionsBindings.customBoolEditor(container, options, fieldOptions);
                }

                return column;
            }

            private static isPoolItem(eventItemInfo: Api.Models.EventItemInfo): boolean {
                return eventItemInfo &&
                    ["TextPool", "Descriptor", "MultiDescriptor", "User", "Group"].indexOf(eventItemInfo.Type) > -1;
            }

            private static createSchemaDefinition(columnKey: string,
                columnType: ColumnType,
                fieldOptions: Forms.FieldOptions): kendo.data.DataSourceSchemaModelField {
                return {
                    field: columnKey,
                    type: fieldOptions.textMode ? "string" : GridHelper.kendoFieldType[columnType],
                    validation: {
                        required: fieldOptions.mandatory
                    },
                    editable: !(fieldOptions.readOnly),
                    defaultValue: fieldOptions.default
                }
            }

            static getColumnType(eventItem: Api.Models.EventItemInfo): ColumnType {
                var type: ColumnType = EventGridHelper.EventItemTypeConverter[eventItem.Type];
                if (type === ColumnType.Date) {
                    if (!eventItem.TypeSpecification) {
                        AppKitchen.logWarning(
                            `TypeSpecification not specified for type: '${eventItem.Type}' on field: '${eventItem
                            .InventoryId}.${eventItem.Id}'.`);
                        return ColumnType.DateTime;
                    }
                    switch (eventItem.TypeSpecification.DateFormat) {
                        case 1: // Date
                            return ColumnType.Date;
                        case 2: // Time
                            return ColumnType.Time;
                        case 3: // DateTime
                            return ColumnType.DateTime;
                        case 4: // UserDefined
                            AppKitchen.logWarning(
                                "User defined date format for Events not supported! Using DateTime instead!");
                            return ColumnType.DateTime;
                        default:
                            const message = `Date format: '${eventItem.TypeSpecification.DateFormat}' not supported.`;
                            AppKitchen.logWarning(message);
                            throw message;
                    }
                }

                return type;
            }
        }

        class FieldOptionsBindings {
            static getDropDownEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                var emptyEntry = {};
                emptyEntry[fieldOptions.pool.textField] = "";
                emptyEntry[fieldOptions.pool.valueField] = "";
                if (fieldOptions.pool.values && fieldOptions.pool.values.length > 0) {
                    $('<input data-bind="value:' + options.field + '"/>')
                        .appendTo(container)
                        .kendoDropDownList({
                            //autoBind: false,
                            dataSource: [emptyEntry].concat(fieldOptions.pool.values),
                            dataTextField: fieldOptions.pool.textField,
                            dataValueField: fieldOptions.pool.valueField
                        });
                }
            }

            static getMultiSelectEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                if (fieldOptions.pool.values && fieldOptions.pool.values.length > 0) {
                    $('<input data-bind="value:' + options.field + '"/>')
                        .appendTo(container)
                        .kendoMultiSelect({
                            autoBind: false,
                            dataSource: fieldOptions.pool.values,
                            dataTextField: fieldOptions.pool.textField,
                            dataValueField: fieldOptions.pool.valueField
                        });
                }
            }

            static getAutoCompleteEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                $('<input data-bind="value:' + options.field + '"/>')
                    .appendTo(container)
                    .kendoAutoComplete({
                        dataSource: fieldOptions.suggest.values,
                        separator: fieldOptions.suggest.multi ? fieldOptions.suggest.separator || ", " : undefined
                    });
            }

            static getDateEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                $('<input data-bind="value:' + options.field + '"/>').appendTo(container).kendoDatePicker({
                    format: fieldOptions.format,
                    min: fieldOptions.min != null ? new Date(fieldOptions.min) : undefined,
                    max: fieldOptions.max != null ? new Date(fieldOptions.max) : undefined
                });
            }

            static getDateTimeEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                $('<input data-bind="value:' + options.field + '"/>').appendTo(container).kendoDateTimePicker({
                    format: fieldOptions.format,
                    min: fieldOptions.min != null ? new Date(fieldOptions.min) : undefined,
                    max: fieldOptions.max != null ? new Date(fieldOptions.max) : undefined
                });
            }

            static getTimeEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                if (fieldOptions.textMode && fieldOptions.allow24) {
                    var picker = $('<input data-bind="value:' + options.field + '"/>').appendTo(container)
                        .kendoComboBox({
                            dataSource: [
                                "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00",
                                "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00",
                                "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00",
                                "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00",
                                "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00",
                                "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00"
                            ],
                            suggest: false,
                            highlightFirst: false
                        }).data("kendoComboBox");
                    picker.wrapper.find(".k-icon.k-i-arrow-s").removeClass("k-i-arrow-s").addClass("k-i-clock");
                } else {
                    $('<input data-bind="value:' + options.field + '"/>').appendTo(container).kendoTimePicker({
                        format: fieldOptions.format,
                        min: fieldOptions.min != null ? new Date(fieldOptions.min) : undefined,
                        max: fieldOptions.max != null ? new Date(fieldOptions.max) : undefined
                    });
                }
            }

            static getNumericEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                $('<input data-bind="value:' + options.field + '"/>').appendTo(container).kendoNumericTextBox({
                    format: fieldOptions.format,
                    decimals: fieldOptions.precision,
                    min: fieldOptions.min,
                    max: fieldOptions.max,
                    //spinners: false,
                    //step: 0
                    step: 1
                });
            }

            static customBoolEditor(container: JQuery,
                options: kendo.ui.GridColumnEditorOptions,
                fieldOptions: Forms.FieldOptions) {
                $('<input data-bind="value:' + options.field + '"/>')
                    .appendTo(container)
                    .kendoDropDownList({
                        dataSource: [{ value: true, text: Strings.Yes }, { value: false, text: Strings.No }],
                        dataTextField: "text",
                        dataValueField: "value"
                    });
            }
        }

        class ColumnBindings {
            private static h24 = new Date(kendo.parseDate("23:00", "HH:mm").valueOf() + 3600000);

            static timeColumnTextModeTemplate(dataItem: kendo.data.Model,
                fieldOptions: Forms.FieldOptions,
                column: kendo.ui.GridColumn): string {
                var value = dataItem[column.field];
                if (value) {
                    if (fieldOptions.allow24) {
                        if (value === "24:00") {
                            return value;
                        }
                        if (value === "00:00") {
                            dataItem[column.field] = "";
                            return "";
                        }
                    }
                    var timeValue = kendo.parseDate(value, "HH:mm");
                    if (timeValue && timeValue.valueOf()) {
                        dataItem[column.field] = kendo.toString(timeValue, "HH:mm");
                        return dataItem[column.field];
                    }
                    var dateValue = kendo.parseDate(value);
                    if (dateValue && dateValue.valueOf()) {
                        dataItem[column.field] = kendo.toString(dateValue, "HH:mm");
                        return dataItem[column.field];
                    }
                }

                dataItem[column.field] = "";
                return "";
            }

            static timeColumnTextModeCompare(left: kendo.data.Model,
                right: kendo.data.Model,
                column: kendo.ui.GridColumn): number {
                var time1 = kendo.parseDate(left[column.field], "HH:mm") || new Date(0);
                var time2 = kendo.parseDate(right[column.field], "HH:mm") || new Date(0);

                if (left[column.field] === "24:00") {
                    time1 = this.h24;
                }

                if (right[column.field] === "24:00") {
                    time2 = this.h24;
                }

                return time1.valueOf() - time2.valueOf();
            }
        }
    }
}