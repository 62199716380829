namespace AppKitchen {

    export module Controls {

        export module Tile {

            export enum TileStyle {
                Dark,
                Bright
            }

            export enum TileSize {
                M,
                L,
                S
            }

            // ReSharper disable once InconsistentNaming
            export interface TileOptions extends AppKitchen.ViewBaseOptions {
                style?: TileStyle;
                size?: TileSize;
                click?: (e?: JQuery, route?: string) => void;
            }

            // ReSharper disable once InconsistentNaming
            export interface TileBaseAttributes extends AppKitchen.ModelBaseAttributes {
                id?: string;
                route?: string;
                icon?: string;
                image?: string;
                title: string;
                description?: string;
                options?: TileOptions;
            }

            export abstract class TileBaseModel<TTileAttributes extends TileBaseAttributes> extends AppKitchen.ModelBase<TTileAttributes> {
                constructor(attributes: TTileAttributes) {
                    super(attributes);
                }
            }

            export abstract class TileBaseView<TTileAttributes extends TileBaseAttributes> extends AppKitchen.ViewBase<TileBaseModel<TTileAttributes>> {
                options: TileOptions;

                constructor(model: TileBaseModel<TTileAttributes>, target: HTMLElement, template: string) {
                    super(model, target, model.get().options);

                    this.setTemplate(template);
                    this.model.bind("change", () => this.render());
                    this.render();
                }

                public abstract render();
            }

            // ReSharper disable once InconsistentNaming
            export interface TileListAttributes<TTileAttributes extends TileBaseAttributes> extends AppKitchen.ModelBaseAttributes {
                tileAttributes: TTileAttributes[],
            }

            export class TileListModel<TTileAttributes extends TileBaseAttributes> extends AppKitchen.ModelBase<TileListAttributes<TTileAttributes>> {
                constructor(tileAttributes: TTileAttributes[]) {
                    super({ tileAttributes: tileAttributes });
                }
            }

            export class TileListView<TTileAttributes extends TileBaseAttributes> extends AppKitchen.ViewBase<TileListModel<TTileAttributes>> {

                constructor(model: TileListModel<TTileAttributes>, target: HTMLElement,
                    private createModel: (t: TTileAttributes) => TileBaseModel<TTileAttributes>,
                    private createView: (model: TileBaseModel<TTileAttributes>, target: HTMLElement) => TileBaseView<TTileAttributes>) {
                    super(model, target, {});

                    this.model.bind("change", () => this.render());

                    this.render(); 
                }

                render() {
                    let tileAttributes = this.model.get().tileAttributes;

                    let div = $('<div class="a-tile-list-wrapper"></div>');
                    for (let tileAttribute of tileAttributes) {
                        let tile = $('<div class="a-tile-list-item"></div>');
                        tile.appendTo(div);
                        let model = this.createModel(tileAttribute);
                        this.createView(model, tile[0]);
                    }
                    div.appendTo(this.$el);

                    return this;
                }
            }

            export class SimpleTileModel extends TileBaseModel<TileBaseAttributes> {
                constructor(attributes: TileBaseAttributes) {
                    super(attributes);
                }
            }

            export class SimpleTileView extends TileBaseView<TileBaseAttributes> {
                constructor(model: SimpleTileModel, target: HTMLElement) {
                    super(model, target, AppKitchen.Templates.SimpleTile);
                }

                public render() {
                    let id = this.model.get().id ? this.model.get().id : GuidHelper.newGuid();
                    let route = this.model.get().route;
                    let title = this.model.get().title;
                    let description = this.model.get().description;
                    let image = this.model.get().image;
                    let icon = this.model.get().icon;
                    if (title && (image || icon)) {

                        this.renderTemplate({
                            id: id,
                            icon: icon,
                            image: image,
                            title: title,
                            description: description,
                            style: TileStyle[this.options.style],
                            size: TileSize[this.options.size],
                        });

                        if (this.options.click && route) {
                            let el = this.$el.find(`.a-tile[data-id=${id}]`);
                            el.bind("click", () => this.options.click(el, route));
                        }
                    }
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface StatusTileAttributes extends TileBaseAttributes {
                status?: string,
                statusIcon?: string,
            }

            export class StatusTileModel extends TileBaseModel<StatusTileAttributes> {
                constructor(attributes: StatusTileAttributes) {
                    super(attributes);
                }
            }

            export class StatusTileView extends TileBaseView<StatusTileAttributes> {
                constructor(model: StatusTileModel, target: HTMLElement) {
                    super(model, target, AppKitchen.Templates.StatusTile);
                }

                public render() {
                    let id = this.model.get().id ? this.model.get().id : GuidHelper.newGuid();
                    let route = this.model.get().route;
                    let title = this.model.get().title;
                    let description = this.model.get().description;
                    let image = this.model.get().image;
                    let icon = this.model.get().icon;
                    let status = this.model.get().status;
                    let statusIcon = this.model.get().statusIcon;
                    if (title && (image || icon)) {

                        this.renderTemplate({
                            id: id,
                            icon: icon,
                            image: image,
                            title: title,
                            description: description,
                            status: status,
                            statusIcon: statusIcon,
                            style: TileStyle[this.options.style],
                            size: TileSize[this.options.size],
                        });

                        if (this.options.click && route) {
                            let el = this.$el.find(`.a-tile[data-id=${id}]`);
                            el.bind("click", () => this.options.click(el, route));
                        }
                    }
                }
            }
        }

    /*
     *  old code from "Helpers.ts"
     */
        //export module Tile {

        //    export enum TileStyle {
        //        Dark,
        //        Bright
        //    }

        //    export enum TileSize {
        //        L,
        //        M
        //    }

        //    // ReSharper disable once InconsistentNaming
        //    export interface TileOptions {
        //        style?: TileStyle,
        //        size?: TileSize;
        //        id: string,
        //        icon?: string,
        //        image?: string,
        //        title: string,
        //        subtitle?: string,
        //        status?: string,
        //        statusIcon?: string,
        //        click?: (id: string) => void,
        //    }

        //    export function create(target: HTMLElement, options: TileOptions) {
        //        UIHelper.renderTemplateTo(target,
        //            AppKitchen.Templates.Tile,
        //            {
        //                style: TileStyle[options.style],
        //                size: TileSize[options.size],
        //                id: options.id,
        //                icon: options.icon,
        //                image: options.image,
        //                title: options.title,
        //                subtitle: options.subtitle,
        //                status: options.status,
        //                statusIcon: options.statusIcon
        //            });
        //        $(target).find(`a-tile[data-id=${options.id}]`).bind("click", () => options.click(options.id));
        //    }

        //    export function append(target: HTMLElement, options: TileOptions) {
        //        options = AppKitchen.OptionsHelper.merge<TileOptions>(options,
        //            {
        //                id: options.id ? options.id : GuidHelper.newGuid(),
        //                title: null,
        //                style: TileStyle.Bright,
        //                size: TileSize.M
        //            });
        //        var rendertedTemplate = UIHelper.renderTemplate(AppKitchen.Templates.Tile,
        //            {
        //                style: TileStyle[options.style],
        //                size: TileSize[options.size],
        //                id: options.id,
        //                icon: options.icon,
        //                image: options.image,
        //                title: options.title,
        //                subtitle: options.subtitle,
        //                status: options.status,
        //                statusIcon: options.statusIcon
        //            });
        //        $(rendertedTemplate).appendTo(target);
        //        $(target).find(`a-tile[data-id=${options.id}]`).bind("click", () => options.click(options.id));
        //    }
        //}
    }
}
