namespace AppKitchen {

    export module Controls {

        // ReSharper disable once InconsistentNaming
        export interface TimelineAttributes extends AppKitchen.ModelBaseAttributes {}

        export class TimelineModel extends AppKitchen.ModelBase<TimelineAttributes> {}

        // ReSharper disable once InconsistentNaming
        export interface TimelineOptions extends AppKitchen.ViewBaseOptions {
            fillHeight?: boolean;
        }

        export class TimelineView extends AppKitchen.ViewBase<TimelineModel> {
            options: TimelineOptions;

            containers: {
                timeline: JQuery;
            }

            constructor(model: TimelineModel, target: HTMLElement, options?: TimelineOptions) {
                super(model,
                    target,
                    AppKitchen.OptionsHelper.merge(options,
                    {
                        fillHeight: false
                    }));

                if (this.options.fillHeight) {
                    this.setTemplate('<div class="a-timeline full-height-grid"></div>');
                } else {
                    this.setTemplate('<div class="a-timeline"></div>');
                }

                this.render();
            }

            //TODO: review this control!
            render() {
                this.renderTemplate({});

                this.containers = {
                    timeline: this.$el.find(".a-timeline")
                }
                
                this.containers.timeline.kendoScheduler({
                    date: new Date("2013/6/13"),
                    startTime: new Date("2013/6/13 07:00 AM"),
                    majorTick: 60,
                    views: [
                        {
                            type: "timeline"
                        },
                        {
                            type: "timelineWeek"
                        },
                        {
                            type: "timelineWorkWeek"
                        },
                        {
                            type: "timelineMonth",
                            startTime: new Date("2013/6/13 00:00 AM"),
                            majorTick: 1440
                        }
                    ],
                    timezone: "Etc/UTC",
                    dataSource: {
                        batch: true,
                        transport: {
                            read: {
                                url: "https://demos.telerik.com/kendo-ui/service/meetings",
                                dataType: "jsonp"
                            },
                            update: {
                                url: "https://demos.telerik.com/kendo-ui/service/meetings/update",
                                dataType: "jsonp"
                            },
                            create: {
                                url: "https://demos.telerik.com/kendo-ui/service/meetings/create",
                                dataType: "jsonp"
                            },
                            destroy: {
                                url: "https://demos.telerik.com/kendo-ui/service/meetings/destroy",
                                dataType: "jsonp"
                            },
                            parameterMap: (options, operation) => {
                                if (operation !== "read" && options.models) {
                                    return { models: kendo.stringify(options.models) };
                                }
                            }
                        },
                        schema: {
                            model: {
                                id: "meetingID",
                                fields: {
                                    meetingID: { from: "MeetingID", type: "number" },
                                    title: { from: "Title", defaultValue: "No title", validation: { required: true } },
                                    start: { type: "date", from: "Start" },
                                    end: { type: "date", from: "End" },
                                    startTimezone: { from: "StartTimezone" },
                                    endTimezone: { from: "EndTimezone" },
                                    description: { from: "Description" },
                                    recurrenceId: { from: "RecurrenceID" },
                                    recurrenceRule: { from: "RecurrenceRule" },
                                    recurrenceException: { from: "RecurrenceException" },
                                    roomId: { from: "RoomID", nullable: true },
                                    attendees: { from: "Attendees", nullable: true },
                                    isAllDay: { type: "boolean", from: "IsAllDay" }
                                }
                            }
                        }
                    },
                    group: {
                        resources: ["Rooms", "Attendees"],
                        orientation: "vertical"
                    },
                    resources: [
                        {
                            field: "roomId",
                            name: "Rooms",
                            dataSource: [
                                { text: "Meeting Room 101", value: 1, color: "#6eb3fa" },
                                { text: "Meeting Room 201", value: 2, color: "#f58a8a" }
                            ],
                            title: "Room"
                        },
                        {
                            field: "attendees",
                            name: "Attendees",
                            dataSource: [
                                { text: "Alex", value: 1, color: "#f8a398" },
                                { text: "Bob", value: 2, color: "#51a0ed" },
                                { text: "Charlie", value: 3, color: "#56ca85" }
                            ],
                            multiple: true,
                            title: "Attendees"
                        }
                    ]
                });
                return this;
            }
        }
    }
}
