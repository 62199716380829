/// <reference path="../general.ts" />
/// <reference path="general.tschart.transformations.ts" />

namespace General {
    export module TsChart {

        export interface LoggerImpl {
            debug: (string) => void;
            error: (string) => void;
        }

        export function applyPreloadTransformation(interactiveDefinition: any,
            targetContainer: HTMLElement,
            setTitle: (title: string) => void,
            reloadAfterTransformation: (interactiveDefinition:any) => void) {


            if (interactiveDefinition.preloadtransformation && interactiveDefinition.preloadtransformation.procedure) {
                var procedure = General.convertToFunction(interactiveDefinition.preloadtransformation.procedure);
                if (procedure) {
                    var transformationTarget = $(targetContainer).find(".mp-interactive-transformation");
                    interactiveDefinition = procedure(transformationTarget, interactiveDefinition, interactiveDefinition.preloadtransformation.data, reloadAfterTransformation);

                    if (interactiveDefinition.title) {
                        //var title = $(targetContainer).find(".mp-interactive-title");
                        //title.html(interactiveDefinition.title);
                        setTitle(interactiveDefinition.title);
                    }
                } else {
                    GeneralLogger.logError("Edoc Transformation Procedure not defined " + interactiveDefinition.preloadtransformation.procedure);
                }
            }
            return interactiveDefinition;
        }

        /* This is a fix for the highchart library. When the navigators baseSeries is refering to a series that does not exist*/
        export function fixDataList(dataLists, interactiveDefinition) {
            if (dataLists.length < 2) {
                return;
            }

            var smallestDateOfAllLists = null;
            var relevantIndex = 0;

            for (var i = 0; i < dataLists.length; i++) {
                var list = dataLists[i];
                var smallestDateOfCurrentList = getSmallestDateFromDataList(list);
                if (smallestDateOfAllLists == null || (smallestDateOfCurrentList != null && smallestDateOfCurrentList < smallestDateOfAllLists)) {
                    smallestDateOfAllLists = smallestDateOfCurrentList;
                    relevantIndex = i;
                }
            }

            if (interactiveDefinition.chartdefinition.navigator.baseSeries == null) {
                interactiveDefinition.chartdefinition.navigator.baseSeries = relevantIndex;
            }

        }

        export function setChartControlsDependingOnSize (chartOptions, highstockchartSelector, initialChartConfig) {

            var height = $(highstockchartSelector).height();
            var width = $(highstockchartSelector).width();

            var navigatorEnabled = height > 300 && initialChartConfig.scrollbar.enabled;
            var buttonsEnabled = width > 400 && initialChartConfig.rangeSelector.enabled;

            var optionsChanged = false;

            if (chartOptions.navigator.enabled !== navigatorEnabled) {
                chartOptions.navigator.enabled = navigatorEnabled;
                chartOptions.scrollbar.enabled = navigatorEnabled;
                optionsChanged = true;
            }
            if (chartOptions.rangeSelector.enabled !== buttonsEnabled) {
                chartOptions.rangeSelector.enabled = buttonsEnabled;
                optionsChanged = true;
            }

            return optionsChanged;
        }

        function getSmallestDateFromDataList(dataList) {
            if (dataList.data && dataList.data.length > 0 && dataList.data[0].length > 0) {
                return dataList.data[0][0];
            }
            return null;
        }

        function addAxisFormatter (axisObj) {

            var addFormatter = function (a) {
                if (a.labels && a.labels.formatter) {
                    var formatterFunc = convertToFunction(a.labels.formatter);
                    a.labels.formatter = formatterFunc;
                }
            };

            if (axisObj) {
                if (Object.prototype.toString.call(axisObj) === '[object Array]') {
                    for (var i in axisObj) {
                        var axis = axisObj[i];
                        addFormatter(axis);
                    }
                } else {
                    addFormatter(axisObj);
                }

            }
        }

        //function evalChartFunctions(chartOptions) {

        //    if (chartOptions.tooltip && chartOptions.tooltip.positioner) {
        //        var positionerProcedure = convertToFunction(chartOptions.tooltip.positioner);
        //        chartOptions.tooltip.positioner = positionerProcedure;
        //    }

        //    if (chartOptions.tooltip && chartOptions.tooltip.formatter) {
        //        var tooltipFormatter = convertToFunction(chartOptions.tooltip.formatter);
        //        chartOptions.tooltip.formatter = tooltipFormatter;
        //    } 

        //    addAxisFormatter(chartOptions.xAxis);
        //    addAxisFormatter(chartOptions.yAxis);
        //    if (chartOptions.navigator)
        //        addAxisFormatter(chartOptions.navigator.xAxis);
        //}

        export function getSeriesPointsForXValue(series, xValue) {
            var points = [];
            for (var s in series) {
                if (!series.hasOwnProperty(s)) {
                    continue;
                }
                var serie = series[s];
                var len = serie.points.length;
                var point = findMatchingPoint(serie.points, xValue, 0, len - 1, len - 1);
                if (point) {
                    points.push(point);
                }

            }
            return points;
        }

        function findMatchingPoint(points, searchX, minIndex, maxIndex, pointsLength) {

            var startPoint = points[minIndex];
            var endPoint = points[maxIndex];

            if (!startPoint || !endPoint || startPoint.x > searchX || endPoint.x < searchX) {
                return null;
            }

            var midIndex = ~~((maxIndex - minIndex) / 2 + minIndex);
            var midIndexPoint = points[midIndex];

            if (midIndexPoint.x === searchX) {
                return midIndexPoint;
            }
            if (midIndex + 5 < maxIndex && midIndex <= pointsLength - 1) {
                var nextPoint = points[midIndex + 1];
                if (searchX > midIndexPoint.x && searchX < nextPoint.x) {
                    return midIndexPoint;
                }
            }



            if (midIndexPoint.x < searchX) {
                return findMatchingPoint(points, searchX, midIndex + 1, maxIndex, pointsLength);
            }
            else if (midIndexPoint.x > searchX) {
                return findMatchingPoint(points, searchX, minIndex, midIndex - 1, pointsLength);
            }
        }

        export function transformDataIfRequired(interactiveDefinition, dataList) {
            if (interactiveDefinition.datatransformation && interactiveDefinition.datatransformation.procedure) {
                var procedure = General.convertToFunction(interactiveDefinition.datatransformation.procedure);
                if (procedure) {
                    return procedure(dataList, interactiveDefinition.datatransformation.data);
                }
            }
            return dataList;
        }

        export function getSeriesLabel(timeseriesId: string, timeseriesName: string) {
            if (!timeseriesName || timeseriesId === timeseriesName) {
                return timeseriesId;
            }
            return timeseriesName + "(" + timeseriesId + ")";
        }

        export function preprocessInteractiveDefinition(interactiveData: AppKitchen.Api.Models.InteractiveData,
            dataList: AppKitchen.Api.Models.ChartData[], defaultChartOptions: Highstock.Options): Highstock.Options {
            let highchartOptions = AppKitchen.OptionsHelper.merge<Highstock.Options>(interactiveData.chartdefinition, defaultChartOptions, true);

            var series: Highcharts.IndividualSeriesOptions[] = [];
            for (let chartseries of interactiveData.chartseries) {
                if (chartseries.hasOwnProperty("enabled") && chartseries.enabled === false) {
                    continue;
                }

                var chartData = null;
                var seriesLabel = null;

                for (let data of dataList) {
                    if (data.datasourceId === chartseries.datasource.datasourceid) {
                        chartData = data.data;
                        seriesLabel = this.getSeriesLabel(data.timeseriesId, data.timeseriesName);
                        break;
                    }
                }

                var seriesStyles = null;
                for (let style of interactiveData.seriesstyles) {
                    if (chartseries.seriesstyleid === style.id) {
                        seriesStyles = style.styles;
                        break;
                    }
                }

                var newSerie = {
                    name: chartseries.label ? chartseries.label : seriesLabel,
                    data: chartData
                };

                newSerie = $.extend(newSerie || {}, seriesStyles[0]);

                if (chartseries.transformation) {
                    var procedure = General.convertToFunction(chartseries.transformation.procedure);

                    if (procedure) {
                        var newSeriesList = procedure(newSerie, chartseries.transformation.data);

                        let j = 0;
                        for (let serie of newSeriesList) {
                            if (seriesStyles.length > j) {
                                serie = $.extend(serie || {}, seriesStyles[j]);
                            } else {
                                serie = $.extend(serie || {}, seriesStyles[0]);
                            }

                            series.push(serie);
                            j++;
                        }
                    } else {
                        GeneralLogger.logError("Failed to convert procedure: '" +
                            chartseries.transformation.procedure +
                            "'");
                    }
                } else {
                    series.push(newSerie);
                }

            }

            if (interactiveData.seriestransformation) {
                var seriesProcedure = General.convertToFunction(interactiveData.seriestransformation.procedure);

                if (seriesProcedure) {
                    series = seriesProcedure(series, interactiveData.seriestransformation.data);
                } else {
                    GeneralLogger.logError("Failed to convert procedure: '" + interactiveData.seriestransformation.procedure + "'");
                }
            }

            highchartOptions.series = series;

            if (interactiveData.chartdefinition.timeGrouping) {
                for (let serie of highchartOptions.series) {
                    Transformations.ChangeTimestampToGroupingFormat(serie.data, interactiveData.chartdefinition.timeGrouping);
                }
            }

            return highchartOptions;
        }

    }
} 
