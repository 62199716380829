namespace AppKitchen {
    export module Controls {
        export module Charts {

            // ReSharper disable once InconsistentNaming
            export interface StackedColumnSeries {
                name: string;
                data: number[];
                color: string;
                stack?: string;
            }

            // ReSharper disable once InconsistentNaming
            export interface StackedColumnChartAttributes extends AppKitchen.ModelBaseAttributes {
                data?: {
                    categories: string[];
                    series: StackedColumnSeries[];
                }
            }
            
            export class StackedColumnChartModel extends AppKitchen.ModelBase<StackedColumnChartAttributes> {
                constructor() {
                    super({
                        data: {
                            categories: [],
                            series: []
                        }
                    });
                }

                data(data: { categories: string[], series: StackedColumnSeries[] }) {
                    var defaultStack = AppKitchen.StringHelper.generateRandom(5);
                    if (data && data.series && data.series.length > 0) {
                        data.series.forEach(series => series.stack = series.stack || defaultStack);
                    }

                    this.set({
                        data: data
                    });
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface StackedColumnChartOptions extends AppKitchen.ViewBaseOptions {
                height?: number;
                valueAxis?: { visible?: boolean, minMax?: number }
                //position: "top" | "bottom" | "left" | "right" | "custom"
                //align: "start" | "center" | "end"
                legend?: { visible?: boolean, position?: string, align?: string, offsetX?: number, offsetY?: number},
                onLegendItemClick?: any;
                onSeriesClick?: any;
            }

            export class StackedColumnChartView extends AppKitchen.ViewBase<StackedColumnChartModel> {
                options: StackedColumnChartOptions;

                chartSelector: string;
                rendered: boolean;

                chart: kendo.dataviz.ui.Chart;

                constructor(model: StackedColumnChartModel, targetContainer: HTMLElement, options?: StackedColumnChartOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge<StackedColumnChartOptions>(options, {
                        height: undefined,
                        valueAxis: AppKitchen.OptionsHelper.merge(options ? options.valueAxis : {}, { visible: true, minMax: undefined }),
                        legend: AppKitchen.OptionsHelper.merge(options ? options.legend : {}, { visible: true, position: "top", align: "end" })
                    }));

                    this.setTemplate('<div class="a-stacked-column-chart-container"></div>');

                    this.rendered = false;

                    this.model.bind('change:data', this.render, this);
                    this.render();
                }

                render() {
                    this.renderTemplate({});

                    var container = this.$el.find(".a-stacked-column-chart-container");

                    var chartData = this.model.get().data;
                    if (chartData) {
                        this.chart = $(container).kendoChart(<any>{
                            legend: {
                                visible: this.options.legend.visible,
                                position: this.options.legend.position,
                                align: this.options.legend.align,
                                offsetX: this.options.legend.offsetX,
                                offsetY: this.options.legend.offsetY,
                                itemStyle: {
                                    color: "#808080"
                                }
                            },
                            chartArea: {
                                background: "",
                                height: this.options.height || this.$el.height()
                            },
                            seriesDefaults: {
                                type: "column",
                                overlay: {
                                    gradient: null
                                },
                                border: {
                                    width: 0
                                }
                            },
                            series: chartData.series,
                            categoryAxis: {
                                categories: chartData.categories,
                                majorGridLines: {
                                    visible: false
                                },
                                line: false
                            },
                            valueAxis: {
                                name: "value",
                                visible: this.options.valueAxis.visible,
                                line: false
                            },
                            tooltip: {
                                visible: true,
                                template: "#= series.name #: #= value #"
                            },
                            transitions: !this.rendered,
                            legendItemClick: this.options.onLegendItemClick,
                            seriesClick: this.options.onSeriesClick
                        }).data("kendoChart");

                        this.setValueAxisMax();

                        this.rendered = chartData.series.length > 0 && chartData.series[0].data.length > 0;
                    } else {
                        this.rendered = false;
                    }

                    return this;
                }

                resize(height?: number) {
                    if (this.chart) {
                        this.chart.setOptions({
                            chartArea: {
                                height: height || this.options.height || this.$el.height()
                            }
                        });
                        this.setValueAxisMax();
                        this.chart.resize();
                    }
                }

                private setValueAxisMax() {
                    if (this.options.valueAxis.minMax) {
                        var xAxis = this.chart.getAxis("value");
                        if (xAxis.range().max < this.options.valueAxis.minMax) {
                            (<any>this.chart.options.valueAxis).max = this.options.valueAxis.minMax;
                            this.chart.resize();
                        }
                    }
                }
            }

        }
    }
}
