namespace AppKitchen {
    export module Controls {
        export module TimeseriesDataViews {

            export class RowBasedSpreadsheetView extends TimeseriesDataSpreadsheetBase {
                
                private headerRows: number;
                private headerCols: number;

                render() {
                    this.headerRows = 1;
                    this.headerCols = 3;

                    const isValid = this.checkTimeseriesDataViewData(this.model.get().timeseries);
                    if (!isValid) {
                        return null;
                    }

                    this.maxLengthTimeseries = this.getMaxLengthTimeseries(this.model.get().timeseries);

                    const sheet = this.getSpreadsheet(this.model.get().timeseries);

                    const spreadsheetOptions: kendo.ui.SpreadsheetOptions = {
                        sheets: [sheet],
                        toolbar: false,
                        sheetsbar: false,
                        columns: sheet.columns.length,
                        rows: this.maxLengthTimeseries.rows + this.headerRows
                    }; 

                    this.spreadSheet = this.spreadsheetContainer.kendoSpreadsheet(spreadsheetOptions).data("kendoSpreadsheet");

                    if (this.maxLengthTimeseries.timeseries !== null && this.maxLengthTimeseries.timeseries.data.length !== 0) {
                        this.fillRowbasedSpreadsheet(this.model.get().timeseries);
                    }

                    if (this.options.editable) {
                        this.spreadSheet.bind("change", () => {
                            this.dirty = true;
                            this.options.onEdit();
                        });
                    }

                    this.dirty = false;
                    return this;
                }

                updateData() {
                    var data = this.model.get().timeseries;
                    if (!data || data.length === 0 || data[0].data.length === 0) {
                        return;
                    }

                    const sheet = this.spreadSheet.sheetByIndex(0);
                    const rowsCount = (<any>sheet)._rows._count;
                    const columnsCount = (<any>sheet)._columns._count;

                    for (var row = sheet.frozenRows(), dataIndex = 0; row < rowsCount; row++ , dataIndex++) {

                        let ts = data[dataIndex];

                        for (var column = sheet.frozenColumns(), tsIndex = 0; column < columnsCount; column++ , tsIndex++) {
                            let value = sheet.range(row, column).values()[0][0];
                            ts.data[tsIndex][1] = value;
                        }
                    }

                    this.model.trigger("change:timeseries", this.model);
                    this.dirty = false;
                }


                getSpreadsheet(tsData: TimeseriesDataViewData[]): kendo.ui.SpreadsheetSheet {
                    var headerRow: kendo.ui.SpreadsheetSheetRow = { cells: [] };

                    // add title cells to header row
                    headerRow.cells.push({
                        value: Strings.TimeseriesGrid_Id,
                        fontSize: 12.8,
                        bold: true,
                        enable: false
                    }, {
                        value: Strings.TimeseriesGrid_Name,
                        fontSize: 12.8,
                        bold: true,
                        enable: false
                    }, {
                        value: Strings.TimeseriesGrid_Unit,
                        fontSize: 12.8,
                        bold: true,
                        enable: false
                    });

                    // define title columns width
                    var sheetColumns = [
                        { width: 250 },
                        { width: 250 },
                        { width: 100 }
                    ];

                    // define value columns width
                    if (tsData && tsData.length > 0) {
                        for (let i = 0; i < this.maxLengthTimeseries.cols; i++) {
                            sheetColumns.push({
                                width: 125
                            });
                        }
                    }
                    return {
                        rows: [headerRow],
                        columns: sheetColumns,
                        frozenRows: this.headerRows,
                        frozenColumns: this.headerCols
                    };
                }

                private fillRowbasedSpreadsheet(tsData: TimeseriesDataViewData[]) {

                    // display date row (first row)
                    var sheet = this.spreadSheet.sheetByIndex(0);

                    var dateValues = this.maxLengthTimeseries.timeseries.data.AsLinq().Select(tsData => new Date(tsData[0])).ToArray();
                    var dateRow = sheet.range(`${AppKitchen.SpreadsheetHelper.getColumnName(this.headerCols + 1)}1:${AppKitchen.SpreadsheetHelper.getColumnName(this.maxLengthTimeseries.timeseries.data.length + this.headerCols)}1`);
                    dateRow.values([dateValues]);
                    dateRow.bold(true);

                    var dateFormat = kendo.culture().calendar.patterns.g;
                    if (this.options.dateFormat) {
                        if (kendo.culture().calendar.patterns[this.options.dateFormat]) {
                            dateFormat = kendo.culture().calendar.patterns[this.options.dateFormat];
                        } else {
                            dateFormat = this.options.dateFormat;
                        }
                    }

                    dateRow.format(dateFormat);
                    dateRow.enable(false);

                    // display value rows
                    var columnIndex = 2;
                    tsData.forEach(ts => {
                        var values = ts.data.AsLinq().Select(tsData => tsData[1] ? tsData[1] : 0).ToArray();
                        var valuesRow = sheet.range(`A${columnIndex}:${AppKitchen.SpreadsheetHelper.getColumnName(ts.data.length + this.headerCols)}${columnIndex}`);

                        // prepend title columns to value row
                        values.unshift(ts.timeseriesId, ts.label, ts.unit);
                        
                        // set values
                        valuesRow.values([values]);
                        valuesRow.enable(this.options.editable);
                        valuesRow.color(ts.color);

                        columnIndex += 1;
                    });

                    //set styling of title columns
                    let bg = AppKitchen.UIHelper.Colors.hex2Rgb(AppKitchen.Colors.CiColor);
                    let headerRange = sheet.range(`A1:${AppKitchen.SpreadsheetHelper.getColumnName(this.headerCols)}${columnIndex}`);

                    headerRange.background(`rgba(${bg.r},${bg.g},${bg.b},.2)`);
                    headerRange.enable(false);
                }
            }
        }
    }
}