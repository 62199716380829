/// <reference path="ProviderBase.ts" />

namespace AppKitchen {

    export module Data.Provider {

        export class PasswordRestrictionInfoProvider extends ProviderBase {

            public load(): JQueryPromise<AppKitchen.Api.Models.PasswordRestrictionInfo> {
                return this.do("PasswordRestrictionInfo");
            }

            public static load(): JQueryPromise<AppKitchen.Api.Models.PasswordRestrictionInfo> {
                return new PasswordRestrictionInfoProvider().load();
            }
        }
    }
}