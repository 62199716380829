namespace AppKitchen {
    export module Controls {
        export module TimeseriesDataViews {

            export enum CellType {
                Header,
                BorderLeft,
                ThickBorderLeft,
                BorderBottom,
                Range,
                DataReadOnly,
                DataEdit,
            }

            // ReSharper disable once InconsistentNaming
            export interface TimeseriesDataSpreadsheetViewOptions extends AppKitchen.ViewBaseOptions {
                spreadsheetFormat?: "asColumns" | "asRows";
                dateFormat?: string;
                editable?: boolean;
                onEdit?: () => void;
            }

            export interface MaxLengthTimeseries {
                timeseries: TimeseriesDataViewData;
                cols: number;
                rows: number;
            }

            export abstract class TimeseriesDataSpreadsheetBase extends AppKitchen.ViewBase<TimeseriesDataViewModel> {
                options: TimeseriesDataSpreadsheetViewOptions;
                protected spreadSheet: kendo.ui.Spreadsheet;
                protected spreadsheetContainer: JQuery;
                protected dirty: boolean;
                protected updatesSuspended: boolean;
                maxLengthTimeseries: MaxLengthTimeseries;


                constructor(model: TimeseriesDataViewModel, targetContainer: HTMLElement, options?: TimeseriesDataSpreadsheetViewOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge<TimeseriesDataSpreadsheetViewOptions>(options, {
                        editable: false,
                        onEdit: () => { }
                    }));

                    this.init();
                    this.updatesSuspended = false;
                    this.model.on("change:timeseries", () => {
                        if (!this.updatesSuspended) {
                            this.spreadsheetContainer.empty();
                            this.render();
                        }
                    });
                    $(window).on("resize", () => {
                        this.resize();
                    }).resize();
                }

                /* SPREADSHEET INITIALIZATION */

                init() {
                    AppKitchen.UIHelper.renderTemplateTo(this.el, Templates.TimeseriesDataSpreadsheetView);
                    this.spreadsheetContainer = this.$el.find(".a-tssv-spreadsheet-container");
                    this.render();
                }

                abstract render(): this;

                protected checkTimeseriesDataViewData(timeseriesDataViewDataList: TimeseriesDataViewData[]): boolean {
                    let length: number = null;
                    let start: number = null;
                    let end: number = null;
                    for (let timeseriesDataViewData of timeseriesDataViewDataList) {
                        if (!timeseriesDataViewData.data || timeseriesDataViewData.data.length === 0) {
                            console.warn(`The data of the timeseries: '${timeseriesDataViewData.timeseriesId}' is empty!`);
                            return false;
                        } else if (!length || !start || ! end) {
                            length = timeseriesDataViewData.data.length;
                            start = timeseriesDataViewData.data[0][0];
                            end = timeseriesDataViewData.data[length - 1][0];
                            if (length > 50000) {
                                console.log(`The timeseries data length is: '${length}'. It could take a while to reader data.`);
                            }
                        } else {
                            if (timeseriesDataViewData.data.length !== length) {
                                console.warn(`The data of the timeseries: '${timeseriesDataViewData.timeseriesId}' has a diffenret length then the others!`);
                                return false;
                            }
                            if (timeseriesDataViewData.data[0][0] !== start) {
                                console.warn(`The start of the timeseries: '${timeseriesDataViewData.timeseriesId}' is a diffenret then the others!`);
                                return false;
                            }
                            if (timeseriesDataViewData.data[timeseriesDataViewData.data.length - 1][0] !== end) {
                                console.warn(`The end of the timeseries: '${timeseriesDataViewData.timeseriesId}' is a diffenret then the others!`);
                                return false;
                            }
                        }
                    }
                    return true;
                }

                protected getMaxLengthTimeseries(timeseries: TimeseriesDataViewData[]): MaxLengthTimeseries {
                    if (!timeseries || timeseries.length === 0) {
                        return {
                            timeseries: null,
                            cols: 0,
                            rows: 0
                        }
                    }

                    var maxLengthTs = timeseries[0];
                    for (var i = 0; i < timeseries.length; i++) {
                        if (timeseries[i].data.length > maxLengthTs.data.length) {
                            maxLengthTs = timeseries[i];
                        }
                    }
                    return {
                        timeseries: maxLengthTs,
                        cols: maxLengthTs.data.length, // data
                        rows: timeseries.length // timeseries
                    };
                }
                
                protected getValidator(): kendo.ui.SpreadsheetSheetRowCellValidation {
                    return {
                        from: "-999999999999",
                        to: "99999999999",
                        comparerType: "between",
                        type: "reject",
                        dataType: "number",
                        messageTemplate: AppKitchen.Strings.TimeseriesDataGrid_OnlyNumbers
                    };
                }

                protected getRange(tsDataList: TimeseriesDataViewData): Date[] {
                    if (!tsDataList) {
                        return [];
                    }

                    return tsDataList.data.AsLinq<number[]>().Select((e) => new Date(e[0])).ToArray();
                }

                updateModel() {
                    if (this.options.editable) {
                        this.suspendUpdates(this.updateData.bind(this));
                    }
                }

                private suspendUpdates(action: () => void) {
                    var backup = this.updatesSuspended;
                    this.updatesSuspended = true;
                    action();
                    this.updatesSuspended = backup;
                }

                abstract updateData(): void;

                abstract getSpreadsheet(tsData: TimeseriesDataViewData[]): kendo.ui.SpreadsheetSheet;

                resize() {
                    if (this.spreadSheet) {
                        this.spreadSheet.resize();
                    }
                }
            }
        }
    }
}