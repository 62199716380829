namespace AppKitchen {

    export module Controls {

        export module Wrappers {

            // ReSharper disable once InconsistentNaming
            export interface MaximizeWrapperOptions<T> {
                renderContent: (targetContainer: HTMLElement) => T;
                maximized?: (T) => void;
                restored?: (T) => void;
                undockUrl?: string;
                title?: string;
            }

            export class MaximizeWrapper<T> {
                container: JQuery;
                el: HTMLElement;
                $el: JQuery;
                options: MaximizeWrapperOptions<T>;
                renderedContent: T;

                constructor(targetContainer: HTMLElement, options: MaximizeWrapperOptions<T>) {
                    this.el = targetContainer;
                    this.$el = $(targetContainer);
                    this.options = options;

                    this.render();
                }

                render() {
                    AppKitchen.UIHelper.renderTemplateTo(this.el, AppKitchen.Templates.MaximizeWrapper);
                    var target = $(this.el).find(".a-app-wrap-content-container")[0];

                    if (!this.options.undockUrl) {
                        this.$el.find(".a-app-wrap-button-undock").hide();
                    }

                    this.renderedContent = this.options.renderContent(target);

                    this.$el.find(".a-app-wrap-button-zoom").click(this.maximize.bind(this));
                    this.$el.find(".a-app-wrap-button-undock").click(this.undock.bind(this));
                }

                undock() {
                    var url = this.options.undockUrl.indexOf("?") !== -1
                        ? this.options.undockUrl + "&undocked"
                        : this.options.undockUrl + "?undocked";

                    AppKitchen.BrowserHelper.openInWindow(url, true);
                }

                maximize() {
                    var sourceElement = $(this.el).find(".a-app-wrap-content-container");
                    var parent = sourceElement.parent();

                    sourceElement.detach();
                    var buttons = [
                                    {
                                        name: "close",
                                        action: (window) => window.close()
                                    }
                    ];

                    if (this.options.undockUrl) {
                        buttons.push(
                                {
                                    name: "undock",
                                    action: (window) => {
                                        this.undock();
                                        window.close();
                                    }
                                });
                    }

                    var windowOptions: AppKitchen.UIHelper.Windows.WindowOptions = {
                        closed: () => {
                            this.onWindowClosed(sourceElement, parent);
                        },
                        opened: () => {
                            this.onWindowOpened();
                        },
                        width: "94%",
                        height: "85%",
                        position: { top: "5%", left: "3%" },
                        animate: false,
                        buttons: buttons,
                        modal: true,
                        easyClose: true,
                        center: false,
                        title: this.options.title
                    }
                    AppKitchen.UIHelper.Windows.openWindow(sourceElement[0], windowOptions);
                }

                onWindowOpened() {
                    if (this.options.maximized) {
                        this.options.maximized(this.renderedContent);
                    }
                }

                onWindowClosed(sourceElement: JQuery, parentElement: JQuery) {
                    sourceElement.detach();
                    sourceElement.removeClass("k-window-content");
                    sourceElement.removeClass("k-content");
                    sourceElement.removeAttr("data-role");
                    parentElement.append(sourceElement);

                    if (this.options.restored) {
                        this.options.restored(this.renderedContent);
                    }
                }
            }
        }
    }
}