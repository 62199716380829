namespace AppKitchen {

    export module Controls {

        export module Grids {
            import Ui = kendo.ui;

            // ReSharper disable once InconsistentNaming
            export enum ColumnType {
                Text,
                Number,
                Date,
                Time,
                DateTime,
                Boolean
            }

            export module GridHelper {
                export var kendoFieldType = {
                    0: "string",   // ColumnType.Text,
                    1: "number",   // ColumnType.Number,
                    2: "date",     // ColumnType.Date,
                    3: "date",     // ColumnType.Time,
                    4: "date",     // ColumnType.DateTime
                    5: "boolean"   // ColumnType.Boolean
                }

                // ReSharper disable once InconsistentNaming
                export var columnFilterUI = {
                    0: undefined,        // ColumnType.Text,
                    1: undefined,        // ColumnType.Number,
                    2: "datepicker",     // ColumnType.Date,
                    3: "timepicker",     // ColumnType.Time,
                    4: "datetimepicker", // ColumnType.DateTime
                    5: undefined         // ColumnType.Boolean
                }

                export function gridColumnTemplate(type: ColumnType, fieldName: string, columnOptions: GridColumnOptions): any {
                    switch (type) {
                        case ColumnType.Text:
                            return columnOptions.multiline? rowData => (rowData[fieldName] || "").nl2br() : undefined;
                        case ColumnType.Number:
                        case ColumnType.Date:
                        case ColumnType.Time:
                        case ColumnType.DateTime:
                            return undefined;
                        case ColumnType.Boolean:
                            return rowData => rowData[fieldName] === true ? AppKitchen.Strings.Yes : (rowData[fieldName] === false ? AppKitchen.Strings.No : "");
                        default:
                            return undefined;
                    }
                }

            }

            // ReSharper disable once InconsistentNaming
            export interface GridAttributes {
                data?: {[key: string]: any}[];
                selectedData?: { [key: string]: any };
                columns?: kendo.ui.GridColumn[];
                schema?: kendo.data.DataSourceSchemaModelField[];
                loading?: boolean;
            }

            export class GridModel extends AppKitchen.ModelBase<GridAttributes> {
                options: any;

                constructor(columns: kendo.ui.GridColumn[], schema: kendo.data.DataSourceSchemaModelField[], options?: any) {
                    super({
                        data: [],
                        selectedData: undefined,
                        columns: columns,
                        schema: schema,
                        loading: false
                    });

                    this.options = options;
                }

                setGridData(data: { [field: string]: any }[], action?: (dataItem: any) => void) {
                    action = action || (dataItem => dataItem);
                    var clonedData = [];
                    data.forEach(dataItem => clonedData.push(action(jQuery.extend(true, {}, dataItem))));
                    this.set({ data: clonedData });
                }

                selectedRowData(rowData: any) {
                    this.set({ selectedData: rowData });
                }
            }

            export interface GridColumnOptions extends GridOptions {
                multiFilter?: { search: boolean };
                align?: string;
                width?: string;
                multiline?: boolean;
            }

            export interface GridOptions extends AppKitchen.ViewBaseOptions {
                fillHeight?: boolean;
                groupable?: boolean;
                editable?: boolean;
                filterable?: boolean;
                extendedFilter?: boolean;
                sortable?: boolean;
                sortMultiple?: boolean;
                selectable?: "row" | "multiple",
                toolbar?: string[];
                reorderable?: boolean;
                headerTooltip?: boolean;
                noRecordsMessage?: string;
                initialSort?: { field: string, dir: string } | { field: string, dir: "desc" | "asc" }[];
                dblclick?: (rowData: any) => void;
                lazyShow?: { initial: number, more: number };
                templates?: { field: string, template: any }[];
                uidField?: string;
                scrollable?: boolean | Object;
                dataBound?: (e: Ui.GridDataBoundEvent) => void;
                beforeCreateGrid?: (options: kendo.ui.GridOptions) => kendo.ui.GridOptions;
            }

            export class GridView extends AppKitchen.ViewBase<GridModel> {
                options: GridOptions;

                gridSelector: string;
                grid: kendo.ui.Grid;

                showingMore: boolean;
                updatingErrorSummary: boolean;

                constructor(model: GridModel, targetContainer: HTMLElement, options?: GridOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, <GridOptions>{
                        fillHeight: false,
                        groupable: false,
                        editable: false,
                        filterable: false,
                        extendedFilter: false,
                        sortable: false,
                        sortMultiple: false,
                        selectable: "row",
                        toolbar: undefined,
                        reorderable: false,
                        headerTooltip: false,
                        noRecordsMessage: undefined,
                        initialSort: undefined,
                        dblclick: undefined,
                        lazyShow: undefined,
                        uidField: undefined,
                        dataBound: undefined
                    }));

                    if (this.options.fillHeight) {
                        this.setTemplate('<div class="a-grid full-height-grid"></div>');
                    } else {
                        this.setTemplate('<div class="a-grid"></div>');
                    }

                    this.gridSelector = '.a-grid';

                    this.model.bind("change:data", this.updateGridData, this);
                    this.renderGrid();
                }

                protected updateGridData() {
                    this.setGridData(this.model.get().data);
                }

                private suspendDataBinding(action: () => void) {
                    var preventDefault = e => e.preventDefault();
                    this.grid.bind("dataBinding", preventDefault);
                    action();
                    this.grid.unbind("dataBinding", preventDefault);
                }

                protected setGridData(data: any[]) {
                    if (this.grid) {
                        // remember current selection
                        var uids: string[] = [];
                        var scrollTop = this.grid.content[0].scrollTop;
                        if (this.options.uidField) {
                            var selection = this.grid.select();
                            for (let j = 0; j < selection.length; j++) {
                                uids.push(this.grid.dataItem(selection[j]).get(this.options.uidField));
                            }
                            //maybe hash code would be better but a) throws exception 'o.Plain() is not a function' and b) member uid would have to be omitted
                            //var hash: number = Object.GetHashCode(this.grid.dataItem(selection[0])); //-> o.Plain() is not a function
                        }

                        if (this.options.filterable) {
                            // must call setDataSource to update kendo column filters

                            // set data without updating the grid
                            this.suspendDataBinding(() => {
                                this.grid.dataSource.data(data);
                            });
                            // call setDataSource on same data source
                            this.grid.setDataSource(this.grid.dataSource); 

                        } else {
                            // faster update if no setDataSource() call needed
                            this.grid.dataSource.data(data);
                        }

                        // reset page size
                        if (this.options.lazyShow) {
                            this.grid.dataSource.pageSize(this.options.lazyShow.initial);
                            this.updateLazyShow();
                        }

                        // restore selection
                        if (uids.length > 0) {
                            var rows = [];
                            var datas = this.grid.dataSource.data();
                            for (let u = 0; u < uids.length; u++) {
                                for (let d = 0; d < datas.length; d++) {
                                    var dataRow = datas[d];
                                    if (dataRow.get(this.options.uidField) === uids[u]) {
                                        rows.push("tr[data-uid='" + dataRow.uid + "']");
                                    }
                                }
                            }
                            for (let i = 0; i < rows.length; i++) {
                                this.grid.select(rows[i]);
                            }
                            this.grid.content.scrollTop(scrollTop);
                        }
                    }
                }

                updateModelData() {
                    this.model.unbind("change:data", this.updateGridData);
                    this.model.set({ data: this.grid.dataSource.data().toJSON() });
                    this.model.bind("change:data", this.updateGridData, this);
                }

                renderGrid() {
                    this.renderTemplate({});
                    var $gridContainer = this.$el.find(this.gridSelector);
                    var schema = this.model.get().schema;

                    var gridFilter: kendo.ui.GridFilterable | boolean = false;
                    if (this.options.filterable) {
                        gridFilter = this.options.extendedFilter ? true : {
                            extra: false, // simple filter
                            operators: { // filter string operators
                                string: {
                                    contains: (<any>kendo.ui).FilterMenu.prototype.options.operators.string.contains,
                                    doesnotcontain: (<any>kendo.ui).FilterMenu.prototype.options.operators.string.doesnotcontain,
                                    startswith: (<any>kendo.ui).FilterMenu.prototype.options.operators.string.startswith,
                                    eq: (<any>kendo.ui).FilterMenu.prototype.options.operators.string.eq
                                }
                            }
                        };
                    }

                    var sortMode = this.options.sortMultiple ? "multiple" : "single";

                    let options: kendo.ui.GridOptions = {
                        //theme: "metro",
                        dataSource: {
                            data: this.model.get().data,
                            pageSize: this.options.lazyShow ? this.options.lazyShow.initial : undefined,
                            schema: {
                                model: {
                                    fields: schema
                                }
                            },
                            sort: this.options.initialSort
                        },
                        filterable: gridFilter,
                        editable: this.options.editable,
                        change: e => this.onSelectionChange(e),
                        sortable: this.options.sortable ? { mode: sortMode, allowUnsort: false} : false,
                        allowCopy: true,
                        selectable: this.options.selectable,
                        navigatable: true,
                        resizable: true,
                        groupable: this.options.groupable,
                        reorderable: this.options.reorderable,
                        toolbar: this.options.toolbar
                            ? GridView.convertToKendoGridToolbarItems(this.options.toolbar)
                            : undefined,
                        columns: this.getColumns(),
                        noRecords: true,
                        messages: {
                            noRecords: this.options.noRecordsMessage
                        },
                        dataBound: this.options.dataBound !== undefined
                            ? (e) => { this.options.dataBound(e); this.dataBound(e) }
                            : (e) => this.dataBound(e),
                        save: e => this.onSave(e),
                        scrollable: this.options.scrollable
                    };

                    if (this.options.beforeCreateGrid) {
                        options = this.options.beforeCreateGrid(options);
                    }

                    this.grid = $gridContainer.kendoGrid(options).data("kendoGrid");


                    if (this.options.headerTooltip) {
                        this.grid.wrapper.find("th")
                            .each((i, e) => {
                                if ($(e).find(".k-link").length > 0) {
                                    $(e).attr("title", $(e).find(".k-link").text());
                                } else {
                                    $(e).attr("title", $(e).text());
                                }
                                $(e).kendoTooltip({ position: "top" });

                                $(e).bind("mouseleave", () => {
                                     $(e).data("kendoTooltip").hide();
                                });
                        });
                    }

                    if (this.options.lazyShow) {
                        this.startLazyShow();
                    }

                    if (this.options.editable) {
                        this.grid.dataSource.bind("change", e => {
                            if (e.action === "remove") {
                                if (e.items.length > 1 || e.items[0]["GridInputErrors"]) {
                                    this.updateErrorSummaryDelayed();
                                }
                            }
                        });
                    }

                    // keyboard navigation
                    this.grid.table.keydown((e) => {
                        // arrow up/down
                        if ([38, 40].indexOf(e.keyCode) >= 0) {
                            setTimeout(() => this.grid.select($("td#aria_active_cell").closest("tr")), 10);
                        }
                        // page up/down
                        if ([33, 34].indexOf(e.keyCode) >= 0) {
                            // TODO: page up/down navigation in grid (not only scrolling)
                            //e.preventDefault();
                        }
                        // home
                        if (e.keyCode === 36) {
                            // TODO: HOME navigation (row selected AND cell active)
                        }
                        // home
                        if (e.keyCode === 35) {
                            // TODO: END navigation (row selected AND cell active)
                        }
                    });

                    if (this.options.dblclick) {
                        this.grid.table.dblclick((e) => {
                            if (!$(e.target).closest("tr").hasClass("k-grouping-row")) {
                                var rowData = this.model.get().selectedData;
                                if (rowData) this.options.dblclick(rowData);
                            }
                        });
                    }

                    this.updateFullHeight();
                }

                private static convertToKendoGridToolbarItems(items: string[]): kendo.ui.GridToolbarItem[] {
                    let result: kendo.ui.GridToolbarItem[] = [];
                    for (var item of items) {
                        //TODO: Check string[] compatibility: https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/toolbar
                        result.push({ name: item });
                    }
                    return result;
                }

                private updateFullHeight() {
                    if (this.options.fillHeight) {
                        var $gridContainer = this.$el.find(this.gridSelector);
                        $gridContainer.addClass("full-height-grid");
                        $gridContainer.find(".k-grid-content").addClass("fill-height");
                        $gridContainer.find(".k-grid-content").css("overflow-y", "scroll");
                        AppKitchen.UIHelper.updateFullHeightGrids(this.el);
                    }
                }

                private startLazyShow() {
                    if (this.grid) {
                        var scroller = this.grid.wrapper.find(".k-grid-content");
                        scroller.on("scroll", () => this.checkLazyShow(scroller));
                    }
                }

                private updateLazyShow() {
                    if (this.grid && this.options.lazyShow) {
                        var container = this.grid.wrapper.find(".k-grid-content");
                        container.find(".a-lazyloader-wrapper").remove();
                        
                        if (this.grid.dataSource.totalPages() > 1) {
                            this.allowGroupCollapse(false);
                            this.checkLazyShow(container);
                        } else {
                            this.allowGroupCollapse(true);
                        }
                    }
                }

                private checkLazyShow(container: JQuery, showAll?: boolean) {
                    if (!this.showingMore &&
                        this.grid &&
                        this.grid.dataSource.totalPages() > 1 &&
                        container.find("> table").height() - container.height() - container.scrollTop() < 1) {

                        container.append('<div class="a-lazyloader-wrapper"><div class="a-lazyloader"></div></div>');

                        AppKitchen.UIHelper.renderLoader(container.find(".a-lazyloader")[0], { type: "concentric" });

                        this.showingMore = true;
                        setTimeout(() => this.showMore(showAll), 300);
                    }
                }

                private showMore(showAll?: boolean) {
                    if (this.grid && this.options.lazyShow) {
                        // backup grid selection and scroll position
                        var selectedIndex = this.grid.select().index();
                        var scrollPosition = this.grid.wrapper.find(".k-grid-content").scrollTop();

                        // increase page size
                        this.grid.dataSource.pageSize(showAll ? this.grid.dataSource.total() : this.grid.dataSource.pageSize() + this.options.lazyShow.more);

                        // restore grid selection and scroll position
                        this.selectRow(selectedIndex);
                        this.grid.wrapper.find(".k-grid-content").scrollTop(scrollPosition);
                    }

                    this.showingMore = false;
                }

                private allowGroupCollapse(allow: boolean) {
                    if (this.grid) {
                        (<any>this.grid.options).allowGroupCollapse = allow;
                        if (allow) {
                            this.grid.wrapper.removeClass("no-collapse");
                        } else {
                            this.grid.wrapper.addClass("no-collapse");
                        }
                    }
                }

                private onSave(e) {
                    if (this.options.editable) {
                        var rowIndex = $("tr", this.grid.tbody).index($(e.container).closest("tr"));
                        setTimeout(() => {
                            this.setErrorMessage(rowIndex, undefined);
                        }, 100);
                    }
                }

                private onSelectionChange(e) {
                    var selectedRows = this.grid.select();
                    if (selectedRows.length > 0) {
                        this.model.selectedRowData(this.grid.dataItem(selectedRows[0]));
                    } else {
                        this.model.selectedRowData(null);
                    }
                }

                private inputErrorTemplate(rowData) {
                    if (rowData.GridInputErrors) {
                        return AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.ErrorInfo16x16, { info: rowData.GridInputErrors });
                    }
                    return "";
                }

                protected dataBound(e) {
                    this.updateLazyShow();
                    if (this.grid && this.options.filterable && this.grid.dataSource.filter()) {
                        var numberOfFilters = this.grid.dataSource.filter().filters.length;
                        if (numberOfFilters > 0) {
                            var noRecordsText = this.grid.wrapper.find(".k-grid-norecords-template").text();
                            this.grid.wrapper.find(".k-grid-norecords-template").text(noRecordsText + " (" + numberOfFilters + " " + AppKitchen.Strings.Grid_ActiveFilters + ")");
                        }
                    }
                }

                private getColumns() {
                    var columns: kendo.ui.GridColumn[] = (this.model.get().columns || []).slice();

                    if (this.options.editable) {
                        columns.push({ command: <any>"destroy", title: "&nbsp;", width: 31 });
                        columns.unshift({ field: "GridInputErrors", title: "&nbsp;", width: 24, template: this.inputErrorTemplate, editor: () => { } });

                        this.$el.kendoTooltip({
                            position: "right",
                            filter: ".a-error-info-16x16",
                            content: e => '<div class="a-grid-error-tooltip">' + $(e.target).find(".a-tooltip-content").html() + '</div>'
                        });

                        this.$el.kendoTooltip({
                            position: "bottom",
                            filter: ".a-error-info-24x24",
                            content: e => '<div class="a-grid-error-summary-tooltip">' + $(e.target).find(".a-tooltip-content").html() + '</div>'
                        });
                    }

                    columns.forEach(column => {
                        if (!column.groupHeaderTemplate) {
                            column.groupHeaderTemplate = this.getDefaultGroupHeaderTemplate(column);
                        }
                    });

                    if (this.options.templates) {
                        columns.forEach(column => {
                            for (let t of this.options.templates) {
                                if (column.field === t.field) {
                                    column.template = t.template;
                                }
                            }
                        });
                    }

                    return columns;
                }

                getDefaultGroupHeaderTemplate(column: kendo.ui.GridColumn): string {
                    return column.title + ": <b>" + "#= value #" + '</b>';
                }

                switchToPreview() {
                    this.grid.setOptions({ editable: false });
                    this.grid.wrapper.find(".k-grid-footer").remove();

                    if (this.options.editable) {
                        // hide toolbar
                        this.$el.find(".k-grid-toolbar").hide();
                        // hide errors and delete column (first and last)
                        this.grid.hideColumn(0);
                        this.grid.hideColumn(this.grid.columns.length - 1);
                    }

                    this.updateFullHeight();
                    this.startLazyShow();
                }

                backToEdit() {
                    if (this.options.editable) {
                        this.grid.setOptions({ editable: true });
                        this.grid.wrapper.find(".k-grid-footer").remove();

                        // show toolbar
                        this.$el.find(".k-grid-toolbar").show();
                        // show errors and delete column (first and last)
                        this.grid.showColumn(0);
                        this.grid.showColumn(this.grid.columns.length - 1);

                        this.updateFullHeight();
                        this.startLazyShow();
                    }
                }

                validateInput(): boolean {
                    var valid = true;

                    if (this.options.editable) {
                        var columnTitles = {};
                        var columns = this.model.get().columns;
                        for (let i = 0; i < columns.length; i++) {
                            columnTitles[columns[i].field] = columns[i].title;
                        }

                        // required fields validation
                        var requiredFields = [];
                        var fields = this.model.get().schema;
                        for (let i = 0; i < fields.length; i++) {
                            if (fields[i].validation && fields[i].validation.required) {
                                requiredFields.push(fields[i].field);
                            }
                        }

                        var gridData = this.grid.dataSource.data().toJSON();
                        for (let i = 0; i < gridData.length; i++) {
                            var missedFields = [];
                            for (let j = 0; j < requiredFields.length; j++) {
                                if (gridData[i][requiredFields[j]] === undefined ||
                                    gridData[i][requiredFields[j]] === null ||
                                    gridData[i][requiredFields[j]] === "") {
                                    missedFields.push(columnTitles[requiredFields[j]]);
                                }
                            }

                            if (missedFields.length > 0) {
                                this.setErrorMessage(i, "Pflichtfelder nicht ausgefüllt: " + missedFields.join(", "));
                                valid = false;
                            }
                        }
                    }

                    return valid;
                }

                setErrorMessage(index: number, message: string) {
                    var dataItem = this.grid.dataItem("tr:eq(" + (index + 1) + ")");
                    if (dataItem) {
                        dataItem.set("GridInputErrors", message);
                        this.updateErrorSummaryDelayed();
                    }
                }

                private updateErrorSummaryDelayed() {
                    if (!this.updatingErrorSummary) {
                        this.updatingErrorSummary = true;
                        setTimeout(() => {
                            this.updateErrorSummary();
                            this.updatingErrorSummary = false;
                        }, 100);
                    }
                }

                clearGrid() {
                    if (this.grid && this.options.editable) {
                        this.setGridData([]);
                        this.updateErrorSummary();
                    }
                }

                private updateErrorSummary() {
                    if (this.grid) {
                        var errors = [];
                        var gridData = this.grid.dataSource.data();
                        for (let i = 0; i < gridData.length; i++) {
                            if (gridData[i]["GridInputErrors"]) {
                                errors.push({
                                    row: i + 1,
                                    message: gridData[i]["GridInputErrors"]
                                });
                            }
                        }

                        this.grid.wrapper.find(".k-grid-toolbar .a-grid-error-summary").remove();

                        if (errors.length > 0) {
                            this.grid.wrapper.find(".k-grid-toolbar").append('<div class="a-grid-error-summary"></div>');

                            AppKitchen.UIHelper.renderTemplateTo(this.grid.wrapper.find(".k-grid-toolbar .a-grid-error-summary")[0], AppKitchen.Templates.ErrorInfo24x24, {
                                info: AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.GridErrorSummary, { errors: errors })
                            });
                        }
                    }
                }

                selectRow(index: number, scroll?: boolean) {
                    index = Math.max(0, Math.round(index));
                    if (this.grid) {
                        this.focusElement(this.grid.wrapper.find("tr:eq(" + (index + 1) + ") td:visible").first(), scroll, true);
                        this.grid.select("tr:eq(" + index + ")");
                    }
                }
                /**
                 * inserts row at current selection
                 */
                insertRow(data: any) {
                    if (this.grid && this.options.editable) {
                        var selectedIndex = this.grid.select().index() || 0;
                        var scrollPosition = this.grid.wrapper.find(".k-grid-content").scrollTop();

                        var insertedRow = this.grid.dataSource.insert(selectedIndex + 1, data);

                        this.grid.select('tr[data-uid="' + insertedRow.uid + '"]');

                        this.grid.wrapper.find(".k-grid-content").scrollTop(scrollPosition);
                        this.selectRow(this.grid.select().index(), true);
                    }
                }

                focusGrid() {
                    var scrollPosition = this.grid.wrapper.find(".k-grid-content").scrollTop();
                    this.grid.wrapper.find("table").focus();
                    this.grid.wrapper.find(".k-grid-content").scrollTop(scrollPosition);
                }

                private focusElement(element: JQuery, scroll?: boolean, trigger?: boolean) {
                    // this method is a modified version of the internal kendo method _setCurrent() in kendo.grid.js
                    // the functionionalty is not guaranteed after kendo update
                    if (this.grid) {
                        var grid: any = this.grid;
                        var current = grid._current;
                        element = $(element);
                        if (element.length) {
                            if (!current || current[0] !== element[0]) {
                                grid._updateCurrentAttr(current, element);
                                if (scroll) {
                                    grid._scrollCurrent();
                                }
                                if (trigger) {
                                    grid.trigger("navigate", { element: element });
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}
