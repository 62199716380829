///<reference path="Fields/Fields.ts"/>

namespace AppKitchen {

    export module Controls {

        export module Forms {

            // ReSharper disable once InconsistentNaming
            export interface FormOptions {
                keyPrefix?: string;
            }

            // ReSharper disable once InconsistentNaming
            export interface FormAttributes extends AppKitchen.ModelBaseAttributes {
                fields?: FieldCollection;
                hasData?: boolean;
            }

            export class FormModel extends AppKitchen.ModelBase<FormAttributes> {
                fieldDict: {[key: string]: FieldModel};
                options: FormOptions;
                submitCallback: (submitOptions: FormSubmitOptions) => void;
                /**
                 * @param {FieldModel[]} fields - List of FieldModels representing the Form Fields
                 * @param {FormOptions} options? - Form options
                 */
                constructor(fields: FieldModel[], options?: FormOptions) {
                    super({
                        fields: new FieldCollection(fields),
                        hasData: false,
                        loading: false
                    });

                    this.options = AppKitchen.OptionsHelper.merge<FormOptions>(options, {
                        keyPrefix: ""
                    });

                    this.bind("change:fields", () => this.updateFieldDict());
                    this.get().fields.bind("add remove", () => this.updateFieldDict());
                    this.updateFieldDict();
                }

                private updateFieldDict() {
                    var dict: { [key: string]: FieldModel } = {};
                    this.get().fields.each(field => dict[field.get().key] = field);
                    this.fieldDict = dict;
                }

                /**
                 * Fills the Fields with data and sets 'hasData' attribute to true
                 * @param {{ [key: string]: any }} data - New Fields data as Object/Dictionary
                 * @param {boolean} merge? - Merge input data with the existing Fields data. If set to false, old data will be overridden or emptied
                 */
                setFieldsData(data: { [key: string]: any }, merge?: boolean) {
                    if (!data)
                        return;

                    this.get().fields.each(field => {
                        if (data.hasOwnProperty(field.get().key) || !merge) {
                            field.set({ value: data[field.get().key] });
                        }
                    });

                    this.set({ hasData: true });
                }

                /**
                 * Clear the Fields data and sets 'hasData' to false
                 */
                clearFieldsData() {
                    this.set({ hasData: false });

                    this.get().fields.each(field => {
                        field.set({ value: undefined});
                    });
                }

                /**
                 * Gets the Fields data as Object/Dictionary
                 * @param {boolean} skipEmpty? - Skip empty Fields or return them with actual undefined/null values
                 */
                getFieldsData(skipEmpty?: boolean): { [key: string]: any } {
                    var data = {};
                    this.get().fields.each(field => {
                        if (field.get().value != null || !skipEmpty) {
                            data[field.get().key] = field.get().value;
                        }
                    });
                    return data;
                }

                /**
                 * Submits the Form. To be overridden in derivates
                 * @param {FormSubmitOptions} submitOptions - Form submit options
                 */
                submit(submitOptions: FormSubmitOptions) {
                    if (this.submitCallback) {
                        this.submitCallback(submitOptions);
                    }
                }

                /**
                 * Gets single Field property
                 * @param {string} field - Field key
                 * @param {string} property - Property name
                 * @returns
                 */
                getProperty(field: string, property: string): any {
                    return this.getField(field).get()[property];
                }

                /**
                 * Sets single Field property
                 * @param {string} field - Field key
                 * @param {string} property - Property name
                 * @param {any} value - Property value
                 */
                setProperty(field: string, property: string, value: any): void {
                    this.getField(field).set({ [property]: value });
                }

                /**
                 * Binds to single Field property change
                 * @param {string} field - Field key
                 * @param {string} property - Property name
                 * @param {Function} callback - Callback action
                 */
                onPropertyChange(field: string, property: string, callback: (value: any, previous?: any) => void, trigger?: boolean) {
                    var fieldModel = this.getField(field);
                    if (trigger) {
                        callback(fieldModel.get()[property], fieldModel.get()[property]);
                    }
                    fieldModel.bind("change:" + property, field => {
                        callback(field.get()[property], field.getPrevious()[property]);
                    });
                }

                /**
                 * Binds to property change on all Fields
                 * @param {string} property - Property name
                 * @param {Function} callback - Callback action
                 */
                onAnyPropertyChange(property: string, callback: (field: FieldModel, value?: any, previous?: any) => void) {
                    this.get().fields.bind("change:" + property, field => {
                        callback(field, field.get()[property], field.getPrevious()[property]);
                    });
                }

                onSubmit(callback: (submitOptions: FormSubmitOptions) => void) {
                    this.submitCallback = callback;
                }

                /**
                 * Gets a Field Model by its key
                 * @param {string} key - Field key
                 * @returns
                 */
                getField(key: string): FieldModel {
                    var fieldKey = this.options.keyPrefix + key;
                    if (this.fieldDict[fieldKey]) {
                        return this.fieldDict[fieldKey];
                    } else {
                        throw "Unknown field: " + fieldKey;
                    }
                }

                /**
                 * Safe method to get Field Model. Returns the Field Model if exists, otherwise undefined
                 * @param {string} key - Field key
                 * @returns 
                 */
                tryGetField(key: string): FieldModel{
                    var fieldKey = this.options.keyPrefix + key;
                    return this.fieldDict[fieldKey];
                }

                /**
                 * Gets single Field value/data
                 * @param {string} field - Field key
                 * @returns
                 */
                getValue(field: string) {
                    return this.getProperty(field, "value");
                }

                /**
                 * Sets single Field value/data
                 * @param {string} field - Field key
                 * @param {any} value - New value
                 */
                setValue(field: string, value: any) {
                    this.setProperty(field, "value", value);
                }

                /**
                 * Binds to single Field value change
                 * @param {string} field - Field key
                 * @param {Function} callback - Callback action
                 */
                onValueChange(field: string, callback: (value: any, previous?: any) => void, trigger?: boolean) {
                    this.onPropertyChange(field, "value", callback, trigger);
                }

                /**
                 * Binds to value change on all Fields
                 * @param {string} field - Field key
                 * @param {Function} callback - Callback action
                 */
                onAnyValueChange(callback: (field: FieldModel, value?: any, previous?: any) => void) {
                    this.onAnyPropertyChange("value", callback);
                }

                /**
                 * Disables single Field (sets property 'disabled' to true)
                 * @param {string} field - Field key
                 */
                disableField(field: string) {
                    this.setProperty(field, "disabled", true);
                }

                /**
                 * Enables single Field (sets property 'disabled' to false)
                 * @param {string} field - Field key
                 */
                enableField(field: string) {
                    this.setProperty(field, "disabled", false);
                }

                /**
                 * Triggers value change event on single Field or all Fields
                 * @param {string} field? - Optional single Field key
                 */
                triggerValueChange(field?: string) {
                    this.triggerPropertyChange("value", field);
                }

                /**
                 * Triggers property change on single Field or all Fields
                 * @param {string} property - Property name
                 * @param {string} field? - Optional single Field key
                 */
                triggerPropertyChange(property: string, field?: string) {
                    if (field) {
                        var fieldModel = this.getField(field);
                        fieldModel.trigger("change:" + property, fieldModel);
                    } else {
                        this.get().fields.each(field => field.trigger("change:" + property, field) );
                    }
                }
            }
            
            // ReSharper disable once InconsistentNaming
            export interface FormSubmitOptions {
                api?: string;
                parse?: (data: { [key: string]: any }) => void;
                success?: () => void;
                error?: (errorText: string) => void;
            }

            // ReSharper disable once InconsistentNaming
            export interface FormViewBaseOptions extends AppKitchen.ViewBaseOptions {
                editable: boolean;
                editOnDemand?: boolean;
                submit?: FormSubmitOptions;
            }

            export abstract class FormViewBase extends AppKitchen.ViewBase<FormModel> {
                options: FormViewBaseOptions;

                fieldViews: FieldViewBase[];
                fieldViewDict: { [id: string]: FieldViewBase };

                constructor(model: FormModel, targetContainer: HTMLElement, options?: FormViewBaseOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {
                        editable: false,
                        editOnDemand: false
                    }));

                    this.setTemplate('<div class="a-form"></div>');

                    this.delegateEvents({
                        'click .a-btn-preview': 'preview',
                        'click .a-btn-back': 'backToEdit',
                        'click .a-btn-submit': 'submit'
                    });

                    this.$el.addClass("a-form");

                    this.$el.toggleClass("a-form-loading", this.model.get().loading);
                    this.model.bind("change:loading", () => {
                        this.$el.toggleClass("a-form-loading", this.model.get().loading);
                    });

                    this.$el.toggleClass("a-form-has-data", this.model.get().hasData);
                    this.model.bind("change:hasData", () => {
                        this.$el.toggleClass("a-form-has-data", this.model.get().hasData);
                    });

                    this.model.bind("change:fields", () => this.render());
                }

                /**
                 * Renders the Form View
                 */
                render() {
                    this.createFields();

                    this.$el.removeClass("a-form-ondemand");
                    this.$el.removeClass("a-form-edit");
                    this.$el.removeClass("a-form-view");
                    this.$el.removeClass("a-form-preview");

                    if (this.options.editable) {
                        if (this.options.editOnDemand) {
                            this.$el.addClass("a-form-ondemand");
                        } else {
                            this.$el.addClass("a-form-edit");
                        }
                    } else {
                        this.$el.addClass("a-form-view");
                    }

                    return this;
                }

                private createFields() {
                    this.fieldViews = [];
                    this.fieldViewDict = {};
                    this.model.get().fields.each(field => this.renderField(field));
                }

                private renderField(field: FieldModel) {
                    var viewClass = this.getFieldViewClass(field.get().type);
                    // ReSharper disable once InconsistentNaming
                    var fieldView = new viewClass(field, { editable: this.options.editable, editOnDemand: this.options.editOnDemand });
                    this.fieldViews.push(fieldView);
                    this.fieldViewDict[fieldView.model.get().key] = fieldView;
                    return fieldView.render();
                }

                private getFieldViewClass(type: FieldType): any {
                    switch (type) {
                        case FieldType.Text:
                            return TextFieldView;
                        case FieldType.Number:
                            return NumberFieldView;
                        case FieldType.Month:
                        case FieldType.Year:
                        case FieldType.Date:
                        case FieldType.Time:
                        case FieldType.DateTime:
                            return DateTimeFieldView;
                        case FieldType.Checkbox:
                            return CheckboxFieldView;
                        default:
                            throw `unknown field type '${type}'!`;
                    }
                }

                /**
                 * Gets a single Field View by key
                 * @param {string} key - Field key
                 * @returns
                 */
                getFieldView(key: string): FieldViewBase {
                    var fieldKey = this.model.options.keyPrefix + key;
                    if (this.fieldViewDict[fieldKey]) {
                        return this.fieldViewDict[fieldKey];
                    } else {
                        throw "Unknown field: " + fieldKey;
                    }
                }

                /**
                 * Safe method to get Field View. Returns the Field View if exists, otherwise undefined
                 * @param {string} key - Field key
                 * @returns
                 */
                tryGetFieldView(key: string): FieldViewBase {
                    var fieldKey = this.model.options.keyPrefix + key;
                    return this.fieldViewDict[fieldKey];
                }

                /**
                 * Validates the input on all Fields and returns true if validation succeeded
                 * @returns
                 */
                validateFields(): boolean {
                    var valid = true;
                    this.fieldViews.forEach(view => {
                        if (!view.tryUpdate()) {
                            valid = false;
                            console.log("Field validation failed for: " + view.model.get().key);
                        }
                    });
                    return valid;
                }

                /**
                 * Switches an editable Form to preview mode
                 */
                preview() {
                    if (this.validateFields()) {
                        this.switchToPreview();
                    }
                }

                protected switchToPreview() {
                    this.fieldViews.forEach(view => view.preview());

                    this.$el.removeClass("a-form-edit");
                    this.$el.addClass("a-form-preview");
                }

                /**
                 * Switches an editable Form back to edit mode
                 */
                backToEdit() {
                    this.fieldViews.forEach(view => view.backToEdit());

                    this.$el.removeClass("a-form-preview");
                    this.$el.addClass("a-form-edit");
                }

                /**
                 * Disables the Form Buttons
                 */
                disableButtons() {
                    this.$el.find(".a-form-btn").prop("disabled", true);
                }

                /**
                 * Enables the Form Buttons
                 */
                enableButtons() {
                    this.$el.find(".a-form-btn").prop("disabled", false);
                }

                /**
                 * Validates the input and submits the Form Model
                 */
                submit() {
                    if (this.options.submit && this.validateFields()) {
                        this.model.submit(this.options.submit);
                    }
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface TemplatedFormViewOptions extends FormViewBaseOptions {
                inputIndex?: number;
                useExistingContent?: boolean;
                renderOnlyInputs?: boolean;
            }

            /**
             * Creates a Form View using a custom HTML template
             */
            export class TemplatedFormView extends FormViewBase {
                options: TemplatedFormViewOptions;

                /**
                 * @param {FormModel} model - Form Model
                 * @param {HTMLElement} targetContainer - Target HTML container
                 * @param {string} template - Form HTML template
                 * @param {TemplatedFormViewOptions} options? - Form View options
                 */
                constructor(model: FormModel, targetContainer: HTMLElement, template: string, options?: TemplatedFormViewOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge<TemplatedFormViewOptions>(options, {
                        editable: false,
                        inputIndex: null,
                        useExistingContent: false,
                        renderOnlyInputs: false
                    }));

                    if (!this.options.useExistingContent) {
                        this.setTemplate(template);
                    }

                    this.render();
                }

                /**
                 * Renders the Form View
                 */
                render() {
                    super.render();

                    // render form template
                    if (!this.options.useExistingContent) {
                        this.renderTemplate({});
                    }

                    var me = this;

                    if (!this.options.renderOnlyInputs) {
                        // render labels & descriptions
                        var labelAttr = "data-field-label" + (this.options.inputIndex != null ? ("-" + this.options.inputIndex) : "");
                        this.$el.find("[" + labelAttr + "]").each((index, element) => {
                            var key = $(element).attr(labelAttr);
                            if (me.fieldViewDict[key]) {
                                $(element).empty();

                                $(element).append(AppKitchen.UIHelper.renderTemplateTo($("<div></div>")[0], AppKitchen.Templates.FormFieldInfo, {
                                    info: me.fieldViewDict[key].model.get().description
                                }).html());

                                var label = me.fieldViewDict[key].model.get().label || "";
                                $(element).append(label.trim());
                            }
                        });
                    }

                    // render inputs
                    var inputAttr = "data-field-input" + (this.options.inputIndex != null ? ("-" + this.options.inputIndex) : "");
                    this.$el.find("[" + inputAttr + "]").each((index, element) => {
                        var key = $(element).attr(inputAttr);
                        if (me.fieldViewDict[key]) {
                            $(element).empty();
                            $(element).append(me.fieldViewDict[key].$el);
                        }
                    });

                    return this;
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface TabularFormViewOptions extends FormViewBaseOptions {
                showFieldNumber?: boolean;
                showGroupRow?: boolean;
                showButtons?: boolean;
            }

            /**
             * Creates a simple tabular Form View
             */
            export class TabularFormView extends FormViewBase {
                options: TabularFormViewOptions;

                /**
                 * @param {FormModel} model - Form Model
                 * @param {HTMLElement} targetContainer - Target HTML container
                 * @param {TabularFormViewOptions} options? - Form View options
                 */
                constructor(model: FormModel, targetContainer: HTMLElement, options?: TabularFormViewOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge(options,{
                        editable: false,
                        showFieldNumber: false,
                        showGroupRow: true,
                        showButtons: true
                    }));
                    this.setTemplate(AppKitchen.Templates.TabularForm);
                    this.render();
                }

                protected getGroupName(groupId: string) {
                    return groupId;
                }

                /**
                 * Renders the Form View
                 */
                render() {
                    super.render();

                    this.renderTemplate({
                        showFieldNumber: this.options.showFieldNumber,
                        showButtons: this.options.showButtons
                    });

                    var table = this.$el.find("table");

                    var fields = this.model.get().fields.models;

                    if (!fields || fields.length === 0) {
                        return this;
                    }

                    var groupHeaderTemplate = _.template(AppKitchen.Templates.TabularFormGroupRow);
                    var currentGroupId = fields[0].get().fieldGroup;

                    var showGroups = this.options.showGroupRow && currentGroupId ? true : false;

                    if (showGroups) {
                        table.append(groupHeaderTemplate({ groupName: this.getGroupName(currentGroupId), showFieldNumber: this.options.showFieldNumber}));
                    }

                    for (let i = 0; i < fields.length; i++) {
                        if (fields[i].get().hidden) {
                            continue;
                        }

                        // render grouping row
                        if (showGroups && currentGroupId !== fields[i].get().fieldGroup) {
                            currentGroupId = fields[i].get().fieldGroup;
                            table.append(groupHeaderTemplate({ groupName: this.getGroupName(currentGroupId) || "&nbsp", showFieldNumber: this.options.showFieldNumber }));
                        }

                        // prepare row number
                        var rowNumber = { rowspan: 0, number: fields[i].get().order };
                        if (this.options.showFieldNumber) {
                            if (i <= 0 || fields[i].get().order !== fields[i - 1].get().order) {
                                let rowSpan = 1;
                                while (i + rowSpan < fields.length && fields[i].get().order === fields[i + rowSpan].get().order) {
                                    rowSpan++;
                                }
                                rowNumber.rowspan = rowSpan;
                            }
                        }

                        // render field row
                        var row = AppKitchen.UIHelper.renderTemplateTo($("<tr></tr>")[0], AppKitchen.Templates.TabularFormRow, {
                            label: fields[i].get().label,
                            description: fields[i].get().description,
                            numberrowspan: rowNumber.rowspan,
                            number: rowNumber.number
                        });
                        row.find(".a-form-field-value").append(this.fieldViewDict[fields[i].get().key].el);

                        // append to table
                        table.append(row);
                    }

                    return this;
                }
            }

            // ReSharper disable once InconsistentNaming
            export interface HorizontalTabularFormViewOptions extends FormViewBaseOptions {
                submitIcon?: string;
                submitTooltip?: string;
                showButtons?: boolean;
                showTooltips?: boolean;
                showLabels?: boolean;
            }

            export class HorizontalTabularFormView extends FormViewBase {
                options: HorizontalTabularFormViewOptions;

                constructor(model: FormModel, targetContainer: HTMLElement, options?: HorizontalTabularFormViewOptions) {
                    super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {
                        editable: false,
                        submitIcon: "icon-search",
                        submitTooltip: "Search",
                        showButtons: false,
                        showTooltips: false,
                        showLabels: true
                    }));

                    this.setTemplate(AppKitchen.Templates.HorizontalTabularForm);

                    this.render();
                }

                private getFieldModelData(): { id: string, label: string }[] {
                    var fields = this.model.get().fields.models;

                    var fieldData: { id: string, label: string }[] = [];

                    for (let field of fields) {
                        fieldData.push({ id: field.get().key, label: field.get().label });
                    }

                    return fieldData;
                }

                render() {
                    super.render();

                    this.renderTemplate(
                        {
                            fieldData: this.getFieldModelData(),
                            showButtons: this.options.showButtons,
                            showLabels: this.options.showLabels,
                            showTooltips: this.options.showTooltips,
                            submitIcon: this.options.submitIcon,
                            submitTooltip: this.options.submitTooltip
                        });

                    var fieldModels = this.model.get().fields.models;

                    for (let fieldModel of fieldModels) {
                        $(this.el).find("#" + fieldModel.get().key).append(this.fieldViewDict[fieldModel.get().key].el);

                        if (this.options.showTooltips && fieldModel.get().label !== null && fieldModel.get().label !== "") {
                            $(this.el).find("#" + fieldModel.get().key).kendoTooltip({
                                position: "top",
                                show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                            });
                        }
                    }

                    if (this.options.showButtons && this.options.showTooltips) {
                        this.$el.find(".a-btn-submit").kendoTooltip({
                            position: "top",
                            show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
                        });
                    }
                    return this;
                }
            }
        }
    }
}