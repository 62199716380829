namespace AppKitchen {

    export module RxEx {

        export class Subject<T> extends Rx.Subject<ChangedArgs<T>> {}

        export interface Observable<T> extends Rx.IObservable<ChangedArgs<T>> {}

        export interface ChangedArgs<T> {
            data: T;
            sender: any;
        }

        export class SubjectWithPrevious<T> extends Rx.Subject<ChangedArgsWithPrevious<T>> {}

        export interface ObservableWithPrevious<T> extends Rx.IObservable<ChangedArgsWithPrevious<T>> {}

        export interface ChangedArgsWithPrevious<T> extends ChangedArgs<T> {
            previous?: T;
        }
    }
}
