namespace AppKitchen {

    export module Controls {

        export module Forms {
            
            // ReSharper disable once InconsistentNaming
            export interface TextPool {
                values: any[];        // values pool (as string array or Value/Text pairs)
                valueField?: string;  // Value member name (if using Value/Text pairs)
                textField?: string;   // Text member name (if using Value/Text pairs)
                restrict?: boolean;   // restrict on given pool (dropdown) or free text allowed (combobox)
                multi?: boolean;      // multi values selection (in restricted mode)
                separator?: string;   // separator for multi values: default ','
                search?: boolean;     // activate search box
            }

            // ReSharper disable once InconsistentNaming
            export interface TextSuggestion {
                values: string[];     // suggested values
                multi?: boolean;      // resuggest after separator
                separator?: string;   // separator for resuggestion: default ', '
            }

            // ReSharper disable once InconsistentNaming
            export interface SwitchTaps {
                onValue: string;
                onLabel: string;
                offValue: string;
                offLabel: string;
                isLeftOn?: boolean;
            }

            // ReSharper disable once InconsistentNaming
            export interface FieldOptions extends AppKitchen.ModelBaseAttributes {
                description?: string;     // field description 
                default?: any;            // default value
                placeholder?: string;     // input placeholder
                order?: number;           // field tabulation order (displayed number in TablularFormView)
                fieldGroup?: string;      // field group (in TabularFormView)
                disabled?: boolean;       // input field in disabled state
                hidden?: boolean;         // hide field (in TabularFormView)
                readOnly?: boolean;       // no input field, display mode
                password?: boolean;       // password input
                showPasswordComplexity?: PasswordStrengthOptions;
                pool?: TextPool;          // values pool (for combobox or dropdown)
                suggest?: TextSuggestion; // suggestions
                mandatory?: boolean;      // field is required
                pattern?: string;         // validation regex pattern
                format?: string;          // display/input format (for DATE/TIME and NUMBER)
                textMode?: boolean;       // forces returned TIME values to be string
                allow24?: boolean;        // allows 24:00 (and disallow 00:00) in TIME fields
                multiline?: number;       // multiline TEXT (number of lines)
                min?: number;             // min value for NUMBER, DATE/TIME (milliseconds), TEXT (length)
                max?: number;             // max value for NUMBER, DATE/TIME (milliseconds), TEXT (length)
                precision?: number;       // input precision for NUMBER
                url?: string;             // render text representation as URL (in view mode)
                kendoOptions?: any;       // custom options for kendo UI components
                switchTaps?: SwitchTaps;  // tap definition for switch
                hideCheckboxLabel?: boolean; // hide the checkbox label
            }

            export enum FieldType {
                Text,
                Number,
                Month,
                Date,
                Time,
                DateTime,
                Checkbox,
                Switch,
                Year
            }

            export module FieldPatterns {
                // ReSharper disable InconsistentNaming
                export var OnlyDigits = "\\d*";
                export var Alphanumeric = "[a-zA-Z0-9]*";
                export var Phone = "\\+?\\d*";
                export var Email = "[-a-z0-9~!$%^&*_=+}{\\'?]+(\\.[-a-z0-9~!$%^&*_=+}{\\'?]+)*@([a-z0-9_][-a-z0-9_]*(\\.[-a-z0-9_]+)*\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|energy|[a-z][a-z])|([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}))(:[0-9]{1,5})?";
                export var UserId = "[a-zA-Z0-9@\\-_\\.]*";
                // ReSharper restore InconsistentNaming
            }

            // ReSharper disable once InconsistentNaming
            export interface FieldAttributes extends FieldOptions {
                key?: string;
                label?: string;
                type?: FieldType;
                value?: any;
                error?: string;
                displayValue?: string;
            }

            export class FieldModel extends AppKitchen.ModelBase<FieldAttributes> {

                constructor(key: string, type: FieldType, label: string, options?: FieldOptions) {
                    super({
                        key: key,
                        label: label,
                        type: type,
                        value: undefined,
                        error: undefined,
                        displayValue: ""
                    });

                    // options
                    this.set(FieldModel.getDefaultOptions(type, options));
                    this.set(options);

                    this.bind("change:value change:format change:pool", () => this.updateDisplayValue());

                    // default value
                    if (this.get().default != null) {
                        this.set({ value: this.get().default });
                    }
                }

                static getDefaultOptions(type: FieldType, customOptions: FieldOptions): FieldOptions {
                    return {
                        description: undefined,
                        default: undefined,
                        placeholder: undefined,
                        order: undefined,
                        fieldGroup: undefined,
                        disabled: false,
                        hidden: false,
                        readOnly: false,
                        mandatory: false,
                        pool: undefined,
                        pattern: undefined,
                        format: this.getDefaultFormat(type, customOptions),
                        textMode: false,
                        allow24: false,
                        suggest: undefined,
                        multiline: undefined,
                        min: undefined,
                        max: undefined,
                        precision: this.getDefaultPrecision(customOptions),
                        password: false,
                        showPasswordComplexity: undefined,
                        url: undefined,
                        kendoOptions: undefined
                    };
                }

                static getDefaultFormat(type: FieldType, customOptions: FieldOptions): string {
                    switch (type) {
                        case FieldType.Text:
                            return undefined;
                        case FieldType.Number:
                            return this.getDefaultNumberFormat(customOptions);
                        case FieldType.Date:
                            return kendo.culture().calendar.patterns.d;
                        case FieldType.Time:
                            return kendo.culture().calendar.patterns.t;
                        case FieldType.DateTime:
                            return kendo.culture().calendar.patterns.g;
                        case FieldType.Month:
                            return kendo.culture().calendar.patterns.y;
                        case FieldType.Year:
                            return 'yyyy';
                    }
                    return undefined;
                }

                static getDefaultNumberFormat(customOptions: FieldOptions): string {
                    if (customOptions && customOptions.precision != null) {
                        return ",#." + Array(customOptions.precision + 1).join("#");
                    }

                    return "n";
                }

                static getDefaultPrecision(customOptions: FieldOptions): number {
                    var format = customOptions ? customOptions.format : undefined;
                    if (format) {
                        // match standard formats: e.g. n4, c2, p2, e0
                        if (/^[ncpe]\d+$/g.test(format)) {
                            var precision = parseInt(format.substr(1));
                            if (precision !== NaN) {
                                return precision;
                            }
                        }

                        // match custom formats: e.g. #.####, 0.00, 0.000###
                        if (/[#0]/g.test(format)) {
                            var customFormatRegex = /[#0]\.([#0]*)/g;
                            var result = customFormatRegex.exec(format);
                            if (result && result.length > 1) {
                                return result[1].length;
                            } else {
                                return 0;
                            }
                        }
                    }

                    return undefined;
                }

                updateDisplayValue() {
                    var value = this.get().value;
                    var displayValue = "";
                    if (value != null) {
                        switch (this.get().type) {
                            case FieldType.Text:
                                displayValue = this.getTextFieldDisplayValue();
                                break;
                            case FieldType.Number:
                                displayValue = kendo.toString(value, this.get().format);
                                break;
                            case FieldType.Year:
                            case FieldType.Month:
                            case FieldType.Date:
                            case FieldType.DateTime:
                            case FieldType.Time:
                                let d = new Date(value);
                                displayValue = Object.prototype.toString.call(d) === '[object Date]'
                                    ? kendo.toString(d, this.get().format)
                                    : value;
                                break;
                            case FieldType.Checkbox:
                                displayValue = value ? AppKitchen.Strings.Yes : AppKitchen.Strings.No;
                                break;
                            case FieldType.Switch:
                                displayValue = this.getSwitchTapDisplayValue();
                                break;
                        }
                    }
                    this.set({ displayValue: (displayValue + "").escapeHTML() });
                }

                private getSwitchTapDisplayValue(): string {
                    var value = this.get().value;
                    var switchTaps = this.get().switchTaps;
                    if (!switchTaps) {
                        return value ? AppKitchen.Strings.Yes : AppKitchen.Strings.No;
                    }
                    if (value === switchTaps.onValue) {
                        return switchTaps.onLabel;
                    } else {
                        return switchTaps.offLabel;
                    }
                }

                private getTextFieldDisplayValue(): string {
                    var value = this.get().value;
                    var pool = this.get().pool;
                    if (pool && pool.values && pool.values.length > 0) {
                        if (pool.multi) {
                            var values: string[] = value ? value.split(pool.separator || ",") : [];
                            var displayValue = values.length > 0 ? this.getDisplayValueFromPool(pool, values[0]) : "";
                            for (let i = 1; i < values.length; i++) {
                                displayValue += ", " + this.getDisplayValueFromPool(pool, values[i]);
                            }
                            return displayValue;
                        } else {
                            return this.getDisplayValueFromPool(pool, value);
                        }
                    }

                    return value || "";
                }

                private getDisplayValueFromPool(pool: TextPool, value: any): string {
                    if (pool.textField && pool.valueField) {
                        for (let i = 0; i < pool.values.length; i++) {
                            if (pool.values[i][pool.valueField] === value) {
                                return pool.values[i][pool.textField];
                            }
                        }
                    }
                    return value;
                }
            }

            export class FieldCollection extends AppKitchen.CollectionBase<FieldModel> {

            }
            
            // ReSharper disable once InconsistentNaming
            export interface FieldViewBaseOptions extends AppKitchen.ViewBaseOptions {
                editable?: boolean;
                editOnDemand?: boolean;
            }

            export abstract class FieldViewBase extends AppKitchen.ViewBase<FieldModel> {
                options: FieldViewBaseOptions;

                inputTemplate: (data: any) => string;
                viewContainerSelector: string;
                inputContainerSelector: string;
                editMode: boolean;
                postRender: (fieldView: FieldViewBase) => void;

                constructor(model: FieldModel, options?: FieldViewBaseOptions) {
                    super(model, undefined, AppKitchen.OptionsHelper.merge(options,
                    {
                        editable: false,
                        editOnDemand: false
                    }));

                    this.tagName = 'div';

                    this.setTemplate(Templates.FormField);
                    this.viewContainerSelector = ".a-form-field-view";
                    this.inputContainerSelector = ".a-form-field-input";

                    this.model.bind("change", this.render, this);
                }

                editModeAllowed(): boolean {
                    return this.options.editable && !this.model.get().readOnly;
                }

                editAllowed(): boolean {
                    return this.options.editable && !this.model.get().readOnly && !this.model.get().disabled;
                }

                render() {
                    kendo.destroy(this.el);

                    this.renderTemplate({
                        displayValue: (this.model.get().displayValue + "").nl2br(),
                        url: this.model.get().url ? this.model.get().url.trim() : "",
                        editOnDemand: this.editAllowed() && this.options.editOnDemand
                    });

                    if (this.model.get().disabled) {
                        this.$el.addClass("disabled");
                    } else {
                        this.$el.removeClass("disabled");
                    }

                    if (this.editModeAllowed()) {
                        this.$el.find(this.inputContainerSelector).html(this.inputTemplate(this.model.toJSON()));
                    } else {
                        this.$el.find(this.inputContainerSelector).remove();
                    }
                    if (this.model.get().hidden) {
                        this.$el.hide();
                    }
                    return this;
                }

                flash() {
                    this.$el.find(this.inputContainerSelector).addClass("a-field-flash");
                    window.setTimeout(() => {
                        this.$el.find(this.inputContainerSelector).removeClass("a-field-flash");
                    }, 1000);
                }

                protected startEdit() {
                    this.$el.find(this.viewContainerSelector).css("display", "none");
                    this.$el.find(this.inputContainerSelector).css("display", "block");
                    this.editMode = true;
                }

                protected endEdit() {
                    this.$el.find(this.inputContainerSelector).css("display", "none");
                    this.$el.find(this.viewContainerSelector).css("display", "block");
                    this.editMode = false;
                }

                abstract tryUpdate(): boolean;
                abstract preview(): boolean;
                abstract backToEdit();
            }

            // ReSharper disable once InconsistentNaming
            export interface ValidatedValue {
                valid: boolean;
                value?: any;
                message?: string;
            }

            export abstract class TextInputFieldViewBase extends FieldViewBase {
                rendered: boolean;
                postRender: (fieldView: TextInputFieldViewBase) => void;

                constructor(model: FieldModel, options?: FieldViewBaseOptions) {
                    super(model, options);

                    this.inputTemplate = _.template(Templates.FormFieldTextInput);
                    this.rendered = false;
                }

                render() {
                    super.render();

                    this.clearMessage();

                    if (this.editModeAllowed()) {
                        if (this.model.get().mandatory && !this.model.get().value) {
                            this.showMessage(AppKitchen.Strings.Form_Required, "mandatory");
                        }

                        if (!this.options.editOnDemand) {
                            this.startEdit();
                            if (this.rendered && this.editAllowed()) {
                                this.validate(this.getInputValue());
                            }
                        } else {
                            this.endEdit();
                        }

                        if (this.model.get().error) {
                            this.showMessage(this.model.get().error, "invalid");
                        }

                        if (this.editAllowed()) {
                            this.delegateInteractionEvents();
                        }

                        if (this.postRender) {
                            this.postRender(this);
                        }
                    } else {
                        this.endEdit();
                    }

                    this.rendered = true;
                    return this;
                }

                startEdit() {
                    super.startEdit();
                    this.applyInputOptions(this.inputContainerSelector + " input");
                }

                startEditAndFocus() {
                    this.startEdit();
                    this.$el.find(this.inputContainerSelector + " input, " + this.inputContainerSelector + " textarea").first().focus();
                }

                protected delegateInteractionEvents() {
                    this.$el.find(".editor").keypress((e) => {
                        if (e.which === 13) {
                            e.stopPropagation();
                            this.updateOnEnter();
                        }
                    });

                    this.$el.find(".editor").keydown((e) => {
                        if (e.which === 27) {
                            e.stopPropagation();
                            this.revertOnEscape();
                        }
                    });

                    this.$el.find("input.editor, textarea.editor").blur(() => {
                        this.updateOnBlur();
                    });

                    if (this.options.editOnDemand) {
                        this.$el.find(this.viewContainerSelector).click(() => this.startEditAndFocus());
                    }
                }

                // type dependent input options (e.g. date/time pickers...), implemented in derivates
                protected abstract applyInputOptions(inputSelector: string): void;

                protected updateOnEnter() {
                    if (this.model.get().multiline)
                        return;

                    if (this.options.editOnDemand) {
                        this.updateAndClose();
                    } else {
                        this.updateAndRefocus();
                    }
                    this.trigger("keypress-enter");
                }

                protected updateOnBlur() {
                    if (this.options.editOnDemand) {
                        this.updateAndClose();
                    } else {
                        this.update();
                    }
                }

                protected revertOnEscape() {
                    if (this.model.get().multiline)
                        return;

                    this.$el.find("input").val(this.model.get().displayValue);
                    this.render();

                    this.updateAndRefocus();
                }

                tryUpdate(): boolean {
                    if (this.editMode) {
                        return this.update();
                    }
                    return true;
                }

                preview(): boolean {
                    if (this.editMode) {
                        return this.updateAndClose();
                    }
                    return true;
                }

                backToEdit() {
                    this.render();
                }

                protected updateAndClose() {
                    if (this.update()) {
                        this.endEdit();
                        return true;
                    }
                    return false;
                }

                protected updateAndRefocus() {
                    this.update();
                    this.$el.find("input").focus();
                }
                
                // tries to update the model input is valid
                // returns false if validation failed
                protected update(): boolean {
                    if (this.editAllowed()) {
                        var textValue = this.getInputValue();
                        var validatedValue = this.validate(textValue);

                        // value is valid => save value
                        if (validatedValue !== false) {
                            this.model.set({
                                value: validatedValue,
                                error: this.model.get().value === validatedValue ? this.model.get().error : undefined
                            });
                            if (validatedValue == null) {
                                this.model.updateDisplayValue();
                            }
                            return true;
                        }

                        // value is invalid => set value to undefined
                        if (!this.options.editOnDemand) {
                            if (textValue) {
                                this.model.set({ value: undefined, displayValue: textValue });
                            } else {
                                this.model.set({
                                    value: undefined,
                                    error: undefined,
                                    displayValue: ""
                                });
                            }
                        }

                        return false;
                    }
                    return true;
                }

                protected getInputValue(): string {
                    return this.$el.find("input").val().toString();
                }

                // validates the string input (required, format) and 
                // returns the value in the corresponding type (e.g. Date)
                protected validate(value: string): any {
                    var valid = true;
                    this.clearMessage();

                    if (this.model.get().mandatory) {
                        if (!value) {
                            valid = false;
                            this.showMessage(AppKitchen.Strings.Form_ErrorMessage_Required, "warning");
                        }
                    }

                    if (this.model.get().error) {
                        this.showMessage(this.model.get().error, "invalid");
                    }

                    var validatedValue = this.validateFormat(value);
                    if (!validatedValue.valid) {
                        valid = false;
                        this.model.set({ error: validatedValue.message || AppKitchen.Strings.Form_ErrorMessage_Format });
                    }

                    if (valid) {
                        return validatedValue.value;
                    }

                    return false;
                }

                clearMessage() {
                    this.$el.removeClass("mandatory");
                    this.$el.removeClass("warning");
                    this.$el.removeClass("invalid");
                    this.$el.find(".a-input-error-message .a-message").html("");
                }

                showMessage(message: string, errorClass: "mandatory" | "warning" | "invalid") {
                    this.clearMessage();
                    this.$el.addClass(errorClass);
                    this.$el.find(".a-input-error-message .a-message").html(message);
                }

                // type dependent validation, implemented in derivates
                protected abstract validateFormat(value: string): ValidatedValue;

                protected validateMin(value: number) {
                    var min = this.model.get().min;
                    if (min !== undefined && value < min)
                        return false;
                    return true;
                }

                protected validateMax(value: number) {
                    var max = this.model.get().max;
                    if (max !== undefined && value > max)
                        return false;
                    return true;
                }
            }

            export class TextFieldView extends TextInputFieldViewBase {

                inputWidget: any;

                protected applyInputOptions(inputSelector: string): void {
                    if (this.model.get().type !== FieldType.Text) {
                        throw "incompatible field type!";
                    }
                    
                    var currentValue: string = this.model.get().value;
                    var pool = this.model.get().pool;
                    var multiline = this.model.get().multiline;
                    var suggest = this.model.get().suggest;

                    if (this.model.get().password && this.model.get().showPasswordComplexity !== undefined) {
                        this.$el.find(inputSelector).password(this.model.get().showPasswordComplexity);
                    }

                    if (pool) {
                        if (pool.multi) {
                            if (pool.restrict) {
                                this.inputWidget = this.$el.find(inputSelector).kendoMultiSelect({
                                    ...this.model.get().kendoOptions,
                                    filter: null,
                                    dataTextField: pool.textField,
                                    dataValueField: pool.valueField,
                                    dataSource: pool.values,
                                    value: currentValue ? currentValue.split(pool.separator || ",") : undefined,
                                    placeholder: this.model.get().placeholder,
                                    change: () => this.updateAndRefocus()
                                }).data("kendoMultiSelect");
                                // allow only TAB, ARROW UP and ARROW DOWN
                                this.inputWidget.input.on("keydown", e => { if ([9, 38, 40].indexOf(e.keyCode) === -1) e.preventDefault(); });
                                this.inputWidget.wrapper.find("input").css("color", "transparent");
                                this.inputWidget.wrapper.find("input").css("cursor", "default");
                            } else {
                                throw "non restricted multi select not implemented";
                            }
                        } else {
                            if (pool.restrict) {
                                var safariClosedBeforeValueChangedFix: boolean = false;
                                var search = pool.search != null ? pool.search : pool.values && pool.values.length > 20;
                                this.inputWidget = this.$el.find(inputSelector).kendoDropDownList({
                                    ...this.model.get().kendoOptions,
                                    filter: search? "contains" : undefined,
                                    dataTextField: pool.textField,
                                    dataValueField: pool.valueField,
                                    dataSource: pool.values,
                                    value: currentValue,
                                    open: () => {
                                    },
                                    close: () => {
                                        safariClosedBeforeValueChangedFix = true;
                                        this.updateAndRefocus();
                                    },
                                    change: () => {
                                        if (safariClosedBeforeValueChangedFix) {
                                            this.updateAndRefocus();
                                            safariClosedBeforeValueChangedFix = false;
                                        }
                                    }
                                }).data("kendoDropDownList");
                                this.inputWidget.wrapper.on("keydown", e => this.widgetKeyDown(e));
                                if (this.options.editOnDemand) {
                                    this.openInputPopup();
                                }
                            } else {
                                this.inputWidget = this.$el.find(inputSelector).kendoComboBox({
                                    ...this.model.get().kendoOptions,
                                    dataTextField: pool.textField,
                                    dataValueField: pool.valueField,
                                    dataSource: pool.values,
                                    value: currentValue,
                                    placeholder: this.model.get().placeholder
                                }).data("kendoComboBox");
                                this.inputWidget.input.keydown("keydown", e => this.widgetKeyDown(e));
                            }
                        }
                    } else if (suggest && suggest.values && suggest.values.length > 0) {
                        this.inputWidget = this.$el.find(inputSelector).kendoAutoComplete({
                            ...this.model.get().kendoOptions,
                            dataSource: suggest.values,
                            separator: suggest.multi ? suggest.separator || ", " : undefined
                        }).data("kendoAutoComplete");
                    } else if (multiline) {
                        var textAreaInput = AppKitchen.UIHelper.renderTemplate(AppKitchen.Templates.FormFieldTextAreaInput, this.model.toJSON());
                        this.$el.find(inputSelector).replaceWith(textAreaInput);
                        this.$el.find("textarea.editor").blur(() => {
                            this.updateOnBlur();
                        });
                    }
                }

                private widgetKeyDown(e) {
                    if (e.keyCode === 40 || e.keyCode === 38) {
                        this.openInputPopup();
                    }
                }

                private openInputPopup() {
                    if (!this.inputWidget.ul.is(":visible")) {
                        this.inputWidget.open();
                    }
                }

                protected updateAndRefocus() {
                    super.updateAndRefocus();
                    if (this.inputWidget) {
                        this.inputWidget.wrapper.focus();
                    }
                }

                protected getInputValue(): string {
                    var value: any;

                    if (this.inputWidget) {
                        value = this.inputWidget.value();
                        if (value) {
                            if (value instanceof Array) {
                                var separator = (this.model.get().pool ? this.model.get().pool.separator : ",") || ",";
                                return value.join(separator);
                            }

                            // remove last separator if in multi suggest mode
                            if (this.model.get().suggest && this.model.get().suggest.multi) {
                                var sep = this.model.get().suggest.separator || ", ";
                                if (value.substr(value.length - sep.length, sep.length) === sep) {
                                    value = value.substr(0, value.length - sep.length);
                                }
                            }

                            return value.toString();
                        }
                        return undefined;
                    }

                    value = this.$el.find("input, textarea").val() || "";

                    return value;
                }

                protected validateFormat(value: string): ValidatedValue {

                    if (this.model.get().type !== FieldType.Text) {
                        throw "incompatible field type!";
                    }

                    if (!value) value = "";

                    // text validation
                    var pattern = this.model.get().pattern;
                    if (pattern) {
                        var regex = new RegExp("^" + pattern + "$");
                        if (value && !regex.test(value)) {
                            return {
                                valid: false,
                                message: AppKitchen.Strings.Form_ErrorMessage_PatternMismatch
                            };
                        }
                    }

                    if (!this.validateMin(value.length))
                        return {
                            valid: false,
                            message: AppKitchen.Strings.Form_ErrorMessage_StringTooShort
                        };

                    if (!this.validateMax(value.length))
                        return {
                            valid: false,
                            message: AppKitchen.Strings.Form_ErrorMessage_StringTooLong
                        };

                    return {
                        valid: true,
                        value: value
                    };
                }
            }

            export class NumberFieldView extends TextInputFieldViewBase {
                numericbox: kendo.ui.NumericTextBox;

                protected applyInputOptions(inputSelector: string): void {
                    if (this.model.get().type !== FieldType.Number) {
                        throw "incompatible field type!";
                    }

                    this.numericbox = this.$el.find(inputSelector).kendoNumericTextBox({
                        ...this.model.get().kendoOptions,
                        value: this.model.get().value,
                        spinners: false,
                        format: this.model.get().format,
                        step: 0,
                        decimals: this.model.get().precision,
                        min: this.model.get().min,
                        max: this.model.get().max
                    }).data("kendoNumericTextBox");
                }

                protected validateFormat(value: string): ValidatedValue {

                    if (this.model.get().type !== FieldType.Number) {
                        throw "incompatible field type!";
                    }

                    // ignore string and get number value from numeric textbox
                    var newValue = this.numericbox.value();

                    if (newValue == null || newValue === NaN)
                        return {
                            valid: true,
                            value: null
                        };

                    if (!this.validateMin(newValue))
                        return {
                            valid: false,
                            message: AppKitchen.Strings.Form_ErrorMessage_NumberTooSmall
                        };

                    if (!this.validateMax(newValue))
                        return {
                            valid: false,
                            message: AppKitchen.Strings.Form_ErrorMessage_NumberTooBig
                        };

                    return {
                        valid: true,
                        value: newValue
                    };
                }

                protected delegateInteractionEvents() {
                    this.$el.find(".editor").keypress((e) => {
                        if (e.which === 13) {
                            e.stopPropagation();
                            this.updateOnEnter();
                        }
                    });

                    this.$el.find(".editor").keydown((e) => {
                        if (e.which === 27) {
                            e.stopPropagation();
                            this.revertOnEscape();
                        }
                    });

                    if (this.options.editOnDemand) {
                        this.$el.find(this.viewContainerSelector).click(() => this.startEditAndFocus());
                        this.$el.find("input.editor:first-child").blur(() => this.updateOnBlur());
                    } else {
                        this.$el.find("input.editor:nth-child(2)").blur(() => this.updateOnBlur());
                    }
                }
            }

            export class DateTimeFieldView extends TextInputFieldViewBase {
                picker: any;

                constructor(model: FieldModel, options?: FieldViewBaseOptions) {
                    super(model, options);
                }

                protected applyInputOptions(inputSelector: string): void {
                    switch (this.model.get().type) {
                        case FieldType.Year:
                            this.picker = this.$el.find(inputSelector).kendoDatePicker({
                                ...this.model.get().kendoOptions,
                                format: "yyyy",
                                start: "decade",
                                depth: "decade",
                                value: this.model.get().value,
                                min: this.model.get().min != null ? new Date(this.model.get().min) : undefined,
                                max: this.model.get().max != null ? new Date(this.model.get().max) : undefined,
                                change: () => this.updateAndRefocus()
                            }).data("kendoDatePicker");
                            break;
                        case FieldType.Month:
                            this.picker = this.$el.find(inputSelector).kendoDatePicker({
                                ...this.model.get().kendoOptions,
                                format: "MMMM yyyy",
                                start: "year",
                                depth: "year",
                                value: this.model.get().value,
                                min: this.model.get().min != null ? new Date(this.model.get().min) : undefined,
                                max: this.model.get().max != null ? new Date(this.model.get().max) : undefined,
                                change: () => this.updateAndRefocus()
                            }).data("kendoDatePicker");
                            break;
                        case FieldType.Date:
                            this.picker = this.$el.find(inputSelector).kendoDatePicker({
                                ...this.model.get().kendoOptions,
                                format: this.model.get().format,
                                value: this.model.get().value,
                                min: this.model.get().min != null ? new Date(this.model.get().min) : undefined,
                                max: this.model.get().max != null ? new Date(this.model.get().max) : undefined,
                                change: () => this.updateAndRefocus()
                            }).data("kendoDatePicker");
                            break;
                        case FieldType.Time:
                            this.picker = this.$el.find(inputSelector).kendoTimePicker({
                                ...this.model.get().kendoOptions,
                                format: this.model.get().format,
                                value: this.model.get().value,
                                min: this.model.get().min != null ? new Date(this.model.get().min) : undefined,
                                max: this.model.get().max != null ? new Date(this.model.get().max) : undefined,
                                change: () => this.updateAndRefocus()
                            }).data("kendoTimePicker");
                            break;
                        case FieldType.DateTime:
                            this.picker = this.$el.find(inputSelector).kendoDateTimePicker({
                                ...this.model.get().kendoOptions,
                                format: this.model.get().format,
                                value: this.model.get().value,
                                min: this.model.get().min != null ? new Date(this.model.get().min) : undefined,
                                max: this.model.get().max != null ? new Date(this.model.get().max) : undefined,
                                change: () => this.updateAndRefocus()
                            }).data("kendoDateTimePicker");
                            break;
                        default:
                            throw "incompatible field type!";
                    }

                    // picker keydown action
                    this.picker.wrapper.find("input").on("keydown", e => this.pickerKeyDown(e));

                    // update format in model
                    if (this.picker) {
                        this.model.set({ format: this.picker.options.format });
                    }
                }

                private pickerKeyDown(e) {
                    if (e.keyCode === 40) {
                        this.openPicker();
                    }
                }

                private openPicker() {
                    if (!this.picker.wrapper.find(".k-popup").is(":visible")) {
                        this.picker.open();
                    }
                }

                protected validateFormat(value: string): ValidatedValue {
                    var type = this.model.get().type;
                    if (type !== FieldType.Month && 
                        type !== FieldType.Date && 
                        type !== FieldType.Time && 
                        type !== FieldType.DateTime && 
                        type !== FieldType.Year) {
                        throw "incompatible field type!";
                    }

                    if (!value)
                        return {
                            valid: true,
                            value: null
                        }

                    var textMode = this.model.get().textMode || false;
                    var allow24 = this.model.get().allow24 || false;
                    var date = kendo.parseDate(value, this.model.get().format);
                    var dateAsNumber = 0;

                    // 24:00
                    if (textMode && allow24 && type === FieldType.Time && this.model.get().format === "HH:mm") {
                        if (value === "24:00") {
                            dateAsNumber = 24 * 3600000;
                        }
                        if (value === "00:00") {
                            return {
                                valid: false,
                                message: AppKitchen.Strings.Form_ErrorMessage_TimeInvalid 
                            };
                        }
                    } else {
                        if (!date || !date.valueOf()) {
                            return {
                                valid: false,
                                message: this.getErrorMessage(type)
                            };
                        }
                        dateAsNumber = this.date2Number(date);
                    }

                    if (!this.validateMin(dateAsNumber))
                        return {
                            valid: false,
                            // TODO: sinnvollere Fehlermeldung (datum zu klein) 
                            message: this.getErrorMessage(type)
                        };

                    if (!this.validateMax(dateAsNumber))
                        return {
                            valid: false,
                            // TODO: sinnvollere Fehlermeldung (datum zu groß)
                            message: this.getErrorMessage(type)
                        };

                    return {
                        valid: true,
                        value: textMode? value : date
                    }
                }

                private getErrorMessage(fieldType: FieldType): string {
                    switch (fieldType) {
                        case FieldType.Time:
                            return AppKitchen.Strings.Form_ErrorMessage_TimeInvalid;
                        case FieldType.DateTime:
                            return AppKitchen.Strings.Form_ErrorMessage_DateTimeInvalid;
                        case FieldType.Year:
                            return AppKitchen.Strings.Form_ErrorMessage_YearInvalid;
                        default:
                            return AppKitchen.Strings.Form_ErrorMessage_DateInvalid;
                    }
                }

                private date2Number(date: Date): number {
                    switch (this.model.get().type) {
                        case FieldType.Time:
                            return date.valueOf() - date.onlyDate().valueOf();
                        case FieldType.Date:
                            return date.onlyDate().valueOf();
                        case FieldType.Month:
                            return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0).valueOf();
                        case FieldType.Year:
                            return new Date(date.getFullYear(), 1, 1, 0, 0, 0, 0).valueOf();
                        case FieldType.DateTime:
                            return date.valueOf();
                    }

                    throw "invalid Field type";
                }
            }

            export class CheckboxFieldView extends FieldViewBase {

                constructor(model: FieldModel, options?: FieldViewBaseOptions) {
                    super(model, options);
                    if (model.get().hideCheckboxLabel) {
                        this.inputTemplate = _.template(Templates.FormFieldCheckbox);
                    } else {
                    this.inputTemplate = _.template(Templates.FieldCheckbox);
                    }
                }

                render() {
                    super.render();

                    if (this.editAllowed()) {
                        this.$el.find("input").on("change", () => this.updateAndRefocus());
                    }

                    if (this.editModeAllowed()) {
                        this.startEdit();
                    } else {
                        this.endEdit();
                    }

                    return this;
                }

                updateAndRefocus() {
                    var isChecked = this.$el.find('input[type="checkbox"]').prop("checked");
                    this.model.set({ value: isChecked });
                    this.$el.find('input[type="checkbox"]').focus();
                }

                tryUpdate(): boolean {
                    return true;
                }

                preview(): boolean {
                    this.endEdit();
                    return true;
                }

                backToEdit() {
                    if (this.editModeAllowed()) {
                        this.startEdit();
                    }
                }
            }

            export class SwitchFieldView extends FieldViewBase {

                containers: {
                    switchLabelOn: JQuery,
                    switchLabelOff: JQuery,
                }

                constructor(model: FieldModel, options?: FieldViewBaseOptions) {
                    super(model, options);
                    this.inputTemplate = _.template(Templates.FormFieldSwitch);
                }

                render() {
                    super.render();

                    let switchTaps = this.model.get().switchTaps;

                    let onTapIndex = switchTaps.isLeftOn ? 1 : 2;
                    let offTapIndex = switchTaps.isLeftOn ? 2 : 1;

                    this.containers = {
                        switchLabelOn: this.$el.find(".k-switch-tap:nth-child(" + onTapIndex + ")"),
                        switchLabelOff: this.$el.find(".k-switch-tap:nth-child(" + offTapIndex + ")")
                    }

                    this.containers.switchLabelOn.text(switchTaps.onLabel);
                    this.containers.switchLabelOff.text(switchTaps.offLabel);

                    if (this.editAllowed()) {
                        this.$el.find("input").on("change", () => this.updateAndRefocus());
                    }

                    if (this.editModeAllowed()) {
                        this.startEdit();
                    } else {
                        this.endEdit();
                    }

                    this.containers.switchLabelOn.removeClass("k-switch-tap-selected");
                    this.containers.switchLabelOff.addClass("k-switch-tap-selected");

                    return this;
                }

                updateAndRefocus() {
                    var isChecked = this.$el.find('input[type="checkbox"]').prop("checked");
                    this.model.set({ value: this.getValue(isChecked) });
                    this.$el.find('input[type="checkbox"]').focus();

                    if (isChecked) {
                        this.containers.switchLabelOn.addClass("k-switch-tap-selected");
                        this.containers.switchLabelOff.removeClass("k-switch-tap-selected");
                    } else {
                        this.containers.switchLabelOn.removeClass("k-switch-tap-selected");
                        this.containers.switchLabelOff.addClass("k-switch-tap-selected");
                    }
                }

                getValue(isChecked: boolean): any {
                    let switchTaps = this.model.get().switchTaps;
                    if (!switchTaps) {
                        return isChecked;
                    }
                    if (isChecked) {
                        return switchTaps.onValue;
                    } else {
                        return switchTaps.offValue;
                    }
                }

                tryUpdate(): boolean {
                    return true;
                }

                preview(): boolean {
                    this.endEdit();
                    return true;
                }

                backToEdit() {
                    this.startEdit();
                }
            }

            export class DescriptorToTextPoolConverter {
                static convert(descriptors: AppKitchen.Api.Models.Descriptor[]): TextPool {
                    return {
                        values: descriptors,
                        valueField: "Id",
                        textField: "Name"
                    };
                }
            }

        }
    }
}