namespace General {
    export module TsChart {

        export module Preloadtransformations {

            export class Templates {
                static preloadTransformationCombobox: string;
            }

            export function ComboboxTransformation(targetContainer, interactiveDefinition, transformationData, callback) {

                General.GeneralRenderer.renderFunc(Templates.preloadTransformationCombobox, null, targetContainer);

                var comboboxDomId = $(targetContainer).find(" .mp-combobox");
                var combobox = $(comboboxDomId);

                for (var i in transformationData.entries) {
                    if (!transformationData.entries.hasOwnProperty(i)) {
                        continue;
                    }

                    var entry = transformationData.entries[i];
                    combobox.append('<option value = "' + entry.id + '">' + entry.label + '</option>');
                }

                var cacheId = (targetContainer.selector || "workaround").replace(/ /g, "_");
                var selectedComboboxId = General.Services.Cache.get("CBox_" + cacheId);

                var chosenEntry = getTransformationDataEntry(transformationData, selectedComboboxId);

                if (!selectedComboboxId || !chosenEntry) {
                    selectedComboboxId = transformationData.entries[0].id;
                }
                combobox.val(selectedComboboxId);

                combobox.on('change', function () {
                    var selectedId = (<any>this).value;
                    var retInteractiveDefinition = getInteractiveDefinition(transformationData, interactiveDefinition, selectedId);
                    callback(retInteractiveDefinition);

                    General.Services.Cache.set("CBox_" + cacheId, selectedId);
                });

                var retDefinition = getInteractiveDefinition(transformationData, interactiveDefinition, selectedComboboxId);
                return retDefinition;
            }
    
            function getInteractiveDefinition (transformationData, interactiveDefinition, selectedComboboxId) {
                var chosenEntry = getTransformationDataEntry(transformationData, selectedComboboxId);
                var retDefinition = getFilterChartSeries(interactiveDefinition, chosenEntry);
                if (chosenEntry.titlereplacer) {
                    retDefinition.title = (<any>String).format(retDefinition.chartdefinition.title.text, chosenEntry.titlereplacer);
                }
                return retDefinition;
            }
    
            function getFilterChartSeries(interactiveDefinition, entry) {

                var relevantSeriesIds = entry.chartseriesids;

                var relevantChartSeries = [];
                for (var i in interactiveDefinition.chartseries) {
                    var chartSerie = interactiveDefinition.chartseries[i];
                    if ($.inArray(chartSerie.id, relevantSeriesIds) >= 0) {
                        relevantChartSeries.push(chartSerie);
                    }
                }

                var retDefinition = jQuery.extend(true, {}, interactiveDefinition);
                retDefinition.chartseries = relevantChartSeries;

                return retDefinition;

            }
    
            function getTransformationDataEntry(transformationData, id) {
                for (var i in transformationData.entries) {
                    var entry = transformationData.entries[i];
                    if (entry.id == id)
                        return entry;
                }
                return null;
            }
        }
    }
}

namespace Portal {
    export module Chart {
        export class PreloadTransformations {
            static ComboboxTransformation(targetContainer, interactiveDefinition, transformationData, callback) {
                return General.TsChart.Preloadtransformations.ComboboxTransformation(targetContainer, interactiveDefinition, transformationData, callback);
            }
        }
    }
}